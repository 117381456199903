/**
 * @file
 *
 * This file houses the Component that gets rendered for any invalid Routes
 */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Link, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { Nav } from '../components/Nav';
import { UserAvatar } from '../components/UserAvatar';
import { getPlatformURL } from '../utils/service';
import { API_ERROR_TYPES } from '../utils/constants';
import { useInitialLoadError } from '../hooks/useInitialLoadChecks';

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(10),
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function NoMatch() {
  useInitialLoadError();

  const classes = useStyles();

  const location = useLocation();

  const state = location.state || {};
  const { isFatal, errorDetails, errorType } = state;

  let title;
  let details = errorDetails ?? '';
  switch (errorType) {
    case API_ERROR_TYPES.UNAUTHORIZED:
      title = 'Unauthorized';
      details = errorDetails ?? 'You are not authorized to access this resource!';
      break;

    default:
      title = 'Something unexpected happen';
      details = 'Try again after sometime. Contact support if issue persists!';
  }

  return (
    <div>
      <Nav>
        <UserAvatar />
      </Nav>
      <div className={classes.content}>
        <Typography variant="h3" gutterBottom>
          {title ? title : <FormattedMessage id="LOST_Q" />}
        </Typography>
        {details && (
          <>
            <Typography variant="h6" gutterBottom>
              {details}
            </Typography>
            <br />
            <br />
          </>
        )}

        <Typography color="textSecondary" variant="body1">
          {isFatal ? (
            <>
              <Link variant="button" className={classes.bold} href={getPlatformURL()}>
                <FormattedMessage id="GO_BACK" />
              </Link>{' '}
              <FormattedMessage id="GO_BACK_SENTENCE" />
              <br />
              <br />
              <FormattedMessage id="ALSO_CONTACT" />{' '}
              <Link variant="button" className={classes.bold} href="mailto:support@integrtr.com">
                support@integrtr.com
              </Link>{' '}
              <FormattedMessage id="ALSO_CONTACT_FINISH" />
            </>
          ) : (
            <FormattedMessage id="GO_BACK_HOME" />
          )}
        </Typography>
      </div>
    </div>
  );
}
