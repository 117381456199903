/**
 * @file
 *
 * This component renders the Integration Panel of the right side drawer
 *
 * Contains options to change the integrations of the system dropped in the Editor
 */

import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import {
  NODE_MODEL_ATTRS,
  GROUP_NODES,
  UI_DIMENSIONS,
  LINK_MODEL_ATTRS,
  MODEL_LINKS,
} from '../../utils/constants';
import { EntitySytemSettingOptions } from './EntitySystemSettingOptions';
import { MapperTemplateConnections } from './MapperTemplateConnections';
import { MapperProjectConnections } from './MapperProjectConnections';
import { shapeNodes } from '../../utils/helpers';

const useStyles = makeStyles(theme => ({
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
  noOptions: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  titleFont: {
    fontSize: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(1.5),
  },
}));

export function IntegrationPanel(props) {
  const classes = useStyles();

  if (
    !props.selectedData ||
    (props.selectedData[NODE_MODEL_ATTRS.CATEGORY] !== GROUP_NODES.ENTITY_GROUP &&
      !props.selectedData[LINK_MODEL_ATTRS.KEY].startsWith('link'))
  ) {
    // TODO: This should not be rendered if the selectedData is not Entity Group, instead the tab should automatically change to a relevant tab
    return (
      <Grid container justifyContent="center" className={classes.noOptions}>
        <Typography className={classes.titleFont}>
          <FormattedMessage id="NO_ACTIONS_AVAILABLE" />
        </Typography>
      </Grid>
    );
  }

  if (
    props.selectedData[LINK_MODEL_ATTRS.KEY].startsWith('link') &&
    !shapeNodes.includes(
      props.diagram.findNodeForKey(props.selectedData[LINK_MODEL_ATTRS.FROM]).data[
        NODE_MODEL_ATTRS.CATEGORY
      ]
    ) &&
    !shapeNodes.includes(
      props.diagram.findNodeForKey(props.selectedData[LINK_MODEL_ATTRS.TO]).data[
        NODE_MODEL_ATTRS.CATEGORY
      ]
    )
  ) {
    if (
      props.selectedData[LINK_MODEL_ATTRS.CATEGORY] === MODEL_LINKS.MAPPER_TEMPLATE &&
      !props.selectedData[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID]
    ) {
      return (
        <Grid item className={classes.drawerBody}>
          <MapperTemplateConnections diagram={props.diagram} selectedData={props.selectedData} />
        </Grid>
      );
    }
    return (
      <Grid item className={classes.drawerBody}>
        <MapperProjectConnections diagram={props.diagram} selectedData={props.selectedData} />
      </Grid>
    );
  }

  if (props.selectedData[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP) {
    return (
      <Grid item className={classes.drawerBody}>
        <EntitySytemSettingOptions selectedData={props.selectedData} onChange={props.onChange} />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" className={classes.noOptions}>
      <Typography className={classes.titleFont}>
        <FormattedMessage id="NO_ACTIONS_AVAILABLE" />
      </Typography>
    </Grid>
  );
}
