/**
 * @file
 *
 * Contains templates related hooks which can fetch templates from the API.
 */

import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { digitalTransformerApiBase } from '../utils/service';

/**
 * Get the templates.
 */
export function useTemplates({ enabled }) {
  const { data, ...rest } = useQuery({
    queryKey: `${digitalTransformerApiBase}/template`,
    enabled,
  });

  const templates = useMemo(
    () =>
      (data ?? []).map(template => ({
        id: template.sheet_template_id,
        name: template.name,
        type: template.template_type,
        description: template.description,
        sheetData: template.sheet_template_data,
        createdAt: template.created_by,
      })),
    [data]
  );

  return {
    templates,
    ...rest,
  };
}
