/**
 * @file
 *
 * This component renders the Size (Height, Width, Diameter) attribute edit form element in the Selection Panel
 */

import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';

import { InnerTextField } from './InnerTextField';

const getSizeValidationSchema = (min, max, label) =>
  yup
    .number()
    .integer('Must be an integer')
    .moreThan(min - 1, `Must be more than or equals to ${min}`)
    .lessThan(max + 1, `Must be less than or equals to ${max}`)
    .required('Must be a number')
    .label(label)
    .typeError('Must be a number');

export function SizeEdit(props) {
  const [inputValue, updateInputValue] = useState(props.value);
  const [error, updateError] = useState(null);
  const inputValueCache = useRef(props.value);

  useEffect(() => {
    if (inputValueCache.current !== props.value) {
      updateInputValue(props.value);
    }
  }, [props.value]);

  const handleInputChange = async event => {
    let value = event.target.value;

    let validationResult = null;
    try {
      const validationSchema = getSizeValidationSchema(props.min, props.max, props.label);

      if (!value || isNaN(value)) {
        value = 0;
      }

      value = await validationSchema.validate(value);
      updateError(null);

      props.onChange({ value, attr: props.attr });
    } catch (validationError) {
      validationResult = validationError.errors[0];
      updateError(validationResult);
    } finally {
      updateInputValue(value);
      inputValueCache.current = value;
    }
  };

  return (
    <>
      <InnerTextField
        width={props.width}
        attr={props.attr}
        value={parseInt(inputValue)}
        onChange={handleInputChange}
        error={error}
      />
    </>
  );
}
