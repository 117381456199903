/**
 * @file
 *
 * This file exports all the ENV variables
 */
import Cookies from 'js-cookie';

const env = process.env.NODE_ENV;

const defaults = {
  cdnBaseURL: 'https://cdn.integrtr.com',
};

export const config = {
  appName: 'digital-transformer',
  appCode: 'digital_transformer',

  env,
  gojsKey: process.env.REACT_APP_GOJS_KEY,
  apiBaseURL: process.env.REACT_APP_API_BASE_URL,
  cdnBaseURL: process.env.REACT_APP_CDN_BASE_URL || defaults.cdnBaseURL,
  tenantId:
    env !== 'production'
      ? process.env.REACT_APP_DEV_TENANT_ID
      : Cookies.get('integrtrSelectedTenantID'),
  authToken: env !== 'production' ? process.env.REACT_APP_DEV_AUTH_TOKEN : Cookies.get('token'),
  localPlatformUrl: process.env.REACT_APP_DEV_PLATFORM_URL,
  i18nEnabled: process.env.REACT_APP_I18N_ON,
  supportEmail: 'support@integrtr.com',
};
