/**
 * @file
 *
 * This component renders the Editor Menu
 *
 */

import React from 'react';
import {
  AppBar,
  Grid,
  IconButton,
  Typography,
  Button,
  Tooltip,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs';
import { IoMdMore, IoIosArrowBack, IoMdSave, IoMdDownload } from 'react-icons/io';
import { RiBarChartBoxLine, RiEditLine } from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';
import { omit, values, noop } from 'lodash-es';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';

import { DropDown } from './DropDown';
import { SHEET_MODES, UI_DIMENSIONS } from '../utils/constants';
import { getLambdaServiceInstance } from '../utils/service';
import { config } from '../utils/config';
import { CustomTooltip } from './LeftSideBar';
import { useSaveSheetMutation } from '../data/projects';
import { useTenantState } from '../data/user';
import { generatePreviewImage, handleExport } from '../utils/helpers';
import { useCallback } from 'react';

const useStyles = makeStyles(theme => ({
  editorMenu: {
    position: 'fixed',
    top: theme.spacing(5.5),
    paddingRight: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    background: theme.palette.common.white,
    boxShadow: theme.shadows[0],
    borderBottom: theme.borders[0],
    width: '100%',
    height: theme.spacing(6),
  },
  editorMenuExpandRight: { paddingRight: theme.spacing(0) },
  analyseModeRight: { paddingRight: theme.spacing(0) },
  navIconButton: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.8),
  },
  drawerBtn: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button: {
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: 'none',
    height: theme.spacing(4),
    minWidth: theme.spacing(11),
  },
  projectDetails: {
    marginLeft: theme.spacing(1.5),
  },
  sideMargin: {
    marginRight: theme.spacing(1),
  },
  version: {
    color: theme.palette.grey[500],
  },
  titleFont: {
    fontSize: theme.spacing(2),
  },
}));

function ActionButton({
  projectId,
  sheetKey,
  sheetName,
  nodeDataArray,
  linkDataArray,
  version,
  syncState,
  modelData,
  isSuggestionsVisible,
  projectName,
  diagramInstance,
}) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const saveSheetMutation = useSaveSheetMutation();

  const updateSheetThumbnail = useCallback(() => {
    if (config.env === 'production') {
      getLambdaServiceInstance()
        .post(`/${projectId}/${sheetKey}`, {
          base64Image: generatePreviewImage(diagramInstance),
        })
        .catch(noop);
    }
  }, [diagramInstance, projectId, sheetKey]);

  const handleSave = useCallback(() => {
    updateSheetThumbnail();

    saveSheetMutation.mutate({
      projectId,
      sheetKey,
      sheet: {
        sheet_name: sheetName,
        sheet_data: {
          nodeDataArray: nodeDataArray,
          linkDataArray: linkDataArray,
          modelData: {
            [SHEET_MODES.INTEGRATION_MODE.KEY]: modelData[SHEET_MODES.INTEGRATION_MODE.KEY],
            [SHEET_MODES.DETAILS_MODE.KEY]: modelData[SHEET_MODES.DETAILS_MODE.KEY],
          },
        },
      },
      version: version,
    });
  }, [
    linkDataArray,
    modelData,
    nodeDataArray,
    projectId,
    saveSheetMutation,
    sheetKey,
    sheetName,
    updateSheetThumbnail,
    version,
  ]);

  if (syncState.dirty && !syncState.syncing && !modelData.isReadOnly) {
    return (
      <Tooltip
        title={isSuggestionsVisible ? formatMessage({ id: 'EXIT_SUGGESTION_PANEL_SAVE' }) : ''}
      >
        {/** this is done to in order to display the tooltip when disabled. Refer: https://material-ui.com/components/tooltips/#disabled-elements  */}
        <span style={{ display: 'inline-block' }}>
          <Button
            variant="contained"
            color="secondary"
            id="saveButton"
            size="small"
            disabled={isSuggestionsVisible}
            disableElevation
            startIcon={<IoMdSave size="18px" />}
            className={clsx(classes.button, classes.sideMargin)}
            onClick={handleSave}
          >
            <Typography component="span" variant="body1">
              <FormattedMessage id="SAVE" />
            </Typography>
          </Button>
        </span>
      </Tooltip>
    );
  }

  if (syncState.syncing) {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="small"
        disabled
        disableElevation
        startIcon={saveSheetMutation.isLoading ? <CircularProgress size={15} /> : null}
        className={clsx(classes.button, classes.sideMargin)}
      >
        <Typography component="span" variant="body1">
          <FormattedMessage id="SAVING" />
        </Typography>
      </Button>
    );
  }

  return (
    <Tooltip
      title={isSuggestionsVisible ? formatMessage({ id: 'EXIT_SUGGESTION_PANEL_EXPORT' }) : ''}
    >
      {/** this is done to in order to display the tooltip when disabled. Refer: https://material-ui.com/components/tooltips/#disabled-elements  */}
      <span style={{ display: 'inline-block' }}>
        <Button
          variant="contained"
          color="default"
          size="small"
          disabled={isSuggestionsVisible}
          disableElevation
          startIcon={<IoMdDownload size="18px" />}
          className={clsx(classes.button, classes.sideMargin)}
          onClick={() => handleExport(projectName, sheetName, diagramInstance)}
        >
          <Typography component="span" variant="body1">
            <FormattedMessage id="EXPORT" />
          </Typography>
        </Button>
      </span>
    </Tooltip>
  );
}

function drawerToggleTooltip(sheetState, isRightDrawerOpen, formatMessage, isSuggestionsVisible) {
  if (sheetState.modelData.isReadOnly) {
    return formatMessage({ id: 'EXIT_ANALYSIS_MODE' });
  }

  if (isSuggestionsVisible) {
    return formatMessage({ id: 'EXIT_SUGGESTION_PANEL_TO_CONTINUE' });
  }

  if (isRightDrawerOpen) {
    return formatMessage({ id: 'CLOSE_DRAWER' });
  } else {
    return formatMessage({ id: 'OPEN_DRAWER' });
  }
}

export function EditorMenu(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const tenant = useTenantState();

  return (
    <AppBar
      className={clsx(classes.editorMenu, {
        [classes.analyseModeRight]: props.sheetState.modelData.isReadOnly,
        [classes.editorMenuExpandRight]: !props.isRightDrawerOpen,
      })}
    >
      <Grid container alignItems="center" style={{ height: '100%' }} justifyContent="space-between">
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <IconButton
                component={RouterLink}
                size="small"
                className={classes.navIconButton}
                to={tenant ? `/t/${tenant.tenant_id}` : '/'}
              >
                <IoIosArrowBack size="23px" />
              </IconButton>
            </Grid>
            <Grid item className={classes.projectDetails}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.titleFont} color="primary" noWrap>
                    <FormattedMessage id="PROJECT" /> - {props.project.name}
                    {props.sheetState.modelData.isReadOnly && ` (${props.sheetState.name})`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" className={classes.version}>
                    <FormattedMessage id="VERSION" />: {props.selectedVersion.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {props.sheetState.modelData.isReadOnly && (
            <Grid item>
              <Typography component="span" className={classes.titleFont} color="textPrimary">
                <FormattedMessage id="ANALYSIS_MODE" />
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              {props.sheetState.modelData.isReadOnly ? (
                <Tooltip
                  title={
                    props.selectedVersion.isActive
                      ? ''
                      : formatMessage({ id: 'EDIT_MODE_LATEST_ONLY' })
                  }
                >
                  {/** this is done to in order to display the tooltip when disabled. Refer: https://material-ui.com/components/tooltips/#disabled-elements  */}
                  <span style={{ display: 'inline-block' }}>
                    <Button
                      variant="contained"
                      color="default"
                      size="small"
                      disableElevation
                      className={clsx(classes.button, classes.sideMargin)}
                      onClick={props.handleAnalyseEditToggle(false)}
                      startIcon={<RiEditLine size="20px" />}
                      disabled={!props.selectedVersion.isActive}
                    >
                      <Typography component="span" variant="body1">
                        <FormattedMessage id="EXIT_TO_EDIT" />
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  disableElevation
                  className={clsx(classes.button, classes.sideMargin)}
                  onClick={props.handleAnalyseEditToggle(true)}
                  startIcon={<RiBarChartBoxLine size="20px" />}
                >
                  <Typography component="span" variant="body1">
                    <FormattedMessage id="ANALYSIS_MODE" />
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid item>
              <ActionButton
                syncState={props.sheetState.syncState}
                modelData={props.sheetState.modelData}
                isSuggestionsVisible={props.isSuggestionsVisible}
                projectId={props.projectId}
                sheetKey={props.sheetKey}
                projectName={props.project.name}
                sheetName={props.sheetName}
                nodeDataArray={props.sheetState.nodeDataArray}
                linkDataArray={props.sheetState.linkDataArray}
                generatePreviewImage={props.generatePreviewImage}
                version={props.version}
                diagramInstance={props.diagramInstance}
              />
            </Grid>
            <Grid item>
              <CustomTooltip
                title={drawerToggleTooltip(
                  props.sheetState,
                  props.isRightDrawerOpen,
                  formatMessage,
                  props.isSuggestionsVisible
                )}
                placement="bottom"
              >
                <span>
                  <IconButton
                    size="small"
                    className={clsx(classes.drawerBtn, classes.sideMargin)}
                    onClick={props.toggleRightDrawer}
                    disabled={props.isSuggestionsVisible}
                  >
                    <BsReverseLayoutSidebarInsetReverse size="18px" />
                  </IconButton>
                </span>
              </CustomTooltip>
            </Grid>
            <Grid item>
              {props.project.isActiveVersion ? (
                !props.sheetState.modelData.isReadOnly && (
                  <Grid item>
                    <DropDown
                      options={values(props.editorDropDownMenuOptions)}
                      onSelect={props.handleDropDownMenuOptionSelect}
                      isProjectDirty={props.isProjectDirty}
                      isActiveVersion={props.project.isActiveVersion}
                    >
                      <IconButton size="small">
                        <IoMdMore size="23px" />
                      </IconButton>
                    </DropDown>
                  </Grid>
                )
              ) : (
                <Grid item>
                  <DropDown
                    options={values(
                      omit(
                        props.editorDropDownMenuOptions,
                        props.editorDropDownMenuOptions.editProject.key,
                        props.editorDropDownMenuOptions.createVersion.key
                      )
                    )}
                    onSelect={props.handleDropDownMenuOptionSelect}
                    isProjectDirty={props.isProjectDirty}
                    isActiveVersion={props.project.isActiveVersion}
                  >
                    <IconButton size="small">
                      <IoMdMore size="23px" />
                    </IconButton>
                  </DropDown>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}
