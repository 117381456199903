/**
 * @file
 *
 * This component renders the left side-bar and the left hand side drawer
 */

import React from 'react';
import { FaShapes, FaThLarge } from 'react-icons/fa';
import { Grid, IconButton, Drawer, makeStyles, withStyles, Tooltip } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { SystemsPalette, ShapesPalette } from './Palette';
import { UI_DIMENSIONS, DRAWER_TYPES } from '../utils/constants';

export const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  sideBar: {
    width: theme.spacing(UI_DIMENSIONS.SIDEBAR_WIDTH),
    height: '100%',
    position: 'fixed',
    top: theme.spacing(6.5),
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(5, 0, 0, 0),
    paddingTop: theme.spacing(1),
    zIndex: theme.zIndex.drawer + 1,
  },
  IconButtons: {
    margin: theme.spacing(1, 0),
  },
  actionIcon: {
    fontSize: theme.spacing(2.5),
    color: theme.palette.common.white,
  },
  drawer: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
  },
  drawerPaper: {
    overflowX: 'hidden',
    position: 'fixed',
    top: theme.spacing(6),
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2, 0, 4, 0),
    margin: theme.spacing(5.5, 0, 0, UI_DIMENSIONS.SIDEBAR_WIDTH),
  },
}));

export function LeftSideBar(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    !props.sheetState.modelData.isReadOnly && (
      <>
        <Grid container className={classes.sideBar} justifyContent="center">
          <Grid item>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.IconButtons}
            >
              <CustomTooltip
                title={formatMessage({ id: 'SYSTEMS' })}
                disableHoverListener={props.isLeftDrawerOpen[DRAWER_TYPES.SYSTEM]}
                placement="left"
              >
                <div>
                  <IconButton
                    disabled={props.isSuggestionsVisible}
                    onClick={() => props.toggleLeftDrawer(DRAWER_TYPES.SYSTEM)}
                  >
                    <FaThLarge className={classes.actionIcon} />
                  </IconButton>
                </div>
              </CustomTooltip>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.IconButtons}
            >
              <CustomTooltip
                title={formatMessage({ id: 'SHAPES_N_TEXTS' })}
                disableHoverListener={props.isLeftDrawerOpen[DRAWER_TYPES.INPUT_COMPONENTS]}
                placement="left"
              >
                <div>
                  <IconButton
                    disabled={props.isSuggestionsVisible}
                    onClick={() => props.toggleLeftDrawer(DRAWER_TYPES.INPUT_COMPONENTS)}
                  >
                    <FaShapes className={classes.actionIcon} />
                  </IconButton>
                </div>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Grid>
        <Drawer
          anchor="left"
          variant="persistent"
          elevation={20}
          open={props.isLeftDrawerOpen[DRAWER_TYPES.SYSTEM]}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <SystemsPalette
            onDragStart={props.handleDragStartForPalette}
            customShapes={props.customShapes}
          />
        </Drawer>
        <Drawer
          anchor="left"
          variant="persistent"
          elevation={20}
          open={props.isLeftDrawerOpen[DRAWER_TYPES.INPUT_COMPONENTS]}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <ShapesPalette onDragStart={props.handleDragStartForPalette} />
        </Drawer>
      </>
    )
  );
}
