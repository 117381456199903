/**
 * @file
 *
 * This component render the Right click context menu in the Editor canvas
 */

import React, { useRef, useCallback } from 'react';
import { makeStyles, lighten, useTheme } from '@material-ui/core';
import { blueGrey, grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import * as go from 'gojs';
import { FormattedMessage } from 'react-intl';

import {
  MODEL_NODES,
  GROUP_NODES,
  DEFAULTS,
  NODE_MODEL_ATTRS,
  STROKE_TYPES,
  DRAWER_TYPES,
  UI_DIMENSIONS,
} from '../utils/constants';
import { uniqueKeyGenerator, goIteratorToArray } from '../models/helpers';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { useDialog } from '../hooks/useDialog';

import { CustomSystemDialog } from './CustomSystemDialog';

const useStyles = makeStyles(theme => ({
  menu: {
    display: 'none',
    opacity: 0,
    position: 'absolute',
    margin: 0,
    padding: theme.spacing(1, 0),
    zIndex: theme.zIndex.tooltip,
    boxShadow:
      '0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)',
    listStyle: 'none',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(1),

    '&.show': {
      display: 'block',
      opacity: 1,
    },
  },
  menuItem: {
    display: 'block',
    position: 'relative',
    minWidth: theme.spacing(15),
    margin: 0,
    padding: theme.spacing(1, 2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 12,
    color: lighten(blueGrey[900], 0.13),
    cursor: 'pointer',

    '&.disabled': {
      cursor: 'not-allowed',
      color: lighten(grey[400], 0.3),
    },

    '&::before': {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0,
      pointerEvents: 'none',
      content: '',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.black,
    },

    '&:hover::before': {
      opacity: 0.04,
    },
  },
}));

/**
 *
 * @param {Part[]} selectedObjects
 * @param {string} category
 */
function checkIfInSameGroupAndCategory(selectedObjects, category) {
  if (!selectedObjects.length) {
    return false;
  }

  const categorySet = new Set();
  const groupSet = new Set();

  selectedObjects.forEach(({ data: { category, group } }) => {
    categorySet.add(category);
    groupSet.add(group);
  });

  return categorySet.size === 1 && groupSet.size === 1 && categorySet.has(category);
}

/**
 * @param {Object} paramObj
 * @param {go.Part[]} paramObj.selectedObjects
 * @param {go.Diagram} paramObj.diagram
 * @param {Object} paramObj.classes
 * @param {function} paramObj.closeContextMenu
 */
function getActionsForFields({ selectedObjects, diagram, classes, closeContextMenu }) {
  const parentNodeData = diagram.findNodeForKey(selectedObjects[0].data[NODE_MODEL_ATTRS.GROUP])
    .data;

  const handleGroupingUpdate = newGroupKey => () => {
    const txnIdentifier = 'field grouping update';
    diagram.startTransaction(txnIdentifier);

    selectedObjects.forEach(node => {
      if (node instanceof go.Node) {
        diagram.model.setDataProperty(node.data, NODE_MODEL_ATTRS.GROUP, newGroupKey);
      }
    });

    diagram.commitTransaction(txnIdentifier);

    closeContextMenu();
    diagram.focus();
  };

  const handleNewFieldsGroupCreation = () => {
    const txnIdentifier = 'creating new field group';
    diagram.startTransaction(txnIdentifier);

    const entityNodeKey =
      parentNodeData[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP
        ? parentNodeData[NODE_MODEL_ATTRS.GROUP]
        : parentNodeData[NODE_MODEL_ATTRS.KEY];

    const fieldsGroupId = uniqueKeyGenerator('node')(diagram.model);
    diagram.model.addNodeData({
      [NODE_MODEL_ATTRS.TITLE]: 'New Group',
      [NODE_MODEL_ATTRS.KEY]: fieldsGroupId,
      [NODE_MODEL_ATTRS.CATEGORY]: GROUP_NODES.FIELDS_GROUP,
      [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.FIELDS_GROUP_COLOR,
      [NODE_MODEL_ATTRS.IS_GROUP]: true,
      [NODE_MODEL_ATTRS.GROUP]: entityNodeKey,
      [NODE_MODEL_ATTRS.STROKE_TYPE]: STROKE_TYPES.SOLID.KEY,
    });

    selectedObjects.forEach(node => {
      if (node instanceof go.Node) {
        diagram.model.setDataProperty(node.data, 'group', fieldsGroupId);
      }
    });

    diagram.commitTransaction(txnIdentifier);

    closeContextMenu();
    diagram.focus();
  };

  /**
   * handler function to add a spacer
   *
   * @param {string} key
   * @param {'above' | 'below'} direction
   */
  const addSpacer = (key, direction) => () => {
    const txnIdentifier = 'adding a spacer';
    diagram.startTransaction(txnIdentifier);

    const node = diagram.findNodeForKey(key);

    const spacerKey = uniqueKeyGenerator('node')(diagram.model);
    diagram.model.addNodeData({
      key: spacerKey,
      location: go.Point.stringify(
        new go.Point(node.location.x, node.location.y + (direction === 'below' ? 1 : -1))
      ),
      size: go.Size.stringify(
        new go.Size(
          parentNodeData[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP ? 240 : 260,
          20
        )
      ),
      group: parentNodeData[NODE_MODEL_ATTRS.KEY],
      category: MODEL_NODES.SPACER,
    });

    diagram.commitTransaction(node);

    closeContextMenu();
    setTimeout(() => {
      diagram.select(diagram.findNodeForKey(spacerKey));
    });
  };

  if (parentNodeData.category === GROUP_NODES.ENTITY_GROUP) {
    const targetFieldGroups = goIteratorToArray(
      diagram.findNodesByExample({
        category: GROUP_NODES.FIELDS_GROUP,
        group: parentNodeData.key,
      })
    );

    return (
      <>
        {selectedObjects.length === 1 && (
          <li
            className={classes.menuItem}
            key="spacer-below"
            onClick={addSpacer(selectedObjects[0].data[NODE_MODEL_ATTRS.KEY], 'below')}
          >
            <FormattedMessage id="ADD_SPACER_BELOW" />
          </li>
        )}
        <li className={classes.menuItem} onClick={handleNewFieldsGroupCreation} key="new">
          <FormattedMessage id="NEW_GROUP" />
        </li>
        {targetFieldGroups.map(({ data }) => (
          <li className={classes.menuItem} onClick={handleGroupingUpdate(data.key)} key={data.key}>
            <FormattedMessage id="GROUP_INTO" values={{ title: data.title }} />
          </li>
        ))}
      </>
    );
  } else {
    const entityNodeData = diagram.findNodeForKey(parentNodeData.group).data;

    const targetFieldGroups = goIteratorToArray(
      diagram.findNodesByExample({
        category: GROUP_NODES.FIELDS_GROUP,
        group: entityNodeData.key,
      }),
      node => node.data.key !== parentNodeData.key
    );

    return (
      <>
        {selectedObjects.length === 1 && (
          <li
            className={classes.menuItem}
            key="spacer-below"
            onClick={addSpacer(selectedObjects[0].data[NODE_MODEL_ATTRS.KEY], 'below')}
          >
            <FormattedMessage id="ADD_SPACER_BELOW" />
          </li>
        )}
        <li
          className={classes.menuItem}
          onClick={handleGroupingUpdate(entityNodeData.key)}
          key={entityNodeData.key}
        >
          <FormattedMessage id="UNGROUP" />
        </li>
        <li className={classes.menuItem} onClick={handleNewFieldsGroupCreation} key="new">
          <FormattedMessage id="NEW_GROUP" />
        </li>
        {targetFieldGroups.map(({ data }) => (
          <li className={classes.menuItem} onClick={handleGroupingUpdate(data.key)} key={data.key}>
            <FormattedMessage id="GROUP_INTO" values={{ title: data.title }} />
          </li>
        ))}
      </>
    );
  }
}

/**
 * @param {Object} paramObj
 * @param {go.Part[]} paramObj.selectedObjects
 * @param {go.Diagram} paramObj.diagram
 * @param {Object} paramObj.classes
 * @param {function} paramObj.closeContextMenu
 */
function getActionsForFieldGroups({ selectedObjects, diagram, classes, closeContextMenu }) {
  const entityGroupKey = selectedObjects[0].data.group;

  const handleUngrouping = () => {
    const txnIdentifier = 'ungrouping entity fields';
    diagram.model.startTransaction(txnIdentifier);

    const fields = goIteratorToArray(
      diagram.findNodesByExample({ category: MODEL_NODES.FIELD, group: selectedObjects[0].key })
    );

    fields.forEach(node => {
      if (node instanceof go.Node) {
        diagram.model.setDataProperty(node.data, 'group', entityGroupKey);
      }
    });

    diagram.removeParts(selectedObjects);

    diagram.select(diagram.findNodeForKey(entityGroupKey));

    diagram.model.commitTransaction('ungrouping entity fields');

    closeContextMenu();
    diagram.focus();
  };

  /**
   * handler function to add a spacer
   *
   * @param {string} key
   * @param {'above' | 'below'} direction
   */
  const addSpacer = (key, direction) => () => {
    const txnIdentifier = 'adding a spacer';
    diagram.startTransaction(txnIdentifier);

    const node = diagram.findNodeForKey(key);

    const spacerKey = uniqueKeyGenerator('node')(diagram.model);
    diagram.model.addNodeData({
      key: spacerKey,
      location: go.Point.stringify(
        new go.Point(node.location.x, node.location.y + (direction === 'below' ? 1 : -1))
      ),
      size: go.Size.stringify(new go.Size(260, 20)),
      group: entityGroupKey,
      category: MODEL_NODES.SPACER,
    });

    diagram.commitTransaction(node);

    closeContextMenu();
    setTimeout(() => {
      diagram.select(diagram.findNodeForKey(spacerKey));
    });
  };

  return (
    <>
      {selectedObjects.length === 1 && (
        <li
          className={classes.menuItem}
          key="spacer-below"
          onClick={addSpacer(selectedObjects[0].data[NODE_MODEL_ATTRS.KEY], 'below')}
        >
          <FormattedMessage id="ADD_SPACER_BELOW" />
        </li>
      )}
      <li className={classes.menuItem} onClick={handleUngrouping}>
        <FormattedMessage id="UNGROUP" />
      </li>
    </>
  );
}

/**
 * @param {Object} paramObj
 * @param {go.Part[]} paramObj.selectedObjects
 * @param {go.Diagram} paramObj.diagram
 * @param {Object} paramObj.classes
 * @param {function} paramObj.closeContextMenu
 * @param {boolean} paramObj.isDialogOpen
 * @param {function} paramObj.openDialog
 * @param {function} paramObj.closeDialog
 */
function getActionsForEntityGroup({
  selectedObjects,
  diagram,
  classes,
  closeContextMenu,
  isDialogOpen,
  openDialog,
  closeDialog,
}) {
  const entityGroupKey = selectedObjects[0].data.key;

  const handleSaveEntity = () => {
    closeContextMenu();
    openDialog();
  };

  return (
    <>
      {selectedObjects.length === 1 && (
        <li className={classes.menuItem} key="save-entity" onClick={handleSaveEntity}>
          <FormattedMessage id="SAVE_ENTITY" />
        </li>
      )}
      <CustomSystemDialog
        onClose={closeDialog}
        open={isDialogOpen}
        entityGroupKey={entityGroupKey}
        diagram={diagram}
      />
    </>
  );
}

export function ContextMenu(props) {
  const classes = useStyles();

  const theme = useTheme();

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const { updateContextMenuState } = props;
  const closeContextMenu = useCallback(() => {
    updateContextMenuState(false);
  }, [updateContextMenuState]);

  const contextMenuRef = useRef();
  useOnClickOutside(contextMenuRef, closeContextMenu);

  if (!props.diagram) {
    return null;
  }

  /**
   * @type {go.Diagram}
   */
  const diagram = props.diagram;

  const selectedObjects = diagram.selection.toArray();

  let options = (
    <li className={classes.menuItem}>
      <FormattedMessage id="NO_ACTIONS_AVAILABLE" />
    </li>
  );
  if (checkIfInSameGroupAndCategory(selectedObjects, MODEL_NODES.FIELD)) {
    options = getActionsForFields({ selectedObjects, diagram, classes, closeContextMenu });
  } else if (checkIfInSameGroupAndCategory(selectedObjects, GROUP_NODES.FIELDS_GROUP)) {
    options = getActionsForFieldGroups({
      selectedObjects,
      diagram,
      classes,
      closeContextMenu,
    });
  } else if (checkIfInSameGroupAndCategory(selectedObjects, GROUP_NODES.ENTITY_GROUP)) {
    options = getActionsForEntityGroup({
      selectedObjects,
      diagram,
      classes,
      closeContextMenu,
      isDialogOpen,
      openDialog,
      closeDialog,
    });
  }

  const mousePointerCoords = diagram.lastInput.viewPoint;

  return (
    <ul
      onContextMenu={event => {
        event.preventDefault();
      }}
      ref={contextMenuRef}
      style={{
        left:
          props.isLeftDrawerOpen[DRAWER_TYPES.SYSTEM] ||
          props.isLeftDrawerOpen[DRAWER_TYPES.INPUT_COMPONENTS]
            ? mousePointerCoords.x + theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH)
            : mousePointerCoords.x,
        top: mousePointerCoords.y + theme.spacing(10),
      }}
      className={clsx(classes.menu, props.show && 'show')}
    >
      {options}
    </ul>
  );
}
