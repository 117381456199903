/**
 * @file
 *
 * Contains user related hooks which can fetch user details from the API.
 */
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { pick } from 'lodash-es';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { createStore } from '@halka/state';
import Cookies from 'js-cookie';

import { config } from '../utils/config';
import { API_ERROR_TYPES, COOKIES } from '../utils/constants';
import { useNotifyError } from '../hooks/useNotifyError';

export const useTenantState = createStore(null);

/**
 * Hook to fetch user details of the authorized user.
 */
export function useUserDetails() {
  const { data, error, isLoading } = useQuery(`/whoAmI`);
  useNotifyError({ error, mustRedirect: true });

  const history = useHistory();

  const user = useMemo(() => {
    let _user = {
      tenants: [],
    };

    if (data) {
      _user = pick(data, 'first_name', 'last_name', 'picture', 'email');

      _user.tenants =
        data?.tenants?.filter(tenant =>
          tenant.permissions.some(({ app_code }) => app_code === config.appCode)
        ) ?? [];
    }

    if (Cookies.get(COOKIES.SSO_AUTH_TOKEN)) {
      _user.isLoggedInUsingSSO = true;
    } else {
      _user.isLoggedInUsingSSO = false;
    }

    return _user;
  }, [data]);

  const { pathname } = useLocation();
  const params = useParams() ?? {};
  const { tenantId } = params;

  useEffect(() => {
    if (data) {
      const prevTenant = useTenantState.get();

      if (tenantId) {
        if (prevTenant?.tenant_id !== tenantId) {
          const tenant = user.tenants.find(t => t.tenant_id === tenantId);

          if (tenant) {
            useTenantState.set(tenant);
          } else {
            if (pathname !== '/error') {
              history.push('/error', {
                errorType: API_ERROR_TYPES.UNAUTHORIZED,
              });
            }
          }
        }
      } else {
        if (user.tenants.length > 0) {
          const tenant = user.tenants[0];

          if (pathname === '/' || pathname === '') {
            history.push(`/t/${tenant.tenant_id}`);
          }
        } else {
          if (pathname !== '/error') {
            history.push('/error', {
              errorType: API_ERROR_TYPES.UNAUTHORIZED,
            });
          }
        }
      }
    }
  }, [tenantId, pathname, user, data, history]);

  return {
    user,
    isLoading,
    error,
  };
}
