/**
 * @file
 *
 * This file houses the Editor component
 *
 * Editor component renders everything related to the Canvas
 * as well as the hovering utilities over it like the Save Button
 * and the Copy From Other Sheet options
 */

import React, { useRef, useLayoutEffect, useEffect, useCallback, useState } from 'react';
import {
  Button,
  makeStyles,
  Fab,
  Tooltip,
  Badge,
  withStyles,
  FormControlLabel,
  Switch,
  IconButton,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MdInfoOutline, MdMenu } from 'react-icons/md';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import { GROUP_NODES, MODEL_NODES, SHEET_MODES } from '../utils/constants';
import {
  createFieldNodeTemplate,
  createFieldsGroupTemplate,
  createFieldSpacerNodeTemplate,
  entityGroupTemplate,
} from '../models/entityModel';
import { SelectSheetTemplateDialog } from './SelectSheetTemplateDialog';
import { OverviewDialog } from './OverviewDialog';
import { sheetStateActions } from '../data/projects';
import { useMapperTemplateStore } from '../data/mapperTemplates';
import { useDialog } from '../hooks/useDialog';
import { handleDragLeaveFromPalette, handleDragOverFromPalette } from '../utils/helpers';

const $ = go.GraphObject.make;

const CustomBadge = withStyles(theme => ({
  badge: {
    top: '-8px',
    right: '-8px',
  },
}))(Badge);

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '92%',
    position: 'relative',
  },
  contentAnalyseMode: {
    height: '98%',
  },
  editorContainer: {
    width: '100%',
    height: '100%',
    background: theme.palette.common.white,
  },
  analysisMode: {
    height: '100%',
  },
  alert: {
    position: 'absolute',
    top: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    left: 0,
    zIndex: theme.zIndex.appBar,
    margin: theme.spacing(0, 2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
  alertContents: {
    display: 'flex',
    alignItems: 'center',
    direction: 'column',
  },
  floatingActionButton: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
    top: theme.spacing(2),
  },
  tipActionButton: {
    left: theme.spacing(2),
    animation: `$grow 5s ${theme.transitions.easing.easeIn} infinite`,
  },
  '@keyframes grow': {
    '0%': {
      transform: 'scale(1)',
    },
    '10%': {
      transform: 'scale(1.2)',
    },
    '25%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  integrationModeSwitch: {
    right: theme.spacing(2),
  },
  menu: {
    zIndex: 2,
    backgroundColor: 'white',
  },
}));

// The hook contains logic for adding context menu to the diagram, reseting the diagram scale and drag and drop listeners
const useDiagramEvents = ({
  sheetState,
  updateContextMenuState,
  updateDiagramInstance,
  onDiagramEvent,
  handleDropFromPalette,
  projectId,
  sheetKey,
}) => {
  const diagramRef = useRef(null);

  useEffect(() => {
    if (!diagramRef.current) {
      return;
    }

    /**
     * @type {go.Diagram}
     */
    const diagram = diagramRef.current.getDiagram();
    if (!diagram instanceof go.Diagram) {
      return;
    }

    diagram.isReadOnly = sheetState.modelData.isReadOnly;
  }, [sheetState.modelData.isReadOnly]);

  useLayoutEffect(() => {
    if (!diagramRef.current) {
      return;
    }

    /**
     * @type {go.Diagram}
     */
    const diagram = diagramRef.current.getDiagram();
    if (!diagram instanceof go.Diagram) {
      return;
    }

    const contextMenu = $(go.HTMLInfo, {
      /**
       * @param {go.GraphObject} obj
       * @param {go.Diagram} diagram
       */
      show: (obj, diagram) => {
        if (diagram.isReadOnly) {
          return;
        }

        updateContextMenuState(true);
      },
      /**
       * @param {go.GraphObject} obj
       * @param {go.Diagram} diagram
       */
      hide: (obj, diagram) => {
        if (diagram.isReadOnly) {
          return;
        }

        updateContextMenuState(false);
      },
    });

    diagram.nodeTemplateMap.add(MODEL_NODES.FIELD, createFieldNodeTemplate(contextMenu));
    diagram.nodeTemplateMap.add(MODEL_NODES.SPACER, createFieldSpacerNodeTemplate(contextMenu));

    diagram.groupTemplateMap.add(GROUP_NODES.FIELDS_GROUP, createFieldsGroupTemplate(contextMenu));
    diagram.groupTemplateMap.add(GROUP_NODES.ENTITY_GROUP, entityGroupTemplate(contextMenu));

    updateDiagramInstance(diagram);

    setTimeout(() => {
      if (sheetState.selectedData) {
        const node = diagram.findNodeForKey(sheetState.selectedData.key);
        diagram.select(node);
      }

      if (sheetState.modelData.scale) {
        diagram.scale = sheetState.modelData.scale;
      } else {
        diagram.zoomToFit();
      }
      diagram.alignDocument(go.Spot.Center, go.Spot.Center);
      // do not remove the 500 time offset, the layout calculation takes some time
      // TODO: find a more deterministic way to do this
    }, 500);

    diagram.addDiagramListener('ChangedSelection', onDiagramEvent);
    diagram.addDiagramListener('PartResized', onDiagramEvent);

    const div = diagram.div;

    const handleDragLeave = handleDragLeaveFromPalette(diagram);
    div.addEventListener('dragleave', handleDragLeave);

    const handleDragOver = handleDragOverFromPalette(diagram);
    div.addEventListener('dragover', handleDragOver);

    div.addEventListener('drop', handleDropFromPalette);

    return () => {
      updateDiagramInstance(null);

      diagram.removeDiagramListener('ChangedSelection', onDiagramEvent);
      diagram.removeDiagramListener('PartResized', onDiagramEvent);

      div.removeEventListener('dragleave', handleDragLeave);
      div.removeEventListener('dragover', handleDragOver);
      div.removeEventListener('drop', handleDropFromPalette);

      sheetStateActions.clearSelectedData({
        projectId,
        sheetKey,
      });

      sheetStateActions.updateSheetScale({ projectId, sheetKey, scale: diagram.scale });
    };
    // eslint-disable-next-line
  }, []);

  const resetDiagramViewport = useCallback(() => {
    setTimeout(() => {
      if (!diagramRef.current) {
        return;
      }

      /**
       * @type {go.Diagram}
       */
      const diagram = diagramRef.current.getDiagram();
      if (!diagram instanceof go.Diagram) {
        return;
      }

      diagram.zoomToFit();
      diagram.alignDocument(go.Spot.Center, go.Spot.Center);
    });
  }, []);

  return { diagramRef, resetDiagramViewport };
};

const useMenu = (projectId, sheetKey) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);
  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return { anchorEl, openMenu, closeMenu };
};

export function Editor({
  sheetState,
  updateContextMenuState,
  updateDiagramInstance,
  onDiagramEvent,
  handleDropFromPalette,
  projectId,
  sheetKey,
  project,
  buttonProps,
  isSuggestionsVisible,
  showSuggestions,
  initDiagram,
  onModelChange,
  diagramInstance,
  sheets,
  indexMaps,
}) {
  const classes = useStyles();

  const suggestedConnections = useMapperTemplateStore(state => state.suggestedConnections);

  const {
    isDialogOpen: isSheetTemplateDialogOpen,
    openDialog: openSheetTemplateDialog,
    closeDialog: closeSheetTemplateDialog,
  } = useDialog();

  const { diagramRef, resetDiagramViewport } = useDiagramEvents({
    sheetState,
    updateContextMenuState,
    updateDiagramInstance,
    onDiagramEvent,
    handleDropFromPalette,
    projectId,
    sheetKey,
  });

  const { anchorEl, openMenu, closeMenu } = useMenu(projectId, sheetKey);

  return (
    <>
      <div
        className={clsx(classes.wrapper, {
          [classes.contentAnalyseMode]: sheetState.modelData.isReadOnly && project.isActiveVersion,
        })}
      >
        {sheetState.nodeDataArray.length === 0 &&
          !sheetState.syncState.dirty &&
          !sheetState.syncState.syncing &&
          !sheetState.modelData.isReadOnly && (
            <Alert
              severity="info"
              className={classes.alert}
              action={
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  {...buttonProps}
                  onClick={openSheetTemplateDialog}
                >
                  <FormattedMessage id="SELECT_SHEET" />
                </Button>
              }
            >
              <div className={classes.alertContents}>
                <FormattedMessage id="GET_STARTED_QUICKLY" />
              </div>
            </Alert>
          )}

        {!sheetState.syncState.syncing && !sheetState.modelData.isReadOnly && (
          <>
            {suggestedConnections.length > 0 && !isSuggestionsVisible && (
              <Tooltip title={<FormattedMessage id="MAPPER_TEMPLATES_AVAILABLE" />}>
                <Fab
                  id="tipButton"
                  size="small"
                  color="primary"
                  className={clsx(classes.floatingActionButton, classes.tipActionButton)}
                  onClick={showSuggestions}
                >
                  <CustomBadge badgeContent={suggestedConnections.length} color="secondary">
                    <MdInfoOutline size="23px" />
                  </CustomBadge>
                </Fab>
              </Tooltip>
            )}
          </>
        )}

        {sheetState.nodeDataArray.length > 0 && (
          <>
            <IconButton
              color="primary"
              className={clsx(classes.floatingActionButton, classes.integrationModeSwitch)}
              onClick={openMenu}
            >
              <Badge
                variant="dot"
                color="error"
                invisible={
                  sheetState.modelData[SHEET_MODES.INTEGRATION_MODE.KEY] &&
                  sheetState.modelData[SHEET_MODES.DETAILS_MODE.KEY]
                }
              >
                <MdMenu />
              </Badge>
            </IconButton>
            <Popper
              className={classes.menu}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement="bottom-end"
              transition
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={closeMenu}>
                      <MenuList id="menu-list-grow">
                        <MenuItem button={false}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={sheetState.modelData[SHEET_MODES.INTEGRATION_MODE.KEY]}
                                onChange={sheetStateActions.toggleSheetMode(
                                  projectId,
                                  sheetKey,
                                  SHEET_MODES.INTEGRATION_MODE.KEY
                                )}
                                name={SHEET_MODES.INTEGRATION_MODE.KEY}
                                color="primary"
                                disabled={(sheetState?.linkDataArray.length ?? 0) < 1}
                              />
                            }
                            label={SHEET_MODES.INTEGRATION_MODE.LABEL}
                            labelPlacement="start"
                          />
                        </MenuItem>
                        <MenuItem button={false}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={sheetState.modelData[SHEET_MODES.DETAILS_MODE.KEY]}
                                onChange={sheetStateActions.toggleSheetMode(
                                  projectId,
                                  sheetKey,
                                  SHEET_MODES.DETAILS_MODE.KEY
                                )}
                                name={SHEET_MODES.DETAILS_MODE.KEY}
                                color="primary"
                                disabled={(sheetState?.linkDataArray.length ?? 0) < 1}
                              />
                            }
                            label={SHEET_MODES.DETAILS_MODE.LABEL}
                            labelPlacement="start"
                          />
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}

        <ReactDiagram
          divClassName={clsx(
            classes.editorContainer,
            sheetState.modelData.isReadOnly && classes.analysisMode
          )}
          ref={diagramRef}
          initDiagram={initDiagram}
          nodeDataArray={sheetState.nodeDataArray}
          linkDataArray={sheetState.linkDataArray}
          modelData={sheetState.modelData}
          skipsDiagramUpdate={sheetState.skipsDiagramUpdate}
          onModelChange={onModelChange}
        />

        <OverviewDialog
          diagramInstance={diagramInstance}
          isReadOnly={sheetState.modelData.isReadOnly}
        />
      </div>

      <SelectSheetTemplateDialog
        open={isSheetTemplateDialogOpen}
        onClose={closeSheetTemplateDialog}
        projectId={projectId}
        sheets={sheets}
        sheetKey={sheetKey}
        indexMaps={indexMaps}
        resetDiagramViewport={resetDiagramViewport}
      />
    </>
  );
}
