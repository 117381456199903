import { Button, Menu, MenuItem, Box } from '@material-ui/core';
import React from 'react';
import { useCallback } from 'react';
import { MdLanguage } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { Language as Languages, useLanguage, useSetLanguage } from '../state/language';

export default function Language() {
  const language = useLanguage();
  const setLanguage = useSetLanguage();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleSelection = useCallback(
    language => () => {
      setLanguage(language);
      setAnchorEl(null);
    },
    [setLanguage, setAnchorEl]
  );

  return (
    <>
      <Button color="inherit" onClick={handleClick}>
        <MdLanguage />
        <Box ml={1}>{language.toUpperCase()}</Box>
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelection(Languages.ENGLISH)}>
          <FormattedMessage id="ENGLISH" />
        </MenuItem>
        <MenuItem onClick={handleSelection(Languages.GERMAN)}>
          <FormattedMessage id="GERMAN" />
        </MenuItem>
      </Menu>
    </>
  );
}
