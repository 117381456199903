/**
 * @file
 *
 * Contains mapper projects related hooks which can fetch mapper projects from the API.
 */

import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { appMapperApiBase } from '../utils/service';

/**
 * Get the mapper projects.
 */
export function useMapperProjects({ enabled }) {
  const { data, ...rest } = useQuery({ queryKey: `${appMapperApiBase}/project`, enabled });

  const mapperProjects = useMemo(
    () =>
      (data ?? [])
        .filter(
          mapperProject =>
            mapperProject.source_user_system_data && mapperProject.destination_user_system_data
        )
        .map(mapperProject => ({
          id: mapperProject.mapperproject_id,
          name: mapperProject.name,
          description: mapperProject.description,
          mapperTemplateCode: mapperProject.mappertemplate_code,
          version: mapperProject.project_version,
          srcSystem: {
            id: mapperProject.source_user_system_data.user_system_id,
            name: mapperProject.source_user_system_data.name,
            system: mapperProject.source_user_system_data.system,
            createdAt: mapperProject.source_user_system_data.created_at,
            updatedAt: mapperProject.source_user_system_data.updated_at,
          },
          destSystem: {
            id: mapperProject.destination_user_system_data.user_system_id,
            name: mapperProject.destination_user_system_data.name,
            system: mapperProject.destination_user_system_data.system,
            createdAt: mapperProject.destination_user_system_data.created_at,
            updatedAt: mapperProject.destination_user_system_data.updated_at,
          },
          createdAt: mapperProject.created_at,
          updatedAt: mapperProject.updated_at,
        })),
    [data]
  );

  return {
    mapperProjects,
    ...rest,
  };
}
