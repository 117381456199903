/**
 * @file
 *
 * This component renders any Selectable Option attribute edit form element in the Selection Panel
 */

import React from 'react';
import { TextField, Grid, MenuItem } from '@material-ui/core';
import { startCase, camelCase } from 'lodash-es';

export function SelectEditField(props) {
  const handleChange = event => {
    const value = event.target.value;
    props.onChange({ attr: props.attr, value });
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <TextField
        select
        style={{ width: props.width }}
        disabled={props.disabled}
        InputProps={{
          style: {
            height: '35px',
          },
        }}
        margin="dense"
        variant="outlined"
        name={props.attr}
        value={props.value}
        onChange={handleChange}
      >
        {props.options.map(option =>
          typeof option === 'string' ? (
            <MenuItem key={option} value={option}>
              {startCase(camelCase(option))}
            </MenuItem>
          ) : (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          )
        )}
      </TextField>
    </Grid>
  );
}
