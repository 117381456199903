import React, { useEffect } from 'react';
import { useTweaks, makeButton } from 'use-tweaks';
import { useUpdateEffect, useLocalStorage } from 'react-use';

import { useAuthTokenInjectStore, ls, lsNamespace } from './authTokenInjectorState';

const paneSelector = '.tp-dfwv';

function AuthTokenInjectorInternal() {
  const { authToken } = useTweaks('Dev Debug View', {
    authToken: useAuthTokenInjectStore.get() ?? '',
    ...makeButton('Reload', () => {
      ls.set(lsNamespace.injectedAuthToken, useAuthTokenInjectStore.get());
      window.location.reload();
    }),
    ...makeButton('Reset All', () => {
      ls.clear();
    }),
  });

  useUpdateEffect(() => {
    useAuthTokenInjectStore.set(authToken);
  }, [authToken]);

  return <div />;
}

export default function AuthTokenInjector() {
  const [isUiVisible, setUiVisibility] = useLocalStorage(lsNamespace.devUiShowState, true);

  useEffect(() => {
    window.__toggleDevUiVisibility = () => {
      const newState = !isUiVisible;

      ls.set(lsNamespace.devUiShowState, newState);

      const $pane = document.querySelector(paneSelector);
      if ($pane) {
        if (newState) {
          $pane.style.display = 'initial';
        } else {
          $pane.style.display = 'none';
        }
      }

      setUiVisibility(newState);
    };

    return () => {
      window.__toggleDevUiVisibility = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUiVisible]);

  if (isUiVisible) {
    return <AuthTokenInjectorInternal />;
  }

  return null;
}
