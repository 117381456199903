/**
 * @file
 *
 * This component contains the input fields for different system specific options
 * like linking of user systems and assigning system type
 */
import React from 'react';
import { Grid, Typography, Tooltip, makeStyles } from '@material-ui/core';
import { values } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { NODE_MODEL_ATTRS, BASE_SYSTEMS, TEXTFIELD_WIDTH } from '../../utils/constants';
import { SelectEditField } from './SelectEditField';
import { UserSystemLinkOption } from './UserSystemLinkOption';

const useStyles = makeStyles(theme => ({
  topMargin: {
    marginTop: theme.spacing(1.5),
  },
}));

export function EntitySytemSettingOptions(props) {
  const classes = useStyles();

  const entitySystemCodeLock = props.selectedData[NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE_LOCK];
  const entitySystemCodeValue =
    props.selectedData[NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE] || BASE_SYSTEMS.CUSTOM.KEY;

  const entitySystemIsLinked =
    !entitySystemCodeLock && props.selectedData[NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID];

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="SYSTEM_TYPE" />
            </Typography>
          </Grid>
          <Grid container>
            <Tooltip
              title={entitySystemIsLinked ? <FormattedMessage id="UNLINK_LINKED_SYSTEM" /> : ''}
            >
              {/** this is done to in order to display the tooltip when disabled. Refer: https://material-ui.com/components/tooltips/#disabled-elements  */}
              <span style={{ display: 'inline-block', width: '100%' }}>
                <SelectEditField
                  width={TEXTFIELD_WIDTH.LG}
                  disabled={entitySystemCodeLock || entitySystemIsLinked}
                  onChange={props.onChange}
                  attr={NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE}
                  value={entitySystemCodeValue}
                  options={values(BASE_SYSTEMS).map(({ KEY, NAME }) => ({ key: KEY, label: NAME }))}
                />
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.topMargin}>
        <UserSystemLinkOption selectedData={props.selectedData} onChange={props.onChange} />
      </Grid>
    </Grid>
  );
}
