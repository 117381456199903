/**
 * @file
 *
 * this file contains a custom CommandHandler class that handle some of the keyboard shortcuts and other commands like paste, copy, etc. differently in a performant manner
 */
import * as go from 'gojs';

export class CustomCommandHandler extends go.CommandHandler {
  constructor() {
    super();

    // default configurations
    this._arrowKeyBehavior = CustomCommandHandler.arrowKeyBehaviorTypes.scroll;
    this._pasteOffset = new go.Point(10, 10);
    this._lastPasteOffset = new go.Point(0, 0);
  }

  static arrowKeyBehaviorTypes = {
    move: 'move',
    scroll: 'scroll',
    none: 'none',
  };

  /**
   * Change the z-ordering of selected parts to pull them forward, in front of all other parts
   * in their respective layers.
   * All unselected parts in each layer with a selected Part with a non-numeric {@link Part#zOrder} will get a zOrder of zero.
   */
  pullToFront() {
    const diagram = this.diagram;

    const txnIdentifier = 'pull to front';
    diagram.startTransaction(txnIdentifier);

    // find the affected Layers
    const layers = new go.Map();
    diagram.selection.each(({ layer }) => {
      layers.set(layer, 0);
    });

    // find the maximum zOrder in each Layer
    layers.iteratorKeys.each(layer => {
      let max = 0;
      layer.parts.each(part => {
        if (part.isSelected) {
          return;
        }

        const z = part.zOrder;
        if (isNaN(z)) {
          part.zOrder = 0;
        } else {
          max = Math.max(max, z);
        }
      });

      layers.set(layer, max);
    });

    // assign each selected Part.zOrder to the computed value for each Layer
    diagram.selection.each(part => {
      assignZOrder(part, layers.get(part.layer) + 1);
    });

    diagram.commitTransaction(txnIdentifier);
  }

  /**
   * Change the z-ordering of selected parts to push them backward, behind of all other parts
   * in their respective layers.
   * All unselected parts in each layer with a selected Part with a non-numeric {@link Part#zOrder} will get a zOrder of zero.
   */
  pushToBack() {
    const diagram = this.diagram;

    const txnIdentifier = 'push to back';
    diagram.startTransaction(txnIdentifier);

    // find the affected Layers
    const layers = new go.Map();
    diagram.selection.each(({ layer }) => {
      layers.set(layer, 0);
    });

    // find the minimum zOrder in each Layer
    layers.iteratorKeys.each(layer => {
      let min = 0;
      layer.parts.each(part => {
        if (part.isSelected) {
          return;
        }
        const z = part.zOrder;

        if (isNaN(z)) {
          part.zOrder = 0;
        } else {
          min = Math.min(min, z);
        }
      });
      layers.set(layer, min);
    });

    // assign each selected Part.zOrder to the computed value for each Layer
    diagram.selection.each(part => {
      assignZOrder(
        part,
        // make sure a group's nested nodes are also behind everything else
        layers.get(part.layer) - 1 - findGroupDepth(part)
      );
    });

    diagram.commitTransaction(txnIdentifier);
  }

  /**
   * This implements custom behaviors for arrow key keyboard events.
   * Set {@link #arrowKeyBehavior} to "select", "move" (the default), "scroll" (the standard behavior), or "none"
   * to affect the behavior when the user types an arrow key.
   */
  doKeyDown() {
    const diagram = this.diagram;

    if (diagram === null) {
      return;
    }

    const event = diagram.lastInput;

    // determines the function of the arrow keys
    if (
      event.key === 'Up' ||
      event.key === 'Down' ||
      event.key === 'Left' ||
      event.key === 'Right'
    ) {
      const behavior = this.arrowKeyBehavior;

      if (behavior === CustomCommandHandler.arrowKeyBehaviorTypes.none) {
        // no-op
        return;
      } else if (behavior === CustomCommandHandler.arrowKeyBehaviorTypes.move) {
        this._arrowKeyMove();
        return;
      }
      // otherwise drop through to get the default scrolling behavior
    }

    // otherwise still does all standard commands
    super.doKeyDown();
  }

  /**
   * method to be overriden in case you want to modify the selection before triggering movement by keyboard
   *
   * by default we return everything
   *
   * @param {go.Set<go.Part>} selection    current selection
   *
   * @returns {go.Set<go.Part>}
   */
  whitelistSelectionBeforeKeyMove(selection) {
    return selection;
  }

  /**
   * To be called when arrow keys should move the Diagram.selection.
   */
  _arrowKeyMove() {
    const diagram = this.diagram;
    const event = diagram.lastInput;

    // moves all selected parts in the specified direction
    // by a pixel
    let vdistance = 1;
    let hdistance = 1;

    let multiplier = 20;

    if (diagram.grid !== null) {
      const cellsize = diagram.grid.gridCellSize;
      hdistance = cellsize.width;
      vdistance = cellsize.height;
    }

    // handling key modifier behaviours
    // if control is being held down, move by multiplier of 20 to move faster
    if (event.control || event.meta) {
      hdistance *= multiplier;
      vdistance *= multiplier;
    } else if (event.shift) {
      hdistance = 1;
      vdistance = 1;
    }

    const txnIdentifier = 'arrow key move';
    diagram.startTransaction(txnIdentifier);

    this.whitelistSelectionBeforeKeyMove(diagram.selection).each(part => {
      if (event.key === 'Up') {
        part.move(new go.Point(part.actualBounds.x, part.actualBounds.y - vdistance));
      } else if (event.key === 'Down') {
        part.move(new go.Point(part.actualBounds.x, part.actualBounds.y + vdistance));
      } else if (event.key === 'Left') {
        part.move(new go.Point(part.actualBounds.x - hdistance, part.actualBounds.y));
      } else if (event.key === 'Right') {
        part.move(new go.Point(part.actualBounds.x + hdistance, part.actualBounds.y));
      }
    });

    diagram.commitTransaction(txnIdentifier);
  }

  /**
   * Reset the last offset for pasting.
   * @param {go.Iterable<go.Part>} coll a collection of {@link Part}s.
   */
  copyToClipboard(coll) {
    super.copyToClipboard(coll);

    this._lastPasteOffset.set(this.pasteOffset);
  }

  /**
   * Paste from the clipboard with an offset incremented on each paste, and reset when copied.
   * @returns {go.Set<go.Part>} a collection of newly pasted {@link Part}s
   */
  pasteFromClipboard() {
    const coll = super.pasteFromClipboard();

    this.diagram.moveParts(coll, this._lastPasteOffset);
    this._lastPasteOffset.add(this.pasteOffset);

    return coll;
  }

  /**
   * Gets or sets the arrow key behavior. Possible values are "move", "scroll" and "none".
   * The default value is "move".
   *
   * @returns {string}
   */
  get arrowKeyBehavior() {
    return this._arrowKeyBehavior;
  }
  /**
   * @param {'move' | 'scroll' | 'none' }
   */
  set arrowKeyBehavior(val) {
    if (
      val !== CustomCommandHandler.arrowKeyBehaviorTypes.move &&
      val !== CustomCommandHandler.arrowKeyBehaviorTypes.scroll &&
      val !== CustomCommandHandler.arrowKeyBehaviorTypes.none
    ) {
      throw new Error(
        `CustomCommandHandler.arrowKeyBehavior must be either "${CustomCommandHandler.arrowKeyBehaviorTypes.move}", "${CustomCommandHandler.arrowKeyBehaviorTypes.scroll}", or "${CustomCommandHandler.arrowKeyBehaviorTypes.none}", not: ${val}`
      );
    }

    this._arrowKeyBehavior = val;
  }

  /**
   * Gets or sets the offset at which each repeated pasteSelection() puts the new copied parts from the clipboard.
   * The default value is (10,10).
   *
   * @returns {Point}
   */
  get pasteOffset() {
    return this._pasteOffset;
  }
  /**
   * @param {go.Point}
   */
  set pasteOffset(val) {
    if (!(val instanceof go.Point)) {
      throw new Error(`CustomCommandHandler.pasteOffset must be a Point, not: ${val}`);
    }

    this._pasteOffset.set(val);
  }
}

/**
 * function to recursively assign zOrder to parts except the root one
 * @param {go.Part} part
 * @param {number} z
 * @param {go.Part} [root]
 */
const assignZOrder = (part, z, root) => {
  if (root === undefined) root = part;
  if (part.layer === root.layer) part.zOrder = z;
  if (part instanceof go.Group) {
    part.memberParts.each(m => {
      assignZOrder(m, z + 1, root);
    });
  }
};

/**
 * function to find the depth of a group
 * @param {go.Part} part
 *
 * @returns {number}
 */
const findGroupDepth = part => {
  if (part instanceof go.Group) {
    let d = 0;
    part.memberParts.each(m => {
      d = Math.max(d, findGroupDepth(m));
    });
    return d + 1;
  } else {
    return 0;
  }
};
