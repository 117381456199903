/**
 * @file
 *
 * This file contains the component that displays the mapper template suggestions
 */

import React, { useCallback } from 'react';
import {
  Typography,
  makeStyles,
  Card,
  CardContent,
  CardActions,
  Button,
  DialogContentText,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { useConfirmationDialog } from 'material-ui-confirmation';
import { MdClose } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  NODE_MODEL_ATTRS,
  LINK_MODEL_ATTRS,
  STROKE_TYPES,
  DEFAULTS,
  MODEL_LINKS,
  UI_DIMENSIONS,
} from '../utils/constants';
import { useMapperTemplateStore } from '../data/mapperTemplates';

const useStyles = makeStyles(theme => ({
  container: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
  tabGroup: {
    minWidth: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  closeBtn: {
    padding: theme.spacing(0, 0, 0.5),
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1.5),
    zIndex: 1,
  },
  card: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH - 4),
    margin: theme.spacing(0, 0, 2, 0),
  },
  key: {
    // textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  meta: {
    margin: theme.spacing(4, 0, 0, 0),
  },
  button: { margin: theme.spacing(1) },
  mapperTemplateName: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function getLinkToBeCreated({ connection: { src, dest, mapperTemplate }, diagram, isPreview }) {
  const srcNode = diagram.findNodeForKey(src[NODE_MODEL_ATTRS.KEY]);
  const destNode = diagram.findNodeForKey(dest[NODE_MODEL_ATTRS.KEY]);

  let link;
  if (srcNode && destNode) {
    link = {
      [LINK_MODEL_ATTRS.TEXT]: '',
      [LINK_MODEL_ATTRS.LABEL]: mapperTemplate.name,
      [LINK_MODEL_ATTRS.STROKE_TYPE]: STROKE_TYPES.SOLID.KEY,
      [LINK_MODEL_ATTRS.STROKE_WIDTH]: DEFAULTS.MAPPER_TEMPLATE_LINK.STROKE_WIDTH,
      [LINK_MODEL_ATTRS.COLOR]: DEFAULTS.MAPPER_TEMPLATE_LINK.COLOR,
      [LINK_MODEL_ATTRS.PREVIEW]: isPreview,
      [LINK_MODEL_ATTRS.FROM]: src[NODE_MODEL_ATTRS.KEY],
      [LINK_MODEL_ATTRS.TO]: dest[NODE_MODEL_ATTRS.KEY],
      [LINK_MODEL_ATTRS.MAPPER_TEMPLATE_CODE]: mapperTemplate.mapperTemplateCode,
      [LINK_MODEL_ATTRS.CATEGORY]: MODEL_LINKS.MAPPER_TEMPLATE,
    };

    // if dest is to the right of src
    if (srcNode.actualBounds.centerX > destNode.actualBounds.centerX) {
      link = {
        ...link,
        [LINK_MODEL_ATTRS.FROM_PORT]: `G:L2::${src[NODE_MODEL_ATTRS.KEY]}`,
        [LINK_MODEL_ATTRS.TO_PORT]: `G:R2::${dest[NODE_MODEL_ATTRS.KEY]}`,
      };
    } else {
      link = {
        ...link,
        [LINK_MODEL_ATTRS.FROM_PORT]: `G:R2::${src[NODE_MODEL_ATTRS.KEY]}`,
        [LINK_MODEL_ATTRS.TO_PORT]: `G:L2::${dest[NODE_MODEL_ATTRS.KEY]}`,
      };
    }
  }

  return link;
}

function useMapperActions(diagram, hideSuggestions) {
  const { getConfirmation } = useConfirmationDialog();
  const { formatMessage } = useIntl();

  const clearAllPreviewConnections = useCallback(() => {
    if (diagram) {
      const oldSkipSettings = diagram.skipsUndoManager;
      diagram.skipsUndoManager = true;

      const txnIdentifier = 'clear all preview links';
      diagram.startTransaction(txnIdentifier);

      const tempLinks = diagram.findLinksByExample({ [LINK_MODEL_ATTRS.PREVIEW]: true });

      diagram.removeParts(tempLinks);

      diagram.commitTransaction(txnIdentifier);

      diagram.skipsUndoManager = oldSkipSettings;
    }
  }, [diagram]);

  const previewConnection = useCallback(
    connection => () => {
      if (diagram) {
        const oldSkipSettings = diagram.skipsUndoManager;
        diagram.skipsUndoManager = true;

        const txnIdentifier = 'display preview links';
        diagram.startTransaction(txnIdentifier);

        const tempLinks = diagram.findLinksByExample({ [LINK_MODEL_ATTRS.PREVIEW]: true });

        diagram.removeParts(tempLinks);

        const linkToBeCreated = getLinkToBeCreated({ connection, diagram, isPreview: true });

        if (linkToBeCreated) {
          diagram.model.addLinkData(linkToBeCreated);
        }

        diagram.commitTransaction(txnIdentifier);

        diagram.skipsUndoManager = oldSkipSettings;
      }
    },
    [diagram]
  );

  const saveConnection = useCallback(
    connection => {
      clearAllPreviewConnections();

      if (diagram) {
        const txnIdentifier = 'create mapper template links';
        diagram.startTransaction(txnIdentifier);

        const linkToBeCreated = getLinkToBeCreated({ connection, diagram });

        if (linkToBeCreated) {
          diagram.model.addLinkData(linkToBeCreated);
        }

        diagram.commitTransaction(txnIdentifier);
      }
    },
    [diagram, clearAllPreviewConnections]
  );

  const initiateConnection = useCallback(
    connection => () => {
      getConfirmation({
        title: formatMessage({ id: 'INITIATE_CONNECTION_TITLE' }),
        body: (
          <DialogContentText>
            <FormattedMessage
              id="INITIATE_CONNECTION_BODY"
              values={{
                source: connection.src[NODE_MODEL_ATTRS.TITLE],
                destination: connection.dest[NODE_MODEL_ATTRS.TITLE],
              }}
            />
          </DialogContentText>
        ),
        onAccept: () => {
          saveConnection(connection);
          hideSuggestions();
        },
      });
    },
    [getConfirmation, hideSuggestions, saveConnection, formatMessage]
  );

  return { clearAllPreviewConnections, previewConnection, initiateConnection };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

export function MapperTemplateSuggestions(props) {
  const classes = useStyles();

  // selector for the mapper templates suggestions
  const suggestedConnections = useMapperTemplateStore(state => state.suggestedConnections);

  /**
   * @type {{diagram: go.Diagram} }
   */
  const { diagram, hideSuggestions } = props;

  const { clearAllPreviewConnections, previewConnection, initiateConnection } = useMapperActions(
    diagram,
    hideSuggestions
  );

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item className={classes.tabGroup}>
          <Grid item className={classes.closeBtn}>
            <IconButton
              aria-label="close"
              size="small"
              color="primary"
              onClick={props.hideSuggestions}
            >
              <MdClose />
            </IconButton>
          </Grid>
          <Tabs
            value={0}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="simple tabs"
          >
            <Tab
              label={
                <Grid item container justifyContent="flex-start" className={classes.title}>
                  <FormattedMessage id="MAPPER_TEMPLATES" />
                </Grid>
              }
              {...a11yProps(0)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <TabPanel value={0} index={0}>
          <Grid container>
            <div onMouseLeave={clearAllPreviewConnections}>
              {suggestedConnections.map(connection => (
                <Card
                  onMouseEnter={previewConnection(connection)}
                  className={classes.card}
                  key={connection.key}
                  variant="outlined"
                >
                  <CardContent>
                    <Typography className={classes.mapperTemplateName} gutterBottom>
                      {connection.mapperTemplate.name}
                    </Typography>
                    {connection.mapperTemplate.description && (
                      <Typography color="textSecondary">
                        {connection.mapperTemplate.description}
                      </Typography>
                    )}
                    <div className={classes.meta}>
                      <Typography variant="body1" component="p">
                        <span className={classes.key}>
                          <FormattedMessage id="SOURCE" /> :{' '}
                        </span>
                        {connection.src[NODE_MODEL_ATTRS.TITLE]}
                      </Typography>
                      <Typography variant="body1" component="p">
                        <span className={classes.key}>
                          <FormattedMessage id="DESTINATION" /> :{' '}
                        </span>
                        {connection.dest[NODE_MODEL_ATTRS.TITLE]}
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions>
                    <Button
                      className={classes.button}
                      disableElevation
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={initiateConnection(connection)}
                    >
                      <FormattedMessage id="APPLY" />
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </div>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}
