/**
 * @file
 *
 * This component is used to render the font specific options for rich text editing on text node
 */
import React from 'react';
import { Grid, Typography, makeStyles, withStyles } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { MdStrikethroughS, MdFormatUnderlined, MdFormatItalic } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { SelectEditField } from './SelectEditField';
import { SizeEdit } from './SizeEdit';
import {
  DEFAULTS,
  FONT_PROPERTIES,
  NODE_MODEL_ATTRS,
  DIMENSIONS,
  TEXTFIELD_WIDTH,
} from '../../utils/constants';
import { getValueWithDefaultFallback } from '../../models/helpers';

const CustomToggleButtonGroup = withStyles(theme => ({
  root: {
    height: '35px',
  },
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles(theme => ({
  root: {
    height: '35px',
    width: '50%',
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[700],
  },
}))(ToggleButton);

const useStyles = makeStyles(theme => ({
  weightGroup: {
    width: '100%',
  },
  toggleGroup: {
    marginTop: theme.spacing(1),
  },
  fontOptions: {
    marginTop: theme.spacing(1),
  },
}));

export function FontOptions(props) {
  const classes = useStyles();

  const toggleButtonStates = [
    props.selectedData[NODE_MODEL_ATTRS.TEXT_ITALIC] && NODE_MODEL_ATTRS.TEXT_ITALIC,
    props.selectedData[NODE_MODEL_ATTRS.TEXT_UNDERLINE] && NODE_MODEL_ATTRS.TEXT_UNDERLINE,
    props.selectedData[NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH] && NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH,
  ].filter(Boolean);

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="FONT" />
            </Typography>
          </Grid>
          <Grid container>
            <SelectEditField
              width={TEXTFIELD_WIDTH.MD}
              onChange={props.onChange}
              attr={NODE_MODEL_ATTRS.FONT_FAMILY}
              value={getValueWithDefaultFallback(
                props.selectedData[NODE_MODEL_ATTRS.FONT_FAMILY],
                DEFAULTS.FONT_PROPERTIES.FAMILY,
                { validValues: FONT_PROPERTIES.FAMILY }
              )}
              options={[FONT_PROPERTIES.FAMILY.SANS_SERIF, FONT_PROPERTIES.FAMILY.SERIF]}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="SIZE" />
            </Typography>
          </Grid>
          <Grid container>
            <SizeEdit
              width={TEXTFIELD_WIDTH.MD}
              onChange={props.onChange}
              attr={NODE_MODEL_ATTRS.FONT_SIZE}
              value={getValueWithDefaultFallback(
                props.selectedData[NODE_MODEL_ATTRS.FONT_SIZE],
                DEFAULTS.FONT_PROPERTIES.SIZE,
                { min: DIMENSIONS.FONT_SIZE.MIN, max: DIMENSIONS.FONT_SIZE.MAX }
              )}
              min={DIMENSIONS.FONT_SIZE.MIN}
              max={DIMENSIONS.FONT_SIZE.MAX}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.fontOptions}
      >
        <Grid item>
          <Grid container>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="STYLE" />
            </Typography>
          </Grid>
          <Grid container>
            <CustomToggleButtonGroup
              size="small"
              className={classes.toggleGroup}
              value={toggleButtonStates}
              aria-label="formatting"
              onChange={event => {
                const attr = event.currentTarget.value;
                const value = !props.selectedData[attr];

                props.onChange({ attr, value });
              }}
            >
              <CustomToggleButton value={NODE_MODEL_ATTRS.TEXT_ITALIC} aria-label="italic">
                <MdFormatItalic size="18px" />
              </CustomToggleButton>
              <CustomToggleButton value={NODE_MODEL_ATTRS.TEXT_UNDERLINE} aria-label="underline">
                <MdFormatUnderlined size="18px" />
              </CustomToggleButton>
              <CustomToggleButton
                value={NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH}
                aria-label="strikethrough"
              >
                <MdStrikethroughS size="18px" />
              </CustomToggleButton>
            </CustomToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="WEIGHT" />
            </Typography>
          </Grid>
          <Grid container>
            <SelectEditField
              width={TEXTFIELD_WIDTH.MD}
              onChange={props.onChange}
              attr={NODE_MODEL_ATTRS.FONT_WEIGHT}
              value={getValueWithDefaultFallback(
                props.selectedData[NODE_MODEL_ATTRS.FONT_WEIGHT],
                DEFAULTS.FONT_PROPERTIES.WEIGHT,
                { validValues: FONT_PROPERTIES.WEIGHT }
              )}
              options={[
                FONT_PROPERTIES.WEIGHT.LIGHTER,
                FONT_PROPERTIES.WEIGHT.NORMAL,
                FONT_PROPERTIES.WEIGHT.BOLD,
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
