/**
 * @file
 *
 * This file includes the model schema specific code for the system entity
 */

import * as go from 'gojs';
import { lightBlue, blueGrey } from '@material-ui/core/colors';

import { UniformColumnLayout } from './extensions/UniformColumnLayout';
import { fonts, bindings, makePort, showPorts } from './helpers';
import { DIMENSIONS, INTEGRATION_INDICATORS, NODE_MODEL_ATTRS } from '../utils/constants';
import { getUserSystemLinkHref } from '../utils/helpers';
import { icons } from './icons';

const $ = go.GraphObject.make;

/**
 * function to check if a particular node can occupy a particular
 * space without overlapping with another node
 *
 * @param {go.Node} node
 * @param {go.Point} gridPoint
 *
 * @returns {go.Point}
 */
export function avoidOverlap(node, gridPoint) {
  const diagram = node.diagram;

  /**
   * traverse the given canvas layers recursively to select all nodes
   *
   * @param {go.GraphObject} obj
   */
  function traverse(obj) {
    const part = obj.part;

    // ignore if it is the selected node itself
    // or a link
    // or a parent of child of the selected node
    if (
      part === node ||
      part instanceof go.Link ||
      part.isMemberOf(node) ||
      node.isMemberOf(part)
    ) {
      return null;
    }

    return part;
  }

  // location of the node
  const { location, actualBounds } = node;

  // tempNode to check if it can fit
  const tempNode = new go.Rect(
    gridPoint.x - (location.x - actualBounds.x),
    gridPoint.y - (location.y - actualBounds.y),
    actualBounds.width,
    actualBounds.height
  );

  // inflate the tempNode to maintain the padding
  tempNode.inflate(0, 10);

  // get all non-temporary layers
  const layers = diagram.layers;
  while (layers.next()) {
    const layer = layers.value;

    if (layer.isTemporary) {
      continue;
    }

    if (layer.findObjectsIn(tempNode, traverse, null, true).count > 0) {
      return node.location;
    }
  }

  return gridPoint;
}

/**
 * custom Part.dragComputation function implementation for limiting where a Node can be dragged
 * inside a group
 *
 * NOTE: we are returning gridPoint for positioning because we have gridSnap enabled
 *
 * @param {go.Part} part
 * @param {go.Point} point
 * @param {go.Point} gridPoint
 *
 * @returns {go.Point}
 */
function stayInGroup(part, point, gridPoint) {
  // fail-safe check if it part of the correct group
  // if it is not then just return the gridPoint
  const group = part.containingGroup;
  if (group === null) {
    return gridPoint;
  }

  // try to stay within the group bounds;
  const groupBounds = group.resizeObject;
  if (groupBounds === null) {
    return gridPoint;
  }

  // get the current location of the node
  const { location } = part;

  const groupTopBound = groupBounds.getDocumentPoint(go.Spot.Top);
  const groupBottomBound = groupBounds.getDocumentPoint(go.Spot.Bottom);

  let { y } = gridPoint;

  const minY = groupTopBound.y + group.placeholder.padding.top;
  const maxY = groupBottomBound.y - group.placeholder.padding.bottom;

  if (y < minY) {
    y = minY + 1;
  } else if (y > maxY) {
    y = maxY - 1;
  }

  const { x } = location;

  return new go.Point(x, y);
}

export const entityGroupTemplate = contextMenu =>
  $(
    go.Group,
    'Auto',
    bindings.location,
    {
      contextMenu,
      mouseEnter: (event, node) => showPorts(node, true),
      mouseLeave: (event, node) => showPorts(node, false),
      background: 'transparent',
      ungroupable: false,
      layout: $(UniformColumnLayout),
      computesBoundsAfterDrag: true,
      handlesDragDropForMembers: true,
      desiredSize: new go.Size(300, NaN),
    },
    bindings.entitySystemCode,
    bindings.entityUserSystemId,
    $(
      go.Shape,
      'RoundedRectangle',
      {
        strokeWidth: 0.5,
      },
      bindings.fillColor.makeTwoWay(),
      bindings.backgroundBasedBorderColor,
      bindings.highlightNode
    ),

    $(
      go.Panel,
      'Vertical',
      {
        stretch: go.GraphObject.Horizontal,
        alignment: go.Spot.Center,
      },
      $(
        go.Panel,
        'Table',
        { defaultAlignment: go.Spot.Left, stretch: go.GraphObject.Fill },
        $(
          go.Shape,
          {
            column: 0,
            margin: new go.Margin(5, 20),
            strokeWidth: 0,
            fill: 'transparent',
            height: 32,
            width: 32,
          },
          bindings.entityLogo,
          bindings.entityLogoColor
        ),
        $(
          go.Panel, // panel for system link icon (contains the circle shape and external link icon)
          'Auto',
          {
            column: 1,
            alignment: go.Spot.Right,
            margin: new go.Margin(5, 10),

            click: (event, graphObject) => {
              const userSystemId = graphObject.part.data[NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID];

              if (userSystemId) {
                const url = getUserSystemLinkHref(userSystemId);

                window.open(url, '_blank');
              }
            },

            cursor: 'pointer',
            visible: false,
          },
          $(go.Shape, 'Circle', {
            fill: '#354a5f',
            height: 32,
            width: 32,
            stroke: null,
          }),
          $(go.Shape, {
            geometryString: icons[INTEGRATION_INDICATORS.SYSTEM_LINKED],
            fill: 'transparent',
            strokeWidth: 2,
            stroke: 'white',
            width: 15,
            height: 15,
          }),
          bindings.showIfSystemIsLinked
        )
      ),
      $(
        go.TextBlock,
        {
          name: 'Text',
          margin: 10,
          font: fonts.entityTitle,
          editable: true,
          minSize: new go.Size(DIMENSIONS.TEXT.MIN, DIMENSIONS.TEXT.MIN),
        },
        bindings.titleText,
        bindings.backgroundBasedFontColor
      ),
      $(go.Placeholder, {
        alignment: go.Spot.Center,
        padding: 20,
      })
    ),
    makePort('G:T1', new go.Spot(0.2, 0)),
    makePort('G:T2', new go.Spot(0.4, 0)),
    makePort('G:T3', new go.Spot(0.6, 0)),
    makePort('G:T4', new go.Spot(0.8, 0)),
    makePort('G:R1', new go.Spot(1, 0.05)),
    makePort('G:R2', new go.Spot(1, 0.35)),
    makePort('G:R3', new go.Spot(1, 0.65)),
    makePort('G:R4', new go.Spot(1, 0.95)),
    makePort('G:B1', new go.Spot(0.2, 1)),
    makePort('G:B2', new go.Spot(0.4, 1)),
    makePort('G:B3', new go.Spot(0.6, 1)),
    makePort('G:B4', new go.Spot(0.8, 1)),
    makePort('G:L1', new go.Spot(0, 0.05)),
    makePort('G:L2', new go.Spot(0, 0.35)),
    makePort('G:L3', new go.Spot(0, 0.65)),
    makePort('G:L4', new go.Spot(0, 0.95))
  );

/**
 * function to lazily generate the fieldsGroupTemplate
 *
 * @param {go.Adornment} contextMenu
 */
export const createFieldsGroupTemplate = contextMenu =>
  $(
    go.Group,
    'Auto',
    bindings.location,
    {
      mouseEnter: (event, node) => showPorts(node, true),
      mouseLeave: (event, node) => showPorts(node, false),
      contextMenu,
      movable: true,
      copyable: true,
      background: 'transparent',
      ungroupable: false,
      layout: $(UniformColumnLayout),
      computesBoundsAfterDrag: true,
      handlesDragDropForMembers: true,
      desiredSize: new go.Size(260, NaN),
      dragComputation: stayInGroup,
    },
    $(
      go.Shape,
      'RoundedRectangle',
      bindings.fillColor.makeTwoWay(),
      bindings.strokeType,
      bindings.strokeTypeBaseStrokeWidth,
      bindings.backgroundBasedBorderColor
    ),
    $(
      go.Panel,
      'Vertical',
      {
        alignment: go.Spot.Center,
      },
      $(
        go.TextBlock,
        {
          name: 'Text',
          margin: 10,
          wrap: go.TextBlock.WrapFit,
          font: fonts.entityFieldGroupTitle,
          editable: true,
          minSize: new go.Size(DIMENSIONS.TEXT.MIN, DIMENSIONS.TEXT.MIN),
        },
        bindings.titleText,
        bindings.backgroundBasedFontColor
      ),
      $(go.Placeholder, {
        alignment: go.Spot.Center,
        padding: 20,
      })
    ),
    makePort('FG:T1', new go.Spot(0.2, 0)),
    makePort('FG:T2', new go.Spot(0.4, 0)),
    makePort('FG:T3', new go.Spot(0.6, 0)),
    makePort('FG:T4', new go.Spot(0.8, 0)),
    makePort('FG:R1', new go.Spot(1, 0.05)),
    makePort('FG:R2', new go.Spot(1, 0.35)),
    makePort('FG:R3', new go.Spot(1, 0.65)),
    makePort('FG:R4', new go.Spot(1, 0.95)),
    makePort('FG:B1', new go.Spot(0.2, 1)),
    makePort('FG:B2', new go.Spot(0.4, 1)),
    makePort('FG:B3', new go.Spot(0.6, 1)),
    makePort('FG:B4', new go.Spot(0.8, 1)),
    makePort('FG:L1', new go.Spot(0, 0.05)),
    makePort('FG:L2', new go.Spot(0, 0.35)),
    makePort('FG:L3', new go.Spot(0, 0.65)),
    makePort('FG:L4', new go.Spot(0, 0.95))
  );

/**
 * a custom resize selection adornment
 */
const customVerticalResizeAdornment = $(
  go.Adornment,
  'Spot',
  $(go.Placeholder),
  $(go.Shape, {
    alignment: go.Spot.Bottom,
    desiredSize: new go.Size(50, 7),
    fill: lightBlue[300],
    stroke: blueGrey[200],
    cursor: 'row-resize',
  })
);

/**
 * function to create the fieldNodeTemplate
 * @param {go.Adornment} contextMenu
 */
export const createFieldNodeTemplate = contextMenu =>
  $(
    go.Node,
    'Auto',
    bindings.location,
    bindings.size,
    {
      contextMenu,
      resizeAdornmentTemplate: customVerticalResizeAdornment,
      movable: true,
      copyable: true,
      stretch: go.GraphObject.Vertical,
      desiredSize: new go.Size(260, NaN),
      minSize: new go.Size(220, 60),
      resizable: true,
      mouseEnter: (event, node) => showPorts(node, true),
      mouseLeave: (event, node) => showPorts(node, false),
      dragComputation: stayInGroup,
    },
    $(
      go.Panel,
      'Auto',
      $(
        go.Shape,
        'RoundedRectangle',
        {
          fill: 'rgba(255, 255, 255, 0.7',
          stroke: 'rgba(0, 0, 0, .2)',
        },
        bindings.strokeType,
        bindings.strokeTypeBaseStrokeWidth
      ),
      $(
        go.TextBlock,
        {
          wrap: go.TextBlock.WrapFit,
          editable: true,
          margin: new go.Margin(10, 5),
          font: fonts.entityField,
          alignment: go.Spot.Center,
        },
        bindings.text
      )
    ),
    makePort('F:T1', new go.Spot(0.2, 0)),
    makePort('F:T2', new go.Spot(0.4, 0)),
    makePort('F:T3', new go.Spot(0.6, 0)),
    makePort('F:T4', new go.Spot(0.8, 0)),
    makePort('F:R1', new go.Spot(1, 0.1)),
    makePort('F:R2', new go.Spot(1, 0.5)),
    makePort('F:R3', new go.Spot(1, 0.9)),
    makePort('F:B1', new go.Spot(0.2, 1)),
    makePort('F:B2', new go.Spot(0.4, 1)),
    makePort('F:B3', new go.Spot(0.6, 1)),
    makePort('F:B4', new go.Spot(0.8, 1)),
    makePort('F:L1', new go.Spot(0, 0.1)),
    makePort('F:L2', new go.Spot(0, 0.5)),
    makePort('F:L3', new go.Spot(0, 0.9))
  );

/**
 * function to create the fieldSpacerNodeTemplate
 * @param {go.Adornment} contextMenu
 */
export const createFieldSpacerNodeTemplate = contextMenu =>
  $(
    go.Node,
    'Auto',
    bindings.location,
    bindings.size,
    {
      contextMenu,
      resizeAdornmentTemplate: customVerticalResizeAdornment,
      movable: true,
      copyable: true,
      stretch: go.GraphObject.Vertical,
      desiredSize: new go.Size(260, NaN),
      minSize: new go.Size(220, 10),
      resizable: true,
      dragComputation: stayInGroup,
    },
    $(
      go.Panel,
      'Auto',
      $(go.Shape, 'RoundedRectangle', {
        fill: 'transparent',
        strokeWidth: 2,
        stroke: 'transparent',
      })
    )
  );
