import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import create from 'zustand';
import produce from 'immer';
import { cloneDeep, sortBy, remove } from 'lodash-es';
import hashSum from 'hash-sum';
import * as go from 'gojs';
import { useIntl } from 'react-intl';
import { noop } from 'lodash-es';
import toast from 'react-hot-toast';

import {
  digitalTransformerApiBase,
  getLambdaServiceInstance,
  getServiceInstance,
} from '../utils/service';
import { generatePreviewImage } from '../utils/helpers';
import { useEffectUntilTrue } from '../hooks/useEffectVariants';
import { useNotifyError } from '../hooks/useNotifyError';
import {
  ENTITY_FIELD_FORM_CHANGE_TYPE,
  GROUP_NODES,
  LINK_MODEL_ATTRS,
  MODEL_LINKS,
  MODEL_NODES,
  NODE_DERIVED_ATTRS,
  NODE_MODEL_ATTRS,
  SHEET_MODES,
} from '../utils/constants';

export const dirtySheets = new Map();

export const addVersionQueryString = version => (version ? `?projectVersion=${version}` : '');

/**
 * function to get initial sheet state from API response
 *
 * @param {object} sheet      API response for sheet data
 * @param {boolean} isActiveVersion
 *
 * @return {object}
 */
const getInitialSheetState = (sheet, isActiveVersion) => {
  const name = sheet.sheet_name || 'Initial';
  const nodeDataArray = sheet.sheet_data ? sheet.sheet_data.nodeDataArray : [];
  const linkDataArray = sheet.sheet_data ? sheet.sheet_data.linkDataArray : [];
  const integrationMode = sheet.sheet_data?.modelData
    ? sheet.sheet_data.modelData[SHEET_MODES.INTEGRATION_MODE.KEY]
    : true;
  const detailsMode = sheet.sheet_data?.modelData
    ? sheet.sheet_data.modelData[SHEET_MODES.DETAILS_MODE.KEY]
    : true;

  return {
    name,
    main: sheet.is_primary,
    nodeDataArray,
    linkDataArray,
    modelData: {
      isReadOnly: !isActiveVersion,
      scale: null,
      integrationMode,
      detailsMode,
    },
    selectedData: null,
    skipsDiagramUpdate: false,
    syncState: {
      syncing: false,
      dirty: false,
    },
  };
};

const initialProjectState = {
  loading: false,
  error: null,
  sheets: {},
  versions: [],
};

const dummyProject = {
  name: '',
  description: '',
  createdAt: new Date().toDateString(),
  updatedAt: new Date().toDateString(),
  ...initialProjectState,
  version: 1,
  versionComment: '',
  isActiveVersion: false,
};

/**
 * function to select a particular project state slice
 *
 * @param {object} projects      hash map of all projects state
 * @param {string} projectId    id of the project to be selected
 *
 * @returns {object}
 */
const getProjectState = (projects, projectId) => projects[projectId];

/**
 * function to select the sheet state slice from a given project state slice
 *
 * @param {object} project      state slice of a particular project
 * @param {string} sheetKey     id of the sheet to be selected
 *
 * @return {object}
 */
const getSheetStateFromProject = (project, sheetKey) => project && project.sheets[sheetKey];

/**
 * function to select a particular sheet state slice
 *
 * @param {object} projects      hash map of all projects state
 * @param {string} projectId    id of the project to be selected
 * @param {string} sheetKey     id of the sheet to be selected
 *
 * @return {object}
 */
export const getSheetState = (projects, projectId, sheetKey) => {
  const project = getProjectState(projects, projectId);

  return project && project.sheets && project.sheets[sheetKey];
};

const refreshMapKeyIndex = (mapKeyIndex, array) => {
  mapKeyIndex.clear();

  array.forEach((item, index) => {
    mapKeyIndex.set(item.key, index);
  });
};

// When we add a sheet template or when we copy a sheet, we need to update the links based
// on the sheet_mode so that everything is in sync. This function initializes the links
// based on the sheet mode.
const initializeLinkDataArray = (linkDataArray, destinationModelData) => {
  return linkDataArray.map(link => {
    if (checkIfLinkIsAnIntegrationLink(link)) {
      link[LINK_MODEL_ATTRS.IS_VISIBLE] = destinationModelData[SHEET_MODES.INTEGRATION_MODE.KEY];
    } else {
      link[LINK_MODEL_ATTRS.IS_VISIBLE] = destinationModelData[SHEET_MODES.DETAILS_MODE.KEY];
    }

    return link;
  });
};

// Function to check whether a link is an integration or not
const checkIfLinkIsAnIntegrationLink = link => {
  return (
    link[LINK_MODEL_ATTRS.CATEGORY] === MODEL_LINKS.MAPPER_TEMPLATE ||
    link[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID] ||
    (link[LINK_MODEL_ATTRS.FROM_PORT].startsWith('G') &&
      link[LINK_MODEL_ATTRS.TO_PORT].startsWith('G'))
  );
};

export const useProjectStore = create(set => ({
  projects: {},

  setState: fn => set(produce(fn)),
}));

export function useProjects({ enabled }) {
  // fetch projects from API
  const { isLoading, isFetching, error, refetch, ...rest } = useQuery({
    queryKey: `${digitalTransformerApiBase}/project`,
    enabled,
  });

  useNotifyError({ error, mustRedirect: true });

  const fetchProjects = useCallback(() => {
    // once the refetch of the latest data has settled we will save the updated data in the state
    refetch().then(response => {
      let projects = {};
      if (response.data) {
        projects = response.data.reduce(
          (acc, project) => ({
            ...acc,
            ...{
              [project.digital_transformer_project_id]: {
                name: project.name,
                description: project.description,
                createdAt: project.created_at,
                updatedAt: project.updated_at,
                version: project.digital_transformer_project_version,
                isActiveVersion: project.is_active_version,
                versionComment: project.version_comment,

                ...initialProjectState,
              },
            },
          }),
          {}
        );
      }

      const { setState } = useProjectStore.getState();
      setState(state => {
        state.projects = projects;
      });
    });

    // we don't want refetchProjects to get a new reference everytime the reference of refetch() changes
    // so we are disabling eslint for the dependencies of the callback hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isProjectsLoading: isFetching || isLoading, fetchProjects, ...rest };
}

const createNewProject = ({ values }) =>
  getServiceInstance().post(`${digitalTransformerApiBase}/project`, values);
export const useCreateNewProjectMutation = () => {
  const { formatMessage } = useIntl();

  const mutation = useMutation(({ values }) => createNewProject({ values }), {
    onSuccess: data => {
      const { setState } = useProjectStore.getState();

      const projectDetails = {
        id: data.digital_transformer_project_id,
        name: data.name,
        description: data.description,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        version: data.digital_transformer_project_version,
        isActiveVersion: data.is_active_version,
        versionComment: data.version_comment,

        ...initialProjectState,
      };

      setState(state => {
        state.projects = {
          [projectDetails.id]: projectDetails,
          ...state.projects,
        };
      });
    },
  });

  useNotifyError({
    error: mutation.error,
    fallbackMessage: formatMessage({ id: 'FAILED_TO_CREATE_PROJECT' }),
  });

  return mutation;
};

const updateProjectDetails = ({ projectId, version, values }) =>
  getServiceInstance().put(
    `${digitalTransformerApiBase}/project/${projectId}${addVersionQueryString(version)}`,
    values
  );
export const useUpdateProjectDetailsMutation = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const mutation = useMutation(
    ({ projectId, version, values }) => updateProjectDetails({ projectId, version, values }),
    {
      onSuccess: (data, { projectId }) => {
        const { setState } = useProjectStore.getState();

        setState(store => {
          const project = getProjectState(store.projects, data.digital_transformer_project_id);

          if (project) {
            project.name = data.name;
            project.description = data.description;
            project.updatedAt = data.updated_at;
          }
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(`${digitalTransformerApiBase}/project`);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: formatMessage({ id: 'FAIL_UPDATE_PROJECT' }),
  });

  return mutation;
};

export function useProjectSheets({ enabled, version, projectId }) {
  // fetch projects from API
  const {
    data: projectResponse,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
    error: projectsError,
    refetch: refetchProjects,
  } = useQuery({
    queryKey: `${digitalTransformerApiBase}/project/${projectId}`,
    enabled,
  });
  // fetch sheets from API (conditionally for a particular version of the project)
  const {
    data: sheetResponse,
    isLoading: isSheetsLoading,
    isFetching: isSheetsFetching,
    error: sheetsError,
    refetch: refetchSheets,
  } = useQuery({
    queryKey: `${digitalTransformerApiBase}/project/${projectId}/sheet${addVersionQueryString(
      version
    )}`,
    enabled,
  });

  const sheets = useMemo(() => {
    if (sheetResponse && projectResponse) {
      const selectedProject = projectResponse.find(p =>
        version ? p.digital_transformer_project_version === version : p.is_active_version
      );
      return sheetResponse.reduce(
        (stateSlice, sheet) => ({
          ...stateSlice,
          ...{
            [sheet.sheet_id]: getInitialSheetState(sheet, selectedProject.is_active_version),
          },
        }),
        {}
      );
    }

    return null;
  }, [projectResponse, sheetResponse, version]);

  const versions = useMemo(() => {
    if (projectResponse) {
      return projectResponse.map(version => ({
        number: version.digital_transformer_project_version,
        comment: version.version_comment,
        isActive: version.is_active_version,
        updatedAt: version.updated_at,
      }));
    }

    return null;
  }, [projectResponse]);

  useEffect(() => {
    const { setState } = useProjectStore.getState();

    setState(state => {
      const project = getProjectState(state.projects, projectId);

      if (!project) {
        return;
      }

      project.loading =
        isSheetsLoading || isProjectsLoading || isSheetsFetching || isProjectsFetching;
      project.error = sheetsError || projectsError || null;
    });
  }, [
    isProjectsLoading,
    isSheetsLoading,
    projectsError,
    sheetsError,
    sheets,
    versions,
    projectId,
    isSheetsFetching,
    isProjectsFetching,
  ]);

  const reset = useEffectUntilTrue(() => {
    if (isSheetsLoading || isProjectsLoading || !sheets || !versions) {
      return false;
    }

    const { setState } = useProjectStore.getState();
    setState(state => {
      const project = getProjectState(state.projects, projectId);

      if (!project) {
        return;
      }

      project.sheets = sheets;
      project.versions = versions;
    });

    return true;
  });

  const refetchProjectSheets = useCallback(async () => {
    await refetchProjects();
    await refetchSheets();

    reset(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { refetchProjectSheets };
}

export const sheetStateActions = {
  renameSheet: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);

      if (!sheetState) {
        return;
      }

      if (sheetState.name !== payload.sheetName) {
        sheetState.name = payload.sheetName;

        sheetState.syncState.dirty = true;
        dirtySheets.set(payload.sheetKey, payload.sheetName);
      }
    });
  },
  copySheet: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const project = getProjectState(state.projects, payload.projectId);

      const fromSheetData = getSheetStateFromProject(project, payload.fromSheetKey);
      const toSheetData = getSheetStateFromProject(project, payload.toSheetKey);

      if (project && fromSheetData && toSheetData) {
        const nodeDataArray = cloneDeep(fromSheetData.nodeDataArray);
        const linkDataArray = cloneDeep(fromSheetData.linkDataArray);

        toSheetData.nodeDataArray = nodeDataArray;
        toSheetData.linkDataArray = initializeLinkDataArray(linkDataArray, toSheetData.modelData);
        toSheetData.skipsDiagramUpdate = false;

        toSheetData.syncState.dirty = true;
        dirtySheets.set(payload.toSheetKey, toSheetData.name);

        refreshMapKeyIndex(payload.indexMaps.mapNodeKeyIndex, nodeDataArray);
        refreshMapKeyIndex(payload.indexMaps.mapLinkKeyIndex, linkDataArray);
      }
    });
  },
  copyTemplate: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);

      const nodeDataArray = cloneDeep(payload.sheetData.nodeDataArray);
      const linkDataArray = cloneDeep(payload.sheetData.linkDataArray);

      sheetState.nodeDataArray = nodeDataArray;
      sheetState.linkDataArray = initializeLinkDataArray(linkDataArray, sheetState.modelData);
      sheetState.skipsDiagramUpdate = false;

      sheetState.syncState.dirty = true;
      dirtySheets.set(payload.sheetKey, sheetState.name);

      refreshMapKeyIndex(payload.indexMaps.mapNodeKeyIndex, nodeDataArray);
      refreshMapKeyIndex(payload.indexMaps.mapLinkKeyIndex, linkDataArray);
    });
  },
  updateCurrentEditorState: (payload, initialSheetHydration) => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const project = getProjectState(state.projects, payload.projectId);
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);

      // On initial sheet load, if the canvas already had nodes in it, then GoJS is going to trigger
      // the OnModalChange event (this is because  on sheet load, the nodes got initialized in the
      // canvas, and everytime the canvas state changes onModalChange event is triggered)
      //
      // Now if there are nodes present in the canvas and if the handleModalChange function is going to
      // be triggered in the initial-load, then the dirty state of the sheet will be set to true because of the
      // logic in the end of the function that sets the sheet to dirty everything there is a change.
      //
      // the initial load is not a change to the sheet so the sheet should not become dirty which is why we have
      // written the below logic.
      //
      // initialSheetHydration is a ref which gets updated whenever the projectId or sheetKey changes. It lets
      // us know if the sheet loaded for the first time or not. Now if it is not an initial load, then we set
      // shouldDirtySheet to true since we don't want to make any changes to the underlying dirtying logic (sheet
      // becomes dirty whenever there is a change in the sheet). We only want to customize the logic when the sheet
      // is loaded for the first time.
      //
      // When the sheet is loaded for the first time and if the sheet is empty, then handleModalChange is not going
      // to be triggered. So if we add a node to the canvas, initialSheetHydration is still going to be true since
      // we are setting the initialSheetHydration ref back to false only after the first sheet update happens and the
      // first update didn't happen. Which is why we are checking if the nodeDataArray is empty or not. If it is empty
      // It means that we are going to make the first update after the initial load and the sheet should become dirty
      //
      // If it is an initial-load and the sheet already has nodes in it then we want to prevent the sheet from
      // getting dirty
      let shouldDirtySheet;
      if (initialSheetHydration.current) {
        if (sheetState?.nodeDataArray?.length === 0) {
          shouldDirtySheet = true;
        } else {
          shouldDirtySheet = false;
        }
      } else {
        shouldDirtySheet = true;
      }

      const { modelChangeIncrementalData, indexMaps } = payload;

      const {
        insertedNodeKeys,
        modifiedNodeData,
        removedNodeKeys,
        insertedLinkKeys,
        modifiedLinkData,
        removedLinkKeys,
        modelData: modifiedModelData,
      } = modelChangeIncrementalData;

      // check if this is the initial mount and skip redux update as the change came from Redux
      // this redundant event gets triggered because of our custom initial zoom setup
      const newNodeKeys = insertedNodeKeys || [];
      const newLinkKeys = insertedLinkKeys || [];
      if (
        newNodeKeys.length === sheetState.nodeDataArray.length &&
        newLinkKeys.length === sheetState.linkDataArray.length
      ) {
        const eventHashSum = hashSum(sortBy([...newNodeKeys, ...newLinkKeys]));
        const stateHashSum = hashSum(
          sortBy([
            ...sheetState.nodeDataArray.map(i => i.key),
            ...sheetState.linkDataArray.map(i => i.key),
          ])
        );

        if (eventHashSum === stateHashSum) {
          return;
        }
      }

      // these are the maps maintained in the component state in the Project component
      const { mapNodeKeyIndex, mapLinkKeyIndex } = indexMaps;

      // maintaining maps of modified data so insertions don't need slow lookups
      const modifiedNodeMap = new Map();
      const modifiedLinkMap = new Map();

      if (modifiedNodeData) {
        // iterate over node data update
        modifiedNodeData.forEach(nodeData => {
          // update our map cache with the latest node data
          modifiedNodeMap.set(nodeData.key, nodeData);

          const index = mapNodeKeyIndex.get(nodeData.key);

          // this is for updates to existing nodes
          // update data in the map cache and state for existing nodes
          if (index !== undefined && index >= 0) {
            sheetState.nodeDataArray[index] = nodeData;

            // if the updated node was also the selected node then replicate the changes in the selected data state as well
            if (
              sheetState.selectedData &&
              sheetState.selectedData.length === 1 &&
              sheetState.selectedData[0].key === nodeData.key
            ) {
              sheetState.selectedData[0] = nodeData;
            }
          }
        });
      }

      if (insertedNodeKeys) {
        // this is to add newly added nodes
        insertedNodeKeys.forEach(key => {
          // we already added all updates to the local map cache
          // we are now fetching data for the newly added nodes from that cache
          const nodeData = modifiedNodeMap.get(key);
          const index = mapNodeKeyIndex.get(key);

          if (nodeData && index === undefined) {
            // update the state and map cache
            mapNodeKeyIndex.set(key, sheetState.nodeDataArray.length);
            sheetState.nodeDataArray.push(nodeData);
          }
        });
      }

      if (removedNodeKeys) {
        // this is to remove nodes that were deleted
        sheetState.nodeDataArray = sheetState.nodeDataArray.filter(
          nodeData => !removedNodeKeys.includes(nodeData.key)
        );
        refreshMapKeyIndex(mapNodeKeyIndex, sheetState.nodeDataArray);
      }

      //
      if (modifiedLinkData) {
        // iterate over node data update
        modifiedLinkData.forEach(linkData => {
          // update our map cache with the latest link data
          modifiedLinkMap.set(linkData.key, linkData);

          const index = mapLinkKeyIndex.get(linkData.key);

          // this is for updates to existing links
          // update data in the map cache and state for existing links
          if (index !== undefined && index >= 0) {
            sheetState.linkDataArray[index] = linkData;

            // if the updated link was also the selected link then replicate the changes in the selected data state as well
            if (
              sheetState.selectedData &&
              sheetState.selectedData.length === 1 &&
              sheetState.selectedData[0].key === linkData.key
            ) {
              sheetState.selectedData[0] = linkData;
            }
          }
        });
      }

      if (insertedLinkKeys) {
        // this is to add newly added links
        insertedLinkKeys.forEach(key => {
          // we already added all updates to the local map cache
          // we are now fetching data for the newly added links from that cache
          const linkData = modifiedLinkMap.get(key);
          const index = mapLinkKeyIndex.get(key);

          if (linkData && index === undefined) {
            // update the state and map cache
            mapLinkKeyIndex.set(linkData.key, sheetState.linkDataArray.length);
            sheetState.linkDataArray.push(linkData);
          }
        });
      }

      if (removedLinkKeys) {
        // this is to remove links that were deleted
        sheetState.linkDataArray = sheetState.linkDataArray.filter(
          linkData => !removedLinkKeys.includes(linkData.key)
        );
        refreshMapKeyIndex(mapLinkKeyIndex, sheetState.linkDataArray);
      }

      // updating model data changes
      if (modifiedModelData) {
        sheetState.modelData = modifiedModelData;
      }

      // the GoJS model already know about the updates
      // so skip updating it
      sheetState.skipsDiagramUpdate = true;

      if (project.isActiveVersion && shouldDirtySheet) {
        sheetState.syncState.dirty = true;
        dirtySheets.set(payload.sheetKey, sheetState.name);
      }

      initialSheetHydration.current = false;
    });
  },
  updateSelectedData: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);

      const {
        selectedData,
        indexMaps: { mapNodeKeyIndex, mapLinkKeyIndex },
      } = payload;

      const getPartData = part => {
        if (part instanceof go.Node) {
          const index = mapNodeKeyIndex.get(part.key);
          if (index !== undefined && index >= 0) {
            const nodeData = sheetState.nodeDataArray[index];
            return nodeData;
          }
        } else if (part instanceof go.Link) {
          const index = mapLinkKeyIndex.get(part.key);
          if (index !== undefined && index >= 0) {
            const linkData = sheetState.linkDataArray[index];
            return linkData;
          }
        }
        return null;
      };

      if (selectedData && selectedData.length) {
        sheetState.selectedData = selectedData.map(getPartData).filter(Boolean);
      } else {
        sheetState.selectedData = null;
      }
    });
  },
  clearSelectedData: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);

      if (sheetState) {
        sheetState.selectedData = null;
      }
    });
  },
  updateSelectedDataAttribute: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);

      if (!sheetState) {
        return;
      }

      const {
        indexMaps: { mapNodeKeyIndex, mapLinkKeyIndex },
        change,
      } = payload;

      if (sheetState.selectedData) {
        sheetState.selectedData.forEach(part => {
          if (
            (change.attr === NODE_DERIVED_ATTRS.DIAMETER &&
              part[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.CIRCLE) ||
            (change.attr === NODE_MODEL_ATTRS.SIZE &&
              part[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.SQUARE)
          ) {
            part[NODE_MODEL_ATTRS.SIZE] = go.Size.stringify(
              new go.Size(Number(change.value), Number(change.value))
            );
          } else if (change.attr === NODE_DERIVED_ATTRS.WIDTH && part[NODE_MODEL_ATTRS.SIZE]) {
            const { height } = go.Size.parse(part.size);
            part[NODE_MODEL_ATTRS.SIZE] = go.Size.stringify(
              new go.Size(Number(change.value), height)
            );
          } else if (change.attr === NODE_DERIVED_ATTRS.HEIGHT && part[NODE_MODEL_ATTRS.SIZE]) {
            const { width } = go.Size.parse(part.size);
            part[NODE_MODEL_ATTRS.SIZE] = go.Size.stringify(
              new go.Size(width, Number(change.value))
            );
          } else if (
            change.attr === NODE_DERIVED_ATTRS.FIELDS &&
            [GROUP_NODES.ENTITY_GROUP, GROUP_NODES.FIELDS_GROUP].includes(part.category)
          ) {
            if (change.type === ENTITY_FIELD_FORM_CHANGE_TYPE.INSERT) {
              sheetState.skipsDiagramUpdate = true;
              return;
            } else if (change.type === ENTITY_FIELD_FORM_CHANGE_TYPE.UPDATE && change.fieldKey) {
              const index = mapNodeKeyIndex.get(change.fieldKey);
              if (index !== undefined && index >= 0) {
                sheetState.nodeDataArray[index].text = change.value;
              }
            } else if (change.type === ENTITY_FIELD_FORM_CHANGE_TYPE.DELETE && change.fieldKey) {
              const index = mapNodeKeyIndex.get(change.fieldKey);
              if (index !== undefined && index >= 0) {
                sheetState.nodeDataArray.splice(index, 1);
                refreshMapKeyIndex(mapNodeKeyIndex, sheetState.nodeDataArray);
              }

              remove(sheetState.linkDataArray, link => {
                if (link.from === change.fieldKey || link.to === change.fieldKey) {
                  mapLinkKeyIndex.delete(link.key);

                  return true;
                }

                return false;
              });
            }
          } else if (change.attr === NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID) {
            if (change.value) {
              part[NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED] = true;
            } else {
              part[NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED] = false;
            }

            part[NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID] = change.value;
          } else {
            part[change.attr] = change.value;
          }
        });

        if (change.shouldUpdate) {
          sheetState.selectedData.forEach(part => {
            const key = part.key;

            if (key.startsWith('node')) {
              const index = mapNodeKeyIndex.get(key);
              if (index !== undefined && index >= 0) {
                sheetState.nodeDataArray[index] = part;
              }
            } else {
              const index = mapLinkKeyIndex.get(key);
              if (index !== undefined && index >= 0) {
                sheetState.linkDataArray[index] = part;
              }
            }
          });

          sheetState.skipsDiagramUpdate = false;
        }

        sheetState.syncState.dirty = true;
        dirtySheets.set(payload.sheetKey, sheetState.name);
      }
    });
  },
  toggleSheetMode: (projectId, sheetKey, mode) => event => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, projectId, sheetKey);

      if (!sheetState || !sheetState.linkDataArray) {
        return;
      }

      const status = event.target.checked;
      sheetState.modelData[mode] = status;

      if (mode === SHEET_MODES.INTEGRATION_MODE.KEY) {
        sheetState.linkDataArray.forEach(link => {
          if (checkIfLinkIsAnIntegrationLink(link)) {
            link[LINK_MODEL_ATTRS.IS_VISIBLE] = status;
          }
        });
      } else {
        sheetState.linkDataArray.forEach(link => {
          if (!checkIfLinkIsAnIntegrationLink(link)) {
            link[LINK_MODEL_ATTRS.IS_VISIBLE] = status;
          }
        });
      }

      sheetState.skipsDiagramUpdate = false;
    });
  },
  updateResizedNode: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);

      const { mapNodeKeyIndex } = payload;

      const key = payload.resizedNode.key;
      const index = mapNodeKeyIndex.get(key);
      if (index !== undefined && index >= 0) {
        sheetState.nodeDataArray[index].size = payload.resizedNode.data.size;
        sheetState.skipsDiagramUpdate = true;
      }

      const selectedPart = sheetState.selectedData.find(part => part.key === key);
      if (selectedPart) {
        selectedPart.size = payload.resizedNode.data.size;
      }

      sheetState.syncState.dirty = true;
      dirtySheets.set(payload.sheetKey, sheetState.name);
    });
  },
  toggleDiagramModelState: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const project = getProjectState(state.projects, payload.projectId);
      const sheetState = getSheetStateFromProject(project, payload.sheetKey);

      if (project.isActiveVersion) {
        sheetState.modelData.isReadOnly = payload.isReadOnly;
      }
    });
  },
  updateSheetScale: payload => {
    const { setState } = useProjectStore.getState();
    setState(state => {
      const sheetState = getSheetState(state.projects, payload.projectId, payload.sheetKey);
      sheetState.modelData.scale = payload.scale;
    });
  },
};

// All mutations related to sheet
const createSheet = ({ projectId, version, sheetName }) =>
  getServiceInstance().post(
    `${digitalTransformerApiBase}/project/${projectId}/sheet${addVersionQueryString(version)}`,
    {
      sheet_name: sheetName,
    }
  );
export const useCreateSheetMutation = () => {
  const { formatMessage } = useIntl();

  const mutation = useMutation(
    ({ projectId, version, sheetName }) => createSheet({ projectId, version, sheetName }),
    {
      onSuccess: (data, { projectId, version }) => {
        // adding new sheet to zustand state
        const { setState } = useProjectStore.getState();
        setState(state => {
          const project = getProjectState(state.projects, data.digital_transformer_project_id);

          if (project) {
            project.sheets[data.sheet_id] = getInitialSheetState(data, project.isActiveVersion);
          }
        });
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: formatMessage({ id: 'FAIL_CREATE_SHEET' }),
  });

  return mutation;
};

const deleteSheet = ({ projectId, sheetKey }) =>
  getServiceInstance().delete(
    `${digitalTransformerApiBase}/project/${projectId}/sheet/${sheetKey}`
  );
export const useDeleteSheetMutation = () => {
  const { setState } = useProjectStore.getState();

  const mutation = useMutation(({ projectId, sheetKey }) => deleteSheet({ projectId, sheetKey }), {
    onMutate: ({ projectId, sheetKey }) => {
      setState(state => {
        const project = getProjectState(state.projects, projectId);

        if (project) {
          const sheet = getSheetStateFromProject(project, sheetKey);

          if (sheet) {
            sheet.syncState.syncing = true;
          }
        }
      });
    },
    onSuccess: (data, { sheetKey, projectId, version }) => {
      // update zustand state
      setState(state => {
        const project = getProjectState(state.projects, projectId);

        if (project) {
          if (!project.sheets[sheetKey].main) {
            delete project.sheets[sheetKey];
          }
        }
      });
    },
    onError: (error, { projectId, sheetKey }) => {
      setState(state => {
        const project = getProjectState(state.projects, projectId);

        if (project) {
          const sheet = getSheetStateFromProject(project, sheetKey);

          if (sheet) {
            sheet.syncState.syncing = false;
          }

          project.error = error;
        }
      });
    },
  });

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to delete sheet',
  });

  return mutation;
};

const saveSheet = ({ projectId, sheetKey, sheet }) =>
  getServiceInstance().put(
    `${digitalTransformerApiBase}/project/${projectId}/sheet/${sheetKey}`,
    sheet
  );
export const useSaveSheetMutation = () => {
  const { setState } = useProjectStore.getState();

  const mutation = useMutation(
    ({ projectId, sheetKey, sheet }) => saveSheet({ projectId, sheetKey, sheet }),
    {
      onMutate: ({ projectId, sheetKey }) => {
        setState(state => {
          const sheetState = getSheetState(state.projects, projectId, sheetKey);

          sheetState.syncState.syncing = true;
          sheetState.syncState.dirty = false;
          dirtySheets.delete(sheetKey);
        });
      },
      onSuccess: (data, { projectId, sheetKey, version }) => {
        // updating zustand state
        setState(state => {
          const sheetState = getSheetState(state.projects, projectId, sheetKey);

          sheetState.syncState.syncing = false;
        });
      },
      onError: (error, { projectId, sheetKey }) => {
        setState(state => {
          const project = getProjectState(state.projects, projectId);

          if (project) {
            const sheet = getSheetStateFromProject(project, sheetKey);

            if (sheet) {
              sheet.syncState.syncing = false;
              sheet.syncState.dirty = true;
              dirtySheets.set(sheetKey, sheet.name);
            }

            project.error = error;
          }
        });
      },
    }
  );

  return mutation;
};

const createTemplate = ({ sheetKey, projectId, values }) =>
  getServiceInstance().post(`${digitalTransformerApiBase}/template`, {
    sheetId: sheetKey,
    transformerProjectId: projectId,
    name: values.name,
    description: values.description,
  });
export const useCreateTemplateMutation = () => {
  const { formatMessage } = useIntl();
  const mutation = useMutation(
    ({ sheetKey, projectId, values }) => createTemplate({ sheetKey, projectId, values }),
    {
      onSuccess: (data, { values, diagramInstance }) => {
        toast.success(formatMessage({ id: 'SUCCESS_TEMPLATE_CREATE' }, { name: values.name }), {
          id: 'create-template-success',
        });

        const base64Image = generatePreviewImage(diagramInstance);

        const { sheet_template_id } = data;
        getLambdaServiceInstance()
          .post(`template/${sheet_template_id}`, {
            base64Image,
          })
          .catch(noop);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: formatMessage({ id: 'FAIL_TEMPLATE_CREATE' }),
  });

  return mutation;
};

const fetchProjectAndSheets = ({ projectId, version }) =>
  Promise.all([
    getServiceInstance().get(`${digitalTransformerApiBase}/project/${projectId}`),
    getServiceInstance().get(
      `${digitalTransformerApiBase}/project/${projectId}/sheet${addVersionQueryString(version)}`
    ),
  ]);
export const useProjectWithSheetsMutation = () => {
  const queryClient = useQueryClient();
  const { setState } = useProjectStore();

  const mutation = useMutation(
    ({ projectId, version }) => fetchProjectAndSheets({ projectId, version }),
    {
      onMutate: ({ projectId }) => {
        setState(state => {
          state.projects = {
            ...state.projects,
            [projectId]: {
              ...(state.projects[projectId] || dummyProject),
              loading: true,
            },
          };
        });
      },
      onSuccess: (data, { projectId, version: selectedVersion }) => {
        const [projectResponse, sheetsResponse] = data;
        const selectedProject = projectResponse.find(p =>
          selectedVersion
            ? p.digital_transformer_project_version === selectedVersion
            : p.is_active_version
        );

        const sheets = sheetsResponse.reduce(
          (stateSlice, sheet) => ({
            ...stateSlice,
            ...{
              [sheet.sheet_id]: getInitialSheetState(sheet, selectedProject.is_active_version),
            },
          }),
          {}
        );

        const versions = projectResponse.map(version => ({
          number: version.digital_transformer_project_version,
          comment: version.version_comment,
          isActive: version.is_active_version,
          updatedAt: version.updated_at,
        }));

        setState(state => {
          state.projects = {
            ...state.projects,
            [selectedProject.digital_transformer_project_id]: {
              name: selectedProject.name,
              description: selectedProject.description,
              createdAt: selectedProject.created_at,
              updatedAt: selectedProject.updated_at,
              version: selectedProject.digital_transformer_project_version,
              versionComment: selectedProject.version_comment,
              isActiveVersion: selectedProject.is_active_version,

              versions: versions,

              loading: false,
              error: null,

              sheets: sheets,
            },
          };
        });

        queryClient.setQueryData(
          `${digitalTransformerApiBase}/project/${projectId}`,
          projectResponse
        );
        queryClient.setQueryData(
          `${digitalTransformerApiBase}/project/${projectId}/sheet${addVersionQueryString(
            selectedVersion
          )}`,
          sheetsResponse
        );
      },
      onError: (error, { projectId }) => {
        setState(state => {
          const project = getProjectState(state.projects, projectId);

          project.loading = false;
          project.error = error;
        });
      },
    }
  );

  return mutation;
};
