/**
 * @file
 *
 * GoJS extension for auto layout of the nested components inside the System Entity structure
 */

import * as go from 'gojs';

import { NODE_MODEL_ATTRS, MODEL_NODES, GROUP_NODES } from '../../utils/constants';

/**
 * @type {go.DiagramEventHandler}
 *
 * function that is used to always keep the layout intact
 */
export function relayoutDiagram(event) {
  const diagram = event.diagram;
  let relayoutNeeded = false;

  if (!diagram.selection) {
    return;
  }

  diagram.selection
    .filter(part =>
      [
        GROUP_NODES.ENTITY_GROUP,
        GROUP_NODES.FIELDS_GROUP,
        MODEL_NODES.FIELD,
        MODEL_NODES.SPACER,
      ].includes(part.data[NODE_MODEL_ATTRS.CATEGORY])
    )
    .each(part => {
      relayoutNeeded = true;

      part.invalidateLayout();

      const groupKey = part.data[NODE_MODEL_ATTRS.GROUP];
      if (groupKey) {
        const group = diagram.findNodeForKey(groupKey);
        group.invalidateLayout();
      }
    });

  if (relayoutNeeded) {
    diagram.layoutDiagram();
  }
}

/**
 * @class
 * This class is responsible for the layout of the entity system parts
 */
export class UniformColumnLayout extends go.GridLayout {
  constructor() {
    super();

    this._spacing = 20;

    this.cellSize = new go.Size(1, 1);
    this.wrappingColumn = 1;
    this.wrappingWidth = 1;
    this.spacing = new go.Size(this._spacing, this._spacing);
    this.alignment = go.GridLayout.Position;
  }

  comparer(cellA, cellB) {
    const { y: ay } = cellA.location;
    const { y: by } = cellB.location;

    if (isNaN(ay) || isNaN(by)) {
      return 0;
    } else if (ay < by) {
      return -1;
    } else if (ay > by) {
      return 1;
    }

    return 0;
  }

  doLayout(collection) {
    /**
     * @type {go.Diagram}
     */
    const diagram = this.diagram;

    const txnIdentifier = 'Auto Layout - Group';
    diagram.startTransaction(txnIdentifier);

    if (this.group !== null) {
      const iterator = diagram.nodes.filter(
        node => node.data[NODE_MODEL_ATTRS.GROUP] === this.group.data[NODE_MODEL_ATTRS.KEY]
      ).iterator;

      while (iterator.next()) {
        const node = iterator.value;

        if (node.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
          node.width = node.desiredSize.width;
          continue;
        }

        const isFieldNode = [MODEL_NODES.FIELD, MODEL_NODES.SPACER].includes(
          node.data[NODE_MODEL_ATTRS.CATEGORY]
        );

        const isNestedField =
          isFieldNode && this.group.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP;

        node.width = isNestedField ? this.group.width - 2 * this._spacing : 260;
      }
    }

    super.doLayout(collection);

    diagram.commitTransaction(txnIdentifier);
  }
}
