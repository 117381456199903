/**
 * @file
 *
 * This component is a customized form of @material-ui Divider component
 * where it stretches beyond it's parent to cover full width
 *
 */

import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  FullWidthDivider: {
    width: '200%',
    margin: theme.spacing(1, 0, 1, -10),
  },
}));

export const FullWidthDivider = ({ className }) => {
  const classes = useStyles();

  return <Divider className={clsx(classes.FullWidthDivider, className)} />;
};
