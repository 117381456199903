import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  GridList,
  GridListTile,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { useCreateCustomShapeMutation, useUpdateCustomShapeMutation } from '../data/customShapes';
import { goIteratorToArray } from '../models/helpers';
import {
  CUSTOM_PALETTE_SHAPES,
  GROUP_NODES,
  MODEL_NODES,
  NODE_MODEL_ATTRS,
} from '../utils/constants';
import { FormikTextField } from './FormikFields';

const cloneNodeData = node => ({ ...node.data });
const purgeNodeData = nodeArray => {
  nodeArray.forEach(node => {
    delete node[NODE_MODEL_ATTRS.KEY];
    delete node[NODE_MODEL_ATTRS.GROUP];
    delete node['__gohashid'];
  });
};

const getAllNodesInEntityGroup = (entityGroupKey, diagram) => {
  const entityGroup = cloneNodeData(diagram.findNodeForKey(entityGroupKey));

  let fieldGroupsInsideEntityGroup = [];
  let fieldsInsideEntityGroup = [];
  let fieldsInsideFieldGroup = [];
  let spacersInsideEntityGroup = [];
  let spacersInsideFieldGroup = [];
  if (entityGroup) {
    fieldGroupsInsideEntityGroup = goIteratorToArray(
      diagram.findNodesByExample({
        category: GROUP_NODES.FIELDS_GROUP,
        group: entityGroup[NODE_MODEL_ATTRS.KEY],
      })
    ).map(cloneNodeData);

    fieldsInsideEntityGroup = goIteratorToArray(
      diagram.findNodesByExample({
        category: MODEL_NODES.FIELD,
        group: entityGroup[NODE_MODEL_ATTRS.KEY],
      })
    ).map(cloneNodeData);

    spacersInsideEntityGroup = goIteratorToArray(
      diagram.findNodesByExample({
        category: MODEL_NODES.SPACER,
        group: entityGroup[NODE_MODEL_ATTRS.KEY],
      })
    ).map(cloneNodeData);

    if (fieldGroupsInsideEntityGroup.length) {
      fieldGroupsInsideEntityGroup.forEach(fieldGroup => {
        fieldsInsideFieldGroup.push(
          goIteratorToArray(
            diagram.findNodesByExample({
              category: MODEL_NODES.FIELD,
              group: fieldGroup[NODE_MODEL_ATTRS.KEY],
            })
          ).map(cloneNodeData)
        );

        spacersInsideFieldGroup.push(
          goIteratorToArray(
            diagram.findNodesByExample({
              category: MODEL_NODES.SPACER,
              group: fieldGroup[NODE_MODEL_ATTRS.KEY],
            })
          ).map(cloneNodeData)
        );
      });
    }

    // We don't need the key, group, location and gohashid of the nodes, so we remove them using the purgeNodeData function
    purgeNodeData([
      entityGroup,
      ...fieldGroupsInsideEntityGroup,
      ...fieldsInsideEntityGroup,
      ...fieldsInsideFieldGroup.flat(),
      ...spacersInsideEntityGroup,
      ...spacersInsideFieldGroup.flat(),
    ]);

    // In the below lines of code we are shaping the data in the form that is required by the drag start and stop functions in Project.jsx
    // This logic shapes the fieldgroups data in the required format
    let fieldGroupsData;
    if (fieldGroupsInsideEntityGroup.length) {
      fieldGroupsData = {
        fieldGroups: fieldGroupsInsideEntityGroup.reduce(
          (allFieldGroups, currentFieldGroup, index) => {
            const newFieldGroup = {
              [currentFieldGroup[NODE_MODEL_ATTRS.TITLE]]: {
                fields: [...fieldsInsideFieldGroup[index]],
                spacers: [...spacersInsideFieldGroup[index]],
                ...currentFieldGroup,
              },
            };

            allFieldGroups = { ...allFieldGroups, ...newFieldGroup };

            return allFieldGroups;
          },
          {}
        ),
      };
    }

    const customSystemDataInRequiredFormat = {
      ...entityGroup,
      ...fieldGroupsData,
      ...(fieldsInsideEntityGroup.length && { fields: fieldsInsideEntityGroup }),
      ...(spacersInsideEntityGroup.length && { spacers: spacersInsideEntityGroup }),
      custom: true, // This flag is to differentiate a custom system from others
    };

    return customSystemDataInRequiredFormat;
  }

  return [];
};

const useDialogStyles = makeStyles(theme => ({
  systemContainer: {
    width: theme.spacing(13.6),
    height: theme.spacing(13),
    border: theme.borders[0],
    padding: theme.spacing(1),
  },
  systemTextFieldContainer: {
    marginLeft: theme.spacing(2.5),
    marginTop: theme.spacing(-1.3),
    width: theme.spacing(24),
  },
  popOver: {
    display: 'inline-block',
    padding: theme.spacing(1),
    maxWidth: theme.spacing(38),
    maxHeight: theme.spacing(30),
    overflow: 'hidden',
  },
  iconTile: {
    border: theme.borders[0],
    margin: theme.spacing(0.5),
  },
  systemIcon: {
    width: '100%',
  },
  systemName: {
    width: '100%',
    height: theme.spacing(4.5),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textOverflow: 'ellipsis',
  },
  textField: {
    height: theme.spacing(8),
  },
}));

const saveCustomSystemDialogId = 'saveCustomSystemDialog';
const updateCustomSystemDialogId = 'updateCustomSystemDialog';
// We use the same component for handling save and deletion of custom systems
// We are using a condition: "if customShapeToBeUpdated exists" to handle save and update seperately
// since we are only passing that prop where we want to call the the dialog for updating the custom system
export const CustomSystemDialog = ({
  onClose,
  open,
  entityGroupKey,
  diagram,
  customShapeToBeUpdated,
}) => {
  const classes = useDialogStyles();
  const { formatMessage } = useIntl();

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(1)
      .required('Required')
      .label(formatMessage({ id: 'SYSTEM_NAME' })),
    description: yup.string().label(formatMessage({ id: 'SYSTEM_DESCRIPTION' })),
  });

  const updateCustomShapeMutation = useUpdateCustomShapeMutation();
  const createCustomShapeMutation = useCreateCustomShapeMutation();

  // This function handles both creation and updating of custom shape depending on the customShapeToBeUpdated
  // variable
  const handleSaveOrUpdateCustomShape = useCallback(
    async values => {
      if (customShapeToBeUpdated) {
        await updateCustomShapeMutation.mutateAsync({
          customShapeId: customShapeToBeUpdated.id,
          values,
        });

        onClose();
      } else {
        await createCustomShapeMutation.mutateAsync({ values });
        onClose();
      }
    },
    [createCustomShapeMutation, customShapeToBeUpdated, onClose, updateCustomShapeMutation]
  );

  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby={
        customShapeToBeUpdated ? updateCustomSystemDialogId : saveCustomSystemDialogId
      }
      open={open}
    >
      <DialogTitle
        id={customShapeToBeUpdated ? updateCustomSystemDialogId : saveCustomSystemDialogId}
      >
        <FormattedMessage
          id={customShapeToBeUpdated ? 'UPDATE_CUSTOM_SHAPE' : 'SAVE_CUSTOM_SHAPE'}
        />
      </DialogTitle>
      <Formik
        validationSchema={schema}
        validateOnMount
        initialValues={
          customShapeToBeUpdated
            ? {
                name: customShapeToBeUpdated.name,
                description: customShapeToBeUpdated.description,
                shape_data: customShapeToBeUpdated.shapeData,
                icon: customShapeToBeUpdated.icon,
              }
            : {
                name: '',
                description: '',
                shape_data: getAllNodesInEntityGroup(entityGroupKey, diagram),
                icon: 'ICON0',
              }
        }
        onSubmit={handleSaveOrUpdateCustomShape}
      >
        {({ isValid, isSubmitting, handleSubmit, initialValues, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container>
                <PopupState variant="popover" id="custom-system-icon-popover">
                  {popupState => (
                    <>
                      <Button
                        {...bindTrigger(popupState)}
                        className={classes.systemContainer}
                        direction="column"
                      >
                        <Grid container direction="column">
                          <Grid item className={classes.systemIcon}>
                            {CUSTOM_PALETTE_SHAPES[values.icon] ??
                              CUSTOM_PALETTE_SHAPES[initialValues.icon]}
                          </Grid>
                          <Grid item className={classes.systemName}>
                            <Typography noWrap variant="body2">
                              {values.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                      <Popover
                        {...bindMenu(popupState)}
                        onClose={popupState.close}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <GridList cols={5} cellHeight={45} className={classes.popOver}>
                          {Object.entries(CUSTOM_PALETTE_SHAPES).map(([key, value]) => (
                            <GridListTile
                              key={key}
                              component={Button}
                              className={classes.iconTile}
                              size="small"
                              onClick={() => {
                                setFieldValue('icon', key);
                                popupState.close();
                              }}
                            >
                              {value}
                            </GridListTile>
                          ))}
                        </GridList>
                      </Popover>
                    </>
                  )}
                </PopupState>

                <Grid
                  item
                  container
                  direction="column"
                  className={classes.systemTextFieldContainer}
                >
                  <Grid item className={classes.textField}>
                    <FormikTextField
                      name="name"
                      label={formatMessage({ id: 'SYSTEM_NAME' })}
                      autoFocus
                    />
                  </Grid>
                  <Grid item>
                    <FormikTextField
                      name="description"
                      label={formatMessage({ id: 'SYSTEM_DESCRIPTION' })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                type="submit"
                disabled={
                  createCustomShapeMutation.isLoading || updateCustomShapeMutation.isLoading
                }
                color="primary"
                variant="contained"
                startIcon={
                  createCustomShapeMutation.isLoading || updateCustomShapeMutation.isLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    ''
                  )
                }
              >
                <FormattedMessage id={customShapeToBeUpdated ? 'UPDATE' : 'SAVE'} />
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
