/**
 * @file
 *
 * This component renders the Content Panel of the right side drawer
 *
 * Contains options to change the content of an object dropped in the Editor
 */

import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { startCase } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import {
  NODE_MODEL_ATTRS,
  GROUP_NODES,
  MODEL_NODES,
  LINK_MODEL_ATTRS,
  UI_DIMENSIONS,
  ENTITY_TYPES,
  TEXTFIELD_WIDTH,
} from '../../utils/constants';
import { EntityFieldForm } from './EntityFieldsForm';
import { TextEditField } from './TextFieldEdit';
import { EntityAttributeOptions } from './EntityAttributeOptions';
import { FullWidthDivider } from './FullWidthDivider';

const useStyles = makeStyles(theme => ({
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
  noOptions: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  contentOptions: {
    marginTop: theme.spacing(1),
  },
  titleFont: {
    fontSize: theme.spacing(2),
  },
}));

function LinkTextInput(props) {
  const classes = useStyles();

  const label = props.selectedData[LINK_MODEL_ATTRS.LABEL] || '';
  const content = props.selectedData[LINK_MODEL_ATTRS.TEXT] || '';

  return (
    <Grid container direction="row" justifyContent="space-between" className={classes.drawerBody}>
      <Grid item>
        <Grid container>
          <Typography color="textSecondary" variant="body2">
            <FormattedMessage id="LABEL" />
          </Typography>
        </Grid>
        <Grid container>
          <TextEditField
            width={TEXTFIELD_WIDTH.LG}
            onChange={props.onChange}
            value={label}
            attr={LINK_MODEL_ATTRS.LABEL}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.contentOptions}>
        <Grid container>
          <Typography color="textSecondary" variant="body2">
            <FormattedMessage id="CONTENT" />
          </Typography>
        </Grid>
        <Grid container>
          <TextEditField
            width={TEXTFIELD_WIDTH.LG}
            onChange={props.onChange}
            value={content}
            attr={LINK_MODEL_ATTRS.TEXT}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export function ContentPanel(props) {
  const classes = useStyles();

  if (
    !props.selectedData ||
    !props.diagram ||
    [MODEL_NODES.SPACER, MODEL_NODES.ACTOR, MODEL_NODES.CLOCK].includes(
      props.selectedData[0][NODE_MODEL_ATTRS.CATEGORY]
    )
  ) {
    return (
      <Grid container justifyContent="center" className={classes.noOptions}>
        <Typography className={classes.titleFont}>
          <FormattedMessage id="NO_ACTIONS_AVAILABLE" />
        </Typography>
      </Grid>
    );
  }

  const selectedPart = props.selectedData[0];

  if (props.selectedData.length > 1) {
    if (props.areAllFromSameCategory) {
      const category = props.categories[0];

      if (category === GROUP_NODES.ENTITY_GROUP) {
        const entityTypeValue = selectedPart[NODE_MODEL_ATTRS.ENTITY_TYPE] || ENTITY_TYPES.NONE;

        return (
          <Grid item className={classes.drawerBody}>
            <EntityAttributeOptions onChange={props.onChange} entityTypeValue={entityTypeValue} />
          </Grid>
        );
      } else {
        return (
          <Grid container justifyContent="center" className={classes.noOptions}>
            <Typography className={classes.titleFont}>
              <FormattedMessage id="NO_ACTIONS_AVAILABLE" />
            </Typography>
          </Grid>
        );
      }
    } else {
      return (
        <Grid container justifyContent="center" className={classes.noOptions}>
          <Typography className={classes.titleFont}>
            <FormattedMessage id="NO_ACTIONS_AVAILABLE" />
          </Typography>
        </Grid>
      );
    }
  }

  let attr;
  if (
    selectedPart[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP ||
    selectedPart[NODE_MODEL_ATTRS.IS_GROUP]
  ) {
    attr = NODE_MODEL_ATTRS.TITLE;
  } else {
    attr = NODE_MODEL_ATTRS.TEXT;
  }

  if (selectedPart[LINK_MODEL_ATTRS.KEY].startsWith('link')) {
    return <LinkTextInput onChange={props.onChange} selectedData={selectedPart} />;
  }

  const value = selectedPart[attr];

  return (
    ![MODEL_NODES.SPACER, MODEL_NODES.ACTOR, MODEL_NODES.CLOCK].includes(
      selectedPart[NODE_MODEL_ATTRS.CATEGORY]
    ) && (
      <Grid item className={classes.drawerBody}>
        {selectedPart[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP && (
          <>
            <EntityAttributeOptions
              onChange={props.onChange}
              entityTypeValue={selectedPart[NODE_MODEL_ATTRS.ENTITY_TYPE]}
            />
            <FullWidthDivider />
          </>
        )}
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Typography color="textSecondary" variant="body2">
                  {startCase(attr)}
                </Typography>
              </Grid>
              <Grid container>
                <TextEditField
                  width={TEXTFIELD_WIDTH.LG}
                  onChange={props.onChange}
                  value={value}
                  attr={attr}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {[GROUP_NODES.ENTITY_GROUP, GROUP_NODES.FIELDS_GROUP].includes(
          selectedPart[NODE_MODEL_ATTRS.CATEGORY]
        ) && (
          <EntityFieldForm
            selectedData={selectedPart}
            nodeDataArray={props.nodeDataArray}
            diagram={props.diagram}
            onChange={props.onChange}
          />
        )}
      </Grid>
    )
  );
}
