/**
 * @file
 *
 * This file contains the component that is rendered in the right panel
 * when multiple field group parts are selected
 */
import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { startCase } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { CommonSection } from '../CommonSection';
import { ColorPicker } from '../ColorPicker';
import { SelectEditField } from '../SelectEditField';
import {
  NODE_MODEL_ATTRS,
  STROKE_TYPES,
  DEFAULTS,
  UI_DIMENSIONS,
  TEXTFIELD_WIDTH,
} from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
  fontOptions: {
    marginTop: theme.spacing(1),
  },
}));

export function MultipleFieldGroupsOptionsPanel(props) {
  const classes = useStyles();

  const colorValue = props.selectedData[0][NODE_MODEL_ATTRS.COLOR] || DEFAULTS.ENTITY_GROUP_COLOR;
  const strokeValue = props.selectedData[0][NODE_MODEL_ATTRS.STROKE_TYPE] || DEFAULTS.STROKE_TYPE;

  return (
    <>
      <CommonSection
        handleDelete={props.handleDelete}
        handleCopy={props.handleCopy}
        copyEnabled={false}
      />
      <Grid container direction="column" className={classes.drawerBody}>
        <Grid item>
          <Grid container>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="BORDER" />
            </Typography>
          </Grid>
          <Grid container>
            <SelectEditField
              width={TEXTFIELD_WIDTH.LG}
              onChange={props.onChange}
              attr={NODE_MODEL_ATTRS.STROKE_TYPE}
              value={strokeValue}
              options={[
                STROKE_TYPES.NONE.KEY,
                STROKE_TYPES.SOLID.KEY,
                STROKE_TYPES.DASHED.KEY,
                STROKE_TYPES.DOTTED.KEY,
              ]}
            />
          </Grid>
        </Grid>
        <Grid item className={classes.fontOptions}>
          <ColorPicker
            onChange={props.onChange}
            label={startCase(NODE_MODEL_ATTRS.COLOR)}
            attr={NODE_MODEL_ATTRS.COLOR}
            value={colorValue}
          />
        </Grid>
      </Grid>
    </>
  );
}
