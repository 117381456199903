/**
 * @file
 *
 * This component determines which component is to be rendered based on the parts that are currently in selection (multiple)
 */
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { MODEL_NODES, GROUP_NODES, UI_DIMENSIONS } from '../../../utils/constants';
import { MultipleFieldsOptionsPanel } from './MultipleFieldsOptionsPanel';
import { CommonSection } from '../CommonSection';
import { MultipleShapesOptionsPanel } from './MultipleShapesOptionsPanel';
import { MultipleEntityGroupOptionsPanel } from './MultipleEntityGroupOptionsPanel';
import { MultipleFieldGroupsOptionsPanel } from './MultipleFieldGroupsOptionsPanel';
import { MultipleTextOptionsPanel } from './MultipleTextOptionsPanel';
import { MultipleLinkOptionsPanel } from './MultipleLinkOptionsPanel';

const useStyles = makeStyles(theme => ({
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
  },
}));

export function MultiSelectOptions(props) {
  const classes = useStyles();

  if (props.areAllFromSameCategory) {
    const category = props.categories[0];

    if (category === 'text') {
      return <MultipleTextOptionsPanel {...props} />;
    } else if (category === 'link') {
      return <MultipleLinkOptionsPanel {...props} />;
    } else if (category === 'shape') {
      return <MultipleShapesOptionsPanel {...props} />;
    } else if (category === GROUP_NODES.ENTITY_GROUP) {
      return <MultipleEntityGroupOptionsPanel {...props} />;
    } else if (category === GROUP_NODES.FIELDS_GROUP) {
      return <MultipleFieldGroupsOptionsPanel {...props} />;
    } else if (category === MODEL_NODES.FIELD) {
      return <MultipleFieldsOptionsPanel {...props} />;
    }
  }

  const copiableParts = props.categories.some(
    category =>
      ![GROUP_NODES.FIELDS_GROUP, MODEL_NODES.SPACER, MODEL_NODES.FIELD].includes(category)
  );

  return (
    <Grid container direction="column" className={classes.drawerBody}>
      <Grid item>
        <CommonSection
          handleDelete={props.handleDelete}
          handleCopy={props.handleCopy}
          copyEnabled={copiableParts}
        />
      </Grid>
    </Grid>
  );
}
