/**
 * @file
 *
 * This file includes all of the custom Shape rendering logics for GoJS
 */

import * as go from 'gojs';

// The following functions are used by a group of regular figures that are defined below:
const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);
const _CachedArrays = [];

/**
 * @return {Array}
 */
function tempArray() {
  const temp = _CachedArrays.pop();
  if (temp === undefined) return [];
  return temp;
}

/**
 * @param {Array} a
 */
function freeArray(a) {
  a.length = 0; // clear any references to objects
  _CachedArrays.push(a);
}

/**
 * This allocates a temporary Array that should be freeArray()'ed by the caller.
 * @param {number} sides
 * @return {Array}
 */
function createPolygon(sides) {
  // Point[] points = new Point[sides + 1];
  const points = tempArray();
  const radius = 0.5;
  const center = 0.5;
  const offsetAngle = Math.PI * 1.5;
  let angle = 0;

  // Loop through each side of the polygon
  for (let i = 0; i < sides; i++) {
    angle = ((2 * Math.PI) / sides) * i + offsetAngle;
    points[i] = new go.Point(center + radius * Math.cos(angle), center + radius * Math.sin(angle));
  }

  // Add the last line
  // points[points.length - 1] = points[0];
  points.push(points[0]);
  return points;
}

// Shape defination starts from below

go.Shape.defineFigureGenerator('Hexagon', function(shape, w, h) {
  const points = createPolygon(6);
  const geo = new go.Geometry();
  const fig = new go.PathFigure(points[0].x * w, points[0].y * h, true);
  geo.add(fig);

  for (let i = 1; i < 6; i++) {
    fig.add(new go.PathSegment(go.PathSegment.Line, points[i].x * w, points[i].y * h));
  }
  fig.add(new go.PathSegment(go.PathSegment.Line, points[0].x * w, points[0].y * h).close());
  freeArray(points);

  geo.spot1 = new go.Spot(0.07, 0.25);
  geo.spot2 = new go.Spot(0.93, 0.75);

  return geo;
});

go.Shape.defineFigureGenerator('Database', function(shape, w, h) {
  const geo = new go.Geometry();
  const cpxOffset = KAPPA * 0.5;
  const cpyOffset = KAPPA * 0.1;
  const fig = new go.PathFigure(w, 0.1 * h, true);
  geo.add(fig);

  // Body
  fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.9 * h));
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      h,
      w,
      (0.9 + cpyOffset) * h,
      (0.5 + cpxOffset) * w,
      h
    )
  );
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0,
      0.9 * h,
      (0.5 - cpxOffset) * w,
      h,
      0,
      (0.9 + cpyOffset) * h
    )
  );
  fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.1 * h));
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      0,
      0,
      (0.1 - cpyOffset) * h,
      (0.5 - cpxOffset) * w,
      0
    )
  );
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      w,
      0.1 * h,
      (0.5 + cpxOffset) * w,
      0,
      w,
      (0.1 - cpyOffset) * h
    )
  );

  // Rings
  const fig2 = new go.PathFigure(w, 0.1 * h, false);
  geo.add(fig2);
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      0.2 * h,
      w,
      (0.1 + cpyOffset) * h,
      (0.5 + cpxOffset) * w,
      0.2 * h
    )
  );
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0,
      0.1 * h,
      (0.5 - cpxOffset) * w,
      0.2 * h,
      0,
      (0.1 + cpyOffset) * h
    )
  );
  fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.2 * h));
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      0.3 * h,
      w,
      (0.2 + cpyOffset) * h,
      (0.5 + cpxOffset) * w,
      0.3 * h
    )
  );
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0,
      0.2 * h,
      (0.5 - cpxOffset) * w,
      0.3 * h,
      0,
      (0.2 + cpyOffset) * h
    )
  );
  fig2.add(new go.PathSegment(go.PathSegment.Move, w, 0.3 * h));
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      0.4 * h,
      w,
      (0.3 + cpyOffset) * h,
      (0.5 + cpxOffset) * w,
      0.4 * h
    )
  );
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0,
      0.3 * h,
      (0.5 - cpxOffset) * w,
      0.4 * h,
      0,
      (0.3 + cpyOffset) * h
    )
  );

  geo.spot1 = new go.Spot(0, 0.4);
  geo.spot2 = new go.Spot(1, 0.9);

  return geo;
});

go.Shape.defineFigureGenerator('Cylinder', function(shape, w, h) {
  const geo = new go.Geometry();
  const cpxOffset = KAPPA * 0.5;
  const cpyOffset = KAPPA * 0.1;
  const fig = new go.PathFigure(w, 0.1 * h, true);
  geo.add(fig);

  // Body
  fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.9 * h));
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      h,
      w,
      (0.9 + cpyOffset) * h,
      (0.5 + cpxOffset) * w,
      h
    )
  );
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0,
      0.9 * h,
      (0.5 - cpxOffset) * w,
      h,
      0,
      (0.9 + cpyOffset) * h
    )
  );
  fig.add(new go.PathSegment(go.PathSegment.Line, 0, 0.1 * h));
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      0,
      0,
      (0.1 - cpyOffset) * h,
      (0.5 - cpxOffset) * w,
      0
    )
  );
  fig.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      w,
      0.1 * h,
      (0.5 + cpxOffset) * w,
      0,
      w,
      (0.1 - cpyOffset) * h
    )
  );

  // Rings
  const fig2 = new go.PathFigure(w, 0.1 * h, false);
  geo.add(fig2);
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0.5 * w,
      0.2 * h,
      w,
      (0.1 + cpyOffset) * h,
      (0.5 + cpxOffset) * w,
      0.2 * h
    )
  );
  fig2.add(
    new go.PathSegment(
      go.PathSegment.Bezier,
      0,
      0.1 * h,
      (0.5 - cpxOffset) * w,
      0.2 * h,
      0,
      (0.1 + cpyOffset) * h
    )
  );

  geo.spot1 = new go.Spot(0, 0.4);
  geo.spot2 = new go.Spot(1, 0.9);

  return geo;
});

go.Shape.defineFigureGenerator('Cloud', function(shape, w, h) {
  return new go.Geometry()
    .add(
      new go.PathFigure(0.08034461 * w, 0.1944299 * h, true)
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.2008615 * w,
            0.05349299 * h,
            -0.09239631 * w,
            0.07836421 * h,
            0.1406031 * w,
            -0.0542823 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.4338609 * w,
            0.074219 * h,
            0.2450511 * w,
            -0.00697547 * h,
            0.3776197 * w,
            -0.01112067 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.6558228 * w,
            0.07004196 * h,
            0.4539471 * w,
            0,
            0.6066018 * w,
            -0.02526587 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.8921095 * w,
            0.08370865 * h,
            0.6914277 * w,
            -0.01904177 * h,
            0.8921095 * w,
            -0.01220843 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.9147671 * w,
            0.3194596 * h,
            1.036446 * w,
            0.04105738 * h,
            1.020377 * w,
            0.3022052 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.9082935 * w,
            0.562044 * h,
            1.04448 * w,
            0.360238 * h,
            0.992256 * w,
            0.5219009 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.9212406 * w,
            0.8217117 * h,
            1.032337 * w,
            0.5771781 * h,
            1.018411 * w,
            0.8120651 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.7592566 * w,
            0.9156953 * h,
            1.028411 * w,
            0.9571472 * h,
            0.8556702 * w,
            1.052487 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.5101666 * w,
            0.9310455 * h,
            0.7431877 * w,
            1.009325 * h,
            0.5624123 * w,
            1.021761 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.2609328 * w,
            0.9344623 * h,
            0.4820677 * w,
            1.031761 * h,
            0.3030112 * w,
            1.002796 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.08034461 * w,
            0.870098 * h,
            0.2329994 * w,
            1.01518 * h,
            0.03213784 * w,
            1.01518 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.06829292 * w,
            0.6545475 * h,
            -0.02812061 * w,
            0.9032597 * h,
            -0.01205169 * w,
            0.6835638 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.06427569 * w,
            0.4265613 * h,
            -0.01812061 * w,
            0.6089503 * h,
            -0.00606892 * w,
            0.4555777 * h
          )
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Bezier,
            0.08034461 * w,
            0.1944299 * h,
            -0.01606892 * w,
            0.3892545 * h,
            -0.01205169 * w,
            0.1944299 * h
          )
        )
    )
    .setSpots(0.1, 0.1, 0.9, 0.9);
});

export const actorGeometryString = `
  M325 79C325 117.108 294.108 148 256 148C217.892 148 187 117.108 187 79C187 40.8923 217.892 10 256 10C294.108 10 325 40.8923 325 79Z x 
  M256 152V370 x 
  M256 167L442 167 x 
  M70 167L256 167 x 
  M256 370L387.522 501.522 x M255.522 370L124 501.522
`;

export const clockGeometryString = `
  M502 256C502 391.862 391.862 502 256 502C120.138 502 10 391.862 10 256C10 120.138 120.138 10 256 10C391.862 10 502 120.138 502 256Z x 
  M256 70V256 x
  M256 256H442
`;

export const externalLinkString = `
  M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14
`;
