import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { MdDns } from 'react-icons/md';

import { logosBase, getAssetURL, digitalTransformerAssetBase } from '../utils/service';

const useStyles = makeStyles(theme => ({
  systemIcon: {
    width: '100%',
    height: '100%',
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
}));

export const SystemAvatar = props => {
  const classes = useStyles();

  if (props.system.toLowerCase().startsWith('sf')) {
    return (
      <Avatar
        variant="square"
        className={props.systemIcon}
        src={getAssetURL(digitalTransformerAssetBase, 'SAP_SF.png')}
      />
    );
  } else if (props.system.toLowerCase().startsWith('sap')) {
    return (
      <Avatar
        variant="square"
        className={classes.systemIcon}
        src={getAssetURL(digitalTransformerAssetBase, 'SAP_HCM.png')}
      />
    );
  } else if (props.system.toLowerCase().startsWith('datev')) {
    return (
      <Avatar
        variant="square"
        className={classes.systemIcon}
        src={getAssetURL(logosBase, 'DATEV.png')}
      />
    );
  }

  return (
    <Avatar variant="square" className={classes.systemIcon} style={{ background: 'transparent' }}>
      <MdDns className={classes.primaryColor} size="23px" />
    </Avatar>
  );
};
