/**
 * @file
 *
 * This component renders the Text attribute edit form element in the Selection Panel
 */

import React, { useState } from 'react';

import { InnerTextField } from './InnerTextField';

export function TextEditField(props) {
  const [error, updateError] = useState(null);

  const handleChange = async event => {
    let value = event.target.value;

    let validationResult = null;
    if (props.validationSchema) {
      try {
        value = await props.validationSchema.validate(value);
        updateError(null);
      } catch (validationError) {
        validationResult = validationError.errors[0];
        updateError(validationResult);
      }
    }

    props.onChange({
      attr: props.attr,
      value,
      shouldUpdate: !validationResult,
    });
  };

  return (
    <InnerTextField
      width={props.width}
      attr={props.attr}
      value={props.value}
      onChange={handleChange}
      label={props.label}
      error={error}
      multiline={props.multiline}
    />
  );
}
