import { useState, useCallback } from 'react';

/**
 * @file
 *
 * This file contains a custom hook to use dialogs
 */
export const useDialog = onOpenCallback => {
  const [isDialogOpen, updateDialogState] = useState(false);
  const openDialog = useCallback(() => {
    if (onOpenCallback) {
      onOpenCallback();
    }
    updateDialogState(true);
  }, [onOpenCallback]);

  const closeDialog = useCallback(() => {
    updateDialogState(false);
  }, []);

  return { isDialogOpen, openDialog, closeDialog };
};
