/**
 * @file
 *
 * This file exports the custom theme setup for the @material-ui components
 */

import { createTheme } from '@material-ui/core/styles';
import { grey, blueGrey, blue } from '@material-ui/core/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#354a5f',
      dark: '#073461',
    },
    secondary: {
      main: blue[500],
    },
    text: {
      primary: blueGrey[900],
      secondary: grey[800],
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: ['Inter', 'sans-serif'],
    button: {
      textTransform: 'none',
    },
    h6: {
      fontSize: 18,
    },
    body2: {
      fontWeight: 'bold',
    },
  },
  borders: ['1px solid rgba(0, 0, 0, 0.12)', '1px solid rgba(0,0,0, 0.2)'],
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiAlert: {
      message: {
        padding: 0,
      },
    },
  },
});
