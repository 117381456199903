/**
 * @file
 *
 * This component renders the Color Picker component in the Selection Panel
 */

import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Typography, Grid, Popover, Button, makeStyles } from '@material-ui/core';
import {
  common,
  yellow,
  orange,
  red,
  blueGrey,
  indigo,
  lightBlue,
  lightGreen,
  teal,
  brown,
  grey,
  green,
} from '@material-ui/core/colors';
import { FormattedMessage } from 'react-intl';

import { TEXTFIELD_WIDTH } from '../../utils/constants';

const colors = [
  grey,
  yellow,
  orange,
  red,
  lightBlue,
  indigo,
  teal,
  lightGreen,
  green,
  brown,
  blueGrey,
];

const useStyles = makeStyles(theme => ({
  button: {
    width: TEXTFIELD_WIDTH.MD,
    height: '35px',
    margin: theme.spacing(1, 0, 1),
  },
}));

export function ColorPicker(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const openColorPicker = event => {
    setAnchorEl(event.currentTarget);
  };
  const closeColorPicker = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Grid container direction="column">
      <Typography color="textSecondary" variant="body2">
        {props.label}
      </Typography>

      <Button
        size="small"
        className={classes.button}
        style={{ backgroundColor: props.value, color: props.value }}
        onClick={openColorPicker}
      >
        <FormattedMessage id="COLOR" />
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={closeColorPicker}
      >
        <SketchPicker
          disableAlpha
          presetColors={[
            common.white,
            ...colors.map(c => c[100]),
            ...colors.map(c => c[700]),
            common.black,
          ]}
          color={props.value}
          onChangeComplete={color => {
            props.onChange({ value: color.hex, attr: props.attr });
          }}
        />
      </Popover>
    </Grid>
  );
}
