/**
 * @file
 *
 * This component renders the Visual Panel of the right side drawer
 *
 * Contains options to change the visual aspect of an object dropped in the Editor
 */

import React, { useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { FontOptions } from './FontOptions';
import { MultiSelectOptions } from './MultiSelectOptions/MultiSelectOptions';
import { NODE_MODEL_ATTRS, DEFAULTS, MODEL_NODES, UI_DIMENSIONS } from '../../utils/constants';
import { ColorPicker } from './ColorPicker';
import { OptionsSection } from './OptionsSection';
import { LinkOptions } from './LinkOptions';
import { CommonSection } from './CommonSection';
import { entityChildNodeCategories } from '../../models/helpers';

const filterCommonAttrOptions = attr =>
  [NODE_MODEL_ATTRS.COLOR, NODE_MODEL_ATTRS.SIZE, NODE_MODEL_ATTRS.STROKE_TYPE].includes(attr);

const useStyles = makeStyles(theme => ({
  noOptions: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
  fontOptions: {
    marginBottom: theme.spacing(1),
  },
}));

function useActions(diagram) {
  const handleCopy = useCallback(() => {
    /**
     * @type {{ diagram: go.Diagram }}
     */
    diagram.commandHandler.copySelection();
    diagram.commandHandler.pasteSelection();

    diagram.focus();
  }, [diagram]);

  const handleDelete = useCallback(() => {
    /**
     * @type {{ diagram: go.Diagram }}
     */
    diagram.commandHandler.deleteSelection();

    diagram.focus();
  }, [diagram]);

  const handlePushToBack = useCallback(() => {
    /**
     * @type {{ diagram: go.Diagram }}
     */
    diagram.commandHandler.pushToBack();

    diagram.focus();
  }, [diagram]);

  const handlePullToFront = useCallback(() => {
    /**
     * @type {{ diagram: go.Diagram }}
     */
    diagram.commandHandler.pullToFront();

    diagram.focus();
  }, [diagram]);

  return {
    handleCopy,
    handleDelete,
    handlePushToBack,
    handlePullToFront,
  };
}

export function VisualPanel(props) {
  const classes = useStyles();

  const { handleCopy, handleDelete, handlePushToBack, handlePullToFront } = useActions(
    props.diagram
  );

  const color = props.selectedData[0][NODE_MODEL_ATTRS.COLOR] || DEFAULTS.COLOR;

  if (props.selectedData.length > 1) {
    return (
      <MultiSelectOptions
        handleCopy={handleCopy}
        handleDelete={handleDelete}
        onChange={props.onChange}
        selectedData={props.selectedData}
        areAllFromSameCategory={props.areAllFromSameCategory}
        categories={props.categories}
      />
    );
  }

  const selectedPart = props.selectedData[0];

  if (selectedPart.key.startsWith('link')) {
    return <LinkOptions selectedData={selectedPart} onChange={props.onChange} />;
  }

  const selectedNode = props.diagram.findNodeForKey(selectedPart.key);

  const copyEnabled = selectedNode && selectedNode.canCopy();

  const isChildNode =
    selectedNode &&
    entityChildNodeCategories.includes(selectedNode.data[NODE_MODEL_ATTRS.CATEGORY]);

  return (
    <>
      <CommonSection
        isChildNode={isChildNode}
        copyEnabled={copyEnabled}
        handleDelete={handleDelete}
        handleCopy={handleCopy}
        handlePushToBack={handlePushToBack}
        handlePullToFront={handlePullToFront}
      />
      <Grid item className={classes.drawerBody}>
        <Grid item>
          {Object.keys(selectedPart)
            .sort()
            .filter(filterCommonAttrOptions)
            .map(attr => (
              <OptionsSection
                key={attr}
                attr={attr}
                selectedData={selectedPart}
                onChange={props.onChange}
              />
            ))}
        </Grid>
        {!selectedPart[NODE_MODEL_ATTRS.CATEGORY] && !selectedPart[NODE_MODEL_ATTRS.IS_GROUP] && (
          <Grid item className={classes.fontOptions}>
            <FontOptions selectedData={selectedPart} onChange={props.onChange} />
          </Grid>
        )}
        {![MODEL_NODES.FIELD, MODEL_NODES.SPACER].includes(
          selectedPart[NODE_MODEL_ATTRS.CATEGORY]
        ) && (
          <Grid item>
            <ColorPicker
              onChange={props.onChange}
              label="Color"
              attr={NODE_MODEL_ATTRS.COLOR}
              value={color}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
