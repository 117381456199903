import produce from 'immer';
import { useQuery } from 'react-query';
import create from 'zustand';
import { useMemo } from 'react';

import { getSheetState, useProjectStore } from './projects';
import { useEffectUntilTrue } from '../hooks/useEffectVariants';
import { useNotifyError } from '../hooks/useNotifyError';
import { delimiters, getPossibleSystemMappingPairs } from '../utils/helpers';
import { appMapperApiBase } from '../utils/service';
import { queueMicrotask } from '../utils/helpers';
import { NODE_MODEL_ATTRS } from '../utils/constants';

export const useMapperTemplateStore = create(set => ({
  mapperTemplates: [],
  suggestedConnections: [],

  setState: fn => set(produce(fn)),
}));

export function useMapperTemplates({ enabled }) {
  const { data, isLoading, error, ...rest } = useQuery({
    queryKey: `${appMapperApiBase}/mapperTemplates`,
    enabled,
  });

  useNotifyError({ error });

  const mapperTemplates = useMemo(() => {
    if (data) {
      return data
        .filter(mapperTemplate => mapperTemplate.is_active)
        .map(mapperTemplate => ({
          mapperTemplateCode: mapperTemplate.mappertemplate_code,
          name: mapperTemplate.name,
          description: mapperTemplate.description,
          srcSystem: mapperTemplate.src_system,
          destSystem: mapperTemplate.dest_system,
          createdAt: mapperTemplate.created_at,
          updatedAt: mapperTemplate.updated_at,
        }));
    }

    return null;
  }, [data]);

  useEffectUntilTrue(() => {
    if (isLoading || !mapperTemplates) {
      return false;
    }

    const { setState } = useMapperTemplateStore.getState();
    setState(state => {
      state.mapperTemplates = mapperTemplates;
    });

    return true;
  }, [mapperTemplates]);

  return { isLoading, error, ...rest };
}

export const mapperTemplateActions = {
  updateSuggestedConnections: payload => {
    const { setState } = useMapperTemplateStore.getState();

    setState(state => {
      const mappingPairs = getPossibleSystemMappingPairs(payload);

      state.suggestedConnections = [];

      if (!mappingPairs || mappingPairs.length === 0) {
        return;
      }

      mappingPairs.forEach(({ src, dest }) => {
        if (state.mapperTemplates) {
          state.mapperTemplates.forEach(mapperTemplate => {
            if (
              mapperTemplate.srcSystem === src[NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE] &&
              mapperTemplate.destSystem === dest[NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE]
            ) {
              state.suggestedConnections.push({
                key: `${src[NODE_MODEL_ATTRS.KEY]}${delimiters.linkSrcDest}${
                  dest[NODE_MODEL_ATTRS.KEY]
                }${delimiters.mapperTemplate}${mapperTemplate.mapperTemplateCode}`,
                src,
                dest,
                mapperTemplate,
              });
            }
          });
        }
      });
    });
  },
  clearSuggestedConnections: () => {
    const { setState } = useMapperTemplateStore.getState();

    setState(state => {
      state.suggestedConnections = [];
    });
  },
};

export const deferredUpdateSuggestedConnections = ({ projectId, sheetKey }) => {
  const { projects } = useProjectStore.getState();
  queueMicrotask(() => {
    const sheetState = getSheetState(projects, projectId, sheetKey);

    if (sheetState) {
      mapperTemplateActions.updateSuggestedConnections({ ...sheetState });
    }
  });
};
