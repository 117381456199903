/**
 * @file
 *
 * This file contains the component that is rendered in the right panel
 * when multiple text parts are selected
 */
import React from 'react';
import { Grid, Typography, makeStyles, withStyles } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { MdStrikethroughS, MdFormatUnderlined, MdFormatItalic } from 'react-icons/md';
import { startCase } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { CommonSection } from '../CommonSection';
import { ColorPicker } from '../ColorPicker';
import { SelectEditField } from '../SelectEditField';
import { SizeEdit } from '../SizeEdit';
import {
  NODE_MODEL_ATTRS,
  DEFAULTS,
  FONT_PROPERTIES,
  DIMENSIONS,
  UI_DIMENSIONS,
  TEXTFIELD_WIDTH,
} from '../../../utils/constants';
import { getValueWithDefaultFallback } from '../../../models/helpers';

const CustomToggleButtonGroup = withStyles(theme => ({
  root: {
    height: '35px',
  },
}))(ToggleButtonGroup);

const CustomToggleButton = withStyles(theme => ({
  root: {
    height: '35px',
    width: '50%',
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[700],
  },
}))(ToggleButton);

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginTop: theme.spacing(1),
  },
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
  toggleGroup: {
    marginTop: theme.spacing(1),
  },
  fontOptions: {
    marginTop: theme.spacing(1),
  },
}));

export function MultipleTextOptionsPanel(props) {
  const classes = useStyles();

  const colorValue = props.selectedData[0][NODE_MODEL_ATTRS.COLOR] || DEFAULTS.COLOR;

  const isItalic = props.selectedData.every(part => part[NODE_MODEL_ATTRS.TEXT_ITALIC]);
  const isUnderline = props.selectedData.every(part => part[NODE_MODEL_ATTRS.TEXT_UNDERLINE]);
  const isStrikethrough = props.selectedData.every(
    part => part[NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH]
  );

  const toggleButtonStates = [
    isItalic && NODE_MODEL_ATTRS.TEXT_ITALIC,
    isUnderline && NODE_MODEL_ATTRS.TEXT_UNDERLINE,
    isStrikethrough && NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH,
  ].filter(Boolean);

  return (
    <>
      <CommonSection handleDelete={props.handleDelete} handleCopy={props.handleCopy} copyEnabled />

      <Grid container direction="column" className={classes.drawerBody}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Typography color="textSecondary" variant="body2">
                  <FormattedMessage id="FONT" />
                </Typography>
              </Grid>
              <Grid container>
                <SelectEditField
                  width={TEXTFIELD_WIDTH.MD}
                  onChange={props.onChange}
                  attr={NODE_MODEL_ATTRS.FONT_FAMILY}
                  value={getValueWithDefaultFallback(
                    props.selectedData[0][NODE_MODEL_ATTRS.FONT_FAMILY],
                    DEFAULTS.FONT_PROPERTIES.FAMILY,
                    { validValues: FONT_PROPERTIES.FAMILY }
                  )}
                  options={[FONT_PROPERTIES.FAMILY.SANS_SERIF, FONT_PROPERTIES.FAMILY.SERIF]}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Typography color="textSecondary" variant="body2">
                  <FormattedMessage id="SIZE" />
                </Typography>
              </Grid>
              <Grid container>
                <SizeEdit
                  width={TEXTFIELD_WIDTH.MD}
                  onChange={props.onChange}
                  attr={NODE_MODEL_ATTRS.FONT_SIZE}
                  value={getValueWithDefaultFallback(
                    props.selectedData[0][NODE_MODEL_ATTRS.FONT_SIZE],
                    DEFAULTS.FONT_PROPERTIES.SIZE,
                    { min: DIMENSIONS.FONT_SIZE.MIN, max: DIMENSIONS.FONT_SIZE.MAX }
                  )}
                  min={DIMENSIONS.FONT_SIZE.MIN}
                  max={DIMENSIONS.FONT_SIZE.MAX}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className={classes.fontOptions}
          >
            <Grid item>
              <Grid container>
                <Typography color="textSecondary" variant="body2">
                  <FormattedMessage id="STYLE" />
                </Typography>
              </Grid>
              <Grid container>
                <CustomToggleButtonGroup
                  size="small"
                  className={classes.toggleGroup}
                  value={toggleButtonStates}
                  aria-label="formatting"
                  onChange={event => {
                    const attr = event.currentTarget.value;

                    let value;
                    if (attr === NODE_MODEL_ATTRS.TEXT_ITALIC) {
                      value = !isItalic;
                    } else if (attr === NODE_MODEL_ATTRS.TEXT_UNDERLINE) {
                      value = !isUnderline;
                    } else if (attr === NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH) {
                      value = !isStrikethrough;
                    }

                    if (value !== undefined) {
                      props.onChange({ attr, value });
                    }
                  }}
                >
                  <CustomToggleButton value={NODE_MODEL_ATTRS.TEXT_ITALIC} aria-label="italic">
                    <MdFormatItalic size="18px" />
                  </CustomToggleButton>
                  <CustomToggleButton
                    value={NODE_MODEL_ATTRS.TEXT_UNDERLINE}
                    aria-label="underline"
                  >
                    <MdFormatUnderlined size="18px" />
                  </CustomToggleButton>
                  <CustomToggleButton
                    value={NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH}
                    aria-label="strikethrough"
                  >
                    <MdStrikethroughS size="18px" />
                  </CustomToggleButton>
                </CustomToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Typography color="textSecondary" variant="body2">
                  <FormattedMessage id="WEIGHT" />
                </Typography>
              </Grid>
              <Grid container>
                <SelectEditField
                  width={TEXTFIELD_WIDTH.MD}
                  onChange={props.onChange}
                  attr={NODE_MODEL_ATTRS.FONT_WEIGHT}
                  value={getValueWithDefaultFallback(
                    props.selectedData[0][NODE_MODEL_ATTRS.FONT_WEIGHT],
                    DEFAULTS.FONT_PROPERTIES.WEIGHT,
                    { validValues: FONT_PROPERTIES.WEIGHT }
                  )}
                  options={[
                    FONT_PROPERTIES.WEIGHT.LIGHTER,
                    FONT_PROPERTIES.WEIGHT.NORMAL,
                    FONT_PROPERTIES.WEIGHT.BOLD,
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.fontOptions}>
          <ColorPicker
            onChange={props.onChange}
            label={startCase(NODE_MODEL_ATTRS.COLOR)}
            attr={NODE_MODEL_ATTRS.COLOR}
            value={colorValue}
          />
        </Grid>
      </Grid>
    </>
  );
}
