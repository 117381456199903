import { useCallback, useEffect, useRef } from 'react';

export function useEffectUntilTrue(callback, deps) {
  const flag = useRef(false);

  useEffect(() => {
    if (flag.current) {
      return;
    }

    flag.current = callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const reset = useCallback(
    invokeImmediately => {
      flag.current = false;

      if (invokeImmediately) {
        callback();
      }
    },
    [callback]
  );

  return reset;
}
