/**
 * @file
 *
 * This component renders the content of right hand side drawer
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Box,
  Tabs,
  Tab,
  Typography,
  makeStyles,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { VisualPanel } from './SelectionInspectorFormElements/VisualPanel';
import { ContentPanel } from './SelectionInspectorFormElements/ContentPanel';
import { IntegrationPanel } from './SelectionInspectorFormElements/IntegrationPanel';
import {
  NODE_MODEL_ATTRS,
  GROUP_NODES,
  MODEL_NODES,
  UI_DIMENSIONS,
  LINK_MODEL_ATTRS,
  MODEL_LINKS,
} from '../utils/constants';
import { shapeNodes } from '../utils/helpers';
import { useTenantState } from '../data/user';

const CustomTab = withStyles(theme => ({
  root: {
    minWidth: '33.3%',
    width: '33.3%',
  },
}))(Tab);

const useStyles = makeStyles(theme => ({
  tabGroup: {
    minWidth: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
  },
  tabPanel: { padding: theme.spacing(0) },
  noOptions: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  noIntegrationOptions: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  titleFont: {
    fontSize: theme.spacing(2),
  },
  tooltipWrapper: {
    '&>button': {
      width: '100%',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const TAB_INDEXES = {
  VISUAL_TAB: 0,
  CONTENT_TAB: 1,
  INTEGRATIONS_TAB: 2,
};

function useTabs(selectedPart) {
  const [tabIndex, setTabIndex] = useState(TAB_INDEXES.VISUAL_TAB);

  // If the selectedPart has a userSystemId, then it means a system is linked to it
  const isSystemLinked = selectedPart[NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID];
  const isMapperProject = selectedPart[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID];
  const isMapperTemplate = selectedPart[LINK_MODEL_ATTRS.CATEGORY] === MODEL_LINKS.MAPPER_TEMPLATE;

  useEffect(() => {
    if (isSystemLinked || isMapperProject || isMapperTemplate) {
      setTabIndex(TAB_INDEXES.INTEGRATIONS_TAB);
    } else {
      setTabIndex(TAB_INDEXES.VISUAL_TAB);
    }
  }, [isMapperProject, isMapperTemplate, isSystemLinked]);

  const updateTabIndex = useCallback(
    (event, newValue) => {
      setTabIndex(newValue);
    },
    [setTabIndex]
  );

  return { tabIndex, updateTabIndex };
}

const useTabState = ({
  selectedData,
  selectedPart,
  diagram,
  areAllFromSameCategory,
  tabIndex,
  updateTabIndex,
  canReadSystems,
  canReadMapperProjects,
}) => {
  const isIntegrationTabDisabled = (() => {
    // If selectedData is null or length of array is more than 1, then disable the integrations panel
    if (!selectedData || selectedData.length > 1 || !selectedPart) {
      return true;
    }

    if (
      // Check if selected part is an entity group or a link, if it isn't then disable the integrations panel
      selectedPart[NODE_MODEL_ATTRS.CATEGORY] !== GROUP_NODES.ENTITY_GROUP &&
      !selectedPart[LINK_MODEL_ATTRS.KEY].startsWith('link')
    ) {
      return true;
      // If the selected part is a link and if the to end or/and from end are shapes then disabled the integrations panel
    }

    if (
      selectedPart[LINK_MODEL_ATTRS.KEY].startsWith('link') &&
      (shapeNodes.includes(
        diagram?.findNodeForKey(selectedPart[LINK_MODEL_ATTRS.TO]).data[NODE_MODEL_ATTRS.CATEGORY]
      ) ||
        shapeNodes.includes(
          diagram?.findNodeForKey(selectedPart[LINK_MODEL_ATTRS.FROM]).data[
            NODE_MODEL_ATTRS.CATEGORY
          ]
        ))
    ) {
      return true;
    }

    // If the selected node is a entity_group and if the user doesn't have read permission for systems,
    // then disable the integrations panel because the integration panel is all about integrating the nodes
    // with a system. If we cannot read systems, then it would be better to just disable the integrations tab.
    if (selectedPart[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP && !canReadSystems) {
      return true;
    }

    if (
      selectedPart[LINK_MODEL_ATTRS.KEY].startsWith('link') &&
      !canReadMapperProjects &&
      !selectedPart[LINK_MODEL_ATTRS.MAPPER_TEMPLATE_CODE]
    ) {
      return true;
    }

    return false;
  })();

  const isContentTabDisabled = (() => {
    // If selectedData is null, disable the content tab
    if (!selectedData || !selectedPart) {
      return true;
    } else if (selectedData.length > 1) {
      // If length of selectedData array is more than 1, then check if the array elements are of same category,
      // if yes, then check if the selectedPart is an entity group. We need the content panel to be accessible for multiple select
      // only if the selected elements are entity groups (to change the system type : cloud/on-premise/none)
      if (
        areAllFromSameCategory &&
        selectedPart[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
      ) {
        return false;
      } else {
        return true;
      }
      // If one element is selected then check if the element is a spacer, actor or clock. If yes, then disabled the tab
    } else {
      return [MODEL_NODES.SPACER, MODEL_NODES.ACTOR, MODEL_NODES.CLOCK].includes(
        selectedPart[NODE_MODEL_ATTRS.CATEGORY]
      );
    }
  })();

  useEffect(() => {
    if (tabIndex === TAB_INDEXES.CONTENT_TAB && isContentTabDisabled) {
      updateTabIndex(null, TAB_INDEXES.VISUAL_TAB);
    } else if (tabIndex === TAB_INDEXES.INTEGRATIONS_TAB && isIntegrationTabDisabled) {
      updateTabIndex(null, TAB_INDEXES.VISUAL_TAB);
    }
  }, [isContentTabDisabled, isIntegrationTabDisabled, tabIndex, updateTabIndex]);

  return { isIntegrationTabDisabled, isContentTabDisabled };
};

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

export function SelectionInspector(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const selectedPart = props.selectedData && props.selectedData[0];

  const { tabIndex, updateTabIndex } = useTabs(selectedPart ?? {});

  let categories;
  let areAllFromSameCategory;
  if (props.selectedData && props.selectedData.length > 1) {
    categories = props.selectedData.map(part => {
      if (part[LINK_MODEL_ATTRS.KEY].startsWith('link')) {
        return 'link';
      }

      if (!part[NODE_MODEL_ATTRS.CATEGORY]) {
        // consider base group as shape
        // because the same attrs are editable in that as the shapes
        return part[NODE_MODEL_ATTRS.IS_GROUP] ? 'shape' : 'text';
      }

      // consider all shapes as same because same attrs are editable
      return shapeNodes.includes(part[NODE_MODEL_ATTRS.CATEGORY])
        ? 'shape'
        : part[NODE_MODEL_ATTRS.CATEGORY];
    });

    areAllFromSameCategory = new Set(categories).size === 1 ? true : false;
  }

  const tenant = useTenantState();
  const canReadSystems = useMemo(
    () => tenant.permissions.some(permission => permission.permission_code === 'read_system'),
    [tenant.permissions]
  );

  const canReadMapperProjects = useMemo(
    () => tenant.permissions.some(permission => permission.permission_code === 'read_mapper'),
    [tenant.permissions]
  );

  const getTooltipTitle = useCallback(() => {
    if (
      selectedPart[LINK_MODEL_ATTRS.KEY].startsWith('link') &&
      !canReadMapperProjects &&
      !selectedPart[LINK_MODEL_ATTRS.MAPPER_TEMPLATE_CODE]
    ) {
      return 'Mapper Projects: Permission Required';
    }

    if (selectedPart[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP && !canReadSystems) {
      return 'Systems: Permission Required';
    }

    return '';
  }, [canReadMapperProjects, canReadSystems, selectedPart]);

  const { isIntegrationTabDisabled, isContentTabDisabled } = useTabState({
    selectedData: props.selectedData,
    selectedPart,
    diagram: props.diagram,
    areAllFromSameCategory,
    tabIndex,
    updateTabIndex,
    canReadSystems,
    canReadMapperProjects,
  });

  if (!selectedPart || !props.diagram) {
    return (
      <Grid container justifyContent="center" className={classes.noOptions}>
        <Typography className={classes.titleFont}>No actions available</Typography>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item className={classes.tabGroup}>
        <Tabs
          value={tabIndex}
          onChange={updateTabIndex}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="simple tabs"
        >
          <CustomTab label={formatMessage({ id: 'VISUAL' })} {...a11yProps(0)} />
          <CustomTab
            disabled={isContentTabDisabled}
            label={formatMessage({ id: 'CONTENT' })}
            {...a11yProps(1)}
          />
          <CloneProps>
            {tabProps => (
              <Tooltip title={getTooltipTitle()}>
                <span className={classes.tooltipWrapper}>
                  <CustomTab
                    {...tabProps}
                    disabled={isIntegrationTabDisabled}
                    label={<FormattedMessage id="INTEGRATION" />}
                    {...a11yProps(2)}
                  />
                </span>
              </Tooltip>
            )}
          </CloneProps>
        </Tabs>
      </Grid>
      <Grid item>
        <TabPanel
          value={tabIndex}
          index={TAB_INDEXES.VISUAL_TAB}
          classes={{ root: classes.tabPanel }}
        >
          <VisualPanel
            diagram={props.diagram}
            nodeDataArray={props.nodeDataArray}
            selectedData={props.selectedData}
            onChange={props.onChange}
            areAllFromSameCategory={areAllFromSameCategory}
            categories={categories}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={TAB_INDEXES.CONTENT_TAB}>
          <ContentPanel
            diagram={props.diagram}
            nodeDataArray={props.nodeDataArray}
            selectedData={props.selectedData}
            onChange={props.onChange}
            areAllFromSameCategory={areAllFromSameCategory}
            categories={categories}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={TAB_INDEXES.INTEGRATIONS_TAB}>
          {props.selectedData.length > 1 ? (
            <Grid container justifyContent="center" className={classes.noIntegrationOptions}>
              <Typography className={classes.titleFont}>
                <FormattedMessage id="NO_ACTIONS_AVAILABLE" />
              </Typography>
            </Grid>
          ) : (
            <IntegrationPanel
              diagram={props.diagram}
              selectedData={selectedPart}
              onChange={props.onChange}
            />
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
}
