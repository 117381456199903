import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { SheetRoutes } from './SheetRoutes';
import { UI_DIMENSIONS, DRAWER_TYPES } from '../utils/constants';

const useStyles = makeStyles(theme => ({
  sheetPanel: {
    position: 'fixed',
    bottom: theme.spacing(0),
    padding: theme.spacing(
      0,
      UI_DIMENSIONS.DRAWER_WIDTH,
      0,
      UI_DIMENSIONS.DRAWER_WIDTH + UI_DIMENSIONS.SIDEBAR_WIDTH
    ),
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.common.white,
    borderTop: theme.borders[0],
    width: '100%',
    height: theme.spacing(5),
  },
  sheetPanelExpandLeft: {
    paddingLeft: ({ isActiveVersion }) =>
      isActiveVersion ? theme.spacing(UI_DIMENSIONS.SIDEBAR_WIDTH) : 0,
  },
  sheetPanelExpandRight: {
    paddingRight: theme.spacing(1),
  },
}));

export function SheetsPanel(props) {
  const classes = useStyles({ isActiveVersion: props.project.isActiveVersion });

  return props.project.isActiveVersion ? (
    !props.sheetState.modelData.isReadOnly && (
      <Grid
        container
        alignItems="center"
        className={clsx(classes.sheetPanel, {
          [classes.sheetPanelExpandLeft]:
            !props.isLeftDrawerOpen[DRAWER_TYPES.SYSTEM] &&
            !props.isLeftDrawerOpen[DRAWER_TYPES.INPUT_COMPONENTS],
          [classes.sheetPanelExpandRight]: !props.isRightDrawerOpen,
        })}
      >
        <SheetRoutes
          version={props.project.version}
          projectId={props.projectId}
          sheetKey={props.sheetKey}
          sheets={props.projectSheets}
          isActiveVersion={props.project.isActiveVersion}
          sheetSwitchDisabled={props.isSuggestionsVisible}
          diagramInstance={props.diagramInstance}
        />
      </Grid>
    )
  ) : (
    <Grid container className={clsx(classes.sheetPanel, classes.sheetPanelExpandLeft)}>
      <SheetRoutes
        version={props.project.version}
        projectId={props.projectId}
        sheetKey={props.sheetKey}
        sheets={props.projectSheets}
        isActiveVersion={props.project.isActiveVersion}
        diagramInstance={props.diagramInstance}
      />
    </Grid>
  );
}
