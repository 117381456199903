/**
 * @file
 *
 * Contains user systems related hooks which can fetch user systems from the API.
 */
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getServiceInstance, systemsApiBase } from '../utils/service';

const userSystemsFetcher = ({ queryKey }) => {
  const [endpoint, systemCode] = queryKey;

  return getServiceInstance().get(`${endpoint}${systemCode ? `?systemCode=${systemCode}` : ''}`);
};

/**
 * Get the user systems for the given system code.
 *
 * @param {string} [code] A system code.
 */
export function useUserSystems(code) {
  const { data, ...rest } = useQuery({
    queryKey: [`${systemsApiBase}/user-system`, code],
    queryFn: userSystemsFetcher,
  });

  const userSystems = useMemo(
    () =>
      (data ?? []).map(system => ({
        id: system.user_system_id,
        code: system.system,
        name: system.name,
        description: system.description,
        systemConfigurations: system.system_configurations,
        updatedAt: system.updated_at,
      })),
    [data]
  );

  return {
    userSystems,
    ...rest,
  };
}
