/**
 * @file
 *
 * This component renders the options that can edited in a link component
 */

import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import { SelectEditField } from './SelectEditField';
import { ColorPicker } from './ColorPicker';
import {
  LINK_MODEL_ATTRS,
  STROKE_TYPES,
  DEFAULTS,
  UI_DIMENSIONS,
  DIMENSIONS,
  TEXTFIELD_WIDTH,
} from '../../utils/constants';
import { SizeEdit } from './SizeEdit';

const useStyles = makeStyles(theme => ({
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  topMargin: {
    marginTop: theme.spacing(1),
  },
}));

export function LinkOptions(props) {
  const classes = useStyles();

  const strokeType = props.selectedData[LINK_MODEL_ATTRS.STROKE_TYPE] || STROKE_TYPES.SOLID.KEY;
  const color = props.selectedData[LINK_MODEL_ATTRS.COLOR] || DEFAULTS.LINK_COLOR;
  const strokeWidth = props.selectedData[LINK_MODEL_ATTRS.STROKE_WIDTH] ?? 1;

  return (
    <Grid item container direction="column" className={classes.drawerBody}>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        className={classes.fullWidth}
      >
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                <FormattedMessage id="TYPE" />
              </Typography>
            </Grid>
            <Grid item>
              <SelectEditField
                width={TEXTFIELD_WIDTH.MD}
                onChange={props.onChange}
                attr={LINK_MODEL_ATTRS.STROKE_TYPE}
                value={strokeType}
                options={[STROKE_TYPES.SOLID.KEY, STROKE_TYPES.DASHED.KEY, STROKE_TYPES.DOTTED.KEY]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                <FormattedMessage id="WIDTH" />
              </Typography>
            </Grid>
            <Grid item>
              <SizeEdit
                width={TEXTFIELD_WIDTH.MD}
                onChange={props.onChange}
                max={DIMENSIONS.LINK_STROKE_WIDTH.MAX}
                min={DIMENSIONS.LINK_STROKE_WIDTH.MIN}
                attr={LINK_MODEL_ATTRS.STROKE_WIDTH}
                value={strokeWidth}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        className={clsx(classes.topMargin, classes.fullWidth)}
      >
        <ColorPicker
          onChange={props.onChange}
          label="Color"
          attr={LINK_MODEL_ATTRS.COLOR}
          value={color}
        />
      </Grid>
    </Grid>
  );
}
