/**
 * @file
 *
 * This file contains the component that is rendered in the right panel
 * when multiple entity group parts are selected
 */
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { startCase } from 'lodash-es';

import { CommonSection } from '../CommonSection';
import { NODE_MODEL_ATTRS, DEFAULTS, UI_DIMENSIONS } from '../../../utils/constants';
import { ColorPicker } from '../ColorPicker';

const useStyles = makeStyles(theme => ({
  drawerBody: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(2),
  },
}));

export function MultipleEntityGroupOptionsPanel(props) {
  const classes = useStyles();

  const colorValue = props.selectedData[0][NODE_MODEL_ATTRS.COLOR] || DEFAULTS.ENTITY_GROUP_COLOR;

  return (
    <>
      <CommonSection
        handleDelete={props.handleDelete}
        handleCopy={props.handleCopy}
        isMultiSelect
        copyEnabled
      />
      <Grid container direction="column" className={classes.drawerBody}>
        <Grid item>
          <ColorPicker
            onChange={props.onChange}
            label={startCase(NODE_MODEL_ATTRS.COLOR)}
            attr={NODE_MODEL_ATTRS.COLOR}
            value={colorValue}
          />
        </Grid>
      </Grid>
    </>
  );
}
