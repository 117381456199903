/**
 * @file
 *
 * This file includes all the shape models schema in GoJS
 *
 */

import * as go from 'gojs';

import {
  commonShapeBodyOptions,
  getCommonNodeSetupOptions,
  commonBlocks,
  bindings,
  makePort,
  commonShapePanelOptions,
} from './helpers';
import { actorGeometryString, clockGeometryString } from './extensions/shapes';
import './extensions/shapes';

const $ = go.GraphObject.make;

export const rectangleNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Rectangle',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.3, 0)),
    makePort('T2', new go.Spot(0.7, 0)),
    makePort('R1', new go.Spot(1, 0.3)),
    makePort('R2', new go.Spot(1, 0.7)),
    makePort('B1', new go.Spot(0.3, 1)),
    makePort('B2', new go.Spot(0.7, 1)),
    makePort('L1', new go.Spot(0, 0.3)),
    makePort('L2', new go.Spot(0, 0.7))
  ),
  commonBlocks.text
);

export const squareNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Square',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.3, 0)),
    makePort('T2', new go.Spot(0.7, 0)),
    makePort('R1', new go.Spot(1, 0.3)),
    makePort('R2', new go.Spot(1, 0.7)),
    makePort('B1', new go.Spot(0.3, 1)),
    makePort('B2', new go.Spot(0.7, 1)),
    makePort('L1', new go.Spot(0, 0.3)),
    makePort('L2', new go.Spot(0, 0.7))
  ),
  commonBlocks.text
);

export const roundedRectangleNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'RoundedRectangle',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.3, 0)),
    makePort('T2', new go.Spot(0.7, 0)),
    makePort('R1', new go.Spot(1, 0.3)),
    makePort('R2', new go.Spot(1, 0.7)),
    makePort('B1', new go.Spot(0.3, 1)),
    makePort('B2', new go.Spot(0.7, 1)),
    makePort('L1', new go.Spot(0, 0.3)),
    makePort('L2', new go.Spot(0, 0.7))
  ),
  commonBlocks.text
);

export const ellipseNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Ellipse',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.5, 0)),
    makePort('R1', new go.Spot(1, 0.5)),
    makePort('B1', new go.Spot(0.5, 1)),
    makePort('L1', new go.Spot(0, 0.5))
  ),
  commonBlocks.text
);

export const circleNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions({ rotatable: false }),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Circle',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.5, 0)),
    makePort('R1', new go.Spot(1, 0.5)),
    makePort('B1', new go.Spot(0.5, 1)),
    makePort('L1', new go.Spot(0, 0.5))
  ),
  commonBlocks.text
);

export const triangleNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Triangle',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.5, 0)),
    makePort('B1', new go.Spot(0.3, 1)),
    makePort('B2', new go.Spot(0.7, 1))
  ),
  commonBlocks.text
);

export const hexagonNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Hexagon',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.5, 0)),
    makePort('R1', new go.Spot(0.9, 0.5)),
    makePort('B1', new go.Spot(0.5, 1)),
    makePort('L1', new go.Spot(0.1, 0.5))
  ),
  commonBlocks.text
);

export const cloudNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Cloud',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.3, 0)),
    makePort('T2', new go.Spot(0.7, 0)),
    makePort('R1', new go.Spot(1, 0.2)),
    makePort('R2', new go.Spot(1, 0.8)),
    makePort('B1', new go.Spot(0.3, 1)),
    makePort('B2', new go.Spot(0.7, 1)),
    makePort('L1', new go.Spot(0, 0.3)),
    makePort('L2', new go.Spot(0, 0.8))
  ),
  commonBlocks.text
);

export const cylinderNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Cylinder',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.5, 0)),
    makePort('R1', new go.Spot(1, 0.3)),
    makePort('R2', new go.Spot(1, 0.7)),
    makePort('B1', new go.Spot(0.5, 1)),
    makePort('L1', new go.Spot(0, 0.3)),
    makePort('L2', new go.Spot(0, 0.7))
  ),
  commonBlocks.text
);

export const databaseNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions({ rotatable: false }),
  bindings.location,
  $(
    go.Panel,
    'Auto',
    commonShapePanelOptions,
    bindings.angle,
    $(
      go.Shape,
      {
        figure: 'Database',
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay(),
      bindings.highlightFillColor
    ),
    makePort('T1', new go.Spot(0.5, 0)),
    makePort('R1', new go.Spot(1, 0.3)),
    makePort('R2', new go.Spot(1, 0.7)),
    makePort('B1', new go.Spot(0.5, 1)),
    makePort('L1', new go.Spot(0, 0.3)),
    makePort('L2', new go.Spot(0, 0.7))
  ),
  commonBlocks.text
);

export const actorNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  bindings.angle,
  $(
    go.Panel,
    'Auto',
    {
      ...commonShapePanelOptions,
      background: 'transparent',
    },
    $(
      go.Shape,
      {
        geometryString: actorGeometryString,
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay()
    )
  )
);

export const clockNodeTemplate = $(
  go.Node,
  'Auto',
  getCommonNodeSetupOptions(),
  bindings.location,
  bindings.angle,
  $(
    go.Panel,
    'Auto',
    {
      ...commonShapePanelOptions,
      background: 'transparent',
    },
    $(
      go.Shape,
      {
        geometryString: clockGeometryString,
        ...commonShapeBodyOptions,
      },
      bindings.size,
      bindings.strokeColor.makeTwoWay()
    )
  )
);
