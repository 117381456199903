/**
 * @file
 *
 * This component renders content of the left hand side drawer
 */

import React, { useCallback, useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Avatar,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import { entries } from 'lodash-es';
import { MdDelete, MdEdit, MdMoreVert, MdTextFields } from 'react-icons/md';
import { MdDns } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useConfirmationDialog } from 'material-ui-confirmation';
import clsx from 'clsx';

import { ShapeIcon } from './ShapeIcon';
import {
  PALETTE,
  DEFAULTS,
  MODEL_NODES,
  NODE_MODEL_ATTRS,
  STROKE_TYPES,
  ENTITY_TYPES,
  BASE_SYSTEMS,
  CUSTOM_PALETTE_SHAPES,
} from '../utils/constants';
import { FullWidthDivider } from '../components/SelectionInspectorFormElements/FullWidthDivider';
import { getSizeString, createEntityCreationData } from '../models/helpers';
import { getAssetURL, digitalTransformerAssetBase } from '../utils/service';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { useDialog } from '../hooks/useDialog';
import { CustomSystemDialog } from './CustomSystemDialog';
import { useDeleteCustomShapeMutation } from '../data/customShapes';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
  },
  tiles: {
    height: theme.spacing(13),
    width: theme.spacing(13.8),
    border: theme.borders[0],
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(0.4),
  },
  drawerTitle: {
    width: '100%',
    padding: theme.spacing(0, 0, 0.5, 1.2),
  },
  systemsContainer: {
    maxWidth: '95%',
    margin: theme.spacing(0, 0.5, 0, 0.5),
  },
  shapesContainer: {
    maxWidth: '92%',
  },
  systemIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  shapeIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(1),
  },
  shape: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  shapeGroup: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(0.4),
    border: theme.borders[0],
    borderRadius: theme.spacing(0.4),
    fontSize: theme.spacing(3.5),
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5),
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  titleFont: {
    fontSize: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  customShapeContainer: ({ height }) => ({
    maxWidth: '95%',
    maxHeight: height - theme.spacing(70),
    overflowX: 'hidden',
    overflow: 'auto',
    display: 'flex',
    margin: theme.spacing(0, 0.5, 0, 0.5),
    flexDirection: 'row',
  }),
  customShapeIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  customShapeName: {
    width: '100%',
    height: theme.spacing(4.5),
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noDataText: {
    fontSize: theme.spacing(2),
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1),
  },
  systemName: {
    width: '100%',
    height: theme.spacing(4.5),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textOverflow: 'ellipsis',
  },
  menuBtn: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
  deleteOption: {
    color: theme.palette.error.main,
  },
  menuItemIcon: {
    minWidth: theme.spacing(3),
  },
}));

export const modelDataForPalette = {
  ...createEntityCreationData(PALETTE.SYSTEM.SUCCESS_FACTORS, {
    fieldGroups: {
      Foundation: [
        {
          text: 'Cost Center',
          stroke: STROKE_TYPES.DASHED.KEY,
        },
        'Org Objects - BU/Div/Dept/team',
        'Position',
        'Work Schedule',
        'User Data File',
      ],
      'Employee Central': [
        'Pers. Information',
        'Employment Info',
        'Job Info',
        'Comp Info - Recurring',
        'Comp Info - Non Recurring',
      ],
    },
    type: ENTITY_TYPES.CLOUD,
    systemCode: BASE_SYSTEMS.SF_EC.KEY,
    systemCodeLock: true,
  }),
  ...createEntityCreationData(PALETTE.SYSTEM.SAP_HCM, {
    fieldGroups: {
      'Pers. Development': ['Org Unit', 'Position', 'Relationships'],
      'Pers. Admin': ['Pers. Info', 'Job', 'Compensation'],
      'Time Mgmt': ['Absences', 'Attendances', 'Quotas', 'Work Schedules'],
      Payroll: ['Country legal infotypes', 'Taxation', 'Insurance/Benefits', 'Payroll runs'],
    },
    type: ENTITY_TYPES.ON_PREMISE,
    systemCode: BASE_SYSTEMS.SAP_HCM.KEY,
    systemCodeLock: true,
  }),
  ...createEntityCreationData(PALETTE.SYSTEM.SAP_ERP_S4_HANA, {
    fieldGroups: {
      Financials: ['Cost Center/Controlling', 'G/L Accounting'],
    },
  }),
  ...createEntityCreationData(PALETTE.SYSTEM.IDM, {
    fieldGroups: {
      'Mini Master': [
        { text: 'Org Objects - BU/Div/Dept/team', stroke: STROKE_TYPES.DASHED.KEY },
        { text: 'Employee', stroke: STROKE_TYPES.DASHED.KEY },
        { text: 'Manager', stroke: STROKE_TYPES.DASHED.KEY },
      ],
    },
    fields: ['Username/Email/..'],
  }),
  ...createEntityCreationData(PALETTE.SYSTEM.CUSTOM, {
    fields: ['Entity 1', 'Entity 2'],
  }),
  [PALETTE.SHAPES.SQUARE.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.SQUARE,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(100, 100),
    [NODE_MODEL_ATTRS.ANGLE]: '0',
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.RECTANGLE.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.RECTANGLE,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(100, 50),
    [NODE_MODEL_ATTRS.ANGLE]: '0',
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.ROUNDED_RECTANGLE.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.ROUNDED_RECTANGLE,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(100, 50),
    [NODE_MODEL_ATTRS.ANGLE]: '0',
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.CIRCLE.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.CIRCLE,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(100),
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.ELLIPSE.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.ELLIPSE,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(100, 50),
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.TRIANGLE.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.TRIANGLE,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(80),
    [NODE_MODEL_ATTRS.ANGLE]: '0',
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.CLOUD.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.CLOUD,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(80),
    [NODE_MODEL_ATTRS.ANGLE]: '0',
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.HEXAGON.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.HEXAGON,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(80),
    [NODE_MODEL_ATTRS.ANGLE]: '0',
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.CYLINDER.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.CYLINDER,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(100),
    [NODE_MODEL_ATTRS.ANGLE]: '0',
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.DATABASE.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.DATABASE,
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(100),
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.ACTOR.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.ACTOR,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(40, 60),
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.SHAPES.CLOCK.KEY]: {
    [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.CLOCK,
    [NODE_MODEL_ATTRS.SIZE]: getSizeString(50),
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
  },
  [PALETTE.TEXT.KEY]: {
    [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
    [NODE_MODEL_ATTRS.FONT_FAMILY]: DEFAULTS.FONT_PROPERTIES.FAMILY,
    [NODE_MODEL_ATTRS.FONT_SIZE]: DEFAULTS.FONT_PROPERTIES.SIZE,
    [NODE_MODEL_ATTRS.FONT_STYLE]: DEFAULTS.FONT_PROPERTIES.STYLE,
    [NODE_MODEL_ATTRS.FONT_WEIGHT]: DEFAULTS.FONT_PROPERTIES.WEIGHT,
  },
};

/**
 *
 * @param {go.Diagram} diagram
 * @param {go.Node} node
 */
export function highlightNode(diagram, node) {
  if (!diagram) {
    return;
  }

  const oldSkipSettings = diagram.skipsUndoManager;
  diagram.skipsUndoManager = true;

  const txnIdentifier = 'highlight current node on dragover';

  diagram.startTransaction(txnIdentifier);
  if (node !== null) {
    diagram.highlight(node);
  } else {
    diagram.clearHighlighteds();
  }
  diagram.commitTransaction(txnIdentifier);

  diagram.skipsUndoManager = oldSkipSettings;
}

const SystemAvatar = props => {
  const classes = useStyles();

  if (props.keyValue.toLowerCase().startsWith('success')) {
    return (
      <Avatar
        variant="square"
        imgProps={{ 'data-dragkey': props.keyValue }}
        className={props.class}
        src={getAssetURL(digitalTransformerAssetBase, 'SAP_SF.png')}
      />
    );
  } else if (props.keyValue.toLowerCase().startsWith('sap')) {
    return (
      <Avatar
        variant="square"
        imgProps={{ 'data-dragkey': props.keyValue }}
        className={props.class}
        src={getAssetURL(digitalTransformerAssetBase, 'SAP_HCM.png')}
      />
    );
  }

  return (
    <Avatar variant="square" className={props.class} style={{ background: 'transparent' }}>
      <MdDns data-dragkey={props.keyValue} className={classes.primaryColor} size="23px" />
    </Avatar>
  );
};

const useDeleteCustomSystem = () => {
  const { getConfirmation } = useConfirmationDialog();

  const deleteCustomShapeMutation = useDeleteCustomShapeMutation();

  const handleDeleteCustomSystem = useCallback(
    customShapeId => {
      getConfirmation({
        title: 'Delete Custom System',
        body: 'Are you sure you want to delete this custom shape?',

        onAccept: async () => {
          await deleteCustomShapeMutation.mutateAsync({ customShapeId });
        },

        acceptButtonProps: {
          autoFocus: false,
          variant: 'contained',
        },
      });
    },
    [deleteCustomShapeMutation, getConfirmation]
  );

  return handleDeleteCustomSystem;
};

export function SystemsPalette(props) {
  const [customShapeToBeUpdated, setCustomShapeToBeUpdated] = useState(null);
  const dimensions = useWindowDimensions();

  const classes = useStyles({ height: dimensions.height });

  const handleDeleteCustomSystem = useDeleteCustomSystem();
  const { formatMessage } = useIntl();

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item container>
          <Grid item className={classes.drawerTitle}>
            <Typography className={classes.titleFont}>
              <FormattedMessage id="SYSTEMS" />
            </Typography>
          </Grid>
          <FullWidthDivider />
          <Grid item>
            <Grid container justifyContent="center">
              <List
                component={Grid}
                container
                direction="row"
                disablePadding
                dense
                className={classes.systemsContainer}
                onDragStart={props.onDragStart}
              >
                {entries(PALETTE.SYSTEM).map(([key, { LABEL: label }]) => (
                  <ListItem
                    component={Grid}
                    item
                    className={classes.tiles}
                    key={key}
                    button
                    draggable
                    data-dragkey={key}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <ListItemAvatar data-dragkey={key} className={classes.avatarContainer}>
                        <SystemAvatar class={classes.systemIcon} keyValue={key} />
                      </ListItemAvatar>
                      <ListItemText data-dragkey={key} primary={label} />
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.topMargin}>
          <Grid item className={classes.drawerTitle}>
            <Typography className={classes.titleFont}>
              <FormattedMessage id="CUSTOM_SYSTEMS" />
            </Typography>
          </Grid>
          <FullWidthDivider />
          {Boolean(props.customShapes.length) ? (
            <Grid item container>
              <Grid container justifyContent="center">
                <List
                  component={Grid}
                  container
                  direction="row"
                  disablePadding
                  dense
                  className={classes.customShapeContainer}
                  onDragStart={props.onDragStart}
                >
                  {props.customShapes.map(customShape => (
                    <ListItem
                      component={Grid}
                      item
                      className={classes.tiles}
                      key={customShape.id}
                      button
                      draggable
                      data-dragkey={customShape.id}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                      >
                        <PopupState
                          variant="popover"
                          popupId={`custom-system-${customShape.id}-menu`}
                        >
                          {popupState => (
                            <>
                              <Tooltip title="Custem System Menu">
                                <IconButton
                                  size="small"
                                  disableRipple
                                  className={classes.menuBtn}
                                  {...bindTrigger(popupState)}
                                >
                                  <MdMoreVert />
                                </IconButton>
                              </Tooltip>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    openDialog();
                                    setCustomShapeToBeUpdated(customShape);
                                  }}
                                >
                                  <ListItemIcon className={classes.menuItemIcon}>
                                    <MdEdit fontSize="18px" />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={formatMessage({ id: 'EDIT_CUSTOM_SYSTEM' })}
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    handleDeleteCustomSystem(customShape.id);
                                  }}
                                >
                                  <ListItemIcon
                                    className={clsx(classes.deleteOption, classes.menuItemIcon)}
                                  >
                                    <MdDelete fontSize="18px" />
                                  </ListItemIcon>
                                  <ListItemText
                                    className={classes.deleteOption}
                                    primary={formatMessage({ id: 'DELETE_CUSTOM_SYSTEM' })}
                                  />
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>

                        <ListItemAvatar
                          data-dragkey={customShape.id}
                          className={classes.customShapeIconContainer}
                        >
                          {CUSTOM_PALETTE_SHAPES[customShape.icon]}
                        </ListItemAvatar>
                        <Tooltip title={customShape.name}>
                          <Grid component={ListItemText} item className={classes.systemName}>
                            <Typography noWrap variant="body2">
                              {customShape.name}
                            </Typography>
                          </Grid>
                        </Tooltip>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          ) : (
            <Grid item container justifyContent="center">
              <Typography className={classes.noDataText}>
                <FormattedMessage id="NO_CUSTOM_SYSTEMS" />
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {isDialogOpen && (
        <CustomSystemDialog
          onClose={closeDialog}
          open={isDialogOpen}
          customShapeToBeUpdated={customShapeToBeUpdated}
          update
        />
      )}
    </>
  );
}

export function ShapesPalette(props) {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item className={classes.drawerTitle}>
        <Typography className={classes.titleFont}>
          <FormattedMessage id="SHAPES_N_TEXTS" />
        </Typography>
      </Grid>
      <FullWidthDivider />
      <Grid item>
        <Grid container justifyContent="center">
          <List
            component={Grid}
            container
            direction="row"
            disablePadding
            dense
            className={classes.shapesContainer}
            onDragStart={props.onDragStart}
          >
            {entries(PALETTE.SHAPES).map(([key, { LABEL: label }]) => (
              <Grid item key={key}>
                <Tooltip title={label}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    data-dragkey={key}
                    draggable
                    className={classes.shapeGroup}
                  >
                    <Avatar data-dragkey={key} variant="rounded" className={classes.shape}>
                      <ShapeIcon data-dragkey={key} type={key} />
                    </Avatar>
                  </Grid>
                </Tooltip>
              </Grid>
            ))}
            <Tooltip title={PALETTE.TEXT.LABEL}>
              <Grid
                draggable
                container
                className={classes.shapeGroup}
                justifyContent="center"
                alignItems="center"
                data-dragkey={PALETTE.TEXT.KEY}
              >
                <MdTextFields />
              </Grid>
            </Tooltip>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
