/**
 * @file
 *
 * This file contains a custom hook to fetch image to use progressive image loading
 */
import { useState, useEffect } from 'react';

import {
  getAssetURL,
  digitalTransformerAssetBase,
  getLambdaServiceInstance,
} from '../utils/service';
import { config } from '../utils/config';

const placeholderImageURL = getAssetURL(digitalTransformerAssetBase, 'project-background.png');

export function useImageFetch({
  imagePath,
  placeholderImage = placeholderImageURL,
  isUsableAsSrc = false,
}) {
  const [src, updateSrc] = useState(placeholderImage);

  useEffect(
    () => {
      if (isUsableAsSrc) {
        updateSrc(imagePath);
        return;
      }

      let srcUrl;

      if (config.env === 'production') {
        getLambdaServiceInstance()
          .get(imagePath, { responseType: 'blob', headers: { Accept: 'image/png' } })
          .then(response => {
            srcUrl = URL.createObjectURL(response);
            updateSrc(srcUrl);
          })
          .catch(() => {
            updateSrc(placeholderImage);
          });
      }

      return () => {
        if (srcUrl) {
          URL.revokeObjectURL(srcUrl);
        }
      };
    },
    // eslint-disable-next-line
    [imagePath]
  );

  return src;
}
