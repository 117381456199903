/**
 * @file
 *
 * Thie file contains miscellaneous helper functions
 */

import {
  NODE_MODEL_ATTRS,
  GROUP_NODES,
  LINK_MODEL_ATTRS,
  MODEL_LINKS,
  MODEL_NODES,
  DIMENSIONS,
} from './constants';
import { pull, trim, without, values } from 'lodash-es';
import * as go from 'gojs';

import { config } from './config';
import { getPlatformURL } from './service';
import { useTenantState } from '../data/user';
import { highlightNode } from '../components/Palette';
import { common } from '@material-ui/core/colors';
import { uniqueKeyGenerator } from '../models/helpers';

let promise;
/**
 * A helper to defer low priority tasks to the microTask queue to virtually enhance performance of high priority tasks
 * i.e. anything resulting from direct user interaction
 */
export const queueMicrotask =
  typeof window.queueMicrotask === 'function'
    ? window.queueMicrotask
    : // reuse resolved promise, and allocate it lazily
      cb =>
        (promise || (promise = Promise.resolve())).then(cb).catch(err =>
          setTimeout(() => {
            throw err;
          }, 0)
        );

/**
 * This delimiters are used to create the key for a particular mapper template linking
 *
 */
export const delimiters = {
  linkSrcDest: ' => ',
  mapperTemplate: ' == ',
};

/**
 * This is a helper function used to check if there can be any valid mapper
 *
 * @param {object} paramObj
 * @param {object[]} nodeDataArray      All the nodes currently in the canvas
 * @param {object[]} linkDataArray      All the links currently in the canvas
 */
export const getPossibleSystemMappingPairs = ({ nodeDataArray, linkDataArray }) => {
  /**
   * @type {object[]}
   */
  const entitySystems = nodeDataArray.filter(
    node => node[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
  );

  if (entitySystems.length < 2) {
    return [];
  }

  const existingMappingKeys = new Set(
    linkDataArray
      .filter(link => link[LINK_MODEL_ATTRS.CATEGORY] === MODEL_LINKS.MAPPER_TEMPLATE)
      .map(
        link =>
          `${link[LINK_MODEL_ATTRS.FROM]}${delimiters.linkSrcDest}${link[LINK_MODEL_ATTRS.TO]}`
      )
  );

  const entityPairs = [];
  entitySystems.forEach(srcSystem => {
    const srcKey = srcSystem[NODE_MODEL_ATTRS.KEY];

    entitySystems.forEach(destSystem => {
      const destKey = destSystem[NODE_MODEL_ATTRS.KEY];

      if (destKey === srcKey) {
        return;
      }

      const mappingKey = `${srcKey}${delimiters.linkSrcDest}${destKey}`;

      const mapperLinkExists = existingMappingKeys.has(mappingKey);

      if (mapperLinkExists) {
        return;
      }

      entityPairs.push({
        src: srcSystem,
        dest: destSystem,
      });
    });
  });

  return entityPairs;
};

/**
 * This is a helper function used to generate the shortname of a system
 *
 * @param {object} system      System fetched from the backend
 */
export function getShortName(system) {
  if (system.system_code.toLowerCase().startsWith('sf')) {
    return trim(system.system_name)
      .split(' ')
      .shift();
  } else if (system.system_code.toLowerCase().startsWith('sap')) {
    if (trim(system.system_name).split(' ').length > 2) {
      return pull(trim(system.system_name).split(' '), 'SAP')
        .map(word => word[0])
        .join('');
    }
    return pull(trim(system.system_name).split(' '), 'SAP').join('');
  }

  return system.system_name;
}

/**
 * This is a helper function used to find and return the shortname of a system
 *
 * @param {Array} systemsData      Array of systems in the redux store
 * @param {string} systemCode      system code of the system for which we want to return the short name
 */
export const getShortNameOfSystem = (systemsData, systemCode) =>
  systemsData?.find(system => system.code === systemCode).shortName ?? '';

/**
 * This is a helper function used to get the link to a mapper project based on the mapper project id
 *
 * @param {String} mapperProjectId
 */
export const getMapperProjectLinkHref = mapperProjectId => {
  const tenant = useTenantState.get();

  return `${getPlatformURL()}/#/mapper/t-${tenant?.tenant_id}-t&/m/detail/${mapperProjectId}`;
};

export const getUserSystemLinkHref = userSystemId => {
  const tenant = useTenantState.get();

  return `${getPlatformURL()}/#/systems/t-${tenant?.tenant_id}-t&/s/system/${userSystemId}`;
};

/*
  Array of all the shape nodes
*/
export const shapeNodes = without(
  values(MODEL_NODES),
  MODEL_NODES.FIELD,
  MODEL_NODES.SPACER,
  MODEL_NODES.WATERMARK
);

/**
 * This is a helper function used to calculate a location for the nodes of a custom shape when dropped in canvas
 *
 * @param {String} persistedNodeLocation             initial location of the node
 * @param {String} persistedEntityGroupLocation      initial location of the entity group
 * @param {go.Point} newEntityGroupLocation          object containing the new location coords of the entity group
 *
 * @returns {String}                                 new location of the node
 */
export const calculateNewLocationForNode = (
  persistedNodeLocation,
  persistedEntityGroupLocation,
  newEntityGroupLocation
) => {
  const initialNodeLocation = new go.Point.parse(persistedNodeLocation);
  const initialEntityGroupLocation = new go.Point.parse(persistedEntityGroupLocation);

  const offsetX = initialEntityGroupLocation.x - initialNodeLocation.x;
  const offsetY = initialEntityGroupLocation.y - initialNodeLocation.y;

  const newLocationForNodeX = newEntityGroupLocation.x - offsetX;
  const newLocationForNodeY = newEntityGroupLocation.y - offsetY;

  const point = new go.Point(newLocationForNodeX, newLocationForNodeY);

  return go.Point.stringify(point);
};

export const getMailToString = ({ emailSubject, emailBody }) => {
  return `mailto:${config.supportEmail}?subject=${emailSubject}&body=${emailBody}`;
};

/**
 * function that calculated the current point in the canvas
 * for a dragged node
 *
 * @param {go.Diagram} diagram
 * @param {Event} event
 *
 * @returns {{x: number, y: number}}
 */
export const calculateLocationPoint = (diagram, event) => {
  /**
   * @type {HTMLCanvasElement}
   */
  const canvas = event.target;
  const pixelRatio = diagram.computePixelRatio();

  if (!canvas instanceof HTMLCanvasElement) {
    return;
  }

  const boundingBox = canvas.getBoundingClientRect();

  const boundingBoxWidth = boundingBox.width || 0.001;
  const boundingBoxHeight = boundingBox.height || 0.001;

  const x = event.clientX - boundingBox.left * (canvas.width / pixelRatio / boundingBoxWidth);
  const y = event.clientY - boundingBox.top * (canvas.height / pixelRatio / boundingBoxHeight);

  return { x, y };
};

/**
 * handler for dragover event on the canvas container
 *
 * @param {go.Diagram} diagram
 *
 * @returns {function(Event): void}
 */
export const handleDragOverFromPalette = diagram => event => {
  event.preventDefault();

  const { x, y } = calculateLocationPoint(diagram, event);
  const point = diagram.transformViewToDoc(new go.Point(x, y));

  const currentNode = diagram.findPartAt(point, true);
  if (currentNode instanceof go.Node) {
    highlightNode(diagram, currentNode);
  } else {
    highlightNode(diagram, null);
  }
};

/**
 * handler for dragover event on the canvas container
 *
 * @param {go.Diagram} diagram
 */
export const handleDragLeaveFromPalette = diagram => () => {
  highlightNode(diagram, null);
};

/**
 * handler for zoom in button
 */
export const handleIncreaseZoom = diagramInstance => {
  if (diagramInstance.current) {
    /**
     * @type {go.Diagram}
     */
    const diagram = diagramInstance.current;

    diagram.commandHandler.increaseZoom();
  }
};

/**
 * handler for zoom out button
 */
export const handleDecreaseZoom = diagramInstance => {
  if (diagramInstance.current) {
    /**
     * @type {go.Diagram}
     */
    const diagram = diagramInstance.current;

    diagram.commandHandler.decreaseZoom();
  }
};

/**
 * handler for zoom to fit button
 */
export const handleZoomToFit = diagramInstance => {
  if (diagramInstance.current) {
    /**
     * @type {go.Diagram}
     */

    const diagram = diagramInstance.current;

    diagram.zoomToFit();
  }
};

/**
 * handler for resetting zoom button
 */
export const handleResetZoom = diagramInstance => {
  if (diagramInstance.current) {
    /**
     * @type {go.Diagram}
     */
    const diagram = diagramInstance.current;

    diagram.commandHandler.resetZoom();
  }
};

/**
 * function to generate preview image
 *
 * @returns {string}
 */
export const generatePreviewImage = diagramInstance => {
  if (diagramInstance.current) {
    /**
     * @type {go.Diagram}
     */
    const diagram = diagramInstance.current;

    const { x, y, height, width } = diagram.documentBounds;

    // tweak this value to tweak the scaling
    // currently it's kept on default value
    const scaleFactor = 1;

    const minimumHeight = DIMENSIONS.PREVIEW.HEIGHT * scaleFactor;
    const minimumWidth = DIMENSIONS.PREVIEW.WIDTH * scaleFactor;

    const topPadding = height >= minimumHeight ? 0 : Math.floor((minimumHeight - height) / 2);
    const leftPadding = width >= minimumWidth ? 0 : Math.floor((minimumWidth - width) / 2);

    const minimumPadding = 20 * scaleFactor;

    const exportHeight = Math.max(minimumHeight, height);
    const exportWidth = Math.max(minimumWidth, width);

    const img = diagram.makeImageData({
      type: 'image/png',
      background: common.white,
      scale: scaleFactor,
      position: new go.Point(
        x - Math.max(leftPadding, minimumPadding),
        y - Math.max(topPadding, minimumPadding)
      ),
      size: new go.Size(
        leftPadding ? exportWidth : exportWidth + 2 * minimumPadding,
        topPadding ? exportHeight : exportHeight + 2 * minimumPadding
      ),
    });

    return img;
  }

  return null;
};

/**
 * function to generate an export image
 *
 * @returns {string | null}
 */
const generateExportImage = diagramInstance => {
  if (diagramInstance.current) {
    /**
     * @type {go.Diagram}
     */

    const diagram = diagramInstance.current;

    const { bottom, right } = diagram.documentBounds;

    const oldSkipSettings = diagram.skipsUndoManager;
    diagram.skipsUndoManager = true;

    const addWatermarkTxnIdentifier = 'add watermark';

    diagram.startTransaction(addWatermarkTxnIdentifier);

    const key = uniqueKeyGenerator('node')(diagram.model);
    diagram.model.addNodeData({
      [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.WATERMARK,
      [NODE_MODEL_ATTRS.LOCATION]: go.Point.stringify(
        new go.Point(right - DIMENSIONS.WATERMARK.WIDTH * 0.5, bottom + 40)
      ),
      [NODE_MODEL_ATTRS.KEY]: key,
    });

    diagram.commitTransaction(addWatermarkTxnIdentifier);

    const img = diagram.makeImageData({
      type: 'image/png',
      background: common.white,
      scale: 1,
      maxSize: new go.Size(Infinity, Infinity),
    });

    const removeWatermarkTxnIdentifier = 'remove watermark';
    diagram.startTransaction(removeWatermarkTxnIdentifier);

    diagram.model.removeNodeData(diagram.findNodeForKey(key).data);

    diagram.commitTransaction(removeWatermarkTxnIdentifier);

    diagram.skipsUndoManager = oldSkipSettings;

    return img;
  }

  return null;
};

/**
 * handler for export current sheet to image button
 */
export const handleExport = (projectName, sheetName, diagramInstance) => {
  const img = generateExportImage(diagramInstance);

  const a = document.createElement('a');
  a.href = img;
  a.download = `${projectName}-${sheetName}.png`;
  a.click();
};
