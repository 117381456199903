/**
 * @file
 *
 * GoJS extension for the inline text editing component
 */

import * as go from 'gojs';
import isHotkey from 'is-hotkey';

const isEnter = isHotkey('enter');
const isTab = isHotkey('tab');
const isEsc = isHotkey('esc');

export function createCustomTextEditorBlock() {
  const input = document.createElement('input');

  const CustomTextEditorBlock = new go.HTMLInfo();

  /**
   * function that handles the mounting logic for the text editor input
   *
   * @param {go.TextBlock} textBlock
   * @param {go.Diagram} diagram
   * @param {go.TextEditingTool} tool
   */
  function showCustomTextEditorBlock(textBlock, diagram, tool) {
    if (!textBlock instanceof go.TextBlock) {
      return;
    }

    input.value = textBlock.text;

    const location = textBlock.getDocumentPoint(go.Spot.TopLeft);
    const position = diagram.transformDocToView(location);

    input.addEventListener('keydown', function(event) {
      // Enter
      if (isEnter(event)) {
        tool.acceptText(go.TextEditingTool.Tab);
        return false;
      } // Tab
      else if (isTab(event)) {
        tool.acceptText(go.TextEditingTool.Tab);
        event.preventDefault();
        return false;
      } // Esc
      else if (isEsc(event)) {
        tool.doCancel();

        if (tool.diagram) {
          tool.diagram.focus();
        }
      }
    });

    input.style.left = `${position.x}px`;
    input.style.top = `${position.y}px`;
    input.style.position = `absolute`;
    input.style.zIndex = 100;

    diagram.div.appendChild(input);

    input.focus();
  }

  /**
   * function that handles the unmounting logic for hte text editor input
   *
   * @param {go.Diagram} diagram
   */
  function hideCustomTextEditorBlock(diagram) {
    // just so if the DOM has been updated by GoJS or React or route change
    // the error is caught and doesn't crash the app
    try {
      diagram.div.removeChild(input);
    } catch (err) {}
  }

  /**
   * function that handles extraction of the value from the text editor input
   */
  function valueFunction() {
    return input.value;
  }

  CustomTextEditorBlock.show = showCustomTextEditorBlock;
  CustomTextEditorBlock.hide = hideCustomTextEditorBlock;
  CustomTextEditorBlock.valueFunction = valueFunction;

  return CustomTextEditorBlock;
}
