import React, { Fragment, useMemo, useCallback } from 'react';
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Divider,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContentText,
  Tooltip,
} from '@material-ui/core';
import { BsArrowRight } from 'react-icons/bs';
import { MdCheck, MdNavigation } from 'react-icons/md';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { isPlainObject } from 'lodash-es';
import { useConfirmationDialog } from 'material-ui-confirmation';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  LINK_MODEL_ATTRS,
  NODE_MODEL_ATTRS,
  GROUP_NODES,
  MODEL_NODES,
  BASE_SYSTEMS,
  UI_DIMENSIONS,
} from '../../utils/constants';
import { SystemAvatar } from '../SystemAvatar';
import { getMapperProjectLinkHref, getShortNameOfSystem } from '../../utils/helpers';
import { useNotifyError } from '../../hooks/useNotifyError';
import { useDialog } from '../../hooks/useDialog';
import { useSystems } from '../../data/systems';
import { useMapperProjects } from '../../data/mapperProjects';
import { useMapperTemplateStore } from '../../data/mapperTemplates';
import { useTenantState } from '../../data/user';

const useDialogStyles = makeStyles(theme => ({
  row: {
    display: 'inline-block',
  },
  firstRow: {
    width: theme.spacing(12),
  },
  secondRow: {
    width: theme.spacing(25),
  },
  thirdRow: {
    width: theme.spacing(23),
  },
  container: {
    margin: theme.spacing(2, 0),
  },
}));

const mapperProjectsDialogId = 'mapperProjectsDialog';
export const MapperProjectsDialog = props => {
  const classes = useDialogStyles();

  const filteredMapperProjects = useMemo(
    () =>
      props.mapperProjects.filter(
        mapperProject =>
          mapperProject.srcSystem.system === props.fromEntity.systemCode &&
          mapperProject.destSystem.system === props.toEntity.systemCode
      ),
    [props.mapperProjects, props.fromEntity.systemCode, props.toEntity.systemCode]
  );

  const handleMapperProjectSelection = mapperProject => {
    props.onClose();

    const txnIdentifier = 'mapper projects linking';
    props.diagram.startTransaction(txnIdentifier);

    const link = props.diagram.findLinkForKey(props.selectedData[LINK_MODEL_ATTRS.KEY]);
    if (link) {
      props.diagram.model.setDataProperty(
        link.data,
        LINK_MODEL_ATTRS.MAPPER_PROJECT_ID,
        mapperProject.id
      );
      props.diagram.model.setDataProperty(link.data, LINK_MODEL_ATTRS.TEXT, mapperProject.name);
    }

    props.diagram.commitTransaction(txnIdentifier);
  };

  const fromSystemType = BASE_SYSTEMS[props.fromEntity.systemCode];
  const fromSystemTypeName = isPlainObject(fromSystemType) ? fromSystemType.NAME : '';

  const toSystemType = BASE_SYSTEMS[props.toEntity.systemCode];
  const toSystemTypeName = isPlainObject(toSystemType) ? toSystemType.NAME : '';

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={props.onClose}
      aria-labelledby={mapperProjectsDialogId}
      open={props.open}
    >
      <DialogTitle id={mapperProjectsDialogId}>
        Select a <strong>{fromSystemTypeName}</strong> to <strong>{toSystemTypeName}</strong> mapper
        project to link
      </DialogTitle>
      {props.loading && (
        <Grid container justifyContent="center" alignItems="center" className={classes.container}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!props.loading && filteredMapperProjects.length === 0 ? (
        <Grid container justifyContent="center" alignItems="center" className={classes.container}>
          <Grid item>
            <Typography variant="body1" align="center">
              <FormattedMessage id="NO_MAPPER_PROJECTS_FOUND" />
              <br />
              <strong>
                <FormattedMessage id="CREATE_NEW_MAPPER_AND_TRY" />
              </strong>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <List dense>
          <ListItem>
            <ListItemText
              inset
              disableTypography
              primary={
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item className={clsx(classes.row, classes.firstRow)}>
                    <Typography variant="button" component="span">
                      <FormattedMessage id="NAME" />
                    </Typography>
                  </Grid>
                  <Grid item className={clsx(classes.row, classes.secondRow)}>
                    <Typography variant="button" component="span">
                      <FormattedMessage id="DESCRIPTION" />
                    </Typography>
                  </Grid>
                  <Grid item className={clsx(classes.row, classes.thirdRow)}>
                    <Typography variant="button" component="span">
                      <FormattedMessage id="LAST_UPDATED" />
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
          </ListItem>
          {filteredMapperProjects.map(mapperProject => {
            const selected = props.linkedMapperProjectId === mapperProject.id;

            return (
              <Fragment key={mapperProject.id}>
                <Divider />
                <ListItem
                  button
                  disabled={selected}
                  onClick={() => handleMapperProjectSelection(mapperProject)}
                >
                  {selected && (
                    <ListItemIcon>
                      <MdCheck size="18px" />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    inset={!selected}
                    disableTypography
                    primary={
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item className={clsx(classes.row, classes.firstRow)}>
                          <Typography variant="body2" component="span">
                            {mapperProject.name}
                          </Typography>
                        </Grid>
                        <Grid item className={clsx(classes.row, classes.secondRow)}>
                          <Typography variant="subtitle1" component="span">
                            {mapperProject.description}
                          </Typography>
                        </Grid>
                        <Grid item className={clsx(classes.row, classes.thirdRow)}>
                          <Typography variant="body1" component="span">
                            {DateTime.fromISO(mapperProject.updatedAt).toFormat(
                              'LLL dd yyyy, hh:mm:ss a'
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  link: {
    width: '100%',
    marginTop: theme.spacing(1.5),
  },
  systemCard: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    border: `solid 1px rgba(0,0,0, 0.3)`,
  },
  mapperProjectName: {
    width: '80%',
  },
  mapperProjectVersion: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(2.5),
    padding: theme.spacing(0.2),
    borderRadius: theme.spacing(0.5),
  },
  mapperProjectDesc: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  systemIconContainer: {
    width: theme.spacing(11),
    height: theme.spacing(9),
    border: theme.borders[0],
    borderRadius: theme.spacing(0.6),
    padding: theme.spacing(0.5),
  },
  systemIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  loader: {
    marginTop: theme.spacing(6),
  },
  chipBorder: {
    border: `solid 1px ${theme.palette.primary.main}`,
  },
  titleFont: {
    fontSize: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  systemName: {
    fontSize: theme.spacing(1.2),
    color: theme.palette.grey[600],
  },
  colorArrow: {
    color: theme.palette.primary.main,
  },
  noOptions: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    paddingTop: theme.spacing(3),
    color: theme.palette.grey[500],
  },
}));

const getEntityGroupNodeData = (diagram, selectedData) => {
  const from = selectedData[LINK_MODEL_ATTRS.FROM];
  const fromNodeData = diagram.findNodeForKey(from).data;

  const to = selectedData[LINK_MODEL_ATTRS.TO];
  const toNodeData = diagram.findNodeForKey(to).data;

  let fromEntityNodeKey;
  if (fromNodeData[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.FIELD) {
    const parentNode = diagram.findNodeForKey(fromNodeData[NODE_MODEL_ATTRS.GROUP]).data;
    if (parentNode[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
      fromEntityNodeKey = parentNode[NODE_MODEL_ATTRS.GROUP];
    } else {
      fromEntityNodeKey = parentNode[NODE_MODEL_ATTRS.KEY];
    }
  } else if (fromNodeData[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
    fromEntityNodeKey = fromNodeData[NODE_MODEL_ATTRS.GROUP];
  } else {
    fromEntityNodeKey = fromNodeData[NODE_MODEL_ATTRS.KEY];
  }

  let toEntityNodeKey;
  if (toNodeData[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.FIELD) {
    const parentNode = diagram.findNodeForKey(toNodeData[NODE_MODEL_ATTRS.GROUP]).data;
    if (parentNode[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
      toEntityNodeKey = parentNode[NODE_MODEL_ATTRS.GROUP];
    } else {
      toEntityNodeKey = parentNode[NODE_MODEL_ATTRS.KEY];
    }
  } else if (toNodeData[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
    toEntityNodeKey = toNodeData[NODE_MODEL_ATTRS.GROUP];
  } else {
    toEntityNodeKey = toNodeData[NODE_MODEL_ATTRS.KEY];
  }

  const fromEntity = diagram.findNodeForKey(fromEntityNodeKey).data;
  const toEntity = diagram.findNodeForKey(toEntityNodeKey).data;

  return { fromEntity, toEntity };
};
export const checkMapperProjectExists = (mapperTemplatesList, fromEntity, toEntity) => {
  return mapperTemplatesList.some(
    mapperTemplate =>
      mapperTemplate.srcSystem === fromEntity.systemCode &&
      mapperTemplate.destSystem === toEntity.systemCode
  );
};

export const useMapperProjectIntegration = ({ diagram, selectedData, formatMessage }) => {
  const tenant = useTenantState();
  const { getConfirmation } = useConfirmationDialog();

  const {
    refetch: refetchMapperProjects,
    mapperProjects,
    isLoading: isMapperProjectsLoading,
  } = useMapperProjects({
    enabled: Boolean(tenant),
  });

  const { isDialogOpen, openDialog, closeDialog } = useDialog(
    useCallback(() => refetchMapperProjects(), [refetchMapperProjects])
  );

  const { fromEntity, toEntity } = useMemo(() => getEntityGroupNodeData(diagram, selectedData), [
    diagram,
    selectedData,
  ]);

  const linkedMapperProjectId = selectedData[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID];

  const selectedMapperProject = useMemo(
    () => mapperProjects.find(mapperProject => mapperProject.id === linkedMapperProjectId),
    [linkedMapperProjectId, mapperProjects]
  );

  const handleMapperProjectIntegration = useCallback(() => {
    if (linkedMapperProjectId) {
      getConfirmation({
        title: formatMessage({ id: 'UNLINK_MAPPER_PROJECT' }),
        body: (
          <DialogContentText>
            <FormattedMessage id="UNLINK_MAPPER_PROJECT_CONFIRMATION" />
          </DialogContentText>
        ),

        onAccept: () => {
          const txnIdentifier = 'mapper projects linking';
          diagram.startTransaction(txnIdentifier);

          const link = diagram.findLinkForKey(selectedData[LINK_MODEL_ATTRS.KEY]);
          if (link) {
            diagram.model.setDataProperty(link.data, LINK_MODEL_ATTRS.MAPPER_PROJECT_ID, null);
            diagram.model.setDataProperty(link.data, LINK_MODEL_ATTRS.TEXT, '');
          }

          diagram.commitTransaction(txnIdentifier);
        },
      });
    } else {
      openDialog();
    }
    return;
  }, [diagram, formatMessage, getConfirmation, linkedMapperProjectId, openDialog, selectedData]);

  return {
    isDialogOpen,
    openDialog,
    closeDialog,
    linkedMapperProjectId,
    fromEntity,
    toEntity,
    selectedMapperProject,
    handleMapperProjectIntegration,
    mapperProjects,
    isMapperProjectsLoading,
  };
};

const mapperTemplatesSelector = state => state.mapperTemplates;
export function MapperProjectConnections(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { systems: systemsData, error: systemsError, isFetching: isSystemsFetching } = useSystems();
  useNotifyError({ error: systemsError });

  const {
    isDialogOpen,
    closeDialog,
    fromEntity,
    linkedMapperProjectId,
    toEntity,
    selectedMapperProject,
    handleMapperProjectIntegration,
    mapperProjects,
    isMapperProjectsLoading,
  } = useMapperProjectIntegration({
    diagram: props.diagram,
    selectedData: props.selectedData,
    formatMessage,
  });

  const mapperTemplatesList = useMapperTemplateStore(mapperTemplatesSelector);

  return (
    <>
      <Grid container>
        <Grid item>
          <Typography color="textSecondary" variant="body2">
            <FormattedMessage id="LINKED_MAPPER_PROJECT" />
          </Typography>
        </Grid>
        {isSystemsFetching && (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            className={classes.loader}
          >
            <CircularProgress />
          </Grid>
        )}
        {!isSystemsFetching &&
          (selectedMapperProject ? (
            <>
              <Grid item container direction="column" className={classes.systemCard}>
                <Grid item container direction="column">
                  <Grid item container direction="row" justifyContent="space-between">
                    <Grid item className={classes.mapperProjectName}>
                      <Typography color="textPrimary" variant="body1">
                        {selectedMapperProject.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      align="center"
                      className={classes.mapperProjectVersion}
                    >
                      <Typography variant="body2">v{selectedMapperProject.version}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.mapperProjectDesc} container>
                    <Typography variant="body1">
                      {selectedMapperProject.description || ''}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-evenly"
                    className={classes.topMargin}
                  >
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      className={classes.systemIconContainer}
                    >
                      <Grid item className={classes.systemIcon}>
                        <SystemAvatar system={selectedMapperProject.srcSystem.system} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.systemName}>
                          {getShortNameOfSystem(
                            systemsData,
                            selectedMapperProject.srcSystem.system
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <BsArrowRight className={classes.colorArrow} size="23px" />
                    </Grid>
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      className={classes.systemIconContainer}
                    >
                      <Grid item className={classes.systemIcon}>
                        <SystemAvatar system={selectedMapperProject.destSystem.system} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.systemName}>
                          {getShortNameOfSystem(
                            systemsData,
                            selectedMapperProject.destSystem.system
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container justifyContent="flex-end" className={classes.topMargin}>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    startIcon={<MdNavigation size="11px" />}
                    size="small"
                    href={getMapperProjectLinkHref(linkedMapperProjectId)}
                    target="_blank"
                  >
                    <FormattedMessage id="NAVIGATE" />
                  </Button>
                </Grid>
              </Grid>
              <Tooltip
                title={
                  checkMapperProjectExists(mapperTemplatesList, fromEntity, toEntity)
                    ? ''
                    : formatMessage({ id: 'MAPPER_PROJECT_NOT_EXIST' })
                }
              >
                <span style={{ width: '100%' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.link}
                    disabled={!checkMapperProjectExists(mapperTemplatesList, fromEntity, toEntity)}
                    onClick={handleMapperProjectIntegration}
                  >
                    {linkedMapperProjectId
                      ? formatMessage({ id: 'UNLINK_MAPPER_PROJECT' })
                      : formatMessage({ id: 'LINK_MAPPER_PROJECT' })}
                  </Button>
                </span>
              </Tooltip>
            </>
          ) : (
            <Tooltip
              title={
                checkMapperProjectExists(mapperTemplatesList, fromEntity, toEntity)
                  ? ''
                  : formatMessage({ id: 'MAPPER_PROJECT_NOT_EXIST' })
              }
            >
              <span style={{ width: '100%' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classes.link}
                  disabled={!checkMapperProjectExists(mapperTemplatesList, fromEntity, toEntity)}
                  onClick={handleMapperProjectIntegration}
                >
                  <FormattedMessage id="LINK_MAPPER_PROJECT" />
                </Button>
              </span>
            </Tooltip>
          ))}
      </Grid>
      <MapperProjectsDialog
        onClose={closeDialog}
        open={isDialogOpen}
        loading={isMapperProjectsLoading}
        fromEntity={fromEntity}
        toEntity={toEntity}
        mapperProjects={mapperProjects}
        linkedMapperProjectId={linkedMapperProjectId}
        diagram={props.diagram}
        selectedData={props.selectedData}
      />
    </>
  );
}
