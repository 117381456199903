/**
 * @file
 *
 * This is the main entry file for the application
 *
 * The initial whoAmI request is triggered from here
 *
 * All the routes and base CSS are also mounted here
 */

import React, { Suspense } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { makeStyles, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';
import { ConfirmationDialogProvider } from 'material-ui-confirmation';
import { IntlProvider } from 'react-intl';
import { QueryClientProvider } from 'react-query';

import { theme } from './utils/theme';
import { useLanguageMessages } from './state/language';
import { queryClient } from './utils/queryClient';
import { Project } from './pages/Project';
import { Home } from './pages/Home';
import { NoMatch } from './pages/NoMatch';
import { config } from './utils/config';
import AuthTokenInjector from './devOnly/AuthTokenInjector';

const useStyles = makeStyles(theme => ({
  '@global': {
    '*': {
      scrollbarWidth: 'none',
      scrollbarColor: `${theme.palette.grey[400]} ${theme.palette.grey[300]}`,
    },
    '*::-webkit-scrollbar': {
      width: theme.spacing(0.5),
      height: theme.spacing(0.5),
    },
    '*::-webkit-scrollbar-track': {
      background: theme.palette.grey[300],
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
      borderRadius: theme.spacing(1),
      border: `3px solid ${theme.palette.grey[400]}`,
    },
    html: {
      height: '100%',
      width: '100%',
    },
    body: {
      margin: 0,
      width: '100%',
      height: '100%',
      background: theme.palette.common.white,
      overflow: 'hidden',
    },
    '#root': {
      width: '100%',
      height: '100%',
    },

    a: {
      textDecoration: 'inherit',
      color: 'inherit',
    },

    '.tp-dfwv': {
      position: 'fixed !important',
      bottom: '8px !important',
      top: 'unset !important',
    },
    '.wb-header': {
      backgroundColor: '#354A5F',
    },
  },
}));

function Routes() {
  return (
    <Switch>
      <Route path="/t/:tenantId/project/:id">
        <Project />
      </Route>
      <Route path="/t/:tenantId">
        <Home />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}

function App() {
  useStyles();
  const messages = useLanguageMessages();

  return (
    <>
      <IntlProvider locale="en" messages={messages}>
        <QueryClientProvider client={queryClient} contextSharing>
          <ThemeProvider theme={theme}>
            <ConfirmationDialogProvider>
              <CssBaseline />
              <Router>
                <Routes />
              </Router>
            </ConfirmationDialogProvider>
          </ThemeProvider>
          <Toaster reverseOrder position="bottom-left" toastOptions={{ duration: 4000 }} />
        </QueryClientProvider>
      </IntlProvider>

      {config.env === 'development' && (
        <Suspense fallback={null}>
          <AuthTokenInjector />
        </Suspense>
      )}
    </>
  );
}

export default App;
