/**
 * @file
 *
 * This component is renders the form dialog for creating a new version of a project
 */
import React from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormikTextField } from './FormikFields';

const id = 'create-version-form-dialog';

export const CreateVersionFormDialog = props => {
  const { formatMessage } = useIntl();

  const schema = yup.object().shape({
    version: yup.number().required(),
    comment: yup.string().label(formatMessage({ id: 'COMMENT' })),
  });

  return (
    <Dialog open={props.open} aria-labelledby={id}>
      <DialogTitle id={id}>
        <FormattedMessage id="CREATE_NEW_VERSION" />
      </DialogTitle>
      <Formik
        validationSchema={schema}
        initialValues={{ version: props.version + 1, comment: '' }}
        onSubmit={props.onSubmit}
      >
        {({ isValid, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormikTextField
                name="version"
                label={formatMessage({ id: 'VERSION_NUMBER' })}
                disabled
              />
              <FormikTextField name="comment" label={formatMessage({ id: 'COMMENT' })} autoFocus />
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                type="submit"
                disabled={!isValid || isSubmitting}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="CREATE" />
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
