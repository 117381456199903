/**
 * @file
 * This is an extension to help with the shaping of the links by user
 *
 */
import * as go from 'gojs';

/**
 * @class
 * The OrthogonalSnapLinkReshapingTool class lets a user drag a tool handle along the link segment, which will move the whole segment and the links snaps to the nearest grid point
 */
export class OrthogonalSnapLinkReshapingTool extends go.LinkReshapingTool {
  /**
   * Constructs an OrthogonalSnapLinkReshapingTool and sets the name for the tool.
   */
  constructor() {
    super();
    this.name = 'OrthogonalSnapLinkReshaping';
    this._alreadyAddedPoint = false;

    // for snapping
    this._gridCellSize = new go.Size(NaN, NaN);
    this._gridOrigin = new go.Size(NaN, NaN);
    this._isGridSnapEnabled = true;
    this._avoidNodes = false;
    // internal state for snap tracking
    this._safePoint = new go.Point(NaN, NaN);
    this._prevSegHoriz = false;
    this._nextSegHoriz = false;
  }

  /**
   * Gets or sets the {@link Size} of each grid cell to which link points will be snapped.
   *
   * The default value is NaN,NaN which means use the {@link Diagram#grid}'s {@link Panel#gridCellSize}.
   *
   * @returns {go.Size}
   */
  get gridCellSize() {
    return this._gridCellSize;
  }
  /**
   * @param {go.Size} val
   */
  set gridCellSize(val) {
    if (!(val instanceof go.Size))
      throw new Error(`new value for ${this.name}.gridCellSize must be a Size, not: ${val}`);
    this._gridCellSize = val.copy();
  }

  /**
   * Gets or sets the {@link Point} origin for the grid to which link points will be snapped.
   *
   * The default value is NaN,NaN which means use the {@link Diagram#grid}'s {@link Panel#gridOrigin}.
   *
   * @return {go.Point}
   */
  get gridOrigin() {
    return this._gridOrigin;
  }
  /**
   * @param {go.Point} val
   */
  set gridOrigin(val) {
    if (!(val instanceof go.Point))
      throw new Error(`new value for ${this.name}.gridOrigin must be a Point, not: ${val}`);
    this._gridOrigin = val.copy();
  }

  /**
   * Gets or sets whether a reshape handle's position should be snapped to a grid point.
   * This affects the behavior of {@link #computeReshape}.
   *
   * The default value is true.
   *
   * @returns {boolean}
   */
  get isGridSnapEnabled() {
    return this._isGridSnapEnabled;
  }
  /**
   * @param {boolean} val
   */
  set isGridSnapEnabled(val) {
    if (typeof val !== 'boolean')
      throw new Error(
        `new value for ${this.name}.isGridSnapEnabled must be a boolean, not: ${val}`
      );
    this._isGridSnapEnabled = val;
  }

  /**
   * Gets or sets whether a reshape handle's position should only be dragged where the
   * adjacent segments do not cross over any nodes.
   * This affects the behavior of {@link #computeReshape}.
   *
   * The default value is false.
   *
   * @returns {boolean}
   */
  get avoidsNodes() {
    return this._avoidsNodes;
  }
  /**
   * @param {boolean} val
   */
  set avoidsNodes(val) {
    if (typeof val !== 'boolean')
      throw new Error(`new value for ${this.name}.avoidsNodes must be a boolean, not: ${val}`);
    this._avoidsNodes = val;
  }

  /**
   * For orthogonal, straight links, create the handles and set reshaping behavior.
   *
   * @param {go.Shape} pathshape
   *
   * @returns {go.Adornment}
   */
  makeAdornment(pathshape) {
    /**
     * @type {go.Link}
     */
    const link = pathshape.part;

    // add all normal handles first
    const adornment = super.makeAdornment(pathshape);

    // add long reshaping handles for orthogonal, straight links
    // skip if the part isn't link or not orthogonal or has non-straight curve
    if (link !== null && link.isOrthogonal && link.curve !== go.Link.Bezier) {
      const firstindex = link.firstPickIndex + (link.resegmentable ? 0 : 1);
      const lastindex = link.lastPickIndex - (link.resegmentable ? 0 : 1);
      for (let i = firstindex; i < lastindex; i++) {
        this.makeSegmentDragHandle(link, adornment, i);
      }
    }

    return adornment;
  }

  /**
   * This override records information about the original point of the handle being dragged,
   * if the {@link #adornedLink} is Orthogonal and if {@link #avoidsNodes} is true.
   */
  doActivate() {
    super.doActivate();

    if (
      this.isActive &&
      this.avoidsNodes &&
      this.adornedLink !== null &&
      this.adornedLink.isOrthogonal &&
      this.handle !== null
    ) {
      // assume the Link's route starts off correctly avoiding all nodes
      this._safePoint = this.diagram.lastInput.documentPoint.copy();
      const link = this.adornedLink;
      const idx = this.handle.segmentIndex;
      this._prevSegHoriz = Math.abs(link.getPoint(idx - 1).y - link.getPoint(idx).y) < 0.5;
      this._nextSegHoriz = Math.abs(link.getPoint(idx + 1).y - link.getPoint(idx).y) < 0.5;
    }
  }

  /**
   * Pretend while dragging a reshape handle the mouse point is at the nearest grid point, if {@link #isGridSnapEnabled} is true.
   * This uses {@link #gridCellSize} and {@link #gridOrigin}, unless those are not real values,
   * in which case this uses the {@link Diagram#grid}'s {@link Panel#gridCellSize} and {@link Panel#gridOrigin}.
   *
   * If {@link #avoidsNodes} is true and the adorned Link is {@link Link#isOrthogonal},
   * this method also avoids returning a Point that causes the adjacent segments, both before and after
   * the current handle's index, to cross over any Nodes that are {@link Node#avoidable}.
   *
   * @param {go.Point} point
   *
   * @returns {go.Point}
   */
  computeReshape(point) {
    let newPoint = point;
    const diagram = this.diagram;

    if (this.isGridSnapEnabled) {
      // first, find the grid to which we should snap
      let cell = this.gridCellSize;
      let orig = this.gridOrigin;

      if (!cell.isReal() || cell.width === 0 || cell.height === 0) {
        cell = diagram.grid.gridCellSize;
      }

      if (!orig.isReal()) {
        orig = diagram.grid.gridOrigin;
      }

      // second, compute the closest grid point
      newPoint = point.copy().snapToGrid(orig.x, orig.y, cell.width, cell.height);
    }

    if (this.avoidsNodes && this.adornedLink !== null && this.adornedLink.isOrthogonal) {
      if (this._checkSegmentsOverlap(newPoint)) {
        this._safePoint = newPoint.copy();
      } else {
        newPoint = this._safePoint.copy();
      }
    }
    // then do whatever LinkReshapingTool would normally do as if the mouse were at that point
    return super.computeReshape(newPoint);
  }

  /**
   * @private
   * Internal method for seeing whether a moved handle will cause any
   * adjacent orthogonal segments to cross over any avoidable nodes.
   * Returns true if everything would be OK.
   *
   * @param {go.Point} point
   *
   * @return {boolean}
   */
  _checkSegmentsOverlap(point) {
    if (this.handle === null) return true;
    if (this.adornedLink === null) return true;
    const index = this.handle.segmentIndex;

    if (index >= 1) {
      const p1 = this.adornedLink.getPoint(index - 1);
      const r = new go.Rect(point.x, point.y, 0, 0);
      const q1 = p1.copy();
      if (this._prevSegHoriz) {
        q1.y = point.y;
      } else {
        q1.x = point.x;
      }
      r.unionPoint(q1);
      const overlaps = this.diagram.findPartsIn(r, true, false);
      if (
        overlaps.any(function(p) {
          return p instanceof go.Node && p.avoidable;
        })
      )
        return false;

      if (index >= 2) {
        const p0 = this.adornedLink.getPoint(index - 2);
        const r = new go.Rect(q1.x, q1.y, 0, 0);
        if (this._prevSegHoriz) {
          r.unionPoint(new go.Point(q1.x, p0.y));
        } else {
          r.unionPoint(new go.Point(p0.x, q1.y));
        }
        const overlaps = this.diagram.findPartsIn(r, true, false);
        if (
          overlaps.any(function(p) {
            return p instanceof go.Node && p.avoidable;
          })
        )
          return false;
      }
    }

    if (index < this.adornedLink.pointsCount - 1) {
      const p2 = this.adornedLink.getPoint(index + 1);
      const r = new go.Rect(point.x, point.y, 0, 0);
      const q2 = p2.copy();
      if (this._nextSegHoriz) {
        q2.y = point.y;
      } else {
        q2.x = point.x;
      }
      r.unionPoint(q2);
      const overlaps = this.diagram.findPartsIn(r, true, false);
      if (
        overlaps.any(function(p) {
          return p instanceof go.Node && p.avoidable;
        })
      )
        return false;

      if (index < this.adornedLink.pointsCount - 2) {
        const p3 = this.adornedLink.getPoint(index + 2);
        const r = new go.Rect(q2.x, q2.y, 0, 0);
        if (this._nextSegHoriz) {
          r.unionPoint(new go.Point(q2.x, p3.y));
        } else {
          r.unionPoint(new go.Point(p3.x, q2.y));
        }
        const overlaps = this.diagram.findPartsIn(r, true, false);
        if (
          overlaps.any(function(p) {
            return p instanceof go.Node && p.avoidable;
          })
        )
          return false;
      }
    }

    return true;
  }

  /**
   * This stops the current reshaping operation and updates any link handles.
   */
  doDeactivate() {
    this._alreadyAddedPoint = false;
    // when we finish, recreate adornment to ensure proper reshaping behavior/cursor
    const link = this.adornedLink;

    if (link !== null && link.isOrthogonal && link.curve !== go.Link.Bezier) {
      const pathshape = link.path;

      if (pathshape !== null) {
        const adornment = this.makeAdornment(pathshape);

        if (adornment !== null) {
          link.addAdornment(this.name, adornment);
          adornment.location = link.position;
        }
      }
    }

    super.doDeactivate();
  }

  /**
   * Change the route of the {@link #adornedLink} by moving the segment corresponding to the current
   * {@link #handle} to be at the given {@link Point}.
   *
   * @param {go.Point} newPoint
   */
  reshape(newPoint) {
    const link = this.adornedLink;

    // identify if the handle being dragged is a segment dragging handle
    if (
      link !== null &&
      link.isOrthogonal &&
      link.curve !== go.Link.Bezier &&
      this.handle !== null &&
      this.handle.toMaxLinks === 999
    ) {
      link.startRoute();
      let index = this.handle.segmentIndex; // for these handles, firstPickIndex <= index < lastPickIndex

      if (!this._alreadyAddedPoint && link.resegmentable) {
        // only change the number of points if Link.resegmentable
        this._alreadyAddedPoint = true;

        if (index === link.firstPickIndex) {
          link.insertPoint(index, link.getPoint(index).copy());
          index++;
          this.handle.segmentIndex = index;
        } else if (index === link.lastPickIndex - 1) {
          link.insertPoint(index, link.getPoint(index).copy());
        }
      }

      const behavior = this.getReshapingBehavior(this.handle);

      if (behavior === go.LinkReshapingTool.Vertical) {
        // move segment vertically
        link.setPointAt(index, link.getPoint(index - 1).x, newPoint.y);
        link.setPointAt(index + 1, link.getPoint(index + 2).x, newPoint.y);
      } else if (behavior === go.LinkReshapingTool.Horizontal) {
        // move segment horizontally
        link.setPointAt(index, newPoint.x, link.getPoint(index - 1).y);
        link.setPointAt(index + 1, newPoint.x, link.getPoint(index + 2).y);
      }

      link.commitRoute();
    } else {
      super.reshape(newPoint);
    }
  }

  /**
   * Create the segment dragging handles.
   * There are two parts: one invisible handle that spans the segment, and a visible handle at the middle of the segment.
   * These are inserted at the front of the adornment such that the normal handles have priority.
   *
   * @param {go.Link} link
   * @param {go.Adornment} adornment
   * @param {number} index
   */
  makeSegmentDragHandle(link, adornment, index) {
    const a = link.getPoint(index);
    let b = link.getPoint(index + 1);
    const seglength = Math.max(Math.abs(a.x - b.x), Math.abs(a.y - b.y));

    // determine segment orientation
    let orient = '';
    if (this.isApprox(a.x, b.x) && this.isApprox(a.y, b.y)) {
      b = link.getPoint(index - 1);

      if (this.isApprox(a.x, b.x)) {
        orient = 'vertical';
      } else if (this.isApprox(a.y, b.y)) {
        orient = 'horizontal';
      }
    } else {
      if (this.isApprox(a.x, b.x)) {
        orient = 'vertical';
      } else if (this.isApprox(a.y, b.y)) {
        orient = 'horizontal';
      }
    }

    // make an invisible handle along the whole segment
    const h = new go.Shape();
    h.strokeWidth = 6;
    h.opacity = 0;
    h.segmentOrientation = go.Link.OrientAlong;
    h.segmentIndex = index;
    h.segmentFraction = 0.5;
    h.toMaxLinks = 999; // set this unsused property to easily identify that we have a segment dragging handle

    if (orient === 'horizontal') {
      this.setReshapingBehavior(h, go.LinkReshapingTool.Vertical);
      h.cursor = 'n-resize';
    } else {
      this.setReshapingBehavior(h, go.LinkReshapingTool.Horizontal);
      h.cursor = 'w-resize';
    }

    h.geometryString = 'M 0 0 L ' + seglength + ' 0';
    adornment.insertAt(0, h);
  }

  /**
   * Compare two numbers to ensure they are almost equal.
   * Used in this class for comparing coordinates of Points.
   *
   * @param {number} x
   * @param {number} y
   *
   * @returns {boolean}
   */
  isApprox(x, y) {
    const d = x - y;
    return d < 0.5 && d > -0.5;
  }
}
