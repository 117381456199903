/**
 * @file
 *
 * The component renders the tab based UI for sheets
 */

import React, { useState, useCallback } from 'react';
import {
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';
import { MdAdd, MdMoreHoriz } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Color from 'color';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useConfirmationDialog } from 'material-ui-confirmation';
import { FormattedMessage, useIntl } from 'react-intl';

import { DropDown } from './DropDown';
import { CreateTemplateFormDialog } from './CreateTemplateFormDialog';
import { FormikTextField } from './FormikFields';

import { useTenantState } from '../data/user';
import {
  sheetStateActions,
  useCreateSheetMutation,
  useDeleteSheetMutation,
} from '../data/projects';
import { useDialog } from '../hooks/useDialog';

const useTabsStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
  },
  indicator: {
    display: 'none',
  },
}));

const useTabItemStyles = makeStyles(({ palette, borders, spacing, breakpoints }) => {
  const defaultBgColor = palette.grey[300];
  const defaultSelectedBgColor = palette.common.white;

  const defaultMinWidth = 150;

  const getTextColor = color => {
    if (Color(color).hex() === Color(palette.common.white).hex()) {
      return palette.secondary.main;
    }

    return palette.grey[600];
  };

  return {
    root: ({ bgColor = defaultBgColor }) => ({
      opacity: 1,
      minHeight: '100%',
      overflow: 'initial',
      color: getTextColor(bgColor),
      backgroundColor: bgColor,
      borderRight: borders[0],
      transition: '0.2s',
      [breakpoints.up('md')]: {
        minWidth: defaultMinWidth,
      },
      '&:before': {
        transition: '0.2s',
      },
      '&:not(:first-of-type)': {
        '&:before': {
          content: '" "',
          position: 'absolute',
          left: 0,
          display: 'block',
          height: 20,
          width: 1,
          zIndex: 1,
          marginTop: spacing(0.5),
        },
      },
      '& + $selected:before': {
        opacity: 0,
      },
      '&:hover': {
        '&:not($selected)': {
          backgroundColor: Color(bgColor)
            .whiten(0.6)
            .hex(),
        },
        '&::before': {
          opacity: 0,
        },
        '& + $root:before': {
          opacity: 0,
        },
      },
    }),
    selected: ({ selectedBgColor = defaultSelectedBgColor }) => ({
      backgroundColor: selectedBgColor,
      color: getTextColor(selectedBgColor),
      '& + $root': {
        zIndex: 1,
      },
      '& + $root:before': {
        opacity: 0,
      },
    }),
    wrapper: {
      width: 'initial',
      zIndex: 2,
      marginTop: spacing(0.5),
      textTransform: 'initial',
      display: 'initial',
    },
  };
});

const useInnerTabStyles = makeStyles(theme => ({
  innerTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 0,
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: theme.spacing(3),
  },
  linkStyle: {
    flexGrow: 1,
    textAlign: 'left',
    padding: theme.spacing(1.25),
  },
  linkStyleNoPadding: {
    paddingRight: theme.spacing(0),
  },
}));

const sheetDropDownMenuOptions = formatMessage => ({
  editSheetName: {
    key: 'editSheetName',
    name: formatMessage({ id: 'EDIT_SHEET_NAME' }),
  },
  deleteSheet: {
    key: 'deleteSheet',
    name: formatMessage({ id: 'DELETE_SHEET' }),
  },
  createTemplate: {
    key: 'createTemplate',
    name: formatMessage({ id: 'CREATE_TEMPLATE' }),
    disabledTitle: formatMessage({ id: 'SAVE_CHANGES_TO_SHEET' }),
    checkIfDisabled: props => props.dirty,
  },
});

/**
 * Component for the elements to be rendered inside a tab toggle component
 * which includes the rename and delete action buttons
 */
export const InnerTab = React.forwardRef((props, ref) => {
  const classes = useInnerTabStyles();
  const { formatMessage } = useIntl();

  const handleSheetDropDownOptionSelect = useCallback(option => {
    switch (option.key) {
      case sheetDropDownMenuOptions(formatMessage).createTemplate.key:
        props.handleTemplateCreate();
        break;
      case sheetDropDownMenuOptions(formatMessage).editSheetName.key:
        props.handleEdit();
        break;
      case sheetDropDownMenuOptions(formatMessage).deleteSheet.key:
        props.handleDelete();
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    sheetDropDownMenuOptions(formatMessage).editSheetName,
    !props.empty && sheetDropDownMenuOptions(formatMessage).createTemplate,
    props.deletable && sheetDropDownMenuOptions(formatMessage).deleteSheet,
  ].filter(Boolean);

  return (
    <div className={clsx(props.className, classes.innerTab)} ref={ref}>
      <Link
        className={clsx(classes.linkStyle, !props.isActiveVersion && classes.linkStyleNoPadding)}
        to={props.to}
      >
        {props.children}
      </Link>
      {props.isActiveVersion && (
        <div className={classes.buttonGroup}>
          {props.syncing ? (
            <CircularProgress size={20} />
          ) : (
            <DropDown
              options={options}
              onSelect={handleSheetDropDownOptionSelect}
              dirty={props.dirty}
            >
              <IconButton size="small">
                <MdMoreHoriz size="18px" />
              </IconButton>
            </DropDown>
          )}
        </div>
      )}
    </div>
  );
});

const schema = formatMessage =>
  yup.object({
    name: yup
      .string()
      .min(1, formatMessage({ id: 'SHEET_NAME_NOT_EMPTY' }))
      .test(
        'is-valid',
        formatMessage({ id: 'SHEET_NAME_NOT_VALID' }),
        value => value && value.trim() && !value.startsWith('*')
      )
      .required(formatMessage({ id: 'SHEET_NAME_NOT_EMPTY' }))
      .label(formatMessage({ id: 'SHEET_NAME' })),
  });

/**
 * The Dialog component that houses the form for the sheet name edit function
 */
export const EditFormDialog = ({ open, onClose, onSubmit, initialFormState: { name } }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} aria-labelledby="edit-sheet-name-dialog-title">
      <DialogTitle id="edit-sheet-name-dialog-title">
        <FormattedMessage id="EDIT_SHEET_NAME" />
      </DialogTitle>
      <Formik
        validationSchema={schema(formatMessage)}
        validateOnMount
        initialValues={{ name }}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormikTextField name="name" label={formatMessage({ id: 'NAME' })} autoFocus />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                type="submit"
                disabled={!isValid || isSubmitting}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="UPDATE" />
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

/**
 * The Dialog component that houses the form to create new sheets
 */
export const CreateFormDialog = ({ open, onClose, onSubmit, createSheetMutation }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} aria-labelledby="create-sheet-name-dialog-title">
      <DialogTitle id="create-sheet-name-dialog-title">
        <FormattedMessage id="ADD_NEW_SHEET" />
      </DialogTitle>
      <Formik
        validationSchema={schema(formatMessage)}
        validateOnMount
        initialValues={{ name: '' }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormikTextField name="name" label={formatMessage({ id: 'NAME' })} autoFocus />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                type="submit"
                disabled={createSheetMutation.isLoading}
                color="primary"
                variant="contained"
                startIcon={createSheetMutation.isLoading ? <CircularProgress size={15} /> : null}
              >
                {createSheetMutation.isLoading ? (
                  <FormattedMessage id="CREATING" />
                ) : (
                  <FormattedMessage id="CREATE" />
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

const defaultInitialFormState = { key: '', name: '' };

const useEditSheetDialog = (projectId, initialFormState, updateInitialFormState) => {
  const {
    isDialogOpen: isEditSheetDialogOpen,
    openDialog,
    closeDialog: closeEditSheetDialog,
  } = useDialog();

  const openEditSheetDialog = useCallback(
    (key, name) => () => {
      updateInitialFormState({ key, name });
      openDialog();
    },
    [openDialog, updateInitialFormState]
  );

  const handleEditFormSubmit = useCallback(
    values => {
      sheetStateActions.renameSheet({
        projectId: projectId,
        sheetKey: initialFormState.key,
        sheetName: values.name.trim(),
      });
      closeEditSheetDialog();
      updateInitialFormState(defaultInitialFormState);
    },
    [closeEditSheetDialog, initialFormState.key, projectId, updateInitialFormState]
  );

  return { isEditSheetDialogOpen, openEditSheetDialog, closeEditSheetDialog, handleEditFormSubmit };
};

const useCreateSheetDialog = (createSheetMutation, projectId, version) => {
  const {
    isDialogOpen: isCreateDialogOpen,
    openDialog: openCreateSheetDialog,
    closeDialog: closeCreateSheetDialog,
  } = useDialog();

  const handleCreateFormSubmit = useCallback(
    async value => {
      await createSheetMutation.mutateAsync({
        projectId,
        version,
        sheetName: value.name,
      });

      closeCreateSheetDialog();
    },
    [closeCreateSheetDialog, createSheetMutation, projectId, version]
  );

  return {
    isCreateDialogOpen,
    openCreateSheetDialog,
    closeCreateSheetDialog,
    handleCreateFormSubmit,
  };
};

/**
 * This is the container component that get exported
 * and is responsible for handling all UI/UX around the
 * tab switching and CRUD functionalities
 */
export const SheetRoutes = props => {
  const tabsStyles = useTabsStyles();
  const tabItemsStyles = useTabItemStyles();

  const tenant = useTenantState();
  const history = useHistory();
  const createSheetMutation = useCreateSheetMutation();
  const deleteSheetMutation = useDeleteSheetMutation();

  const { formatMessage } = useIntl();
  const { getConfirmation } = useConfirmationDialog();

  const [initialFormState, updateInitialFormState] = useState(defaultInitialFormState);

  // Handles Edit Sheet Dialog State and Form submition
  const {
    isEditSheetDialogOpen,
    openEditSheetDialog,
    closeEditSheetDialog,
    handleEditFormSubmit,
  } = useEditSheetDialog(props.projectId, initialFormState, updateInitialFormState);

  // Handles Create Sheet Dialog State and Form submition
  const {
    isCreateDialogOpen,
    openCreateSheetDialog,
    closeCreateSheetDialog,
    handleCreateFormSubmit,
  } = useCreateSheetDialog(createSheetMutation, props.projectId, props.version);

  // Handles Create Template Dialog State
  const {
    isDialogOpen: isCreateTemplateDialogOpen,
    openDialog,
    closeDialog: closeCreateTemplateDialog,
  } = useDialog();

  const openCreateTemplateDialog = useCallback(
    (key, name) => () => {
      updateInitialFormState({ key, name });
      openDialog();
    },
    [openDialog]
  );

  // Handles Sheet deletion
  const handleDeleteSheet = useCallback(
    (sheetKey, name, version) => () => {
      getConfirmation({
        title: formatMessage({ id: 'DELETE_SHEET' }),
        body: (
          <DialogContentText>
            <FormattedMessage id="DELETE_CONFIRMATION" /> - <b>{name}</b>?
          </DialogContentText>
        ),
        acceptButtonProps: {
          variant: 'contained',
          color: 'primary',
        },
        onAccept: () => {
          const { tenant_id } = useTenantState.get() ?? {};

          history.replace(
            `/t/${tenant_id}/project/${props.projectId}?sheet=${props.sheets[0].key}`
          );
          setTimeout(() => {
            deleteSheetMutation.mutate({ projectId: props.projectId, sheetKey, version });
          }, 0);
        },
      });
    },
    [deleteSheetMutation, formatMessage, getConfirmation, history, props.projectId, props.sheets]
  );

  return (
    <>
      <Tabs value={props.sheetKey} classes={tabsStyles} variant="scrollable" scrollButtons="auto">
        {props.sheets.map(({ key, name, main, empty, syncState: { syncing, dirty } }) => (
          <Tab
            key={key}
            classes={tabItemsStyles}
            label={`${dirty ? '* ' : ''}${name}`}
            value={key}
            dirty={dirty}
            empty={empty}
            disabled={syncing || props.sheetSwitchDisabled}
            component={InnerTab}
            syncing={syncing}
            isActiveVersion={props.isActiveVersion}
            deletable={!main}
            handleDelete={handleDeleteSheet(key, name, props.version)}
            handleEdit={openEditSheetDialog(key, name)}
            handleTemplateCreate={openCreateTemplateDialog(key, name)}
            to={`/t/${tenant?.tenant_id}/project/${props.projectId}?sheet=${key}${
              props.version ? `&version=${props.version}` : ''
            }`}
          />
        ))}

        {props.isActiveVersion && (
          <Tab
            key="add"
            classes={tabItemsStyles}
            icon={<MdAdd size="18px" />}
            style={{ width: 40, minWidth: 40 }}
            disabled={props.sheetSwitchDisabled}
            onClick={openCreateSheetDialog}
          />
        )}
      </Tabs>
      {isEditSheetDialogOpen && (
        <EditFormDialog
          open={isEditSheetDialogOpen}
          initialFormState={initialFormState}
          onSubmit={handleEditFormSubmit}
          onClose={closeEditSheetDialog}
        />
      )}

      <CreateFormDialog
        open={isCreateDialogOpen}
        onSubmit={handleCreateFormSubmit}
        onClose={closeCreateSheetDialog}
        createSheetMutation={createSheetMutation}
      />

      <CreateTemplateFormDialog
        sheetKey={initialFormState.key}
        sheetName={initialFormState.name}
        projectId={props.projectId}
        open={isCreateTemplateDialogOpen}
        onClose={closeCreateTemplateDialog}
        diagramInstance={props.diagramInstance}
      />
    </>
  );
};
