/**
 * @file
 *
 * This component is used to render the user avatar and name on the Nav bar
 */

import React from 'react';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
  Avatar,
  Typography,
  makeStyles,
  useTheme,
  Menu,
  Button,
  MenuItem,
  Link,
  Divider,
  Grid,
} from '@material-ui/core';
import { MdError, MdExitToApp, MdMessage, MdPersonOutline } from 'react-icons/md';
import clsx from 'clsx';

import { getMailToString } from '../utils/helpers';
import { useTenantState } from '../data/user';

const reportIssueEmail = {
  subject: 'INTEGRTR.Digital Transformer | Found an Issue!',
  getBody: (user, tenant) =>
    `%0A%0A%0A-----------------------------------------------%0AUser: ${user?.first_name ??
      ''} ${user?.last_name ?? ''} (${user?.email ?? ''})
    %0ATenant: ${tenant?.name ?? ''} (${tenant?.tenant_id ??
      ''})%0A-----------------------------------------------`,
};

const feedbackEmail = {
  subject: 'INTEGRTR.Digital Transformer | Application Feedback',
  getBody: (user, tenant) =>
    `%0A%0A%0A-----------------------------------------------%0AUser: ${user?.first_name ??
      ''} ${user?.last_name ?? ''} (${user?.email ?? ''})
    %0ATenant: ${tenant?.name ?? ''} (${tenant?.tenant_id ??
      ''})%0A-----------------------------------------------`,
};

const useStyles = makeStyles(theme => ({
  username: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  menuTriggerButton: {
    textTransform: 'none',
  },
  reportIssueOption: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: 'rgba(255, 225, 226, 0.5)',
    },
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    background: theme.palette.primary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  optionIcons: {
    color: theme.palette.grey[600],
  },
}));

export function UserAvatar({ loading: isUserLoading, user, handleLogout }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item>
      <PopupState variant="popover" popupId="user-menu">
        {popupState => (
          <>
            <Button classes={{ root: classes.menuTriggerButton }} {...bindTrigger(popupState)}>
              <Typography className={classes.username}>
                {isUserLoading ? '...' : user?.first_name ?? '...'}
              </Typography>
              <Avatar className={classes.avatar} src={user?.picture}>
                <MdPersonOutline color={theme.palette.common.white} />
              </Avatar>
            </Button>
            <Menu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              {...bindMenu(popupState)}
              getContentAnchorEl={null}
            >
              <MenuItem
                component={Link}
                href={getMailToString({
                  emailSubject: feedbackEmail.subject,
                  emailBody: feedbackEmail.getBody(user, useTenantState.get()),
                })}
                onClick={popupState.close}
              >
                <MdMessage
                  className={clsx(classes.optionIcons, classes.rightMargin)}
                  fontSize={20}
                />
                Share Feedback
              </MenuItem>
              <MenuItem
                component={Link}
                href={getMailToString({
                  emailSubject: reportIssueEmail.subject,
                  emailBody: reportIssueEmail.getBody(user, useTenantState.get()),
                })}
                className={classes.reportIssueOption}
                onClick={popupState.close}
              >
                <MdError className={classes.rightMargin} fontSize={20} />
                Report Issue
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout(popupState, window.location.origin)}>
                <MdExitToApp
                  className={clsx(classes.optionIcons, classes.rightMargin)}
                  fontSize={20}
                />
                Log out
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
    </Grid>
  );
}
