/**
 * @file
 *
 * This component renders the tabbed modal view that allows user to choose a template or sheet to get started with
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  withStyles,
  darken,
  Typography,
} from '@material-ui/core';
import { MdExpandMore } from 'react-icons/md';
import { values } from 'lodash-es';
import ProgressiveImage from 'react-progressive-image';
import { FormattedMessage } from 'react-intl';

import { useImageFetch } from '../hooks/useImageFetch';
import { TEMPLATE_TYPES } from '../utils/constants';
import { useNotifyError } from '../hooks/useNotifyError';
import { getAssetURL, digitalTransformerAssetBase } from '../utils/service';
import { useTemplates } from '../data/templates';
import { sheetStateActions } from '../data/projects';

const id = 'choose-template-dialog';

const TABS = {
  SHEETS: { key: 'sheets', label: 'Sheets' },
  INTEGRTR_TEMPLATES: { key: 'integrtrTemplates', label: 'INTEGRTR Templates' },
  CUSTOM_TEMPLATES: { key: 'customTemplates', label: 'Custom Templates' },
};

const useTabPanelStyles = makeStyles(theme => ({
  tabPanel: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: props => props.direction,
  },
}));

function TabPanel({ children, selectedTabKey, direction, tabKey, ...props }) {
  const classes = useTabPanelStyles({ direction });

  return (
    <div
      role="tabpanel"
      hidden={selectedTabKey !== tabKey}
      id={`tabanel-${tabKey}`}
      aria-labelledby={`tab-${tabKey}`}
      {...props}
      className={classes.tabPanel}
    >
      {selectedTabKey === tabKey && children}
    </div>
  );
}

const useImageStyles = makeStyles(() => ({
  previewContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 400,
  },
  previewImage: {
    width: 'auto',
    maxWidth: '100%',
    height: 400,
    objectFit: 'cover',
    transition: 'all .25s ease-out',
  },
}));

function PreviewImage({ projectId, template, sheet }) {
  const classes = useImageStyles();

  let imagePath;
  if (template) {
    imagePath =
      template.type === TEMPLATE_TYPES.CUSTOM
        ? `template/${template.id}`
        : getAssetURL(digitalTransformerAssetBase, `template/${template.id}`);
  } else {
    imagePath = `${projectId}/${sheet.main ? 'cover' : sheet.key}`;
  }

  const src = useImageFetch({
    imagePath,
    ...(template && template.type === TEMPLATE_TYPES.SYSTEM && { isUsableAsSrc: true }),
  });

  return (
    <ProgressiveImage src={src}>
      {(_src, loading) => (
        <div className={classes.previewContainer}>
          <img
            src={_src}
            style={{ opacity: loading ? 0.5 : 1 }}
            className={classes.previewImage}
            alt={`${template ? template.name : sheet.name} preview`}
          />
        </div>
      )}
    </ProgressiveImage>
  );
}

const StyledTabs = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: darken(theme.palette.primary.main, 0.2),
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  sheetTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabPanelContainer: {
    margin: theme.spacing(2, 0),
  },
}));

const useSheetTemplates = ({
  sheets,
  sheetKey,
  projectId,
  indexMaps,
  resetDiagramViewport,
  onClose,
  templates,
}) => {
  const [selectedTabKey, updateSelectedTabKey] = useState(TABS.SHEETS.key);
  const handleTabChange = useCallback((event, tabKey) => {
    updateSelectedTabKey(tabKey);
  }, []);

  const filteredSheets = useMemo(
    () => sheets.filter(sheet => sheetKey !== sheet.key && !sheet.empty),
    [sheetKey, sheets]
  );
  const customTemplates = useMemo(
    () => templates?.filter(template => template.type === 'custom') ?? [],
    [templates]
  );
  const systemTemplates = useMemo(
    () => templates?.filter(template => template.type === 'system') ?? [],
    [templates]
  );

  const handleSheetUse = useCallback(
    fromSheetKey => event => {
      event.stopPropagation();

      sheetStateActions.copySheet({
        projectId,
        fromSheetKey,
        toSheetKey: sheetKey,
        indexMaps,
      });

      resetDiagramViewport();

      onClose();
    },
    [indexMaps, onClose, projectId, resetDiagramViewport, sheetKey]
  );

  const handleTemplateUse = useCallback(
    sheetData => event => {
      event.stopPropagation();

      sheetStateActions.copyTemplate({
        projectId,
        sheetKey,
        sheetData,
        indexMaps,
      });

      resetDiagramViewport();

      onClose();
    },
    [indexMaps, onClose, projectId, resetDiagramViewport, sheetKey]
  );

  return {
    filteredSheets,
    customTemplates,
    systemTemplates,
    selectedTabKey,
    handleTabChange,
    handleSheetUse,
    handleTemplateUse,
  };
};

export function SelectSheetTemplateDialog({
  sheets,
  sheetKey,
  projectId,
  indexMaps,
  resetDiagramViewport,
  open,
  onClose,
}) {
  const classes = useStyles();

  // Handles fetching and refetching of mapper templates
  const { templates, isLoading, error, refetch: refetchTemplates } = useTemplates({
    enabled: open,
  });
  useNotifyError({ error });

  useEffect(() => {
    if (open) {
      refetchTemplates();
    }
  }, [open, refetchTemplates]);

  // Handles tab change and provides us with the list of custom templates and system templates
  const {
    filteredSheets,
    customTemplates,
    systemTemplates,
    selectedTabKey,
    handleTabChange,
    handleSheetUse,
    handleTemplateUse,
  } = useSheetTemplates({
    sheets,
    sheetKey,
    projectId,
    indexMaps,
    resetDiagramViewport,
    onClose,
    templates,
  });

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} aria-labelledby={id} open={open}>
      <DialogTitle id={id}>
        <FormattedMessage id="CHOOSE_TEMPLATE" />
      </DialogTitle>
      <DialogContent>
        <StyledTabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
          value={selectedTabKey}
          onChange={handleTabChange}
        >
          {values(TABS).map(({ key, label }) => (
            <StyledTab
              key={key}
              value={key}
              label={label}
              id={`tab-${key}`}
              aria-controls={`tabpanel-${key}`}
            />
          ))}
        </StyledTabs>
        <div className={classes.tabPanelContainer}>
          <TabPanel
            direction={filteredSheets.length === 0 ? 'row' : 'column'}
            selectedTabKey={selectedTabKey}
            tabKey={TABS.SHEETS.key}
          >
            {filteredSheets.length ? (
              filteredSheets.map((sheet, index) => (
                <Accordion key={sheet.key} defaultExpanded={index === 0}>
                  <AccordionSummary expandIcon={<MdExpandMore />}>
                    <div className={classes.sheetTitle}>
                      <div>
                        <Typography component="p">{sheet.name}</Typography>
                      </div>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleSheetUse(sheet.key)}
                      >
                        <FormattedMessage id="SELECT" />
                      </Button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PreviewImage projectId={projectId} sheet={sheet} />
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography variant="body1" gutterBottom>
                <FormattedMessage id="NO_SHEET_AVAILABLE" />
              </Typography>
            )}
          </TabPanel>

          <TabPanel
            direction={isLoading || systemTemplates.length === 0 ? 'row' : 'column'}
            selectedTabKey={selectedTabKey}
            tabKey={TABS.INTEGRTR_TEMPLATES.key}
          >
            {isLoading && <CircularProgress />}
            {!isLoading &&
              (systemTemplates.length ? (
                systemTemplates.map((template, index) => (
                  <Accordion key={template.id} defaultExpanded={index === 0}>
                    <AccordionSummary expandIcon={<MdExpandMore />}>
                      <div className={classes.sheetTitle}>
                        <div>
                          <Typography component="p">{template.name}</Typography>
                          <Typography component="p" variant="caption">
                            {template.description}
                          </Typography>
                        </div>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={handleTemplateUse(template.sheetData)}
                        >
                          <FormattedMessage id="SELECT" />
                        </Button>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <PreviewImage template={template} />
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography variant="body1" gutterBottom>
                  <FormattedMessage id="NO_TEMPLATES_AVAILABLE" />
                </Typography>
              ))}
          </TabPanel>

          <TabPanel
            direction={isLoading || customTemplates.length === 0 ? 'row' : 'column'}
            selectedTabKey={selectedTabKey}
            tabKey={TABS.CUSTOM_TEMPLATES.key}
          >
            {isLoading && <CircularProgress />}
            {!isLoading &&
              (customTemplates.length ? (
                customTemplates.map((template, index) => (
                  <Accordion key={template.id} defaultExpanded={index === 0}>
                    <AccordionSummary expandIcon={<MdExpandMore />}>
                      <div className={classes.sheetTitle}>
                        <div>
                          <Typography component="p">{template.name}</Typography>
                          <Typography component="p" variant="caption">
                            {template.description}
                          </Typography>
                        </div>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={handleTemplateUse(template.sheetData)}
                        >
                          <FormattedMessage id="SELECT" />
                        </Button>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <PreviewImage template={template} />
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography variant="body1" gutterBottom>
                  <FormattedMessage id="NO_TEMPLATES_AVAILABLE" />
                </Typography>
              ))}
          </TabPanel>
        </div>
      </DialogContent>
    </Dialog>
  );
}
