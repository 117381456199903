/**
 * @file
 *
 * This component render the top Nav bar in different routes of the application
 */

import React from 'react';
import {
  makeStyles,
  AppBar,
  Grid,
  Link,
  IconButton,
  Button,
  Menu,
  MenuItem,
  DialogContentText,
} from '@material-ui/core';
import { AiOutlineHome } from 'react-icons/ai';
import { MdCheck } from 'react-icons/md';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useHistory } from 'react-router-dom';
import { useConfirmationDialog } from 'material-ui-confirmation';

import { UserAvatar } from './UserAvatar';
import {
  getAssetURL,
  getPlatformURL,
  digitalTransformerAssetBase,
  getServiceInstance,
  redirectToLoginPage,
} from '../utils/service';
import { config } from '../utils/config';
import { useTenantState, useUserDetails } from '../data/user';
import Language from './Language';

const useStyles = makeStyles(theme => ({
  logo: {
    width: 'auto',
    height: theme.spacing(2.9),
    margin: theme.spacing(1, 2.5),
  },
  appBar: {
    height: theme.spacing(5.5),
    zIndex: theme.zIndex.drawer + 2,
    boxShadow: theme.shadows[0],
  },
  parentHeight: {
    height: '100%',
  },
  navIconButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },

  title: {
    textAlign: 'center',
  },
  tenantBtn: {
    textTransform: 'initial',
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  checkmark: {
    marginLeft: theme.spacing(1),
  },
}));

const getNormalizedTenantName = tenant =>
  tenant.has_onboarded_directly ? 'Default Tenant' : tenant.name;

export function Nav() {
  const classes = useStyles();

  const { user, isLoading } = useUserDetails();
  const tenant = useTenantState();

  const history = useHistory();

  const { getConfirmation } = useConfirmationDialog();

  const handleLogout = (popupState, redirectURL) => () => {
    getServiceInstance()
      .post('/logout', {}, { withCredentials: true })
      .then(() => {
        if (popupState) {
          popupState.close();
        }

        redirectToLoginPage(redirectURL);
      });
  };

  const handleTenantSwitch = (popupState, tenant) => () => {
    if (user.isLoggedInUsingSSO === tenant.sso_enabled) {
      history.push(`/t/${tenant.tenant_id}`);
      popupState.close();
    } else {
      popupState.close();

      getConfirmation({
        title: 'Switch Tenant',
        body: (
          <DialogContentText>
            You are about to switch to a tenant which may require you to enter your login
            credentials. This action will log you out from the current tenant.
            <br />
            <br />
            <strong>Are you sure you want to proceed with the redirection?</strong>
          </DialogContentText>
        ),

        onAccept: () => {
          handleLogout(null, `${window.location.origin}/t/${tenant.tenant_id}`)();
        },

        acceptButtonProps: {
          disableElevation: true,
          variant: 'contained',
        },
      });
    }
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.parentHeight}
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  component={Link}
                  size="small"
                  className={classes.navIconButton}
                  href={`${getPlatformURL()}/#/launchpad/t-${tenant?.tenant_id}-t`}
                >
                  <AiOutlineHome size="18px" />
                </IconButton>
              </Grid>
              <Grid
                item
                component={Link}
                href={`${getPlatformURL()}/#/launchpad/t-${tenant?.tenant_id}-t`}
              >
                <img
                  src={getAssetURL(digitalTransformerAssetBase, 'integrtr.png')}
                  className={classes.logo}
                  alt="integrtr"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.title}>
            {Boolean(tenant) && user.tenants.length > 1 && (
              <PopupState variant="popover" popupId="tenant-switch-menu">
                {popupState => (
                  <>
                    <Button
                      variant="outlined"
                      color="default"
                      size="small"
                      disableElevation
                      className={classes.tenantBtn}
                      {...bindTrigger(popupState)}
                    >
                      {getNormalizedTenantName(tenant)}
                    </Button>
                    <Menu
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      {...bindMenu(popupState)}
                      getContentAnchorEl={null}
                    >
                      {user.tenants.map(_tenant => (
                        <MenuItem
                          key={_tenant.tenant_id}
                          onClick={handleTenantSwitch(popupState, _tenant)}
                          disabled={tenant.tenant_id === _tenant.tenant_id}
                        >
                          {getNormalizedTenantName(_tenant)}
                          {tenant.tenant_id === _tenant.tenant_id && (
                            <MdCheck size={18} className={classes.checkmark} />
                          )}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </PopupState>
            )}
          </Grid>

          <Grid item>
            <Grid container alignItems="center">
              {config.i18nEnabled && (
                <Grid item>
                  <Language />
                </Grid>
              )}

              <UserAvatar loading={isLoading} user={user} handleLogout={handleLogout} />
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
}
