/**
 * @file
 *
 * This file houses the Home route Component
 *
 * It lists all the project available for the user in the selected tenant
 */

import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { entries } from 'lodash-es';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';

import { Nav } from '../components/Nav';
import { assetBase, getAssetURL } from '../utils/service';
import { ProjectFormDialog } from '../components/ProjectFormDialog';
import { ThumbnailImage } from '../components/ThumbnailImage';
import { useInitialLoadNormal } from '../hooks/useInitialLoadChecks';
import { useTenantState, useUserDetails } from '../data/user';
import { useCreateNewProjectMutation, useProjects, useProjectStore } from '../data/projects';
import { useDialog } from '../hooks/useDialog';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    color: theme.palette.common.white,
    fontSize: 12,
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: theme.typography.fontWeightBold,
  },

  projectTile: {
    width: 300,
    minHeight: 280,
    margin: theme.spacing(2, 3),
    marginLeft: 0,
    paddingBottom: theme.spacing(2),
    boxShadow: theme.shadows[0],
    border: theme.borders[0],
    borderRadius: theme.spacing(0.5),
    position: 'relative',
  },
  projectThumbnail: {
    borderBottom: theme.borders[0],
    height: theme.spacing(20),
  },
  projectVersion: {
    position: 'absolute',
    top: 5,
    right: 8,
  },
  projectName: {
    margin: theme.spacing(2, 2, 1),
    minHeight: theme.spacing(5),
    width: '90%',
  },
  projectDesc: {
    margin: theme.spacing(0, 2),
    color: theme.palette.grey[600],
    minHeight: theme.spacing(5),
    width: '90%',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  main: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(10),
    overflowY: 'auto',
    height: '100%',
  },
  container: {
    height: '100%',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  rightMargin: {
    marginRight: theme.spacing(4),
  },
  projects: {
    paddingTop: theme.spacing(2),
  },
  noDataButton: {
    marginTop: theme.spacing(3),
  },
  noDataImage: {
    width: 400,
  },
  greyText: {
    color: theme.palette.grey[500],
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  titleFont: {
    fontSize: theme.spacing(2),
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const useCreateProject = () => {
  const createNewProjectMutation = useCreateNewProjectMutation();

  const {
    isDialogOpen: isCreateProjectDialogOpen,
    openDialog: openCreateProjectDialog,
    closeDialog: closeCreateProjectDialog,
  } = useDialog();

  const handleCreateNewProject = useCallback(
    async values => {
      await createNewProjectMutation.mutateAsync({ values });
      closeCreateProjectDialog();
    },
    [closeCreateProjectDialog, createNewProjectMutation]
  );

  return {
    isCreateProjectDialogOpen,
    openCreateProjectDialog,
    closeCreateProjectDialog,
    handleCreateNewProject,
    createNewProjectMutation,
  };
};

export function Home() {
  useInitialLoadNormal();

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const tenant = useTenantState();

  const { isLoading: isUserLoading } = useUserDetails();

  const { isProjectsLoading, error, fetchProjects } = useProjects({
    enabled: Boolean(tenant),
  });

  useEffect(() => {
    if (tenant) {
      fetchProjects();
    }
  }, [fetchProjects, tenant]);

  const projects = useProjectStore(useCallback(state => state.projects, []));

  const {
    isCreateProjectDialogOpen,
    openCreateProjectDialog,
    closeCreateProjectDialog,
    handleCreateNewProject,
    createNewProjectMutation,
  } = useCreateProject();

  const projectEntries = entries(projects || {});
  return (
    <div className={classes.container}>
      <Nav />

      {(isProjectsLoading || isUserLoading) && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.main}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <Typography
              component="h2"
              color="textSecondary"
              className={clsx(classes.greyText, classes.topMargin, classes.titleFont)}
            >
              <FormattedMessage id="LOADING_PROJECTS" />
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container direction="column" className={classes.main}>
        <Grid item>
          <Grid container className={classes.projects}>
            {!(isProjectsLoading || isUserLoading) &&
              !error &&
              (projectEntries.length ? (
                <>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography variant="h5" component="h2" className={classes.rightMargin}>
                        <FormattedMessage id="YOUR_PROJECTS" />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disableElevation
                        startIcon={<MdAdd />}
                        className={classes.button}
                        onClick={openCreateProjectDialog}
                      >
                        <FormattedMessage id="CREATE_PROJECT" />
                      </Button>
                    </Grid>
                  </Grid>

                  {projectEntries.map(([projectId, project]) => {
                    return (
                      <Grid item key={projectId}>
                        <Link to={`/t/${tenant.tenant_id}/project/${projectId}`}>
                          <Grid container direction="column" className={classes.projectTile}>
                            <Grid
                              item
                              justifyContent="center"
                              alignItems="center"
                              className={classes.projectThumbnail}
                              container
                            >
                              <ThumbnailImage height="100%" projectId={projectId} />
                            </Grid>
                            <Grid item className={classes.projectVersion}>
                              <Typography className={clsx(classes.titleFont, classes.textBold)}>
                                v{project.version}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              alignItems="center"
                              container
                              className={classes.projectName}
                            >
                              <CustomTooltip title={project.name}>
                                <Typography className={classes.titleFont}>
                                  {project.name}
                                </Typography>
                              </CustomTooltip>
                            </Grid>
                            <Grid item className={classes.projectDesc}>
                              <CustomTooltip title={project.description}>
                                <Typography variant="body1">
                                  {project.description || '--'}
                                </Typography>
                              </CustomTooltip>
                            </Grid>
                          </Grid>
                        </Link>
                      </Grid>
                    );
                  })}
                </>
              ) : (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.main}
                >
                  <Grid item>
                    <img
                      src={getAssetURL(assetBase, 'no_projects.png')}
                      className={classes.noDataImage}
                      alt="no-data"
                    />
                  </Grid>
                  <Grid item>
                    <Typography className={clsx(classes.greyText, classes.titleFont)}>
                      <FormattedMessage id="LOOKS_EMPTY_CANVAS" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      startIcon={<MdAdd />}
                      className={clsx(classes.button, classes.noDataButton)}
                      onClick={openCreateProjectDialog}
                    >
                      <Typography className={classes.titleFont} component="span">
                        <FormattedMessage id="CREATE_FIRST_PROJECT" />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>

      <ProjectFormDialog
        id="create-project-form-dialog-title"
        title={formatMessage({ id: 'CREATE_PROJECT' })}
        open={isCreateProjectDialogOpen}
        onClose={closeCreateProjectDialog}
        initialValues={{ name: '', description: '' }}
        onSubmit={handleCreateNewProject}
        acceptText={
          createNewProjectMutation.isLoading
            ? formatMessage({ id: 'CREATING' })
            : formatMessage({ id: 'CREATE' })
        }
        mutationObject={createNewProjectMutation}
      />
    </div>
  );
}
