/**
 * @file
 *
 * this file contains all the base templates for GoJS and some helpers for those models
 */
import * as go from 'gojs';
import { blueGrey, common, grey } from '@material-ui/core/colors';

import {
  GROUP_NODES,
  MODEL_NODES,
  NODE_MODEL_ATTRS,
  DIMENSIONS,
  LINK_MODEL_ATTRS,
} from '../utils/constants';
import { bindings, fonts, linkSelectionAdornment } from './helpers';
import { watermarkImage } from './watermarkImage';
import { getMapperProjectLinkHref } from '../utils/helpers';
import './extensions/HyperlinkText';
import { externalLinkString } from './extensions/shapes';

const $ = go.GraphObject.make;

/**
 * Handle a node drop into a group or canvas background
 *
 * @param {go.DiagramEvent} event
 * @param {go.Group} group
 */
export function finishDrop(event, group) {
  const selectedObjects = event.diagram.selection.toArray();

  // if the dragged nodes are part of a entity group then disable drop into group action
  for (let selectedObject of selectedObjects) {
    if (
      selectedObject.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP ||
      selectedObject.data[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.FIELD ||
      selectedObject.data[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.SPACER
    ) {
      event.diagram.currentTool.doCancel();
      return;
    }
  }

  // add the dropped node to the group if the drop happens on a group
  // otherwise add the node to the top level i.e. as a standalone node
  const validDrop =
    group !== null
      ? group.addMembers(group.diagram.selection, true)
      : event.diagram.commandHandler.addTopLevelParts(event.diagram.selection, true);

  // if either of the drop action fails to complete i.e. return a falsy value
  // cancel the current tool's further action to rollback everything
  // and maintain a valid model state
  if (!validDrop) {
    event.diagram.currentTool.doCancel();
  }
}

/**
 * highlight the group involved in the drag and drop action
 *
 * @param {go.DiagramEvent} event
 * @param {go.Group} group
 * @param {boolean} show flag to determine to highlight or not
 */
function highlightGroup(event, group, show) {
  if (!group) {
    return;
  }

  event.handled = true;
  if (show) {
    const { draggingTool } = group.diagram.toolManager;
    const map = draggingTool.draggedParts || draggingTool.copiedParts;

    if (group.canAddMembers(map.toKeySet())) {
      group.isHighlighted = true;
      return;
    }
  }

  group.isHighlighted = false;
}

export const groupTemplate = $(
  go.Group,
  'Vertical',
  {
    background: 'transparent',
    mouseDragEnter: (event, group) => highlightGroup(event, group, true),
    mouseDragLeave: (event, group) => highlightGroup(event, group, false),
    computesBoundsAfterDrag: true,
    mouseDrop: finishDrop,
    handlesDragDropForMembers: true,
  },
  $(
    go.TextBlock,
    { alignment: go.Spot.Center, editable: true, font: fonts.entityTitle },
    bindings.titleText
  ),
  $(
    go.Panel,
    'Auto',
    $(
      go.Shape,
      'RoundedRectangle',
      { parameter1: 10 },
      bindings.fillColor.makeTwoWay(),
      bindings.highlightStrokeColor
    ),
    $(go.Placeholder, { padding: 15 })
  )
);

/**
 * This is the base text node template
 */
export const textNodeTemplate = $(
  go.Node,
  'Auto',
  {
    locationSpot: go.Spot.Center,
    rotatable: true,
    rotateObjectName: 'Text',
    fromLinkable: false,
    toLinkable: false,
    padding: 10,
  },
  bindings.location,
  $(
    go.TextBlock,
    {
      name: 'Text',
      margin: 6,
      editable: true,
      fromLinkable: false,
      toLinkable: false,
    },
    bindings.richText,
    bindings.text,
    bindings.angle,
    bindings.textUnderline,
    bindings.textStrikethrough,
    bindings.strokeColor.makeTwoWay()
  )
);

/**
 * This is the base link template
 */
export const linkTemplate = $(
  go.Link,
  {
    routing: go.Link.Orthogonal,
    adjusting: go.Link.End,
    curve: go.Link.JumpGap,
    corner: 5,
    reshapable: true,
    resegmentable: true,
    toShortLength: 10,
    relinkableFrom: true,
    relinkableTo: true,
    selectable: true,
    selectionAdornmentTemplate: linkSelectionAdornment,
    visible: true,
  },
  bindings.linkPoints,
  bindings.linkVisible,
  $(
    go.Shape, // this is ghost link stroke with high stroke width to make clicking on link easier
    {
      cursor: 'pointer',
      isPanelMain: true,
      strokeWidth: 10,
      stroke: 'transparent',
    }
  ),
  $(
    go.Shape, // this is the primary link stroke that is the one visible and configurable using various bindings
    {
      isPanelMain: true,
    },
    bindings.linkStrokeWidth,
    bindings.linkStroke,
    bindings.strokeType
  ),
  $(
    go.Shape, // the arrow head
    {
      toArrow: 'standard',
      stroke: null,
    },
    bindings.linkArrowScale,
    bindings.linkFill
  ),
  $(
    go.Panel, // panel for mapper project link icon (contains the circle shape and external link icon)
    'Auto',
    {
      segmentFraction: 0.5,
      click: (event, graphObject) => {
        const mapperProjectId = graphObject.part.data[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID];

        if (mapperProjectId) {
          const url = getMapperProjectLinkHref(mapperProjectId);

          window.open(url, '_blank');
        }
      },
      // Don't remove this, it breaks the conditional logic in the binding used
      visible: false,
      cursor: 'pointer',
      toolTip: $(
        'ToolTip',
        bindings.linkLabelVisible,
        $(go.TextBlock, { name: 'HyperLinkToolTipTextBlock', margin: 4 }, bindings.label)
      ),
    },
    bindings.showIfMapperProjectLinked,
    $(go.Shape, 'Circle', {
      fill: '#354a5f',
      width: 45,
      height: 45,
      stroke: null,
    }),
    $(go.Shape, {
      geometryString: externalLinkString,
      fill: 'transparent',
      strokeWidth: 2,
      stroke: 'white',
      width: 15,
      height: 15,
    })
  ),
  $(
    go.Panel, // panel for link label
    'Auto',
    {
      segmentFraction: 0.5,
    },
    bindings.hideIfMapperProjectLinked,
    $(go.Shape, {
      fill: common.white,
      stroke: grey[200],
    }),
    $(
      go.TextBlock, // label text
      {
        margin: 10,
        editable: true,
      },
      bindings.label
    )
  ),
  {
    toolTip: $(
      // link tooltip
      go.Adornment,
      'Auto',
      {
        shadowVisible: true,
        shadowColor: blueGrey[200],
        isShadowed: true,
        shadowBlur: 1,
        shadowOffset: new go.Point(3, 3),
      },
      bindings.linkTooltipVisible,
      $(go.Shape, 'RoundedRectangle', {
        fill: common.white,
        stroke: null,
      }),
      $(
        go.TextBlock,
        {
          margin: new go.Margin(10),
          shadowVisible: false,
        },
        bindings.text
      )
    ),
  }
);

/**
 * This is the mapper template link template
 */
export const mapperTemplateLinkTemplate = $(
  go.Link,
  {
    routing: go.Link.Orthogonal,
    adjusting: go.Link.End,
    curve: go.Link.JumpGap,
    corner: 5,
    reshapable: true,
    resegmentable: true,
    toShortLength: 10,
    relinkableFrom: true,
    relinkableTo: true,
    selectable: true,
    selectionAdornmentTemplate: linkSelectionAdornment,
    visible: true,
  },
  bindings.linkPoints,
  bindings.linkVisible,
  $(
    go.Shape, // this is ghost link stroke with high stroke width to make clicking on link easier
    {
      cursor: 'pointer',
      isPanelMain: true,
      strokeWidth: 20,
      stroke: 'transparent',
    }
  ),
  $(
    go.Shape, // this is the primary link stroke that is the one visible and configurable using various bindings
    {
      isPanelMain: true,
    },
    bindings.linkStrokeWidth,
    bindings.linkStroke,
    bindings.strokeType,
    bindings.linkPreview
  ),
  $(
    go.Shape, // the arrow head
    {
      toArrow: 'standard',
      stroke: null,
    },
    bindings.linkArrowScale,
    bindings.linkFill,
    bindings.linkPreview
  ),
  $(
    go.Panel, // panel for mapper project link icon (contains the circle shape and external link icon)
    'Auto',
    {
      segmentFraction: 0.5,
      click: (event, graphObject) => {
        const mapperProjectId = graphObject.part.data[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID];

        if (mapperProjectId) {
          const url = getMapperProjectLinkHref(mapperProjectId);

          window.open(url, '_blank');
        }
      },
      // Don't remove this, it breaks the conditional logic in the binding used
      visible: 'false',
      cursor: 'pointer',
      toolTip: $(
        'ToolTip',
        bindings.linkLabelVisible,
        $(go.TextBlock, { name: 'HyperLinkToolTipTextBlock', margin: 4 }, bindings.label)
      ),
    },
    bindings.showIfMapperProjectLinked,
    $(go.Shape, 'Circle', {
      fill: '#354a5f',
      width: 45,
      height: 45,
      stroke: null,
    }),
    $(go.Shape, {
      geometryString: externalLinkString,
      fill: 'transparent',
      strokeWidth: 2,
      stroke: 'white',
      width: 15,
      height: 15,
    })
  ),
  $(
    go.Panel, // panel for link label
    'Auto',
    {
      segmentFraction: 0.5,
    },
    bindings.linkLabelVisible,
    $(go.Shape, {
      fill: common.white,
      stroke: grey[200],
    }),
    $(
      go.TextBlock, // label text
      {
        margin: 10,
        editable: true,
      },
      bindings.label
    )
  ),
  {
    toolTip: $(
      // link tooltip
      go.Adornment,
      'Auto',
      {
        shadowVisible: true,
        shadowColor: blueGrey[200],
        isShadowed: true,
        shadowBlur: 1,
        shadowOffset: new go.Point(3, 3),
      },
      bindings.linkTooltipVisible,
      $(go.Shape, 'RoundedRectangle', {
        fill: common.white,
        stroke: null,
      }),
      $(
        go.TextBlock,
        {
          margin: new go.Margin(10),
          shadowVisible: false,
        },
        bindings.text
      )
    ),
  }
);

export const watermarkTemplate = $(
  go.Node,
  'Auto',
  bindings.location,
  $(
    go.Panel,
    'Table',
    $(go.TextBlock, {
      editable: false,
      text: 'Designed with',
      font: fonts.entityField,
      row: 0,
      column: 0,
    }),
    $(go.Picture, watermarkImage, {
      background: common.white,
      height: DIMENSIONS.WATERMARK.HEIGHT,
      width: DIMENSIONS.WATERMARK.WIDTH,
      row: 1,
      column: 0,
    })
  )
);

/**
 * This function is used to check if a link is valid and can be drawn
 *
 * used by the link drawing tool in GoJS
 *
 * @param {go.Node} fromNode
 * @param {go.GraphObject} fromPort
 * @param {go.Node} toNode
 * @param {go.GraphObject} toPort
 */
export function basicLinkValidation(fromNode, fromPort, toNode, toPort) {
  const fromNodeCategory = fromNode.data[NODE_MODEL_ATTRS.CATEGORY];
  const fromNodeGroup = fromNode.data[NODE_MODEL_ATTRS.GROUP];
  const fromNodeKey = fromNode.data[NODE_MODEL_ATTRS.KEY];

  const toNodeCategory = toNode.data[NODE_MODEL_ATTRS.CATEGORY];
  const toNodeGroup = toNode.data[NODE_MODEL_ATTRS.GROUP];
  const toNodeKey = toNode.data[NODE_MODEL_ATTRS.KEY];

  // disable linking between sibling fields
  if (fromNodeCategory === MODEL_NODES.FIELD && toNodeCategory === MODEL_NODES.FIELD) {
    // disable if they are direct siblings
    if (fromNodeGroup === toNodeGroup) {
      return false;
    }

    const fromNodeEntityGroup =
      fromNode.containingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
        ? fromNode.containingGroup
        : fromNode.containingGroup.containingGroup;

    const toNodeEntityGroup =
      toNode.containingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
        ? toNode.containingGroup
        : toNode.containingGroup.containingGroup;

    // disable if they are in the same entity group
    if (
      fromNodeEntityGroup.data[NODE_MODEL_ATTRS.KEY] ===
      toNodeEntityGroup.data[NODE_MODEL_ATTRS.KEY]
    ) {
      return false;
    }
  }
  // disable linking between field and field group under the same entity group (from field -> field group)
  else if (fromNodeCategory === MODEL_NODES.FIELD && toNodeCategory === GROUP_NODES.FIELDS_GROUP) {
    // disable when field is direct child of the field group
    if (fromNodeGroup === toNodeKey) {
      return false;
    }

    const fromNodeEntityGroup =
      fromNode.containingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
        ? fromNode.containingGroup
        : fromNode.containingGroup.containingGroup;

    // disable if they are under the same entity group
    if (fromNodeEntityGroup.data[NODE_MODEL_ATTRS.KEY] === toNodeGroup) {
      return false;
    }
  }
  // disable linking between field and field group under the same entity group (from field group -> field)
  else if (toNodeCategory === MODEL_NODES.FIELD && fromNodeCategory === GROUP_NODES.FIELDS_GROUP) {
    // disable when field is direct child of the field group
    if (toNodeGroup === fromNodeKey) {
      return false;
    }

    const toNodeEntityGroup =
      toNode.containingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
        ? toNode.containingGroup
        : toNode.containingGroup.containingGroup;

    // disable if they are under the same entity group
    if (toNodeEntityGroup.data[NODE_MODEL_ATTRS.KEY] === fromNodeGroup) {
      return false;
    }
  }
  // disable linking between parent-child field group and entity group (from field group -> entity group)
  else if (
    fromNodeCategory === GROUP_NODES.FIELDS_GROUP &&
    toNodeCategory === GROUP_NODES.ENTITY_GROUP &&
    fromNodeGroup === toNodeKey
  ) {
    return false;
  }
  // disable linking between parent-child field group and entity group (from entity group -> field group)
  else if (
    toNodeCategory === GROUP_NODES.FIELDS_GROUP &&
    fromNodeCategory === GROUP_NODES.ENTITY_GROUP &&
    toNodeGroup === fromNodeKey
  ) {
    return false;
  }
  // disable linking between parent child field and entity group (from field -> entity group)
  else if (fromNodeCategory === MODEL_NODES.FIELD && toNodeCategory === GROUP_NODES.ENTITY_GROUP) {
    const fromNodeContainingGroup = fromNode.containingGroup;

    // disable if field is a direct children of the entity group
    if (
      fromNodeContainingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP &&
      fromNodeGroup === toNodeKey
    ) {
      return false;
    }
    // if field is a direct children of a field group check it's parent entity group
    else if (fromNodeContainingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
      const parentEntityGroup = fromNodeContainingGroup.containingGroup;
      // disable if the entity group is the same as the fromNode containing entity group
      if (
        parentEntityGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP &&
        parentEntityGroup.data[NODE_MODEL_ATTRS.KEY] === toNodeKey
      ) {
        return false;
      }
    }
  }
  // disable linking between parent child field and entity group (from entity group -> field)
  else if (toNodeCategory === MODEL_NODES.FIELD && fromNodeCategory === GROUP_NODES.ENTITY_GROUP) {
    const toNodeContainingGroup = toNode.containingGroup;

    // disable if field is a direct children of the entity group
    if (
      toNodeContainingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP &&
      toNodeGroup === fromNodeKey
    ) {
      return false;
    }
    // if field is a direct children of a field group check it's parent entity group
    else if (toNodeContainingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
      const parentEntityGroup = toNodeContainingGroup.containingGroup;
      // disable if the entity group is the same as the toNode containing entity group
      if (
        parentEntityGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP &&
        parentEntityGroup.data[NODE_MODEL_ATTRS.KEY] === fromNodeKey
      ) {
        return false;
      }
    }
  }
  return true;
}
