/**
 * @file
 *
 * React hook to display error toast on any type of error from service APIs
 */
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Box, IconButton } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { API_ERROR_TYPES } from '../utils/constants';
import { redirectToLoginPage } from '../utils/service';
import { config } from '../utils/config';

export const wrapErrorMessageWithFallback = (error, fallbackMessage) => {
  const message = error?.errorDetail ?? error?.errorDetails ?? error?.msg ?? error?.message;

  if (!message || typeof message !== 'string') {
    return fallbackMessage;
  }

  if (message.length > 100) {
    return fallbackMessage;
  }

  return message;
};

const errorKeyCache = new Set();

export function useNotifyError({ error, mustRedirect = false, fallbackMessage }) {
  const history = useHistory();

  useEffect(() => {
    if (error && !errorKeyCache.has(error.key)) {
      errorKeyCache.add(error.key);
      toast.error(
        t => (
          <Box display="flex" alignItems="center">
            {wrapErrorMessageWithFallback(error, fallbackMessage ?? 'Error: Something went wrong!')}
            <Box ml={2}>
              <IconButton
                size="small"
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                <MdClose />
              </IconButton>
            </Box>
          </Box>
        ),
        {
          duration: 10000,
          id: error.errorDetail ?? error.key,
        }
      );

      if (error.type === API_ERROR_TYPES.JWT) {
        if (config.env === 'production') {
          redirectToLoginPage(window.location.href);
        }
      } else if (error.type === API_ERROR_TYPES.UNAUTHORIZED && mustRedirect) {
        history.push('/error', {
          errorType: error.type,
        });
      } else if (mustRedirect) {
        history.push('/error', {
          isFatal: true,
          errorDetails: error.msg,
          errorType: error.type,
        });
      }
    }
  }, [error, mustRedirect, fallbackMessage, history]);
}
