/**
 * @file
 *
 * This component is used in tandem with Formik form for easy integration
 *
 * validation result, value and change handler bindings are automatically handled when used inside a Formik render props function
 */

import React from 'react';
import { TextField } from 'formik-material-ui';

export function FormikTextField({ InputLabelProps = {}, ...props }) {
  return (
    <TextField
      fullWidth
      type="text"
      margin="dense"
      autoComplete=""
      {...props}
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
    />
  );
}

export function FormikSelectField({ InputLabelProps = {}, ...props }) {
  return (
    <TextField
      fullWidth
      select
      margin="dense"
      {...props}
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
    >
      {props.children}
    </TextField>
  );
}
