/**
 * @file
 *
 * This file exports the base SVGs for the icons used in the GoJS system category
 */

import { ENTITY_TYPES, INTEGRATION_INDICATORS } from '../utils/constants';

export const icons = {
  [ENTITY_TYPES.CLOUD]:
    'M32 20.548c0-2.565-1.771-4.716-4.156-5.296-0.101-4.022-3.389-7.252-7.433-7.252-2.369 0-4.477 1.109-5.839 2.835-0.764-0.987-1.959-1.624-3.303-1.624-2.307 0-4.176 1.871-4.176 4.179 0 0.201 0.015 0.399 0.043 0.592-0.351-0.063-0.711-0.098-1.080-0.098-3.344-0-6.054 2.712-6.054 6.058s2.71 6.058 6.054 6.058l20.508-0c3.004-0.006 5.438-2.444 5.438-5.451z',
  [ENTITY_TYPES.ON_PREMISE]:
    'M6 28h20c3.314 0 6-2.686 6-6h-32c0 3.314 2.686 6 6 6zM26 24h2v2h-2v-2zM30 4h-28l-2 16h32z',
  [ENTITY_TYPES.NONE]: '',
  [INTEGRATION_INDICATORS.SYSTEM_LINKED]:
    'M5.334 4.545a9.99 9.99 0 0 1 3.542-2.048A3.993 3.993 0 0 0 12 3.999a3.993 3.993 0 0 0 3.124-1.502 9.99 9.99 0 0 1 3.542 2.048 3.993 3.993 0 0 0 .262 3.454 3.993 3.993 0 0 0 2.863 1.955 10.043 10.043 0 0 1 0 4.09c-1.16.178-2.23.86-2.863 1.955a3.993 3.993 0 0 0-.262 3.455 9.99 9.99 0 0 1-3.542 2.047A3.993 3.993 0 0 0 12 20a3.993 3.993 0 0 0-3.124 1.502 9.99 9.99 0 0 1-3.542-2.047 3.993 3.993 0 0 0-.262-3.455 3.993 3.993 0 0 0-2.863-1.954 10.043 10.043 0 0 1 0-4.091 3.993 3.993 0 0 0 2.863-1.955 3.993 3.993 0 0 0 .262-3.454zM13.5 14.597a3 3 0 1 0-3-5.196 3 3 0 0 0 3 5.196z',
  [INTEGRATION_INDICATORS.NONE]: '',
};
