/**
 * @file
 *
 * A "HyperlinkText" is either a TextBlock or a Panel containing a TextBlock that when clicked
 * opens a new browser window with a given or computed URL.
 * When the user's mouse passes over a "HyperlinkText", the text is underlined.
 * When the mouse hovers over a "HyperlinkText", it shows a tooltip that displays the URL.
 *
 * Typical usages:
 *    $("HyperlinkText", "https://integrtr.com", "Visit INTEGRTR landing page")
 *
 *    $("HyperlinkText",
 *        function(node) { return "https://integrtr.com/" + path; },
 *        function(node) { return "Visit INTEGRTR landing page - " + path; })
 *
 *    $("HyperlinkText",
 *        function(node) { return "https://integrtr.com/" + path; },
 *        $(go.Panel, "Auto",
 *            $(go.Shape, ...),
 *            $(go.TextBlock, ...)
 *        )
 *    )
 */

import * as go from 'gojs';

const $ = go.GraphObject.make;

go.GraphObject.defineBuilder(
  'HyperlinkText',
  /**
 * 
 * The first argument to the "HyperlinkText" builder should be either the URL string or a function
 * that takes the data-bound Panel and returns the URL string.
 * If the URL string is empty or if the function returns an empty string,
 * the text will not be underlined on a mouse-over and a click has no effect.

 * The second argument to the "HyperlinkText" builder may be either a string to display in a TextBlock,
 * or a function that takes the data-bound Panel and returns the string to display in a TextBlock.
 * If no text string or function is provided, it assumes all of the arguments are used to
 * define the visual tree for the "HyperlinkText", in the normal fashion for a Panel.

 * The result is either a TextBlock or a Panel.
 */
  args => {
    // the URL is required as the first argument, either a string or a pure function returning a string
    const url = go.GraphObject.takeBuilderArgument(
      args,
      undefined,
      arg => typeof arg === 'string' || typeof arg === 'function'
    );
    // the text for the HyperlinkText is the optional second argument, either a string or a pure function returning a string
    let text = go.GraphObject.takeBuilderArgument(
      args,
      null,
      arg => typeof arg === 'string' || typeof arg === 'function'
    );

    // see if the visual tree is supplied in the arguments to the "HyperlinkText"
    let anyGraphObjects = false;
    for (let i = 0; i < args.length; i++) {
      const firstArgument = args[i];
      if (firstArgument && firstArgument instanceof go.GraphObject) {
        anyGraphObjects = true;
      }
    }

    // define the click behavior
    const click = (event, graphObject) => {
      let url = graphObject._url;
      if (typeof url === 'function') {
        url = url(graphObject.findTemplateBinder());
      }
      if (url) {
        // do not link to external untrusted apps with this
        window.open(url, '_blank');
      }
    };

    // define the tooltip
    const toolTip = $(
      'ToolTip',
      $(
        go.TextBlock,
        { name: 'HyperLinkToolTipTextBlock', margin: 4 },
        new go.Binding('text', '', obj => {
          // here graphObject will be in the Adornment, need to get the HyperlinkText/TextBlock
          obj = obj.part.adornedObject;
          let url = obj._url;
          if (typeof url === 'function') {
            url = url(obj.findTemplateBinder());
          }

          return url;
        }).ofObject()
      ),
      new go.Binding('visible', 'text', Boolean).ofObject('HyperLinkToolTipTextBlock')
    );

    // if the text is provided, use a new TextBlock; otherwise assume the TextBlock is provided
    if (typeof text === 'string' || typeof text === 'function' || !anyGraphObjects) {
      if (text === null && typeof url === 'string') {
        text = url;
      }
      const textBlock = $(go.TextBlock, {
        _url: url,
        cursor: 'pointer',
        mouseEnter(event, obj) {
          let url = obj._url;
          if (typeof url === 'function') {
            url = url(obj.findTemplateBinder());
          }
          if (url) {
            obj.isUnderline = true;
          }
        },
        mouseLeave(event, obj) {
          obj.isUnderline = false;
        },
        click, // defined above
        toolTip, // shared by all HyperlinkText textblocks
      });
      if (typeof text === 'string') {
        textBlock.text = text;
      } else if (typeof text === 'function') {
        textBlock.bind(new go.Binding('text', '', text).ofObject());
      } else if (typeof url === 'function') {
        textBlock.bind(new go.Binding('text', '', url).ofObject());
      }

      return textBlock;
    } else {
      function findTextBlock(graphObject) {
        if (graphObject instanceof go.TextBlock) {
          return graphObject;
        }
        if (graphObject instanceof go.Panel) {
          const iterator = graphObject.elements;
          while (iterator.next()) {
            const result = findTextBlock(iterator.value);
            if (result !== null) {
              return result;
            }
          }
        }
        return null;
      }

      return $(go.Panel, {
        _url: url,
        cursor: 'pointer',
        mouseEnter(event, panel) {
          const textBlock = findTextBlock(panel);
          let url = panel._url;
          if (typeof url === 'function') {
            url = url(panel.findTemplateBinder());
          }

          if (textBlock !== null && url) {
            textBlock.isUnderline = true;
          }
        },
        mouseLeave(event, panel) {
          const textBlock = findTextBlock(panel);
          if (textBlock !== null) {
            textBlock.isUnderline = false;
          }
        },
        click, // defined above
        toolTip, // shared by all HyperlinkText panels
      });
    }
  }
);
