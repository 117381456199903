/**
 * @file
 *
 * This component is used to render forms related to Project details
 */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormikTextField } from './FormikFields';

export const ProjectFormDialog = props => {
  const { formatMessage } = useIntl();

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(1)
      .required('Required')
      .label(formatMessage({ id: 'NAME' })),
    description: yup.string().label(formatMessage({ id: 'DESCRIPTION' })),
  });

  return (
    <Dialog open={props.open} aria-labelledby={props.id}>
      <DialogTitle id={props.id}>{props.title}</DialogTitle>
      <Formik
        validationSchema={schema}
        validateOnMount
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
      >
        {({ isValid, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormikTextField name="name" label={formatMessage({ id: 'NAME' })} autoFocus />
              <FormikTextField
                name="description"
                label={formatMessage({ id: 'DESCRIPTION' })}
                multiline
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                type="submit"
                disabled={props.mutationObject.isLoading}
                color="primary"
                variant="contained"
                startIcon={props.mutationObject.isLoading ? <CircularProgress size={15} /> : ''}
              >
                {props.acceptText}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
