/**
 * @file
 *
 * This is a component that renders a simple drop down menu
 */
import React, { cloneElement } from 'react';
import { Menu, MenuItem, Typography, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  menuItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const DropDown = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = option => () => {
    props.onSelect(option);

    if (props.closeOnSelect !== false) {
      handleClose();
    }
  };

  return (
    <div>
      {cloneElement(props.children, {
        'aria-controls': 'drop-down',
        'aria-haspopup': 'true',
        onClick: handleClick,
      })}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.options.map(option => {
          const isDisabled = Boolean(option.checkIfDisabled) && option.checkIfDisabled(props);

          return (
            <Tooltip key={option.key} title={isDisabled ? option.disabledTitle : ''}>
              {/** this is done to in order to display the tooltip when disabled. Refer: https://material-ui.com/components/tooltips/#disabled-elements  */}
              <span style={{ display: 'block' }}>
                <MenuItem
                  onClick={handleSelect(option)}
                  disabled={isDisabled}
                  className={classes.menuItem}
                >
                  <Typography variant="body1">{option.name}</Typography>
                  {option.details && (
                    <Typography variant="caption" component="p">
                      {option.details}
                    </Typography>
                  )}
                </MenuItem>
              </span>
            </Tooltip>
          );
        })}
      </Menu>
    </div>
  );
};
