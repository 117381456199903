/**
 * @file
 *
 * This file contains different mini components that render type, systemCode and userSystem related options for an entity system
 */
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { SelectEditField } from './SelectEditField';
import { ENTITY_TYPES, NODE_MODEL_ATTRS, TEXTFIELD_WIDTH } from '../../utils/constants';

export function EntityAttributeOptions(props) {
  return (
    <Grid item>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Grid container>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="TYPE" />
            </Typography>
          </Grid>
          <Grid container>
            <SelectEditField
              width={TEXTFIELD_WIDTH.LG}
              onChange={props.onChange}
              attr={NODE_MODEL_ATTRS.ENTITY_TYPE}
              value={props.entityTypeValue}
              label="Type"
              options={[ENTITY_TYPES.NONE, ENTITY_TYPES.CLOUD, ENTITY_TYPES.ON_PREMISE]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
