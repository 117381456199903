/**
 * @file
 *
 * Custom hook for updating the dimension as the window dimensions change
 *
 */

import { useEffect, useState } from 'react';

/**
 * This hook is used to get the updated window dimensions when the window is resized
 *
 * @returns {{height: number, width: number}}
 */
export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleDimensions = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleDimensions);
    return () => window.removeEventListener('resize', handleDimensions);
  }, []);

  return dimensions;
};
