/**
 * @file
 *
 * This component renders the dialog to change version
 */
import React, { Fragment } from 'react';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  ListItemIcon,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { MdCheck } from 'react-icons/md';
import clsx from 'clsx';
import { orderBy } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

const id = 'switch-version-dialog';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'inline-block',
  },
  firstRow: {
    width: theme.spacing(15),
  },
  secondRow: {
    width: theme.spacing(25),
  },
  thirdRow: {
    width: theme.spacing(20),
  },
}));

export function SwitchVersionDialog(props) {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="sm" onClose={props.onClose} aria-labelledby={id} open={props.open}>
      <DialogTitle id={id}>
        <FormattedMessage id="SWITCH_VERSION" />
      </DialogTitle>
      <List dense>
        <ListItem>
          <ListItemText
            inset
            disableTypography
            primary={
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item className={clsx(classes.row, classes.firstRow)}>
                  <Typography variant="button" component="span">
                    <FormattedMessage id="VERSION" />
                  </Typography>
                </Grid>
                <Grid item className={clsx(classes.row, classes.secondRow)}>
                  <Typography variant="button" component="span">
                    <FormattedMessage id="COMMENT" />
                  </Typography>
                </Grid>
                <Grid item className={clsx(classes.row, classes.thirdRow)}>
                  <Typography variant="button" component="span">
                    <FormattedMessage id="LAST_UPDATED" />
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </ListItem>
        {orderBy(props.versions, ['key'], ['desc']).map(version => {
          const selected = props.selectedVersion.key === version.key;

          return (
            <Fragment key={version.key}>
              <Divider />
              <ListItem button onClick={props.onSelect(version)} disabled={selected}>
                {selected && (
                  <ListItemIcon>
                    <MdCheck size="18px" />
                  </ListItemIcon>
                )}
                <ListItemText
                  inset={!selected}
                  disableTypography
                  primary={
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item className={clsx(classes.row, classes.firstRow)}>
                        <Typography variant="body2" component="span">
                          {version.name}
                        </Typography>
                      </Grid>
                      <Grid item className={clsx(classes.row, classes.secondRow)}>
                        <Typography variant="subtitle1" component="span">
                          {version.comment}
                        </Typography>
                      </Grid>
                      <Grid item className={clsx(classes.row, classes.thirdRow)}>
                        <Typography variant="body1" component="span">
                          {DateTime.fromISO(version.updatedAt).toFormat('LLL dd yyyy, hh:mm:ss a')}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            </Fragment>
          );
        })}
      </List>
    </Dialog>
  );
}
