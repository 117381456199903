/**
 * @file
 *
 * Contains custom shapes related hooks which can fetch custom shapes from the API.
 */

import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { digitalTransformerApiBase, getServiceInstance } from '../utils/service';
import { useNotifyError } from '../hooks/useNotifyError';

/**
 * Get the custom shapes.
 */
export function useCustomShapes({ enabled }) {
  const { data, ...rest } = useQuery({
    queryKey: `${digitalTransformerApiBase}/custom-shape`,
    enabled,
  });

  const customShapes = useMemo(
    () =>
      (data ?? []).map(customShape => ({
        id: customShape.custom_shape_id,
        name: customShape.name,
        description: customShape.description,
        shapeData: customShape.shape_data,
        icon: customShape.icon,
        createdAt: customShape.created_at,
        updatedAt: customShape.updated_at,
      })),
    [data]
  );

  return {
    customShapes,
    ...rest,
  };
}

const deleteCustomShape = ({ customShapeId }) =>
  getServiceInstance().delete(`${digitalTransformerApiBase}/custom-shape/${customShapeId}`);
export const useDeleteCustomShapeMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(({ customShapeId }) => deleteCustomShape({ customShapeId }), {
    onSuccess: (data, { customShapeId }) => {
      const customShapes = queryClient.getQueryData(`${digitalTransformerApiBase}/custom-shape`);

      const filteredCustomShapes = customShapes.filter(
        customShape => customShape.custom_shape_id !== customShapeId
      );

      queryClient.setQueryData(`${digitalTransformerApiBase}/custom-shape`, filteredCustomShapes);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`${digitalTransformerApiBase}/custom-shape`);
    },
  });

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update custom shape',
  });

  return mutation;
};

const updateCustomShape = ({ customShapeId, values }) =>
  getServiceInstance().put(`${digitalTransformerApiBase}/custom-shape/${customShapeId}`, values);
export const useUpdateCustomShapeMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ customShapeId, values }) => updateCustomShape({ customShapeId, values }),
    {
      onSuccess: (data, { customShapeId }) => {
        const customShapes = queryClient.getQueryData(`${digitalTransformerApiBase}/custom-shape`);

        const updatedCustomShapeIndex = customShapes.findIndex(
          customShape => customShape.custom_shape_id === customShapeId
        );

        customShapes[updatedCustomShapeIndex] = data;

        queryClient.setQueryData(`${digitalTransformerApiBase}/custom-shape`, customShapes);
      },
      onSettled: () => {
        queryClient.invalidateQueries(`${digitalTransformerApiBase}/custom-shape`);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to delete custom shape',
  });

  return mutation;
};

const createCustomShape = ({ values }) =>
  getServiceInstance().post(`${digitalTransformerApiBase}/custom-shape`, values);
export const useCreateCustomShapeMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(({ values }) => createCustomShape({ values }), {
    onSuccess: data => {
      const customShapes = queryClient.getQueryData(`${digitalTransformerApiBase}/custom-shape`);

      queryClient.setQueryData(`${digitalTransformerApiBase}/custom-shape`, [
        data,
        ...customShapes,
      ]);
    },
    onSettled: () => {
      queryClient.invalidateQueries(`${digitalTransformerApiBase}/custom-shape`);
    },
  });

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create custom shape',
  });

  return mutation;
};
