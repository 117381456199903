/**
 * @file
 *
 * This component takes care of linking entity system nodes with user created systems in the platform from the right selection panel
 */

import React, { Fragment, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  Link,
  makeStyles,
  Dialog,
  DialogTitle,
  CircularProgress,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
  MenuItem,
  Button,
  Chip,
  withStyles,
} from '@material-ui/core';
import { useConfirmationDialog } from 'material-ui-confirmation';
import { MdWarning, MdCheckCircle, MdCheck, MdAdd, MdError } from 'react-icons/md';
import { GoGear } from 'react-icons/go';
import { keys, values, isPlainObject, capitalize, orderBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { Formik } from 'formik';
import * as yup from 'yup';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';

import { FormikSelectField, FormikTextField } from '../FormikFields';
import { NODE_MODEL_ATTRS, BASE_SYSTEMS } from '../../utils/constants';
import { useNotifyError } from '../../hooks/useNotifyError';
import { getServiceInstance, systemsApiBase } from '../../utils/service';
import toast from 'react-hot-toast';
import { SystemAvatar } from '../SystemAvatar';
import { useDialog } from '../../hooks/useDialog';
import { useUserSystems } from '../../data/userSystems';
import { useSystems } from '../../data/systems';
import { getUserSystemLinkHref } from '../../utils/helpers';
import { useTenantState } from '../../data/user';

const createUserSystemDialogId = 'createUserSystemDialog';
function CreateUserSystemDialog(props) {
  const { formatMessage } = useIntl();

  const schema = yup.object().shape({
    system: yup
      .string()
      .oneOf(keys(BASE_SYSTEMS))
      .required()
      .label(formatMessage({ id: 'SYSTEM' })),
    name: yup
      .string()
      .required()
      .label('Name'),
    description: yup.string().label(formatMessage({ id: 'DESCRIPTION' })),
  });

  return (
    <Dialog open={props.open} aria-labelledby={createUserSystemDialogId}>
      <DialogTitle id={createUserSystemDialogId}>
        <FormattedMessage id="CREATE_USER_SYSTEM" />
      </DialogTitle>
      <Formik
        validationSchema={schema}
        initialValues={{
          system: props.systemCode || BASE_SYSTEMS.CUSTOM.KEY,
          name: '',
          description: '',
        }}
        onSubmit={props.onSubmit}
      >
        {({ isValid, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormikSelectField disabled name="system" label="System">
                {values(BASE_SYSTEMS).map(({ KEY, NAME }) => (
                  <MenuItem key={KEY} value={KEY}>
                    {NAME}
                  </MenuItem>
                ))}
              </FormikSelectField>
              <FormikTextField name="name" label={formatMessage({ id: 'NAME' })} autoFocus />
              <FormikTextField name="description" label={formatMessage({ id: 'DESCRIPTION' })} />
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                type="submit"
                disabled={!isValid || isSubmitting}
                color="primary"
                variant="contained"
              >
                <FormattedMessage id="CREATE" />
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

const useDialogStyles = makeStyles(theme => ({
  title: {
    position: 'relative',
  },
  floatingButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  row: {
    display: 'inline-block',
  },
  firstRow: {
    width: theme.spacing(12),
  },
  secondRow: {
    width: theme.spacing(25),
  },
  thirdRow: {
    width: theme.spacing(23),
  },
  container: {
    margin: theme.spacing(2, 0),
  },
}));

const userSystemsDialogId = 'userSystemsDialog';
function UserSystemsLinkDialog(props) {
  const classes = useDialogStyles();

  const systems = props.systems.filter(system => system.code === props.systemCode);

  const selectedSystemType = BASE_SYSTEMS[props.systemCode];
  const selectedSystemTypeName = isPlainObject(selectedSystemType) ? selectedSystemType.NAME : '';

  const tenant = useTenantState();
  const canCreateSystems = useMemo(
    () => tenant.permissions.some(permission => permission.permission_code === 'create_system'),
    [tenant.permissions]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={props.onClose}
      aria-labelledby={userSystemsDialogId}
      open={props.open}
    >
      <DialogTitle id={userSystemsDialogId}>
        Select a <strong>{selectedSystemTypeName}</strong> system to link
      </DialogTitle>
      {props.loading && (
        <Grid container justifyContent="center" alignItems="center" className={classes.container}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}

      {!props.loading &&
        (systems.length === 0 ? (
          <Grid container justifyContent="center" alignItems="center" className={classes.container}>
            <Grid item>
              <Typography variant="body1">
                <FormattedMessage id="NO_SYSTEMS_FOUND" /> - {BASE_SYSTEMS[props.systemCode].KEY}
                <br /> <FormattedMessage id="CREATE_SYSTEM_TRY_AGAIN" />
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <List dense>
            <ListItem>
              <ListItemText
                inset
                disableTypography
                primary={
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item className={clsx(classes.row, classes.firstRow)}>
                      <Typography variant="button" component="span">
                        <FormattedMessage id="NAME" />
                      </Typography>
                    </Grid>
                    <Grid item className={clsx(classes.row, classes.secondRow)}>
                      <Typography variant="button" component="span">
                        <FormattedMessage id="DESCRIPTION" />
                      </Typography>
                    </Grid>
                    <Grid item className={clsx(classes.row, classes.thirdRow)}>
                      <Typography variant="button" component="span">
                        <FormattedMessage id="LAST_UPDATED" />
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            {systems.map(system => {
              const selected = props.linkedSystemId === system.id;

              return (
                <Fragment key={system.id}>
                  <Divider />
                  <ListItem button onClick={props.onSelect(system.id)} disabled={selected}>
                    {selected && (
                      <ListItemIcon>
                        <MdCheck size="18px" />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      inset={!selected}
                      disableTypography
                      primary={
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item className={clsx(classes.row, classes.firstRow)}>
                            <Typography variant="body2" component="span">
                              {system.name}
                            </Typography>
                          </Grid>
                          <Grid item className={clsx(classes.row, classes.secondRow)}>
                            <Typography variant="subtitle1" component="span">
                              {system.description}
                            </Typography>
                          </Grid>
                          <Grid item className={clsx(classes.row, classes.thirdRow)}>
                            <Typography variant="body1" component="span">
                              {DateTime.fromISO(system.updatedAt).toFormat(
                                'LLL dd yyyy, hh:mm:ss a'
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        ))}
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={props.openCreateUserSystemDialog}
          startIcon={<MdAdd />}
          disabled={!canCreateSystems}
        >
          <FormattedMessage id="CREATE_NEW_SYSTEM" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  systemCard: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    border: `solid 1px rgba(0,0,0, 0.3)`,
  },
  systemCardLogo: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  systemIcon: {
    width: '100%',
    height: '100%',
  },
  systemName: {
    width: '80%',
  },
  systemCardDesc: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  systemConfigs: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0.8),
    borderRadius: theme.spacing(0.4),
    width: '47%',
    border: `solid 1px ${theme.palette.warning.main}`,
    color: theme.palette.warning.main,
  },
  editSystem: {
    marginLeft: theme.spacing(1),
  },
  link: {
    width: '100%',
    marginTop: theme.spacing(1.5),
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  textGray: {
    color: theme.palette.grey[600],
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  loader: {
    marginTop: theme.spacing(6),
  },
}));

const CustomChip = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  icon: {
    fontSize: '15px',
  },
}))(Chip);

const useConfigStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main,
  },
  successBorder: {
    border: `solid 1px ${theme.palette.success.main}`,
  },
  failure: {
    color: theme.palette.error.main,
  },
  failureBorder: {
    border: `solid 1px ${theme.palette.error.main}`,
  },
  warningBorder: {
    border: `solid 1px ${theme.palette.warning.main}`,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}));

const getChipProps = (configStyles, status) => {
  if (status === 'complete') {
    return {
      className: configStyles.successBorder,
      icon: <MdCheckCircle className={configStyles.success} />,
    };
  } else if (status === 'failed') {
    return {
      className: configStyles.failureBorder,
      icon: <MdError className={configStyles.failure} />,
    };
  }

  return {
    className: configStyles.warningBorder,
    icon: <MdWarning className={configStyles.warning} />,
  };
};
const Configurations = ({ type, systemConfigs }) => {
  const configStyles = useConfigStyles();

  const systemConfig = systemConfigs.find(
    config => config.system_config_type === type.system_config_type_code
  );

  return (
    <CustomChip
      variant="outlined"
      color="default"
      {...getChipProps(configStyles, systemConfig?.state)}
      label={capitalize(type.configuration_type)}
    />
  );
};

const SystemCard = ({ systemsData, userSystem, userSystemId }) => {
  const classes = useStyles();

  const selectedSystem = systemsData.find(system => system.code === userSystem.code);

  // This logic sorts the systems in a descending order with respect to the 'created_at' property
  // so that the latest configuration status is on the top
  const sortedSystemConfigs = orderBy(
    userSystem.systemConfigurations,
    systemStatus => new Date(systemStatus.created_at),
    ['desc']
  );

  return (
    <Grid container direction="column" alignContent="flex-end" className={classes.systemCard}>
      <Grid container justifyContent="flex-end">
        <Grid container direction="column">
          <Grid item container alignItems="center" direction="row">
            <Grid item className={classes.systemName}>
              <Typography color="textPrimary" variant="body1">
                {userSystem.name || userSystemId}
              </Typography>
            </Grid>
            <Grid item className={classes.systemCardLogo} justifyContent="flex-start" container>
              <SystemAvatar system={userSystem.code} />
            </Grid>
          </Grid>

          <Grid item className={classes.systemCardDesc} container>
            <Typography variant="body1">{userSystem.description || ''}</Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent={
              selectedSystem?.systemConfigTypes.length > 1 ? 'space-between' : 'center'
            }
            className={classes.topMargin}
          >
            {selectedSystem?.systemConfigTypes.map(type => (
              <Configurations
                key={type.configuration_type}
                type={type}
                systemConfigs={sortedSystemConfigs}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item container justifyContent="flex-end" className={classes.topMargin}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<GoGear />}
            size="small"
            component={Link}
            underline="none"
            href={getUserSystemLinkHref(userSystemId)}
            target="_blank"
            className={classes.editSystem}
          >
            <FormattedMessage id="RECONFIGURE" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export function UserSystemLinkOption(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { userSystems, isLoading, error, refetch: refetchUserSystems } = useUserSystems();
  useNotifyError({ error });

  const { systems: systemsData, error: systemsError, isFetching: isSystemsFetching } = useSystems();
  useNotifyError({ error: systemsError });

  const onOpenCallback = () => {
    refetchUserSystems();
  };

  const { isDialogOpen, openDialog, closeDialog } = useDialog(onOpenCallback);

  const [isCreateUserSystemUserOpen, updateCreateDialogSystemDialogState] = useState(false);
  const openCreateUserSystemDialog = () => {
    closeDialog();
    updateCreateDialogSystemDialogState(true);
  };
  const closeCreateUserSystemDialog = () => {
    updateCreateDialogSystemDialogState(false);
  };

  const handleCreateNewSystem = (values, actions) => {
    getServiceInstance()
      .post(`${systemsApiBase}/user-system`, values)
      .then(response => {
        refetchUserSystems();

        closeCreateUserSystemDialog();

        openDialog();
      })
      .catch(() => {
        toast.error(formatMessage({ id: 'FAIL_CREATE_SYSTEM' }), {
          id: 'create-system-failure',
        });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const systemCode =
    props.selectedData[NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE] || BASE_SYSTEMS.CUSTOM.KEY;
  const userSystemId = props.selectedData[NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID];
  const userSystem = userSystemId && userSystems?.find(system => system.id === userSystemId);

  const { getConfirmation } = useConfirmationDialog();
  const unlinkSystem = () => {
    getConfirmation({
      title: formatMessage({ id: 'UNLINK_SYSTEM' }),
      body: (
        <DialogContentText>
          <FormattedMessage id="UNLINK_SYSTEM_CONFIRMATION" />
        </DialogContentText>
      ),
      acceptButtonProps: {
        variant: 'contained',
        color: 'primary',
      },
      onAccept: () => {
        props.onChange({ attr: NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID, value: undefined });
      },
    });
  };

  const linkSystem = systemId => () => {
    closeDialog();

    props.onChange({ attr: NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID, value: systemId });
  };

  return (
    <>
      <Grid item className={classes.container}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage id="LINKED_SYSTEM" />
            </Typography>
          </Grid>
          {isSystemsFetching && (
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className={classes.loader}
            >
              <CircularProgress />
            </Grid>
          )}
          {!isSystemsFetching &&
            (userSystemId && userSystem && systemsData ? (
              <>
                <Grid item className={classes.container} container>
                  {userSystemId && userSystem && systemsData && (
                    <Grid item>
                      <SystemCard
                        userSystem={userSystem}
                        systemsData={systemsData}
                        userSystemId={userSystemId}
                      />
                    </Grid>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    className={classes.link}
                    onClick={unlinkSystem}
                  >
                    <FormattedMessage id="UNLINK" />
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  className={classes.link}
                  onClick={openDialog}
                >
                  <FormattedMessage id="LINK_SYSTEM" />
                </Button>
              </>
            ))}
        </Grid>
      </Grid>

      <UserSystemsLinkDialog
        open={isDialogOpen}
        onClose={closeDialog}
        openCreateUserSystemDialog={openCreateUserSystemDialog}
        onSelect={linkSystem}
        loading={isLoading}
        systems={userSystems ?? []}
        systemCode={systemCode}
        linkedSystemId={userSystemId}
      />
      <CreateUserSystemDialog
        systemCode={systemCode}
        open={isCreateUserSystemUserOpen}
        onClose={closeCreateUserSystemDialog}
        onSubmit={handleCreateNewSystem}
      />
    </>
  );
}
