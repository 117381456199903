/**
 * @file
 *
 * This file contains the form to edit the entity fields in a system component
 */

import React, { useRef } from 'react';
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  makeStyles,
  Button,
} from '@material-ui/core';
import { MdAdd, MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import {
  ENTITY_FIELD_FORM_CHANGE_TYPE,
  GROUP_NODES,
  MODEL_NODES,
  NODE_DERIVED_ATTRS,
  NODE_MODEL_ATTRS,
  STROKE_TYPES,
} from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  gutterTop: {
    marginTop: theme.spacing(1),
  },
  button: {
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(1, 0, 5),
    boxShadow: 'none',
  },
}));

/**
 *
 * @param {Object} selectedData
 * @param {Object[]} nodeDataArray
 * @param {go.Diagram} diagram
 */
function getFields(selectedData, nodeDataArray, diagram) {
  const fieldGroupKeys =
    selectedData.category === GROUP_NODES.ENTITY_GROUP
      ? nodeDataArray
          .filter(
            nodeData =>
              nodeData.category === GROUP_NODES.FIELDS_GROUP && nodeData.group === selectedData.key
          )
          .map(fieldGroup => fieldGroup.key)
      : [];

  const positionMap = new Map();
  const fields = nodeDataArray.reduce((acc, nodeData) => {
    if (nodeData.category === MODEL_NODES.FIELD) {
      if (nodeData.group === selectedData.key || fieldGroupKeys.includes(nodeData.group)) {
        const key = nodeData.key;
        const renderedNode = diagram.findNodeForKey(key);

        if (renderedNode) {
          positionMap.set(key, renderedNode.location.y);
          acc.push(nodeData);
        }
      }
    }

    return acc;
  }, []);
  fields.sort((a, b) => {
    if (positionMap.get(a.key) < positionMap.get(b.key)) {
      return -1;
    } else if (positionMap.get(a.key) > positionMap.get(b.key)) {
      return 1;
    }
    return 0;
  });

  return fields;
}

export function EntityFieldForm(props) {
  const classes = useStyles();

  const fields = getFields(props.selectedData, props.nodeDataArray, props.diagram);

  const textFieldRef = useRef();

  const updateField = fieldKey => event => {
    props.onChange({
      attr: NODE_DERIVED_ATTRS.FIELDS,
      value: event.target.value,
      fieldKey,
      type: ENTITY_FIELD_FORM_CHANGE_TYPE.UPDATE,
    });
  };

  const insertField = () => {
    /**
     * @type {go.Diagram}
     */
    const diagram = props.diagram;

    const txnIdentifier = 'add a field node';
    diagram.startTransaction(txnIdentifier);
    diagram.model.addNodeData({
      [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.FIELD,
      [NODE_MODEL_ATTRS.TEXT]: '',
      [NODE_MODEL_ATTRS.GROUP]: props.selectedData.key,
      [NODE_MODEL_ATTRS.STROKE_TYPE]: STROKE_TYPES.SOLID.KEY,
    });
    diagram.commitTransaction(txnIdentifier);

    props.onChange({
      attr: NODE_DERIVED_ATTRS.FIELDS,
      type: ENTITY_FIELD_FORM_CHANGE_TYPE.INSERT,
    });

    setTimeout(() => {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    });
  };

  const deleteField = fieldKey => () => {
    props.onChange({
      attr: NODE_DERIVED_ATTRS.FIELDS,
      type: ENTITY_FIELD_FORM_CHANGE_TYPE.DELETE,
      fieldKey,
    });
  };

  return (
    <Grid
      className={classes.gutterTop}
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Typography color="textSecondary" variant="body2">
          <FormattedMessage id="FIELDS" />
        </Typography>
      </Grid>
      {fields.map(({ text, key }, index) => (
        <TextField
          key={key}
          value={text}
          placeholder={'Field Name'}
          type="text"
          margin="dense"
          autoComplete=""
          {...(index + 1 === fields.length && { inputRef: textFieldRef })}
          variant="outlined"
          fullWidth
          onChange={updateField(key)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ width: '30px' }}>
                <IconButton color="default" onClick={deleteField(key)}>
                  <MdDelete size="18px" />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              height: '35px',
            },
          }}
        />
      ))}
      <Button
        fullWidth
        className={classes.button}
        variant="contained"
        color="primary"
        disableElevation
        startIcon={<MdAdd />}
        onClick={insertField}
      >
        <FormattedMessage id="ADD" />
      </Button>
    </Grid>
  );
}
