/**
 * @file
 *
 * This is the main file where we subscribe to the project state
 * to render everything in the project details view
 */

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid, CircularProgress } from '@material-ui/core';
import { Redirect, Prompt, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import qs from 'query-string';
import { entries, flatten } from 'lodash-es';
import * as go from 'gojs';
import isHotkey from 'is-hotkey';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';

import { Nav } from '../components/Nav';
import { UserAvatar } from '../components/UserAvatar';
import { initDiagram } from '../models';
import { Editor } from '../components/Editor';
import { modelDataForPalette, highlightNode } from '../components/Palette';
import {
  MODEL_NODES,
  GROUP_NODES,
  NODE_MODEL_ATTRS,
  ENTITY_TYPES,
  STROKE_TYPES,
  DEFAULTS,
  BASE_SYSTEMS,
  UI_DIMENSIONS,
  DRAWER_TYPES,
} from '../utils/constants';
import { uniqueKeyGenerator } from '../models/helpers';
import { ContextMenu } from '../components/ContextMenu';
import { getServiceInstance, digitalTransformerApiBase } from '../utils/service';
import { ProjectFormDialog } from '../components/ProjectFormDialog';
import { CreateVersionFormDialog } from '../components/CreateVersionDialog';
import { SwitchVersionDialog } from '../components/SwitchVersionDialog';
import { RightSidebar } from '../components/RightSidebar';
import { deferredUpdateSuggestedConnections } from '../data/mapperTemplates';
import { EditorMenu } from '../components/EditorMenu';
import { LeftSideBar } from '../components/LeftSideBar';
import { SheetsPanel } from '../components/SheetsPanel';
import { calculateLocationPoint, calculateNewLocationForNode } from '../utils/helpers';
import { useCustomShapes } from '../data/customShapes';
import { useMapperProjects } from '../data/mapperProjects';
import { useTenantState } from '../data/user';
import { useInitialLoadNormal } from '../hooks/useInitialLoadChecks';
import {
  dirtySheets,
  sheetStateActions,
  useProjectSheets,
  useProjectStore,
  useProjectWithSheetsMutation,
  useUpdateProjectDetailsMutation,
} from '../data/projects';
import { mapperTemplateActions, useMapperTemplates } from '../data/mapperTemplates';
import { useDialog } from '../hooks/useDialog';

const isSaveEvent = isHotkey('mod+s');

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
  },
  drawerPaper: {
    overflowX: 'hidden',
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    padding: theme.spacing(8, 2, 4, 2),
    margin: theme.spacing(5.5, 0, 0, UI_DIMENSIONS.SIDEBAR_WIDTH),
  },
  root: {
    width: '100%',
    height: '100%',
  },
  main: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(20),
  },
  buttonGroup: {
    boxShadow: theme.shadows[0],
  },
  button: {
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: 'none',
    height: theme.spacing(4),
  },
  sideMargin: {
    margin: theme.spacing(0, 1),
  },
  projectTitle: {
    marginLeft: theme.spacing(1),
  },
  menuRightPanel: {
    marginRight: theme.spacing(3),
  },
  content: {
    height: '100%',
    width: '100%',
    position: 'relative',
    paddingTop: theme.spacing(11.5),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH + 2),
    paddingLeft: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH + UI_DIMENSIONS.SIDEBAR_WIDTH + 2),
    background: theme.palette.grey[100],
  },
  contentExpandedLeft: {
    paddingLeft: theme.spacing(UI_DIMENSIONS.SIDEBAR_WIDTH + 2),
  },
  contentExpandedRight: {
    paddingRight: theme.spacing(2),
  },
  contentAnalyseMode: {
    padding: theme.spacing(13.5, 2, 0, 2),
  },

  versionMenu: {
    marginRight: theme.spacing(-0.5),
    marginLeft: theme.spacing(0),
  },
  createVersionButton: {
    marginLeft: theme.spacing(-0.5),
  },
  floatingButton: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: theme.shadows[0],
    bottom: theme.spacing(1),
  },
  paletteToggleButton: {
    transform: 'rotate(-30deg)',
  },
  paletteOpen: {
    left: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH + 1),
  },
  paletteClose: {
    left: theme.spacing(1),
  },
  selectionPanelToggleButton: {
    transform: 'rotate(30deg)',
  },
  selectionPanelOpen: {
    right: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH + 1),
  },
  selectionPanelClose: {
    right: theme.spacing(2),
  },
}));

/**
 * get prompt before route change if unsaved changes are present
 */
const handleRouteChange = location => {
  const { pathname, search } = location;

  if (pathname && pathname.includes('/project/') && search && search.startsWith('?sheet=')) {
    return true;
  }

  const sheetNames = [...dirtySheets.entries()].map(([_, v]) => v);

  return `Are you sure? Unsaved changes in the following sheet${
    sheetNames.length >= 1 ? 's' : ''
  } will be discarded -\n${sheetNames.join('\n')}`;
};

/**
 * get before unload message prompt if unsaved changes are present
 *
 * @param {BeforeUnloadEvent} event
 */
const handleBeforeUnloadRaw = event => {
  if (dirtySheets.size) {
    event.preventDefault();
    event.returnValue = '';
  } else {
    delete event.returnValue;
  }
};

// we do this so that the main sheet is always first and cannot be deleted
// and we can't rely on JS objects to maintain their key order
const getProjectSheets = projectSheets =>
  entries(projectSheets).reduce((acc, [key, { name, main, nodeDataArray, syncState }]) => {
    if (main) {
      acc.unshift({ key, name, main, empty: !nodeDataArray.length, syncState });
    } else {
      acc.push({ key, name, main, empty: !nodeDataArray.length, syncState });
    }

    return acc;
  }, []);

const getSheetState = (project, sheetKey) => project && project.sheets[sheetKey];

const useDiagram = () => {
  const diagramInstance = useRef(null);

  const updateCanvasSizing = useCallback(() => {
    if (diagramInstance.current) {
      /**
       * @type {go.Diagram}
       */
      const diagram = diagramInstance.current;

      // run the side effects in the next tick
      setTimeout(() => {
        // imperitavely request the diagram canvas to update since the parent DOM has changed dimensions
        diagram.requestUpdate();

        // bring the diagram to focus for better UX
        diagram.focus();
      }, 0);
    }
  }, [diagramInstance]);

  // this bit of state is to forcefully update a component in case of a diagramInstance only update (which is mutable ref for perf reason)
  const [diagramInstanceUpdateCount, updateDiagramInstanceUpdateCount] = useState(0);

  const updateDiagramInstance = useCallback(
    diagram => {
      diagramInstance.current = diagram;
      updateDiagramInstanceUpdateCount(diagramInstanceUpdateCount + 1);
    },
    [diagramInstanceUpdateCount, diagramInstance]
  );

  return { diagramInstance, updateCanvasSizing, diagramInstanceUpdateCount, updateDiagramInstance };
};

export const useSelectionPanel = updateCanvasSizing => {
  const [isRightDrawerOpen, updateRightDrawerState] = useState(true);

  const toggleRightDrawer = useCallback(() => {
    updateRightDrawerState(!isRightDrawerOpen);

    updateCanvasSizing();
  }, [isRightDrawerOpen, updateRightDrawerState, updateCanvasSizing]);

  return { isRightDrawerOpen, toggleRightDrawer, updateRightDrawerState };
};

export const usePalette = (diagramInstance, updateCanvasSizing, customShapes) => {
  const draggedInstance = useRef(null);

  /**
   * handler for drop event on the canvas container
   */
  const handleDropFromPalette = useCallback(
    event => {
      event.preventDefault();

      /**
       * @type {go.Diagram}
       */
      const diagram = diagramInstance.current;

      if (!draggedInstance.current) {
        return;
      }

      const { offsetX, offsetY } = draggedInstance.current;
      const { x, y } = calculateLocationPoint(diagram, event);
      const point = diagram.transformViewToDoc(new go.Point(x - offsetX, y - offsetY));

      let groupKey;
      const node = diagram.findPartAt(point, false);
      if (node instanceof go.Group && !node.data[NODE_MODEL_ATTRS.CATEGORY]) {
        groupKey = node.data.key;
      } else if (node instanceof go.Node) {
        const group = node.containingGroup;
        if (group !== null && !node.data[NODE_MODEL_ATTRS.CATEGORY]) {
          groupKey = group.data.key;
        }
      }

      let transferredData;

      try {
        transferredData = JSON.parse(event.dataTransfer.getData('nodeData'));
      } catch (err) {
        return;
      }

      if (transferredData.custom) {
        const initialEntityGroupCoords = transferredData[NODE_MODEL_ATTRS.LOCATION];

        if (transferredData.category === GROUP_NODES.ENTITY_GROUP) {
          const txnIdentifier = 'create a new custom entity group';
          diagram.startTransaction(txnIdentifier);

          const entityGroupId = uniqueKeyGenerator('node')(diagram.model);

          const fieldGroupNodesWithFields = entries(transferredData.fieldGroups).map(
            ([fieldGroupTitle, values]) => {
              const fieldGroupNode = {
                [NODE_MODEL_ATTRS.TITLE]: fieldGroupTitle,
                [NODE_MODEL_ATTRS.CATEGORY]: GROUP_NODES.FIELDS_GROUP,
                [NODE_MODEL_ATTRS.COLOR]: values[NODE_MODEL_ATTRS.COLOR],
                [NODE_MODEL_ATTRS.IS_GROUP]: true,
                [NODE_MODEL_ATTRS.LOCATION]: calculateNewLocationForNode(
                  values[NODE_MODEL_ATTRS.LOCATION],
                  initialEntityGroupCoords,
                  point
                ),
                [NODE_MODEL_ATTRS.GROUP]: entityGroupId,
                [NODE_MODEL_ATTRS.KEY]: uniqueKeyGenerator('node')(diagram.model),
                [NODE_MODEL_ATTRS.STROKE_TYPE]: values[NODE_MODEL_ATTRS.STROKE_TYPE],
              };

              const fieldNodes = values.fields.map(field => ({
                [NODE_MODEL_ATTRS.TEXT]: field[NODE_MODEL_ATTRS.TEXT],
                [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.FIELD,
                [NODE_MODEL_ATTRS.KEY]: uniqueKeyGenerator('node')(diagram.model),
                [NODE_MODEL_ATTRS.GROUP]: fieldGroupNode[NODE_MODEL_ATTRS.KEY],
                [NODE_MODEL_ATTRS.LOCATION]: calculateNewLocationForNode(
                  field[NODE_MODEL_ATTRS.LOCATION],
                  initialEntityGroupCoords,
                  point
                ),
                [NODE_MODEL_ATTRS.SIZE]: field[NODE_MODEL_ATTRS.SIZE],
                [NODE_MODEL_ATTRS.STROKE_TYPE]: field[NODE_MODEL_ATTRS.STROKE_TYPE],
              }));

              const spacers = values.spacers?.map(spacer => ({
                [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.SPACER,
                [NODE_MODEL_ATTRS.GROUP]: fieldGroupNode[NODE_MODEL_ATTRS.KEY],
                [NODE_MODEL_ATTRS.LOCATION]: calculateNewLocationForNode(
                  spacer[NODE_MODEL_ATTRS.LOCATION],
                  initialEntityGroupCoords,
                  point
                ),
                [NODE_MODEL_ATTRS.KEY]: uniqueKeyGenerator('node')(diagram.model),
                [NODE_MODEL_ATTRS.SIZE]: spacer[NODE_MODEL_ATTRS.SIZE],
              }));

              return [fieldGroupNode, ...fieldNodes, ...spacers];
            }
          );

          const ungroupedFields =
            transferredData.fields?.map(field => ({
              [NODE_MODEL_ATTRS.TEXT]: field[NODE_MODEL_ATTRS.TEXT],
              [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.FIELD,
              [NODE_MODEL_ATTRS.GROUP]: entityGroupId,
              [NODE_MODEL_ATTRS.LOCATION]: calculateNewLocationForNode(
                field[NODE_MODEL_ATTRS.LOCATION],
                initialEntityGroupCoords,
                point
              ),
              [NODE_MODEL_ATTRS.KEY]: uniqueKeyGenerator('node')(diagram.model),
              [NODE_MODEL_ATTRS.SIZE]: field[NODE_MODEL_ATTRS.SIZE],
              [NODE_MODEL_ATTRS.STROKE_TYPE]: field[NODE_MODEL_ATTRS.STROKE_TYPE],
            })) ?? [];

          const ungroupedSpacers =
            transferredData.spacers?.map(spacer => ({
              [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.SPACER,
              [NODE_MODEL_ATTRS.GROUP]: entityGroupId,
              [NODE_MODEL_ATTRS.LOCATION]: calculateNewLocationForNode(
                spacer[NODE_MODEL_ATTRS.LOCATION],
                initialEntityGroupCoords,
                point
              ),
              [NODE_MODEL_ATTRS.KEY]: uniqueKeyGenerator('node')(diagram.model),
              [NODE_MODEL_ATTRS.SIZE]: spacer[NODE_MODEL_ATTRS.SIZE],
            })) ?? [];

          const nodes = [
            {
              [NODE_MODEL_ATTRS.TITLE]: transferredData[NODE_MODEL_ATTRS.TITLE],
              [NODE_MODEL_ATTRS.CATEGORY]: GROUP_NODES.ENTITY_GROUP,
              [NODE_MODEL_ATTRS.COLOR]: transferredData[NODE_MODEL_ATTRS.COLOR],
              [NODE_MODEL_ATTRS.IS_GROUP]: true,
              [NODE_MODEL_ATTRS.KEY]: entityGroupId,
              [NODE_MODEL_ATTRS.LOCATION]: go.Point.stringify(point),
              [NODE_MODEL_ATTRS.ENTITY_TYPE]:
                transferredData[NODE_MODEL_ATTRS.ENTITY_TYPE] || ENTITY_TYPES.NONE,
              [NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE]:
                transferredData[NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE],
              [NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE_LOCK]: Boolean(
                transferredData[NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE]
              ),
              [NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID]:
                transferredData[NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID],
              [NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED]:
                transferredData[NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED],
            },
            ...flatten(fieldGroupNodesWithFields),
            ...ungroupedFields,
            ...ungroupedSpacers,
          ];
          diagram.model.addNodeDataCollection(nodes);
          diagram.commitTransaction(txnIdentifier);
        } else {
          const txnIdentifier = 'create a new custom simple node';
          diagram.startTransaction(txnIdentifier);
          const nodeData = {
            ...transferredData,
            [NODE_MODEL_ATTRS.LOCATION]: go.Point.stringify(point),
            ...(groupKey && { [NODE_MODEL_ATTRS.GROUP]: groupKey }),
          };
          diagram.model.addNodeData(nodeData);
          diagram.commitTransaction(txnIdentifier);
        }
      } else {
        if (transferredData.category === GROUP_NODES.ENTITY_GROUP) {
          const txnIdentifier = 'create a new  entity group';
          diagram.startTransaction(txnIdentifier);

          const entityGroupId = uniqueKeyGenerator('node')(diagram.model);

          const getFieldText = field => (typeof field === 'string' ? field : field.text);
          const getFieldStroke = field => field.stroke || DEFAULTS.STROKE_TYPE;

          const fieldGroupNodesWithFields = entries(transferredData.fieldGroups).map(
            ([fieldGroupTitle, fields]) => {
              const fieldGroupNode = {
                [NODE_MODEL_ATTRS.TITLE]: fieldGroupTitle,
                [NODE_MODEL_ATTRS.CATEGORY]: GROUP_NODES.FIELDS_GROUP,
                [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.FIELDS_GROUP_COLOR,
                [NODE_MODEL_ATTRS.IS_GROUP]: true,
                [NODE_MODEL_ATTRS.GROUP]: entityGroupId,
                [NODE_MODEL_ATTRS.KEY]: uniqueKeyGenerator('node')(diagram.model),
                [NODE_MODEL_ATTRS.STROKE_TYPE]: STROKE_TYPES.SOLID.KEY,
              };

              const fieldNodes = fields.map(field => ({
                [NODE_MODEL_ATTRS.TEXT]: getFieldText(field),
                [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.FIELD,
                [NODE_MODEL_ATTRS.GROUP]: fieldGroupNode[NODE_MODEL_ATTRS.KEY],
                [NODE_MODEL_ATTRS.SIZE]: go.Size.stringify(new go.Size(240, 60)),
                [NODE_MODEL_ATTRS.STROKE_TYPE]: getFieldStroke(field),
              }));

              return [fieldGroupNode, ...fieldNodes];
            }
          );

          const ungroupedFields = transferredData.fields
            ? transferredData.fields.map(field => ({
                [NODE_MODEL_ATTRS.TEXT]: getFieldText(field),
                [NODE_MODEL_ATTRS.CATEGORY]: MODEL_NODES.FIELD,
                [NODE_MODEL_ATTRS.GROUP]: entityGroupId,
                [NODE_MODEL_ATTRS.SIZE]: go.Size.stringify(new go.Size(260, 60)),
                [NODE_MODEL_ATTRS.STROKE_TYPE]: getFieldStroke(field),
              }))
            : [];

          const nodes = [
            {
              [NODE_MODEL_ATTRS.TITLE]: transferredData.entityGroupTitle,
              [NODE_MODEL_ATTRS.CATEGORY]: GROUP_NODES.ENTITY_GROUP,
              [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.ENTITY_GROUP_COLOR,
              [NODE_MODEL_ATTRS.IS_GROUP]: true,
              [NODE_MODEL_ATTRS.KEY]: entityGroupId,
              [NODE_MODEL_ATTRS.LOCATION]: go.Point.stringify(point),
              [NODE_MODEL_ATTRS.ENTITY_TYPE]: transferredData.type || ENTITY_TYPES.NONE,
              [NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE]:
                transferredData.systemCode || BASE_SYSTEMS.CUSTOM.key,
              [NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE_LOCK]: Boolean(transferredData.systemCodeLock),
              [NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED]:
                transferredData[NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED],
            },
            ...flatten(fieldGroupNodesWithFields),
            ...ungroupedFields,
          ];
          diagram.model.addNodeDataCollection(nodes);
          diagram.commitTransaction(txnIdentifier);
        } else {
          const txnIdentifier = 'create a new simple node';
          diagram.startTransaction(txnIdentifier);
          const nodeData = {
            ...transferredData,
            [NODE_MODEL_ATTRS.LOCATION]: go.Point.stringify(point),
            ...(groupKey && { [NODE_MODEL_ATTRS.GROUP]: groupKey }),
          };

          diagram.model.addNodeData(nodeData);
          diagram.commitTransaction(txnIdentifier);
        }
      }

      diagram.focus();
    },
    [diagramInstance]
  );

  const handleDragEndForPalette = useCallback(() => {
    highlightNode(diagramInstance.current, null);
  }, [diagramInstance]);

  useEffect(() => {
    document.addEventListener('dragend', handleDragEndForPalette);

    return () => {
      document.removeEventListener('dragend', handleDragEndForPalette);
    };
    // eslint-disable-next-line
  }, []);

  /**
   * Event handler for when we start to drag a node from the palette
   *
   * @param {React.SyntheticEvent<HTMLDivElement, React.DragEvent>} event
   */
  const handleDragStartForPalette = useCallback(
    event => {
      const target = event.target;

      let transferData;
      if (modelDataForPalette[target.dataset.dragkey]) {
        transferData = modelDataForPalette[target.dataset.dragkey];
      } else {
        transferData = customShapes.find(shape => shape.id === target.dataset.dragkey).shapeData;
      }

      event.nativeEvent.dataTransfer.setData('nodeData', JSON.stringify(transferData));

      draggedInstance.current = {
        target,
        offsetX: event.nativeEvent.offsetX - target.clientWidth / 2,
        offsetY: event.nativeEvent.offsetY - target.clientHeight / 2,
      };
    },
    [draggedInstance, customShapes]
  );

  const [isLeftDrawerOpen, updateLeftDrawerState] = useState({
    [DRAWER_TYPES.SYSTEM]: false,
    [DRAWER_TYPES.INPUT_COMPONENTS]: false,
  });

  const toggleLeftDrawer = useCallback(
    openDrawer => {
      updateLeftDrawerState({ [openDrawer]: !isLeftDrawerOpen[openDrawer] });

      updateCanvasSizing();
    },
    [isLeftDrawerOpen, updateLeftDrawerState, updateCanvasSizing]
  );

  return {
    isLeftDrawerOpen,
    toggleLeftDrawer,
    handleDragStartForPalette,
    handleDragEndForPalette,
    handleDropFromPalette,
    updateLeftDrawerState,
    draggedInstance,
  };
};

const useVersion = (projectId, fetchProjectWithSheetsMutation) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const {
    isDialogOpen: isVersionDialogOpen,
    openDialog: openVersionDialog,
    closeDialog: closeVersionDialog,
  } = useDialog();

  const handleCreateVersion = useCallback(
    (values, actions) => {
      getServiceInstance()
        .post(`${digitalTransformerApiBase}/project/${projectId}`, {
          version_comment: values.comment,
        })
        .then(async response => {
          closeVersionDialog();

          const version = response.digital_transformer_project_version;

          await fetchProjectWithSheetsMutation.mutateAsync({ projectId, version });

          const { tenant_id } = useTenantState.get() ?? {};

          history.push(`/t/${tenant_id}/project/${projectId}?version=${version}`);
        })
        .catch(() => {
          toast.error(formatMessage({ id: 'FAIL_CREATE_VERSION' }), {
            id: 'create-version-failure',
          });

          actions.setSubmitting(false);
        });
    },
    [closeVersionDialog, fetchProjectWithSheetsMutation, formatMessage, history, projectId]
  );

  return {
    isVersionDialogOpen,
    openVersionDialog,
    closeVersionDialog,
    handleCreateVersion,
  };
};

export function Project() {
  useInitialLoadNormal();

  const tenant = useTenantState();

  const { pathname, search } = useLocation();

  const projectId = pathname.split('/').pop();
  const sheetKey = qs.parse(search).sheet;
  let version = parseInt(qs.parse(search).version, 10);
  version = Number.isInteger(version) && version > 0 ? version : undefined;

  const project = useProjectStore(state => state.projects[projectId]);
  const { refetchProjectSheets } = useProjectSheets({
    projectId,
    version,
    enabled: Boolean(tenant) && Boolean(project),
  });
  const isProjectLoading = project?.loading ?? true;

  const { formatMessage } = useIntl();
  const classes = useStyles();

  const fetchProjectWithSheetsMutation = useProjectWithSheetsMutation();

  const editorDropDownMenuOptions = {
    switchVersion: {
      key: 'switchVersion',
      name: formatMessage({ id: 'SWITCH_VERSION' }),
    },
    createVersion: {
      key: 'createVersion',
      name: formatMessage({ id: 'CREATE_NEW_VERSION' }),
      disabledTitle: formatMessage({ id: 'SAVE_CURRENT_CHANGES' }),
      checkIfDisabled: props => props.isProjectDirty,
    },
    editProject: {
      key: 'editProject',
      name: formatMessage({ id: 'EDIT_PROJECT_DETAILS' }),
    },
  };

  const { customShapes } = useCustomShapes({ enabled: Boolean(tenant) });

  useMapperProjects({ enabled: Boolean(tenant) });
  useMapperTemplates({ enabled: Boolean(tenant) });

  const { diagramInstance, updateCanvasSizing, updateDiagramInstance } = useDiagram();

  const {
    isLeftDrawerOpen,
    toggleLeftDrawer,
    handleDragStartForPalette,
    handleDropFromPalette,
    updateLeftDrawerState,
    draggedInstance,
  } = usePalette(diagramInstance, updateCanvasSizing, customShapes);

  const {
    isVersionDialogOpen,
    openVersionDialog,
    closeVersionDialog,
    handleCreateVersion,
  } = useVersion(projectId, fetchProjectWithSheetsMutation);
  const history = useHistory();

  const { isRightDrawerOpen, toggleRightDrawer, updateRightDrawerState } = useSelectionPanel(
    updateCanvasSizing
  );

  // clear the dirty sheet cache when project or version changes
  useEffect(() => {
    dirtySheets.clear();
  }, [projectId, version]);

  const sheetState = getSheetState(project, sheetKey);

  const sheetName = sheetState && sheetState.name;

  const mapNodeKeyIndex = useRef(new Map());
  const mapLinkKeyIndex = useRef(new Map());

  const [showContextMenu, updateContextMenuState] = useState(false);

  const [isEditProjectDialogOpen, updateEditProjectDialogState] = useState(false);
  const openEditProjectDialog = () => {
    updateEditProjectDialogState(true);
  };
  const closeEditProjectDialog = () => {
    updateEditProjectDialogState(false);
  };

  const updateProjectDetailsMutation = useUpdateProjectDetailsMutation();

  const handleEditProject = async values => {
    await updateProjectDetailsMutation.mutateAsync({ projectId, version, values });
    closeEditProjectDialog();
  };

  const [isSwitchVersionDialogOpen, updateSwitchVersionDialogState] = useState(false);
  const openSwitchVersionDialog = () => {
    updateSwitchVersionDialogState(true);
  };
  const closeSwitchVersionDialog = () => {
    updateSwitchVersionDialogState(false);
  };
  const handleVersionSwitch = version => async () => {
    closeSwitchVersionDialog();

    const { tenant_id } = useTenantState.get() ?? {};

    await fetchProjectWithSheetsMutation.mutateAsync({ projectId, version: version.key });
    history.push(`/t/${tenant_id}/project/${projectId}?version=${version}`);
  };

  // toggle logic for mapper template suggestions visibility
  const [isSuggestionsVisible, updateSuggestionsVisibility] = useState(false);
  const showSuggestions = () => {
    updateRightDrawerState(true);
    updateLeftDrawerState({ system: false, inputComponents: false });
    updateSuggestionsVisibility(true);

    updateCanvasSizing();
  };

  const hideSuggestions = () => {
    updateSuggestionsVisibility(false);

    updateCanvasSizing();

    deferredUpdateSuggestedConnections({ projectId, sheetKey });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadRaw);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnloadRaw);
    };
  });

  useEffect(() => {
    if (tenant) {
      if (project) {
        refetchProjectSheets();
      } else {
        fetchProjectWithSheetsMutation.mutate({ projectId, version });
      }
    }
    // eslint-disable-next-line
  }, [tenant]);

  useEffect(() => {
    if (!isProjectLoading && sheetName) {
      mapNodeKeyIndex.current.clear();
      mapLinkKeyIndex.current.clear();

      if (sheetState && sheetState.nodeDataArray && sheetState.linkDataArray) {
        sheetState.nodeDataArray.forEach((nodeData, index) => {
          mapNodeKeyIndex.current.set(nodeData.key, index);
        });

        sheetState.linkDataArray.forEach((linkData, index) => {
          mapLinkKeyIndex.current.set(linkData.key, index);
        });
      }

      draggedInstance.current = null;
    }
    // eslint-disable-next-line
  }, [sheetKey, sheetName, isProjectLoading]);

  useEffect(() => {
    const handleSaveShortcut = event => {
      if (isSaveEvent(event)) {
        event.preventDefault();

        const saveButton = document.getElementById('saveButton');

        if (saveButton && saveButton.getAttribute('disabled') !== true) {
          saveButton.click();
        }
      }
    };

    window.addEventListener('keydown', handleSaveShortcut);

    return () => {
      window.removeEventListener('keydown', handleSaveShortcut);
    };
  }, []);

  const initialSheetHydration = useRef(false);
  // clear suggestion connections on new sheet load
  useEffect(() => {
    initialSheetHydration.current = true;
    mapperTemplateActions.clearSuggestedConnections();
  }, [sheetKey, projectId]);

  // create the handleModelChange as a normal callback handler that invokes the debounced dispatch
  // by passing a fully formed payload object
  // this is where we depend on the state closures
  // useCallback dependencies ensure we do not use any stale closures
  // as a fallback if the debounce dispatch doesn't exists invoke the dispatch directly
  const handleModelChange = useCallback(
    /**
     * handler for changes to the data binding on the nodes and links
     *
     * @param {go.IncrementalData} modelChangeIncrementalData
     */
    modelChangeIncrementalData => {
      const payload = {
        projectId,
        sheetKey,
        modelChangeIncrementalData,
        indexMaps: {
          mapNodeKeyIndex: mapNodeKeyIndex.current,
          mapLinkKeyIndex: mapLinkKeyIndex.current,
        },
      };

      sheetStateActions.updateCurrentEditorState(payload, initialSheetHydration);

      if (
        (modelChangeIncrementalData.insertedNodeKeys &&
          modelChangeIncrementalData.insertedNodeKeys.length) ||
        (modelChangeIncrementalData.removedNodeKeys &&
          modelChangeIncrementalData.removedNodeKeys.length) ||
        (modelChangeIncrementalData.removedLinkKeys &&
          modelChangeIncrementalData.removedLinkKeys.length)
      ) {
        deferredUpdateSuggestedConnections({ projectId, sheetKey });
      }
    },
    [projectId, sheetKey]
  );

  // debounced model change handler for better performance

  const handleDiagramEvent = useCallback(
    /**
     * handler for events coming in from the canvas
     * related to changes to the diagram
     *
     * @param {go.DiagramEvent} diagramEvent
     */
    diagramEvent => {
      const name = diagramEvent.name;

      switch (name) {
        case 'ChangedSelection':
          let selectedData = null;
          /**
           * @type {go.Set<go.Part> | null}
           */
          const selection = diagramEvent.subject;

          if (selection) {
            selectedData = selection.toArray();

            sheetStateActions.updateSelectedData({
              projectId,
              sheetKey,
              selectedData,
              indexMaps: {
                mapNodeKeyIndex: mapNodeKeyIndex.current,
                mapLinkKeyIndex: mapLinkKeyIndex.current,
              },
            });
          } else {
            sheetStateActions.clearSelectedData({ projectId, sheetKey });
          }

          break;
        case 'PartResized':
          const resizedNode = diagramEvent.subject;

          sheetStateActions.updateResizedNode({
            projectId,
            sheetKey,
            resizedNode,
            mapNodeKeyIndex: mapNodeKeyIndex.current,
          });

          break;

        default:
      }
    },
    [projectId, sheetKey]
  );

  const handleSelectedDataAttrChange = change => {
    sheetStateActions.updateSelectedDataAttribute({
      projectId,
      sheetKey,
      indexMaps: {
        mapNodeKeyIndex: mapNodeKeyIndex.current,
        mapLinkKeyIndex: mapLinkKeyIndex.current,
      },
      change: {
        shouldUpdate: true, // can be overidden by payload passed
        ...change,
      },
    });
  };

  const handleAnalyseEditToggle = isReadOnly => () => {
    sheetStateActions.toggleDiagramModelState({
      projectId: projectId,
      sheetKey,
      isReadOnly,
    });

    updateCanvasSizing();
  };

  const handleDropDownMenuOptionSelect = option => {
    switch (option.key) {
      case editorDropDownMenuOptions.createVersion.key:
        openVersionDialog();
        break;
      case editorDropDownMenuOptions.editProject.key:
        openEditProjectDialog();
        break;
      case editorDropDownMenuOptions.switchVersion.key:
        openSwitchVersionDialog();
        break;
      default:
    }
  };

  // rendering logic starts from here

  const projectSheets = project && project.sheets ? getProjectSheets(project.sheets) : [];

  const loadingStateView = (
    <div className={classes.root}>
      <Nav>
        <Grid container alignItems="center">
          <Grid item>
            <UserAvatar />
          </Grid>
        </Grid>
      </Nav>
      <Grid container direction="column" className={classes.main}>
        <Grid item>
          <Grid container justifyContent="center">
            <CircularProgress style={{ marginTop: 300 }} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  if (!project || projectSheets.length === 0) {
    return loadingStateView;
  }

  if (isProjectLoading) {
    return loadingStateView;
  }

  const isSheetKeyValid =
    Boolean(sheetKey) && Boolean(projectSheets.find(sheet => sheet.key === sheetKey));

  if (!isSheetKeyValid) {
    return (
      <Redirect
        to={`/t/${tenant?.tenant_id}/project/${projectId}?sheet=${
          projectSheets[0].key
        }&version=${version || project.version}`}
      />
    );
  } else if (!version) {
    return (
      <Redirect
        to={`/t/${tenant?.tenant_id}/project/${projectId}?sheet=${sheetKey}&version=${project.version}`}
      />
    );
  }

  const isProjectDirty = projectSheets.some(sheet => sheet.syncState.dirty);

  const versions = project.versions.map(version => ({
    name: `v${version.number}${version.isActive ? ' (Latest)' : ''}`,
    comment: version.comment,
    key: version.number,
    updatedAt: version.updatedAt,
    isActive: version.isActive,
  }));
  const selectedVersion = versions.find(v => v.key === project.version);

  return (
    <>
      <div className={classes.root}>
        <Prompt
          when={projectSheets.some(sheet => sheet.syncState.dirty)}
          message={handleRouteChange}
        />
        <Nav />
        <LeftSideBar
          sheetState={sheetState}
          isLeftDrawerOpen={isLeftDrawerOpen}
          toggleLeftDrawer={toggleLeftDrawer}
          handleDragStartForPalette={handleDragStartForPalette}
          isSuggestionsVisible={isSuggestionsVisible}
          customShapes={customShapes}
        />
        <RightSidebar
          sheetKey={sheetKey}
          sheetState={sheetState}
          isRightDrawerOpen={isRightDrawerOpen}
          hideSuggestions={hideSuggestions}
          diagramInstance={diagramInstance}
          handleSelectedDataAttrChange={handleSelectedDataAttrChange}
          isSuggestionsVisible={isSuggestionsVisible}
        />

        <EditorMenu
          projectId={projectId}
          project={project}
          sheetKey={sheetKey}
          sheetName={sheetName}
          sheetState={sheetState}
          selectedVersion={selectedVersion}
          handleAnalyseEditToggle={handleAnalyseEditToggle}
          handleDropDownMenuOptionSelect={handleDropDownMenuOptionSelect}
          isProjectDirty={isProjectDirty}
          isSuggestionsVisible={isSuggestionsVisible}
          editorDropDownMenuOptions={editorDropDownMenuOptions}
          isLeftDrawerOpen={isLeftDrawerOpen}
          isRightDrawerOpen={isRightDrawerOpen}
          toggleRightDrawer={toggleRightDrawer}
          version={version}
          diagramInstance={diagramInstance}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentExpandedLeft]:
              !isLeftDrawerOpen[DRAWER_TYPES.SYSTEM] &&
              !isLeftDrawerOpen[DRAWER_TYPES.INPUT_COMPONENTS] &&
              !sheetState.modelData.isReadOnly,
            [classes.contentExpandedRight]: !sheetState.modelData.isReadOnly && !isRightDrawerOpen,
            [classes.contentAnalyseMode]: sheetState.modelData.isReadOnly,
          })}
        >
          <ContextMenu
            diagram={diagramInstance.current}
            show={showContextMenu}
            updateContextMenuState={updateContextMenuState}
            isLeftDrawerOpen={isLeftDrawerOpen}
          />
          <Editor
            key={sheetKey}
            sheetState={sheetState}
            projectId={projectId}
            project={project}
            sheetKey={sheetKey}
            sheets={projectSheets}
            diagramInstance={diagramInstance}
            initDiagram={initDiagram}
            indexMaps={{
              mapNodeKeyIndex: mapNodeKeyIndex.current,
              mapLinkKeyIndex: mapLinkKeyIndex.current,
            }}
            onModelChange={handleModelChange}
            onDiagramEvent={handleDiagramEvent}
            updateDiagramInstance={updateDiagramInstance}
            updateContextMenuState={updateContextMenuState}
            handleDropFromPalette={handleDropFromPalette}
            isSuggestionsVisible={isSuggestionsVisible}
            showSuggestions={showSuggestions}
          />
        </main>

        <SheetsPanel
          project={project}
          projectId={projectId}
          sheetKey={sheetKey}
          projectSheets={projectSheets}
          isSuggestionsVisible={isSuggestionsVisible}
          sheetState={sheetState}
          isLeftDrawerOpen={isLeftDrawerOpen}
          isRightDrawerOpen={isRightDrawerOpen}
          diagramInstance={diagramInstance}
        />
      </div>

      {isEditProjectDialogOpen && project && project.name && (
        <ProjectFormDialog
          id="edit-project-form-dialog-title"
          title={formatMessage({ id: 'EDIT_PROJECT' })}
          open={isEditProjectDialogOpen}
          onClose={closeEditProjectDialog}
          initialValues={{ name: project.name, description: project.description }}
          onSubmit={handleEditProject}
          acceptText={
            updateProjectDetailsMutation.isLoading
              ? formatMessage({ id: 'UPDATING' })
              : formatMessage({ id: 'UPDATE' })
          }
          mutationObject={updateProjectDetailsMutation}
        />
      )}

      <CreateVersionFormDialog
        open={isVersionDialogOpen}
        onClose={closeVersionDialog}
        onSubmit={handleCreateVersion}
        version={
          project.versions && project.versions.length
            ? project.versions.find(v => v.isActive).number
            : 0
        }
      />

      <SwitchVersionDialog
        open={isSwitchVersionDialogOpen}
        versions={versions}
        selectedVersion={selectedVersion}
        onClose={closeSwitchVersionDialog}
        onSelect={handleVersionSwitch}
      />
    </>
  );
}
