/**
 * @file
 *
 * Entrypoint of the React app
 */

// polyfilling
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as go from 'gojs';

import App from './App';
import { config } from './utils/config';

let mountedContainerId;

go.Diagram.licenseKey = config.gojsKey;

const renderApp = (RootComponent, id) => {
  ReactDOM.render(<RootComponent />, document.getElementById(id));
};

window.mountReactApp = id => {
  mountedContainerId = id;

  renderApp(App, id);
};

if (config.env === 'development') {
  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default;

      renderApp(NextApp, mountedContainerId);
    });
  }
}

window.unmountReactApp = () => {
  if (mountedContainerId) {
    return ReactDOM.unmountComponentAtNode(document.getElementById(mountedContainerId));
  }
};

// don't auto mount when building for UI5 embedding
if (!config.ui5Embed) {
  window.mountReactApp('root');
}
