import { createStore } from '@halka/state';

import { config } from '../utils/config';

export const lsNamespace = {
  injectedAuthToken: '__integrtr_digital_transformer_dev_injected_auth_token',
  devUiShowState: '__integrtr_digital_transformer_dev_ui_show_state',
};

export const ls = {
  get(name, defaultValue = null) {
    try {
      return localStorage.getItem(name) ?? defaultValue;
    } catch (err) {
      return defaultValue;
    }
  },
  set(name, value) {
    try {
      localStorage.setItem(name, value);
    } finally {
    }
  },
  del(name) {
    try {
      localStorage.removeItem(name);
    } finally {
    }
  },
  clear() {
    try {
      localStorage.clear();
    } finally {
    }
  },
};

export const useAuthTokenInjectStore = createStore(
  ls.get(lsNamespace.injectedAuthToken) || config.authToken
);
