/**
 * @file
 *
 * This file exports the data bindings and other helpers needed for GoJS setup
 */

import * as go from 'gojs';
import { nanoid } from 'nanoid';
import { startCase, values, without } from 'lodash-es';
import { lighten } from '@material-ui/core';
import { blueGrey, common, lightBlue } from '@material-ui/core/colors';
import Color from 'color';

import {
  DIMENSIONS,
  DEFAULTS,
  NODE_MODEL_ATTRS,
  GROUP_NODES,
  LINK_MODEL_ATTRS,
  STROKE_TYPES,
  FONT_PROPERTIES,
  BASE_SYSTEMS,
  MODEL_NODES,
} from '../utils/constants';
import { icons } from './icons';

const $ = go.GraphObject.make;

/**
 * a curried utility function to map a value from [min, max] range to another [min, max] range
 *
 * @param {[number, number]} from
 * @param {[number, number]} to
 *
 * @returns {function(Number): Number}
 */
const rangeMap = (from, to) => value => {
  const [fromMin, fromMax] = from;
  const [toMin, toMax] = to;
  // Scaling up an order, and then down, to bypass a potential,
  // precision issue with negative numbers.
  return ((((toMax - toMin) * (value - fromMin)) / (fromMax - fromMin)) * 10 + 10 * toMin) / 10;
};

export const guidelineColor = blueGrey[800];

/**
 * node categories that are at the root level
 */
export const primaryNodeCategories = without(
  values(MODEL_NODES),
  MODEL_NODES.FIELD,
  MODEL_NODES.SPACER,
  MODEL_NODES.WATERMARK
).concat([GROUP_NODES.ENTITY_GROUP]);

/**
 * entity child nodes
 */
export const entityChildNodeCategories = [
  MODEL_NODES.FIELD,
  MODEL_NODES.SPACER,
  GROUP_NODES.FIELDS_GROUP,
];

/**
 * A unique generator for nodes and link prefixed with `node:` and `link:`
 * @param {'node' | 'link'} type
 *
 * @returns {function(go.Model): string}
 */
export const uniqueKeyGenerator = type => model => {
  let id = nanoid(10);
  while (model[`find${startCase(type)}DataForKey`](id)) {
    id = nanoid(10);
  }
  return `${type}:${id}`;
};

export const fonts = {
  shapeNode: 'bold 13pt Roboto, sans-serif',
  entityFieldGroupTitle: 'bold 12pt Roboto, sans-serif',
  entityField: 'bold 13px Roboto, sans-serif',
  entityTitle: 'bold 15pt Roboto, sans-serif',
};

export const getSizeString = (width, height = width) =>
  go.Size.stringify(new go.Size(width, height));

/**
 * function to conditionally make ports visible
 * @param {go.Node} node
 * @param {boolean} show
 */
export function showPorts(node, show) {
  if (node && node.diagram) {
    const isReadOnly = node.diagram.isReadOnly;

    node.ports.each(function(port) {
      if (port.portId) {
        port.opacity = show && !isReadOnly ? 1 : 0;
        port.cursor = show && !isReadOnly ? 'pointer' : 'initial';
      }
    });
  }
}

/**
 * function to toggle visibility of all ports
 * @param {go.Diagram} diagram
 * @param {boolean} show
 */
export function showAllPorts(diagram, show) {
  diagram.nodes.each(node => {
    showPorts(node, show);
  });
}

/**
 * function to create dynamic ports
 *
 * @param {string} name
 * @param {go.Spot} spot
 */
export function makePort(name, spot) {
  return $(
    go.Panel,
    'Auto',
    {
      alignment: spot,
      alignmentFocus: spot,
      fromSpot: spot,
      toSpot: spot,
      cursor: 'pointer',
      fromLinkable: true,
      toLinkable: true,
      opacity: 0,
    },
    new go.Binding('portId', 'key', key => `${name}::${key}`),
    $(go.Shape, 'Circle', {
      fill: lighten(common.white, 0.1),
      stroke: null,
      desiredSize: new go.Size(10, 10),
    }),
    $(go.Shape, 'XLine', {
      name: 'x',
      fill: null,
      alignment: go.Spot.Center,
      stroke: blueGrey[300],
      desiredSize: new go.Size(5, 5),
    })
  );
}

const resizeAdornmentTemplate = $(
  go.Adornment,
  'Spot',
  { locationSpot: go.Spot.Right },
  $(go.Placeholder),
  $(go.Shape, {
    alignment: go.Spot.TopLeft,
    cursor: 'nw-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),
  $(go.Shape, {
    alignment: go.Spot.Top,
    cursor: 'n-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),
  $(go.Shape, {
    alignment: go.Spot.TopRight,
    cursor: 'ne-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),

  $(go.Shape, {
    alignment: go.Spot.Left,
    cursor: 'w-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),
  $(go.Shape, {
    alignment: go.Spot.Right,
    cursor: 'e-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),

  $(go.Shape, {
    alignment: go.Spot.BottomLeft,
    cursor: 'se-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),
  $(go.Shape, {
    alignment: go.Spot.Bottom,
    cursor: 's-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),
  $(go.Shape, {
    alignment: go.Spot.BottomRight,
    cursor: 'sw-resize',
    desiredSize: new go.Size(6, 6),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  })
);

export const selectionAdornmentTemplate = $(
  go.Adornment,
  'Auto',
  $(go.Shape, { fill: null, stroke: 'deepskyblue', strokeWidth: 1.5, strokeDashArray: [4, 2] }),
  $(go.Placeholder)
);

const rotateAdornmentTemplate = $(
  go.Adornment,
  { locationSpot: go.Spot.Center, locationObjectName: 'RotateHandle' },
  $(go.Shape, 'Circle', {
    name: 'RotateHandle',
    cursor: 'pointer',
    desiredSize: new go.Size(7, 7),
    fill: lightBlue[200],
    stroke: blueGrey[300],
  }),
  $(go.Shape, {
    geometryString: 'M3.5 7 L3.5 30',
    isGeometryPositioned: true,
    stroke: blueGrey[300],
    strokeWidth: 1.5,
    strokeDashArray: [4, 2],
  })
);

const SHAPE_PANEL = 'ShapePanel';
const SHAPE_BODY = 'ShapeBody';
export const OBJECT_NAMES = {
  SHAPE_PANEL,
  SHAPE_BODY,
};

export const commonShapePanelOptions = {
  name: SHAPE_PANEL,
};

export const commonShapeBodyOptions = {
  fill: 'transparent',
  strokeWidth: 2,
  name: SHAPE_BODY,
  spot1: go.Spot.TopLeft,
  spot2: go.Spot.BottomRight,
  minSize: new go.Size(DIMENSIONS.BASE.MIN, DIMENSIONS.BASE.MIN),
  maxSize: new go.Size(DIMENSIONS.BASE.MAX, DIMENSIONS.BASE.MAX),
};

export const getCommonNodeSetupOptions = ({
  resizable = true,
  rotatable = true,
  resizeObjectName = SHAPE_BODY,
  rotateObjectName = SHAPE_PANEL,
  locationObjectName = SHAPE_PANEL,
} = {}) => ({
  ...(resizable && { resizable, resizeObjectName, resizeAdornmentTemplate }),
  ...(rotatable && { rotatable, rotateObjectName, rotateAdornmentTemplate }),
  locationSpot: go.Spot.Center,
  locationObjectName,
  mouseEnter: (event, node) => showPorts(node, true),
  mouseLeave: (event, node) => showPorts(node, false),
});

export const linkSelectionAdornment = $(
  go.Adornment,
  'Link',
  $(
    go.Shape,
    // isPanelMain declares that this Shape shares the Link.geometry
    { isPanelMain: true, fill: lightBlue[100], stroke: blueGrey[300], strokeWidth: 1 }
  ) // use selection object's strokeWidth
);

/**
 * helper function to parse link points
 * @param {string} pointsString   stringified array of points
 */
const parsePoints = pointsString => {
  const points = JSON.parse(pointsString);

  const list = new go.List();
  for (let i = 0; i < points.length; i += 2) {
    const x = parseFloat(points[i]);
    const y = parseFloat(points[i + 1]);
    list.add(new go.Point(x, y));
  }

  return list;
};

/**
 * helper function to stringify points object
 * @param {go.Iterable<go.Point>} pointsObj  an iterable object of points
 */
const stringifyPoints = pointsObj => {
  const iterator = pointsObj.iterator;
  const points = [];
  while (iterator.next()) {
    const point = iterator.value;
    points.push(point.x);
    points.push(point.y);
  }

  return JSON.stringify(points);
};

/**
 * function to derive border color from background color
 */
const getBorderColor = color =>
  Color(color).isDark()
    ? Color(color)
        .lighten(0.4)
        .rgb()
        .string()
    : Color(color)
        .darken(0.4)
        .rgb()
        .string();

/**
 * a helper function to create the callback to handle highlighted state of shapes / nodes
 * @param {string | number} highlightedValue
 * @param {string | number} defaultValue
 * @param {string} [dataBinding]
 *
 * @returns {function(boolean, go.Shape): string | number}
 */
const highlightBindingHelper = (highlightedValue, defaultValue, dataBinding) => (
  isHighlighted,
  shape
) => {
  if (isHighlighted) {
    return highlightedValue;
  }

  return shape.part.data[dataBinding] || defaultValue;
};

/**
 * a function to get value with default fallback and validity check
 *
 * @param {string} value   the actual value
 * @param {string | number} defaultValue    default value if invalid value found
 * @param {object} validityChecks
 * @param {number} validityChecks.min   if the expected value is a number it must be larger than this
 * @param {number} validityChecks.max   if the expected value is a number it must be smaller than this
 * @param {Object<string, string>} validityChecks.validValues    a map which contains the valid values for the field if it is a string
 */
export const getValueWithDefaultFallback = (value, defaultValue, { min, max, validValues }) => {
  if (!value) {
    return defaultValue;
  }

  if (typeof value === 'number') {
    return value >= min && value <= max ? value : defaultValue;
  } else {
    return values(validValues).includes(value) ? value : defaultValue;
  }
};

/**
 * function to set / get the value of entity system code safely
 *
 * @param {string} code
 *
 * @returns {string}
 */
const parseTwoWaySystemCode = key => {
  if (!key || !BASE_SYSTEMS[key]) {
    return BASE_SYSTEMS.CUSTOM.KEY;
  }

  return BASE_SYSTEMS[key].KEY;
};

export const bindings = {
  get richText() {
    return new go.Binding('font', '', data => {
      const weight = getValueWithDefaultFallback(
        data[NODE_MODEL_ATTRS.FONT_WEIGHT],
        DEFAULTS.FONT_PROPERTIES.WEIGHT,
        { validValues: FONT_PROPERTIES.WEIGHT }
      );
      const size = getValueWithDefaultFallback(
        data[NODE_MODEL_ATTRS.FONT_SIZE],
        DEFAULTS.FONT_PROPERTIES.SIZE,
        { min: DIMENSIONS.FONT_SIZE.MIN, max: DIMENSIONS.FONT_SIZE.MAX }
      );
      const family = getValueWithDefaultFallback(
        data[NODE_MODEL_ATTRS.FONT_FAMILY],
        DEFAULTS.FONT_PROPERTIES.FAMILY,
        { validValues: FONT_PROPERTIES.FAMILY }
      );
      const style = Boolean(data[NODE_MODEL_ATTRS.TEXT_ITALIC])
        ? FONT_PROPERTIES.STYLE.ITALIC
        : FONT_PROPERTIES.STYLE.NORMAL;

      return `${style} normal ${weight} ${size}px ${family}`;
    });
  },
  get textUnderline() {
    return new go.Binding('isUnderline', NODE_MODEL_ATTRS.TEXT_UNDERLINE).makeTwoWay();
  },
  get textStrikethrough() {
    return new go.Binding('isStrikethrough', NODE_MODEL_ATTRS.TEXT_STRIKETHROUGH).makeTwoWay();
  },
  get size() {
    return new go.Binding('desiredSize', NODE_MODEL_ATTRS.SIZE, go.Size.parse).makeTwoWay(
      go.Size.stringify
    );
  },
  get location() {
    return new go.Binding('location', NODE_MODEL_ATTRS.LOCATION, go.Point.parse).makeTwoWay(
      go.Point.stringify
    );
  },
  get linkFill() {
    return new go.Binding('fill', LINK_MODEL_ATTRS.COLOR);
  },
  get linkStroke() {
    return new go.Binding('stroke', LINK_MODEL_ATTRS.COLOR);
  },
  get linkTooltipVisible() {
    return new go.Binding('visible', LINK_MODEL_ATTRS.TEXT, Boolean);
  },
  get linkLabelVisible() {
    return new go.Binding('visible', '', data => {
      if (data[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID]) {
        return false;
      }

      return Boolean(data[LINK_MODEL_ATTRS.LABEL]);
    });
  },
  get linkVisible() {
    return new go.Binding('visible', LINK_MODEL_ATTRS.IS_VISIBLE, Boolean);
  },
  get label() {
    return new go.Binding('text', LINK_MODEL_ATTRS.LABEL).makeTwoWay();
  },
  get text() {
    return new go.Binding('text', NODE_MODEL_ATTRS.TEXT).makeTwoWay();
  },
  get titleText() {
    return new go.Binding('text', NODE_MODEL_ATTRS.TITLE).makeTwoWay();
  },
  get angle() {
    return new go.Binding('angle', NODE_MODEL_ATTRS.ANGLE).makeTwoWay();
  },
  get linkPoints() {
    return new go.Binding('points', LINK_MODEL_ATTRS.POINTS, parsePoints).makeTwoWay(
      stringifyPoints
    );
  },
  get linkCurviness() {
    return new go.Binding('curviness').makeTwoWay();
  },
  get strokeColor() {
    return new go.Binding('stroke', NODE_MODEL_ATTRS.COLOR);
  },
  get strokeType() {
    return new go.Binding(
      'strokeDashArray',
      NODE_MODEL_ATTRS.STROKE_TYPE,
      strokeType => STROKE_TYPES[strokeType].VALUE
    );
  },
  get highlightNode() {
    return new go.Binding('strokeWidth', NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED, isSystemLinked =>
      isSystemLinked ? 5 : 0.5
    );
  },
  get strokeTypeBaseStrokeWidth() {
    return new go.Binding('strokeWidth', NODE_MODEL_ATTRS.STROKE_TYPE, strokeType =>
      strokeType === STROKE_TYPES.NONE.KEY ? 0 : 1
    );
  },
  get fillColor() {
    return new go.Binding('fill', NODE_MODEL_ATTRS.COLOR);
  },
  get backgroundBasedBorderColor() {
    return new go.Binding('stroke', NODE_MODEL_ATTRS.COLOR, getBorderColor);
  },
  get backgroundBasedFontColor() {
    return new go.Binding('stroke', NODE_MODEL_ATTRS.COLOR, color =>
      go.Brush.isDark(color) ? common.white : common.black
    );
  },
  get highlightStrokeColor() {
    return new go.Binding(
      'stroke',
      'isHighlighted',
      highlightBindingHelper(DEFAULTS.HIGHLIGHT_COLOR, 'transparent')
    ).ofObject();
  },
  get highlightFillColor() {
    return new go.Binding(
      'fill',
      'isHighlighted',
      highlightBindingHelper(DEFAULTS.HIGHLIGHT_COLOR, 'transparent')
    ).ofObject();
  },
  get entityLogo() {
    return new go.Binding('geometry', NODE_MODEL_ATTRS.ENTITY_TYPE, type =>
      go.Geometry.parse(icons[type], true)
    );
  },
  get entityLogoColor() {
    return new go.Binding('fill', NODE_MODEL_ATTRS.COLOR, getBorderColor);
  },
  get showIfSystemIsLinked() {
    return new go.Binding('visible', NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED, Boolean);
  },

  get entitySystemCode() {
    return new go.Binding(
      '_systemCode',
      NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE,
      parseTwoWaySystemCode
    ).makeTwoWay(parseTwoWaySystemCode);
  },
  get linkPreview() {
    return new go.Binding('opacity', LINK_MODEL_ATTRS.PREVIEW, isPreview => (isPreview ? 0.3 : 1));
  },
  get linkStrokeWidth() {
    return new go.Binding('strokeWidth', LINK_MODEL_ATTRS.STROKE_WIDTH);
  },
  get linkArrowScale() {
    return new go.Binding(
      'scale',
      LINK_MODEL_ATTRS.STROKE_WIDTH,
      rangeMap(
        [DIMENSIONS.LINK_STROKE_WIDTH.MIN, DIMENSIONS.LINK_STROKE_WIDTH.MAX],
        [DIMENSIONS.LINK_ARROW_SIZE.MIN, DIMENSIONS.LINK_ARROW_SIZE.MAX]
      )
    );
  },
  get showIfMapperProjectLinked() {
    return new go.Binding('visible', LINK_MODEL_ATTRS.MAPPER_PROJECT_ID, Boolean);
  },
  get hideIfMapperProjectLinked() {
    return new go.Binding('visible', '', data => {
      if (data[LINK_MODEL_ATTRS.LABEL] && !data[LINK_MODEL_ATTRS.MAPPER_PROJECT_ID]) {
        return true;
      }
      return false;
    });
  },
  // virtual bindings
  // these bindings aren't used by GoJS
  get entitySystemCodeLock() {
    return new go.Binding('_systemCodeLock', NODE_MODEL_ATTRS.ENTITY_SYSTEM_CODE_LOCK, Boolean);
  },
  get entityUserSystemId() {
    return new go.Binding('_userSystemId', NODE_MODEL_ATTRS.ENTITY_USER_SYSTEM_ID);
  },
};

export const commonBlocks = {
  get text() {
    return $(
      go.TextBlock,
      {
        name: 'Text',
        margin: 6,
        font: fonts.shapeNode,
        editable: true,
        minSize: new go.Size(DIMENSIONS.TEXT.MIN, DIMENSIONS.TEXT.MIN),
      },
      bindings.text
    );
  },
};

export const createEntityCreationData = (entity, data) => {
  return {
    [entity.KEY]: {
      [NODE_MODEL_ATTRS.CATEGORY]: GROUP_NODES.ENTITY_GROUP,
      [NODE_MODEL_ATTRS.ENTITY_SYSTEM_LINKED]: false,
      entityGroupTitle: entity.LABEL,
      ...data,
    },
  };
};

/**
 * function that takes a go iterator and returns a JS array
 *
 * @param {go.Iterator} iterator
 * @param {function(any): boolean} [predicate]
 */
export function goIteratorToArray(iterator, predicate) {
  const arr = [];

  iterator.each(i => {
    if (!predicate) {
      arr.push(i);
      return;
    }

    if (predicate(i)) {
      arr.push(i);
    }
  });

  return arr;
}
