/**
 * @file
 *
 * This component render all the top section Options in the Selection Panel
 * i.e. mostly options related to the visual rendering of a component
 */

import React from 'react';
import { startCase } from 'lodash-es';
import * as go from 'gojs';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { SizeEdit } from './SizeEdit';
import { SelectEditField } from './SelectEditField';
import {
  NODE_MODEL_ATTRS,
  DIMENSIONS,
  MODEL_NODES,
  NODE_DERIVED_ATTRS,
  STROKE_TYPES,
  TEXTFIELD_WIDTH,
} from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  optionsMargin: {
    marginBottom: theme.spacing(1),
  },
}));

export function OptionsSection(props) {
  const classes = useStyles();

  const value = props.selectedData[props.attr];

  const isEntityFields = [MODEL_NODES.FIELD, MODEL_NODES.SPACER].includes(
    props.selectedData[NODE_MODEL_ATTRS.CATEGORY]
  );

  if (props.attr === NODE_MODEL_ATTRS.SIZE && !isEntityFields) {
    const { width, height } = go.Size.parse(value);

    if (props.selectedData[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.CIRCLE) {
      return (
        <Grid item className={classes.optionsMargin}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Typography color="textSecondary" variant="body2">
                  {startCase(NODE_DERIVED_ATTRS.DIAMETER)}
                </Typography>
              </Grid>
              <Grid container>
                <SizeEdit
                  width={TEXTFIELD_WIDTH.LG}
                  onChange={props.onChange}
                  min={DIMENSIONS.BASE.MIN}
                  max={DIMENSIONS.BASE.MAX}
                  attr={NODE_DERIVED_ATTRS.DIAMETER}
                  value={width}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (props.selectedData[NODE_MODEL_ATTRS.CATEGORY] === MODEL_NODES.SQUARE) {
      return (
        <Grid item className={classes.optionsMargin}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Typography color="textSecondary" variant="body2">
                  {startCase(NODE_MODEL_ATTRS.SIZE)}
                </Typography>
              </Grid>
              <Grid container>
                <SizeEdit
                  width={TEXTFIELD_WIDTH.LG}
                  onChange={props.onChange}
                  min={DIMENSIONS.BASE.MIN}
                  max={DIMENSIONS.BASE.MAX}
                  attr={NODE_MODEL_ATTRS.SIZE}
                  value={width}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item className={classes.optionsMargin}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Typography color="textSecondary" variant="body2">
                {startCase(NODE_DERIVED_ATTRS.WIDTH)}
              </Typography>
            </Grid>
            <Grid container>
              <SizeEdit
                width={TEXTFIELD_WIDTH.MD}
                onChange={props.onChange}
                max={DIMENSIONS.BASE.MAX}
                min={DIMENSIONS.BASE.MIN}
                attr={NODE_DERIVED_ATTRS.WIDTH}
                value={width}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Typography color="textSecondary" variant="body2">
                {startCase(NODE_DERIVED_ATTRS.HEIGHT)}
              </Typography>
            </Grid>
            <Grid container>
              <Grid container>
                <SizeEdit
                  width={TEXTFIELD_WIDTH.MD}
                  onChange={props.onChange}
                  max={DIMENSIONS.BASE.MAX}
                  min={DIMENSIONS.BASE.MIN}
                  attr={NODE_DERIVED_ATTRS.HEIGHT}
                  value={height}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (props.attr === NODE_MODEL_ATTRS.STROKE_TYPE) {
    return (
      <Grid item className={classes.optionsMargin}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Typography color="textSecondary" variant="body2">
                <FormattedMessage id="BORDER" />
              </Typography>
            </Grid>
            <Grid container>
              <SelectEditField
                width={TEXTFIELD_WIDTH.LG}
                onChange={props.onChange}
                attr={NODE_MODEL_ATTRS.STROKE_TYPE}
                value={props.selectedData[NODE_MODEL_ATTRS.STROKE_TYPE]}
                options={[
                  STROKE_TYPES.NONE.KEY,
                  STROKE_TYPES.SOLID.KEY,
                  STROKE_TYPES.DASHED.KEY,
                  STROKE_TYPES.DOTTED.KEY,
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return null;
}
