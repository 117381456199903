/**
 * @file
 *
 * This component renders the drawer toggle button and the right hand side drawer
 */

import React from 'react';
import { Drawer, makeStyles, Fab, withStyles } from '@material-ui/core';
import clsx from 'clsx';

import { SelectionInspector } from './SelectionInspector.jsx';
import { MapperTemplateSuggestions } from './MapperTemplateSuggestions';
import { UI_DIMENSIONS } from '../utils/constants.js';

export const CustomFab = withStyles(theme => ({
  root: {
    height: theme.spacing(4.5),
    width: theme.spacing(4.5),
    borderRadius: theme.spacing(0.5),
  },
}))(Fab);

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
  },
  drawerPaper: {
    overflowX: 'hidden',
    width: theme.spacing(UI_DIMENSIONS.DRAWER_WIDTH),
    marginTop: theme.spacing(5.5),
  },
}));

export function RightSidebar(props) {
  const classes = useStyles();

  return (
    !props.sheetState.modelData.isReadOnly && (
      <>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={props.isRightDrawerOpen}
          classes={{ paper: clsx(classes.drawerPaper) }}
          anchor="right"
        >
          {props.isSuggestionsVisible ? (
            <MapperTemplateSuggestions
              key={props.sheetKey}
              hideSuggestions={props.hideSuggestions}
              diagram={props.diagramInstance.current}
            />
          ) : (
            <SelectionInspector
              key={props.sheetKey}
              diagram={props.diagramInstance.current}
              nodeDataArray={props.sheetState.nodeDataArray}
              selectedData={props.sheetState.selectedData}
              onChange={props.handleSelectedDataAttrChange}
              isSuggestionsVisible={props.isSuggestionsVisible}
            />
          )}
        </Drawer>
        )
      </>
    )
  );
}
