/**
 * @file
 *
 * This file holds all the base configuration for the API calls using axios
 */

import axios from 'axios';
import { nanoid } from 'nanoid';
import Cookies from 'js-cookie';

import { useAuthTokenInjectStore } from '../devOnly/authTokenInjectorState';
import { config } from './config';
import { COOKIES } from './constants';
import { useTenantState } from '../data/user';

export const digitalTransformerAssetBase = 'digital-transformer';
export const assetBase = 'assets';
export const logosBase = 'logos';
/**
 * function to generate the CDN url for an asset
 */
export const getAssetURL = (prefix, assetName) => {
  return `${config.cdnBaseURL}/${prefix}/${assetName}`;
};

/**
 * function to get the ENV prefix from the hostnam
 *
 * @returns {string}
 */
const getDomainEnvFromHostname = () => {
  const subdomain = window.location.hostname.split('.').shift();

  const envWithDashPrefixed = subdomain.replace(config.appName, '');

  return envWithDashPrefixed;
};

/**
 * function to get the right env
 *
 * @returns { 'dev' | 'staging' | 'production' | 'demo' | 'predemo' | 'experiment' | 'hotfix' }
 */
export const getAppEnv = () => {
  const envWithDashPrefixed = getDomainEnvFromHostname();

  if (envWithDashPrefixed) {
    return envWithDashPrefixed.slice(1);
  }

  return 'production';
};

/**
 * function to get the Platform URL
 *
 * @return {string}
 */
export const getPlatformURL = () =>
  config.env !== 'production'
    ? config.localPlatformUrl
    : `https://platform${getDomainEnvFromHostname()}.integrtr.com`;

/**
 * @function
 *
 * redirect to login page url
 *
 * @param {string} [locationHref]
 */
export const redirectToLoginPage = (locationHref = window.location.href) =>
  window.location.replace(
    `${getPlatformURL()}/login?redirectTo=${encodeURIComponent(locationHref)}`
  );

/**
 * function to get auth token
 *
 */
export const getAuthToken = () => {
  let { authToken } = config;

  if (config.env === 'development') {
    let injectedAuthToken = useAuthTokenInjectStore.get();
    if (injectedAuthToken) {
      authToken = injectedAuthToken;
    }
  }

  if (config.env === 'production') {
    authToken = Cookies.get(COOKIES.BASIC_AUTH_TOKEN) ?? Cookies.get(COOKIES.SSO_AUTH_TOKEN);
  }

  return authToken;
};

/**
 * function to get the headers for API calls
 *
 * @param {string} tenantId
 * @param {object} options
 * @param {boolean} options.tenantId
 * @param {string} options.authToken
 *
 * @throws If cookies cannot be found in production ENV then we redirect them to Home
 *
 * @returns {{ 'X-TenantId': string, 'Authorization': string }}
 */
const getHeaders = ({ authToken = getAuthToken() } = {}) => {
  if (!authToken) {
    if (config.env === 'production') {
      redirectToLoginPage();
    }
  }

  const { tenant_id: tenantId } = useTenantState.get() ?? {};

  return {
    Authorization: `Bearer ${authToken}`,
    ...(Boolean(tenantId) && { 'X-TenantId': tenantId }),
  };
};

/**
 * function to get the API base URL based on the ENV values
 *
 * @return {string}
 */
const getAPIBaseURL = () => {
  if (config.env !== 'production') {
    return config.apiBaseURL;
  }

  const env = getDomainEnvFromHostname();

  return `https://platform${env}-api.integrtr.com`;
};

export const appMapperApiBase = '/app/mapper';
export const digitalTransformerApiBase = '/app/digital_transformer';
export const systemsApiBase = '/app/system';

const responseInterceptor = response => response.data;
const errorInterceptor = error => {
  // attach unique error keys to each error so that useNotifyError hook
  // can identify new errors and show them
  let errorResponse = { key: nanoid(10) };
  if (error.response && error.response.data) {
    errorResponse = {
      ...errorResponse,
      ...(typeof error.response.data === 'object'
        ? { ...error.response.data }
        : { errorDetails: error.response.data }),
    };
  }

  throw errorResponse;
};

/**
 * @returns [AxiosInstance]
 */
export const getServiceInstance = ({ authToken } = {}) => {
  const serviceInstance = axios.create({
    headers: {
      ...getHeaders({ authToken }),
      'Content-Type': 'application/json',
    },
    baseURL: `${getAPIBaseURL()}/api`,
  });

  serviceInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return serviceInstance;
};

/**
 * function to get the API base URL based on the ENV values
 *
 * @return {string}
 */
export const getLambdaAPIBaseURL = () => {
  if (config.env !== 'production') {
    return '';
  }

  const env = getDomainEnvFromHostname();

  return `https://digital-transformer${env}-lambda.integrtr.com`;
};

/**
 * @returns [AxiosInstance]
 */
export const getLambdaServiceInstance = ({ authToken } = {}) => {
  const serviceInstance = axios.create({
    headers: {
      ...getHeaders({ authToken }),
      'Content-Type': 'application/json',
    },
    baseURL: `${getLambdaAPIBaseURL()}/api`,
  });

  serviceInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return serviceInstance;
};
