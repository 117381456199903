/**
 * @file
 *
 * this file exports all the constants to be used in the application
 */

import React from 'react';
import { grey, lightBlue, blue, common, blueGrey, teal } from '@material-ui/core/colors';
import { lighten } from '@material-ui/core';
import { MdDevices } from 'react-icons/md';
import {
  AiOutlineBank,
  AiOutlineCalculator,
  AiOutlineConsoleSql,
  AiOutlineDatabase,
  AiOutlinePieChart,
  AiOutlineUsergroupAdd,
  AiOutlineWindows,
} from 'react-icons/ai';
import {
  RiCalendarEventLine,
  RiFundsBoxLine,
  RiKeyLine,
  RiServerLine,
  RiTruckLine,
} from 'react-icons/ri';
import { BsBriefcase, BsClipboardData, BsEnvelope } from 'react-icons/bs';

export const SHEET_MODES = {
  INTEGRATION_MODE: {
    KEY: 'integrationMode',
    LABEL: 'Integration Mode',
  },
  DETAILS_MODE: {
    KEY: 'detailsMode',
    LABEL: 'Details Mode',
  },
};

export const DIMENSIONS = {
  BASE: {
    MAX: 1000,
    MIN: 40,
  },
  TEXT: {
    MIN: 5,
  },
  WATERMARK: {
    HEIGHT: 35,
    WIDTH: 135,
  },
  FONT_SIZE: {
    MAX: 64,
    MIN: 8,
  },
  PREVIEW: {
    HEIGHT: 800,
    WIDTH: 800,
  },
  LINK_STROKE_WIDTH: {
    MIN: 1,
    MAX: 10,
  },
  LINK_ARROW_SIZE: {
    MIN: 1,
    MAX: 3,
  },
};

export const TEXTFIELD_WIDTH = {
  MD: 105,
  LG: 225,
};

export const FONT_PROPERTIES = {
  FAMILY: {
    SERIF: 'serif',
    SANS_SERIF: 'sans-serif',
  },
  STYLE: {
    NORMAL: 'normal',
    ITALIC: 'italic',
  },
  WEIGHT: {
    NORMAL: 'normal',
    BOLD: 'bold',
    LIGHTER: 'lighter',
  },
};

export const STROKE_TYPES = {
  NONE: {
    KEY: 'NONE',
    VALUE: null,
  },
  SOLID: {
    KEY: 'SOLID',
    VALUE: null,
  },
  DASHED: {
    KEY: 'DASHED',
    VALUE: [8, 4],
  },
  DOTTED: {
    KEY: 'DOTTED',
    VALUE: [2, 2],
  },
};

export const UI_DIMENSIONS = {
  DRAWER_WIDTH: 32,
  SIDEBAR_WIDTH: 5,
};

export const DRAWER_TYPES = {
  SYSTEM: 'system',
  INPUT_COMPONENTS: 'inputComponents',
};

export const DEFAULTS = {
  COLOR: common.black,
  TEXT: 'text',
  HIGHLIGHT_COLOR: blue[600],
  GROUP_COLOR: grey[100],
  ENTITY_GROUP_COLOR: lighten(lightBlue[50], 0.5),
  FIELDS_GROUP_COLOR: blue[50],
  LINK_COLOR: grey[600],
  FONT_PROPERTIES: {
    FAMILY: FONT_PROPERTIES.FAMILY.SANS_SERIF,
    SIZE: 16,
    STYLE: FONT_PROPERTIES.STYLE.NORMAL,
    WEIGHT: FONT_PROPERTIES.WEIGHT.NORMAL,
  },
  STROKE_TYPE: STROKE_TYPES.SOLID.KEY,
  DRAG_SELECT: {
    FILL: blueGrey[50],
    STROKE: blueGrey[900],
    STROKE_WIDTH: 2,
  },
  OVERVIEW: {
    WIDTH: 400,
    HEIGHT: 300,
    STROKE: teal[900],
    FILL: teal[100],
  },
  LINK: {
    STROKE_WIDTH: 3,
  },
  MAPPER_TEMPLATE_LINK: {
    STROKE_WIDTH: 5,
    COLOR: blueGrey[900],
  },
};

export const CUSTOM_PALETTE_SHAPES = {
  ICON0: <RiServerLine size="23px" />,
  ICON1: <AiOutlinePieChart size="23px" />,
  ICON2: <AiOutlineCalculator size="23px" />,
  ICON3: <RiTruckLine size="23px" />,
  ICON4: <AiOutlineConsoleSql size="23px" />,
  ICON5: <AiOutlineDatabase size="23px" />,
  ICON6: <AiOutlineWindows size="23px" />,
  ICON7: <RiFundsBoxLine size="23px" />,
  ICON8: <RiKeyLine size="23px" />,
  ICON9: <RiCalendarEventLine size="23px" />,
  ICON10: <MdDevices size="23px" />,
  ICON11: <AiOutlineUsergroupAdd size="23px" />,
  ICON12: <BsEnvelope size="23px" />,
  ICON13: <BsClipboardData size="23px" />,
  ICON14: <BsBriefcase size="23px" />,
  ICON15: <AiOutlineBank size="23px" />,
};

export const ENTITY_FIELD_FORM_CHANGE_TYPE = {
  INSERT: 'FIELD_INSERT',
  UPDATE: 'FIELD_UPDATE',
  DELETE: 'FIELD_DELETE',
};

export const BASE_SYSTEMS = {
  SAP_HCM: { KEY: 'SAP_HCM', NAME: 'SAP Human Capital Management' },
  SF_EC: { KEY: 'SF_EC', NAME: 'SuccessFactors Employee Central' },
  WORKDAY: { KEY: 'WORKDAY', NAME: 'Workday' },
  DATEV: { KEY: 'DATEV', NAME: 'DATEV' },
  CUSTOM: { KEY: 'CUSTOM', NAME: 'Custom' },
};

export const ENTITY_TYPES = {
  CLOUD: 'cloud',
  ON_PREMISE: 'onPremise',
  NONE: 'none',
};

export const INTEGRATION_INDICATORS = {
  SYSTEM_LINKED: 'systemLinked',
  NONE: 'none',
};

export const MODEL_NODES = {
  SQUARE: 'Square',
  RECTANGLE: 'Rectangle',
  ROUNDED_RECTANGLE: 'RoundedRectangle',
  CIRCLE: 'Circle',
  ELLIPSE: 'Ellipse',
  TRIANGLE: 'Triangle',
  CLOUD: 'Cloud',
  HEXAGON: 'Hexagon',
  CYLINDER: 'Cylinder',
  DATABASE: 'Database',
  FIELD: 'Field',
  SPACER: 'Spacer',
  WATERMARK: 'Watermark',
  ACTOR: 'Actor',
  CLOCK: 'Clock',
};

export const GROUP_NODES = {
  ENTITY_GROUP: 'EntityGroup',
  FIELDS_GROUP: 'FieldsGroup',
};

export const NODE_MODEL_ATTRS = {
  TITLE: 'title',
  TEXT: 'text',
  COLOR: 'color',
  SIZE: 'size',
  ANGLE: 'angle',
  CATEGORY: 'category',
  IS_GROUP: 'isGroup',
  GROUP: 'group',
  KEY: 'key',
  LOCATION: 'location',
  ENTITY_TYPE: 'entityType',
  STROKE_TYPE: 'strokeType',
  FONT_WEIGHT: 'fontWeight',
  FONT_SIZE: 'fontSize',
  FONT_FAMILY: 'fontFamily',
  TEXT_ITALIC: 'isItalic',
  TEXT_UNDERLINE: 'isUnderline',
  TEXT_STRIKETHROUGH: 'isStrikethrough',
  ENTITY_SYSTEM_CODE: 'systemCode',
  ENTITY_SYSTEM_CODE_LOCK: 'systemCodeLock',
  ENTITY_USER_SYSTEM_ID: 'userSystemId',
  ENTITY_SYSTEM_LINKED: 'isUserSystemLinked',
};

export const NODE_DERIVED_ATTRS = {
  HEIGHT: 'height',
  WIDTH: 'width',
  DIAMETER: 'diameter',
  FIELDS: 'fields',
};

export const LINK_MODEL_ATTRS = {
  FROM: 'from',
  FROM_PORT: 'fromPort',
  TO: 'to',
  TO_PORT: 'toPort',
  KEY: 'key',
  GROUP: 'group',
  TEXT: 'text',
  LABEL: 'label',
  POINTS: 'points',
  STROKE_TYPE: 'strokeType',
  STROKE_WIDTH: 'strokeWidth',
  COLOR: 'color',
  CATEGORY: 'category',
  PREVIEW: 'preview',
  MAPPER_TEMPLATE_CODE: 'mapperTemplateCode',
  MAPPER_PROJECT_ID: 'mapperProjectId',
  IS_VISIBLE: 'isVisible',
};

export const MODEL_LINKS = {
  MAPPER_TEMPLATE: 'mapper_template',
};

export const PALETTE = {
  SYSTEM: {
    SUCCESS_FACTORS: {
      KEY: 'SUCCESS_FACTORS',
      LABEL: 'SuccessFactors',
    },
    SAP_HCM: {
      KEY: 'SAP_HCM',
      LABEL: 'HCM',
    },
    SAP_ERP_S4_HANA: {
      KEY: 'SAP_ERP_S4_HANA',
      LABEL: 'ERP/S4 HANA',
    },
    IDM: {
      KEY: 'IDM',
      LABEL: 'IDM',
    },
    CUSTOM: {
      KEY: 'CUSTOM',
      LABEL: 'Custom',
    },
  },
  SHAPES: {
    SQUARE: {
      KEY: 'SQUARE',
      LABEL: 'Square',
    },
    RECTANGLE: {
      KEY: 'RECTANGLE',
      LABEL: 'Rectangle',
    },
    ROUNDED_RECTANGLE: {
      KEY: 'ROUNDED_RECTANGLE',
      LABEL: 'Rounded Rectangle',
    },
    CIRCLE: {
      KEY: 'CIRCLE',
      LABEL: 'Circle',
    },
    ELLIPSE: {
      KEY: 'ELLIPSE',
      LABEL: 'Ellipse',
    },
    TRIANGLE: {
      KEY: 'TRIANGLE',
      LABEL: 'Triangle',
    },
    CLOUD: {
      KEY: 'CLOUD',
      LABEL: 'Cloud',
    },
    HEXAGON: {
      KEY: 'HEXAGON',
      LABEL: 'Hexagon',
    },
    CYLINDER: {
      KEY: 'CYLINDER',
      LABEL: 'Cylinder',
    },
    DATABASE: {
      KEY: 'DATABASE',
      LABEL: 'Database',
    },
    ACTOR: {
      KEY: 'ACTOR',
      LABEL: 'Actor',
    },
    CLOCK: {
      KEY: 'CLOCK',
      LABEL: 'Clock',
    },
  },
  TEXT: {
    KEY: 'TEXT',
    LABEL: 'Text',
  },
};

export const TEMPLATE_TYPES = {
  CUSTOM: 'custom',
  SYSTEM: 'system',
};

export const COOKIES = {
  BASIC_AUTH_TOKEN: 'basicToken',
  SSO_AUTH_TOKEN: 'ssoToken',
};

export const API_ERROR_TYPES = {
  JWT: 'jwt_error',
  UNAUTHORIZED: 'authentication_error',
  INCORRECT_PAYLOAD: 'incorrect_payload',
};
