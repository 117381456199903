/**
 * @file
 *
 * This file renders the shape icons in the Palette
 */

import React from 'react';
import { common } from '@material-ui/core/colors';

import { PALETTE } from '../utils/constants';

export const ShapeIcon = ({ type }) => {
  switch (type) {
    case PALETTE.SHAPES.SQUARE.KEY:
      return <SquareIcon />;
    case PALETTE.SHAPES.RECTANGLE.KEY:
      return <RectangleIcon />;
    case PALETTE.SHAPES.ROUNDED_RECTANGLE.KEY:
      return <RoundedRectangleIcon />;
    case PALETTE.SHAPES.CIRCLE.KEY:
      return <CircleIcon />;
    case PALETTE.SHAPES.ELLIPSE.KEY:
      return <EllipseIcon />;
    case PALETTE.SHAPES.TRIANGLE.KEY:
      return <TriangleIcon />;
    case PALETTE.SHAPES.CLOUD.KEY:
      return <CloudIcon />;
    case PALETTE.SHAPES.HEXAGON.KEY:
      return <HexagonIcon />;
    case PALETTE.SHAPES.CYLINDER.KEY:
      return <CylinderIcon />;
    case PALETTE.SHAPES.DATABASE.KEY:
      return <DatabaseIcon />;
    case PALETTE.SHAPES.ACTOR.KEY:
      return <ActorIcon />;
    case PALETTE.SHAPES.CLOCK.KEY:
      return <ClockIcon />;
    default:
      return null;
  }
};

const commonOptions = {
  height: '512',
  width: '512',
  style: { background: common.white },
  xmlns: 'http://www.w3.org/2000/svg',
};

const SquareIcon = () => (
  <svg {...commonOptions} viewBox="0 0 506.1 506.1">
    <path d="m489.609 506.1h-473.118c-9.108 0-16.491-7.384-16.491-16.491v-473.118c0-9.108 7.383-16.491 16.491-16.491h473.119c9.107 0 16.49 7.383 16.49 16.491v473.118c0 9.107-7.383 16.491-16.491 16.491zm-456.628-32.982h440.138v-440.137h-440.138z" />
  </svg>
);

const RectangleIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512">
    <path
      d="M497,51H15C6.716,51,0,57.716,0,66v380c0,8.284,6.716,15,15,15h482c8.284,0,15-6.716,15-15V66
			C512,57.716,505.284,51,497,51z M482,431H30V81h452V431z"
    />
  </svg>
);

const RoundedRectangleIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512">
    <path
      d="M432,0H80C35.888,0,0,35.888,0,80v352c0,44.112,35.888,80,80,80h352c44.112,0,80-35.888,80-80V80
			C512,35.888,476.112,0,432,0z M472,432c0,22.056-17.944,40-40,40H80c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h352
			c22.056,0,40,17.944,40,40V432z"
    />
  </svg>
);

const EllipseIcon = () => (
  <svg {...commonOptions} viewBox="0 0 88.332 88.333">
    <path
      d="M44.166,75.062C19.812,75.062,0,61.202,0,44.167C0,27.13,19.812,13.27,44.166,13.27c24.354,0,44.166,13.859,44.166,30.896
			C88.332,61.204,68.52,75.062,44.166,75.062z M44.166,16.27C21.467,16.27,3,28.784,3,44.167c0,15.381,18.467,27.896,41.166,27.896
			s41.166-12.515,41.166-27.896C85.332,28.785,66.865,16.27,44.166,16.27z"
    />
  </svg>
);

const CircleIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512">
    <path
      d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256
			C512,114.509,397.503,0,256,0z M256,477.867c-122.337,0-221.867-99.529-221.867-221.867S133.663,34.133,256,34.133
			S477.867,133.663,477.867,256S378.337,477.867,256,477.867z"
    />
  </svg>
);

const TriangleIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512">
    <path
      d="M505.618,408.764L293.606,57.81c-17.166-28.611-58.552-28.495-75.634-0.027L6.377,408.776
			c-0.005,0.009-0.011,0.018-0.016,0.027c-17.646,29.409,3.506,66.806,37.825,66.806h423.631
			C502.084,475.608,523.323,438.274,505.618,408.764z M467.816,445.613H44.186c-10.966,0-17.742-11.938-12.12-21.35l211.611-351.02
			c5.464-9.108,18.695-9.184,24.229,0.039l212.011,350.954C485.564,433.644,478.802,445.613,467.816,445.613z"
    />
  </svg>
);

const CloudIcon = () => (
  <svg {...commonOptions} viewBox="-20 -129 638.67144 638">
    <path d="m478.90625 132.8125c-4.785156.003906-9.5625.292969-14.3125.863281-12.894531-41.988281-51.628906-70.683593-95.550781-70.773437-10.933594-.011719-21.789063 1.804687-32.121094 5.363281-25.578125-55.308594-86.195313-85.367187-145.699219-72.25-59.511718 13.121094-101.867187 65.875-101.824218 126.808594.003906 10.53125 1.316406 21.019531 3.890624 31.222656-56.695312 8.65625-97.203124 59.46875-92.988281 116.667969 4.207031 57.203125 51.71875 101.542968 109.070313 101.796875h369.535156c66.191406 0 119.847656-53.660157 119.847656-119.851563s-53.65625-119.847656-119.847656-119.847656zm0 219.722656h-369.535156c-49.238282.214844-89.472656-39.253906-90.207032-88.488281-.730468-49.234375 38.304688-89.878906 87.53125-91.132813 3.195313-.089843 6.152344-1.703124 7.957032-4.339843 1.8125-2.640625 2.246094-5.980469 1.171875-8.992188-19.824219-56.730469 9.664062-118.855469 66.152343-139.367187 56.484376-20.511719 118.964844 8.226562 140.15625 64.460937.96875 2.609375 2.976563 4.691407 5.546876 5.753907 2.574218 1.0625 5.46875 1.003906 7.992187-.160157 10.457031-4.863281 21.84375-7.382812 33.371094-7.394531 38 .070312 70.722656 26.835938 78.3125 64.070312 1.085937 5.414063 6.359375 8.914063 11.765625 7.820313 6.511718-1.304687 13.136718-1.96875 19.785156-1.976563 55.160156 0 99.875 44.71875 99.875 99.871094 0 55.160156-44.714844 99.875-99.875 99.875zm0 0" />
  </svg>
);

const HexagonIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512">
    <path
      d="M510.432,249.597L388.297,38.537c-2.096-3.631-5.967-5.378-10.159-5.378H133.861c-4.192,0-8.063,1.747-10.159,5.378
			L1.567,249.841c-2.09,3.631-2.09,7.976,0,11.607l122.135,211.535c2.096,3.632,5.967,5.858,10.159,5.858h244.276
			c4.192,0,8.063-2.288,10.159-5.919l122.135-211.569C512.523,257.722,512.523,253.228,510.432,249.597z M371.369,455.384H140.63
			L25.27,256.003L140.63,56.616h230.738l115.36,199.387L371.369,455.384z"
    />
  </svg>
);

const CylinderIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512">
    <path
      d="M425.621,38.187C414.763,1.216,272.789,0,256,0S97.237,1.216,86.379,38.187c-0.64,1.387-1.045,2.859-1.045,4.48v426.667
			c0,1.621,0.405,3.093,1.045,4.48C97.237,510.784,239.211,512,256,512s158.763-1.216,169.621-38.187
			c0.64-1.387,1.045-2.859,1.045-4.48V42.667C426.667,41.045,426.261,39.573,425.621,38.187z M256,21.333
			c87.723,0,137.685,13.248,148.075,21.333C393.685,50.752,343.723,64,256,64S118.315,50.752,107.925,42.667
			C118.315,34.581,168.277,21.333,256,21.333z M405.333,467.989c-6.101,7.851-56.448,22.677-149.333,22.677
			c-93.995,0-144.619-15.211-149.333-21.333V65.429C149.312,84.544,242.603,85.333,256,85.333s106.688-0.789,149.333-19.904V467.989
			z"
    />
  </svg>
);

const DatabaseIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512">
    <path
      d="M256,0C136.384,0,42.667,42.176,42.667,96S136.384,192,256,192s213.333-42.176,213.333-96S375.616,0,256,0z M256,170.667
			c-113.152,0-192-39.36-192-74.667s78.848-74.667,192-74.667S448,60.693,448,96S369.152,170.667,256,170.667z"
    />
    <path
      d="M458.667,192c-5.888,0-10.667,4.779-10.667,10.667c0,35.307-78.848,74.667-192,74.667s-192-39.36-192-74.667
			C64,196.779,59.221,192,53.333,192s-10.667,4.779-10.667,10.667c0,53.824,93.717,96,213.333,96s213.333-42.176,213.333-96
			C469.333,196.779,464.555,192,458.667,192z"
    />
    <path
      d="M458.667,298.667c-5.888,0-10.667,4.779-10.667,10.667C448,344.64,369.152,384,256,384S64,344.64,64,309.333
			c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667c0,53.824,93.717,96,213.333,96s213.333-42.176,213.333-96
			C469.333,303.445,464.555,298.667,458.667,298.667z"
    />
    <path
      d="M458.667,85.333C452.779,85.333,448,90.112,448,96v320c0,35.307-78.848,74.667-192,74.667S64,451.307,64,416V96
			c0-5.888-4.779-10.667-10.667-10.667S42.667,90.112,42.667,96v320c0,53.824,93.717,96,213.333,96s213.333-42.176,213.333-96V96
			C469.333,90.112,464.555,85.333,458.667,85.333z"
    />
  </svg>
);

const ActorIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512" strokeWidth="20" fill="white" stroke="black">
    <path d="M325 79C325 117.108 294.108 148 256 148C217.892 148 187 117.108 187 79C187 40.8923 217.892 10 256 10C294.108 10 325 40.8923 325 79Z" />
    <path d="M256 152V370" />
    <path d="M256 167L442 167" />
    <path d="M70 167L256 167" />
    <path d="M256 370L387.522 501.522" />
    <path d="M255.522 370L124 501.522" />
  </svg>
);

const ClockIcon = () => (
  <svg {...commonOptions} viewBox="0 0 512 512" strokeWidth="20" fill="white" stroke="black">
    <path d="M502 256C502 391.862 391.862 502 256 502C120.138 502 10 391.862 10 256C10 120.138 120.138 10 256 10C391.862 10 502 120.138 502 256Z" />
    <path d="M256 70V256" />
    <path d="M256 256H442" />
  </svg>
);
