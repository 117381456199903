/**
 * @file
 *
 * This component renders the overview dialog
 */
import React, { useCallback, useState } from 'react';
import {
  Grid,
  Button,
  ButtonGroup,
  Tooltip,
  makeStyles,
  Collapse,
  Drawer,
} from '@material-ui/core';
import { ReactOverview } from 'gojs-react';
import {
  MdAdd,
  MdRemove,
  MdZoomOutMap,
  MdCenterFocusStrong,
  MdKeyboard,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { useIntl } from 'react-intl';

import { initOverview } from '../models';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import {
  handleDecreaseZoom,
  handleIncreaseZoom,
  handleResetZoom,
  handleZoomToFit,
} from '../utils/helpers';
import { useDialog } from '../hooks/useDialog';
import { KeyboardShortcuts } from '../components/KeyboardShortcuts';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    zIndex: theme.zIndex.modal - 1,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  overviewContainer: ({ height, width }) => ({
    width: width / 7,
    height: height / 7,
    boxShadow: theme.shadows[0],
    border: theme.borders[0],
    backgroundColor: theme.palette.common.white,
  }),
  overview: {
    width: '100%',
    height: '100%',
  },
  zoomActions: {
    boxShadow: theme.shadows[0],
    marginTop: theme.spacing(0.8),
  },

  button: {
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: theme.shadows[0],
    height: theme.spacing(3.5),
    minWidth: theme.spacing(3.5),
    backgroundColor: theme.palette.common.white,
  },
  keymapBtn: {
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: theme.shadows[0],
    height: theme.spacing(3.5),
    border: theme.borders[1],
    minWidth: theme.spacing(3.5),
    backgroundColor: theme.palette.common.white,
  },
  buttonGroup: {
    border: theme.borders[1],
    boxShadow: theme.shadows[0],
  },
}));

const useOverviewDialog = () => {
  const [isOverviewDialogExpanded, setIsOverviewDialogExpanded] = useState(true);

  const toggleOverviewDialog = useCallback(() => {
    setIsOverviewDialogExpanded(prevState => !prevState);
  }, [setIsOverviewDialogExpanded]);

  return { isOverviewDialogExpanded, toggleOverviewDialog };
};

export const OverviewDialog = ({ diagramInstance, isReadOnly }) => {
  const dimensions = useWindowDimensions();
  const classes = useStyles({ height: dimensions.height, width: dimensions.width });

  const { formatMessage } = useIntl();

  const { isOverviewDialogExpanded, toggleOverviewDialog } = useOverviewDialog();
  const {
    isDialogOpen: isKeyboardShortcutsPanelOpen,
    openDialog: openKeyboardShortcutsPanel,
    closeDialog: closeKeyboardShortcutsPanel,
  } = useDialog();

  return (
    <>
      <Grid className={classes.container}>
        {!isReadOnly && (
          <Collapse in={isOverviewDialogExpanded} timeout="auto">
            <Grid className={classes.overviewContainer}>
              <ReactOverview
                initOverview={initOverview}
                divClassName={classes.overview}
                observedDiagram={diagramInstance.current}
              />
            </Grid>
          </Collapse>
        )}
        <Grid
          container
          direction="row"
          className={classes.zoomActions}
          justifyContent="space-between"
        >
          <Grid item>
            <ButtonGroup
              className={classes.buttonGroup}
              variant="contained"
              color="default"
              size="small"
            >
              <Tooltip title={formatMessage({ id: 'ZOOM_IN' })}>
                <Button
                  disableElevation
                  className={classes.button}
                  onClick={() => handleIncreaseZoom(diagramInstance)}
                >
                  <MdAdd size="18px" />
                </Button>
              </Tooltip>
              <Tooltip title={formatMessage({ id: 'ZOOM_TO_FIT' })}>
                <Button
                  disableElevation
                  className={classes.button}
                  onClick={() => handleZoomToFit(diagramInstance)}
                >
                  <MdZoomOutMap size="18px" />
                </Button>
              </Tooltip>
              <Tooltip title={formatMessage({ id: 'RESET_ZOOM' })}>
                <Button
                  disableElevation
                  className={classes.button}
                  onClick={() => handleResetZoom(diagramInstance)}
                >
                  <MdCenterFocusStrong size="18px" />
                </Button>
              </Tooltip>
              <Tooltip title={formatMessage({ id: 'ZOOM_OUT' })}>
                <Button
                  disableElevation
                  className={classes.button}
                  onClick={() => handleDecreaseZoom(diagramInstance)}
                >
                  <MdRemove size="18px" />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>

          {!isReadOnly && (
            <>
              <Grid item>
                <Tooltip title={formatMessage({ id: 'KEYMAP' })}>
                  <Button
                    variant="contained"
                    disableElevation
                    color="default"
                    size="small"
                    className={classes.keymapBtn}
                    onClick={openKeyboardShortcutsPanel}
                  >
                    <MdKeyboard size="18px" />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={formatMessage({ id: 'OVERVIEW' })} placement="bottom">
                  <Button
                    variant="contained"
                    disableElevation
                    color="default"
                    size="small"
                    className={classes.keymapBtn}
                    onClick={toggleOverviewDialog}
                  >
                    {isOverviewDialogExpanded ? (
                      <MdVisibility size="18px" />
                    ) : (
                      <MdVisibilityOff size="18px" />
                    )}
                  </Button>
                </Tooltip>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={isKeyboardShortcutsPanelOpen}
        onClose={closeKeyboardShortcutsPanel}
      >
        <KeyboardShortcuts />
      </Drawer>
    </>
  );
};
