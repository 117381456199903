/**
 * @file
 *
 * This file contains the component that displays the keyboard shortcuts
 */

import React from 'react';
import { makeStyles, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

const widthUnit = 36;
const useStyles = makeStyles(theme => ({
  container: {
    width: theme.spacing(widthUnit),
  },
  title: {
    margin: theme.spacing(2),
  },
}));

const isMac = typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;
const metaKey = isMac ? '⌘' : 'Ctrl';

const shortcuts = formatMessage => [
  {
    keys: [metaKey, 'C'],
    details: formatMessage({ id: 'COPY' }),
  },
  {
    keys: [metaKey, 'V'],
    details: formatMessage({ id: 'PASTE' }),
  },
  {
    keys: [metaKey, 'A'],
    details: formatMessage({ id: 'SELECT_ALL' }),
  },
  {
    keys: [metaKey, 'S'],
    details: formatMessage({ id: 'SAVE' }),
  },
  {
    keys: [metaKey, 'Z'],
    details: formatMessage({ id: 'UNDO' }),
  },
  {
    keys: [metaKey, 'Y'],
    details: formatMessage({ id: 'REDO' }),
  },
  {
    keys: ['←', '↑', '→', '↓'],
    details: formatMessage({ id: 'SCROLL_CANVAS' }),
    group: true,
  },
  {
    keys: ['+'],
    details: formatMessage({ id: 'ZOOM_IN' }),
  },
  {
    keys: ['-'],
    details: formatMessage({ id: 'ZOOM_OUT' }),
  },
  {
    keys: ['Shift', 'Z'],
    details: formatMessage({ id: 'PREV_SCALE' }),
  },
  {
    keys: ['Shift', 'X'],
    details: formatMessage({ id: 'RESET_ZOOM' }),
  },
  {
    keys: [metaKey, 'G'],
    details: formatMessage({ id: 'GROUP_TOP_LEVEL' }),
  },
  {
    keys: [metaKey, 'Shift', 'G'],
    details: formatMessage({ id: 'UNGROUP_TOP_LEVEL' }),
  },
  {
    keys: ['F2'],
    details: formatMessage({ id: 'EDIT_TEXT_SELECTED' }),
  },
];

export function KeyboardShortcuts() {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container} role="presentation">
      <Typography variant="h5" className={classes.title}>
        <FormattedMessage id="KEYBOARD_SHORTCUTS" />
      </Typography>
      <List>
        {shortcuts(formatMessage).map(({ keys, details, group }) => (
          <ListItem key={keys.join('::')}>
            <ListItemText
              primary={
                <Typography variant="h6">
                  {keys.map((key, index) => (
                    <React.Fragment key={key}>
                      <code>{key}</code>
                      {index + 1 !== keys.length && (group ? ' , ' : ' + ')}
                    </React.Fragment>
                  ))}
                </Typography>
              }
              secondary={details}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
