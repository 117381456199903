import React, { useCallback, useMemo } from 'react';
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  DialogContentText,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { BsArrowRight } from 'react-icons/bs';
import { FaLink } from 'react-icons/fa';
import { useConfirmationDialog } from 'material-ui-confirmation';
import { useIntl, FormattedMessage } from 'react-intl';

import { SystemAvatar } from '../SystemAvatar';
import { useNotifyError } from '../../hooks/useNotifyError';
import { getShortNameOfSystem } from '../../utils/helpers';
import { useSystems } from '../../data/systems';
import { useMapperTemplateStore } from '../../data/mapperTemplates';
import { MapperProjectsDialog, useMapperProjectIntegration } from './MapperProjectConnections';
import { useTenantState } from '../../data/user';

const useStyles = makeStyles(theme => ({
  systemCard: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    border: `solid 1px rgba(0,0,0, 0.3)`,
    minHeight: theme.spacing(23),
  },
  mapperTemplateName: {
    width: '100%',
  },
  mapperTemplateDesc: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  systemIconContainer: {
    width: theme.spacing(11),
    height: theme.spacing(9),
    border: theme.borders[0],
    borderRadius: theme.spacing(0.6),
    padding: theme.spacing(0.5),
  },
  systemIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  mapperProjectContainer: {
    marginTop: theme.spacing(1),
    width: '100%',
    border: theme.borders[0],
    borderRadius: theme.spacing(0.6),
    padding: theme.spacing(0.5),
  },
  mapperProjectName: {
    width: '80%',
  },
  mapperProjectVersion: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(2.5),
    padding: theme.spacing(0.2),
    borderRadius: theme.spacing(0.5),
  },
  mapperProjectDesc: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  chipBorder: {
    border: `solid 1px ${theme.palette.primary.main}`,
  },
  titleFont: {
    fontSize: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  systemName: {
    fontSize: theme.spacing(1.2),
    color: theme.palette.grey[600],
  },
  colorArrow: {
    color: theme.palette.primary.main,
  },
  link: {
    width: '100%',
    marginTop: theme.spacing(1.5),
  },
}));

export function MapperTemplateConnections(props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const {
    isDialogOpen,
    closeDialog,
    fromEntity,
    linkedMapperProjectId,
    toEntity,
    handleMapperProjectIntegration,
    mapperProjects,
    isMapperProjectsLoading,
  } = useMapperProjectIntegration({
    diagram: props.diagram,
    selectedData: props.selectedData,
    formatMessage,
  });

  const mapperTemplatesList = useMapperTemplateStore(state => state.mapperTemplates);

  const tenant = useTenantState();
  const canReadMapperProjects = tenant.permissions.some(
    permission => permission.permission_code === 'read_mapper'
  );

  const { getConfirmation } = useConfirmationDialog();

  const { systems: systemsData, error: systemsError, isFetching: isSystemsFetching } = useSystems();
  useNotifyError({ error: systemsError });

  const selectedMapperTemplate = useMemo(
    () =>
      mapperTemplatesList.find(
        mapperTemplate =>
          mapperTemplate.mapperTemplateCode === props.selectedData.mapperTemplateCode
      ),
    [mapperTemplatesList, props.selectedData.mapperTemplateCode]
  );

  const handleDelete = useCallback(() => {
    getConfirmation({
      title: formatMessage({ id: 'UNLINK_MAPPER_TEMPLATE' }),
      body: (
        <DialogContentText>
          <FormattedMessage id="UNLINK_MAPPER_TEMPLATE_CONFIRMATION" />
        </DialogContentText>
      ),

      onAccept: () => {
        /**
         * @type {{ diagram: go.Diagram }}
         */
        props.diagram.commandHandler.deleteSelection();

        props.diagram.focus();
      },
    });
  }, [props.diagram, getConfirmation, formatMessage]);

  return (
    <>
      <Grid container>
        <Grid item>
          <Typography color="textSecondary" variant="body2">
            <FormattedMessage id="LINKED_MAPPER_TEMPLATE" />
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.systemCard}
        >
          {isSystemsFetching ? (
            <CircularProgress />
          ) : (
            <>
              <Grid item container direction="column">
                <Grid item className={classes.mapperTemplateName}>
                  <Typography color="textPrimary" variant="body1">
                    {selectedMapperTemplate.name}
                  </Typography>
                </Grid>

                <Grid item className={classes.mapperTemplateDesc} container>
                  <Typography variant="body1">
                    {selectedMapperTemplate.description || ''}
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                  className={classes.topMargin}
                >
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    className={classes.systemIconContainer}
                  >
                    <Grid item className={classes.systemIcon}>
                      <SystemAvatar system={selectedMapperTemplate.srcSystem} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.systemName}>
                        {getShortNameOfSystem(systemsData, selectedMapperTemplate.srcSystem)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <BsArrowRight className={classes.colorArrow} size="23px" />
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    className={classes.systemIconContainer}
                  >
                    <Grid item className={classes.systemIcon}>
                      <SystemAvatar system={selectedMapperTemplate.destSystem} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.systemName}>
                        {getShortNameOfSystem(systemsData, selectedMapperTemplate.destSystem)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justifyContent="flex-end" className={classes.topMargin}>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    startIcon={<FaLink size="11px" />}
                    size="small"
                    onClick={handleDelete}
                  >
                    <FormattedMessage id="UNLINK" />
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Tooltip title={!canReadMapperProjects ? 'Mapper Projects: Permission Required' : ''}>
          <span style={{ width: '100%' }}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className={classes.link}
              disabled={!canReadMapperProjects}
              onClick={handleMapperProjectIntegration}
            >
              <FormattedMessage id="LINK_MAPPER_PROJECT" />
            </Button>
          </span>
        </Tooltip>
      </Grid>
      <MapperProjectsDialog
        onClose={closeDialog}
        open={isDialogOpen}
        loading={isMapperProjectsLoading}
        fromEntity={fromEntity}
        toEntity={toEntity}
        mapperProjects={mapperProjects}
        linkedMapperProjectId={linkedMapperProjectId}
        diagram={props.diagram}
        selectedData={props.selectedData}
      />
    </>
  );
}
