import { createStore } from '@halka/state';
import { useCallback } from 'react';

import english from '../i18n/i18n.json';
import german from '../i18n/i18n_de.json';

export const Language = {
  ENGLISH: 'en',
  GERMAN: 'de',
};

/**
 * Hook to get the current language.
 */
export const useLanguage = createStore(() => {
  const lang = localStorage.getItem('integrtr-dt-lang');
  if ([Language.ENGLISH, Language.GERMAN].includes(lang)) {
    return lang;
  }

  return Language.ENGLISH;
});

/**
 * Hook to set language.
 */
export function useSetLanguage() {
  return useCallback(language => {
    useLanguage.set(language);
    localStorage.setItem('integrtr-dt-lang', language);
  }, []);
}

/**
 * Hook to get the current language's messages.
 */
export function useLanguageMessages() {
  const lang = useLanguage();

  switch (lang) {
    case Language.GERMAN:
      return { ...english, ...german };
    case Language.ENGLISH:
    default:
      return english;
  }
}
