/**
 * @file
 *
 * This component displays the preview images of project in the Home Screen
 */
import React from 'react';
import ProgressiveImage from 'react-progressive-image';
import clsx from 'clsx';

import { getAssetURL, digitalTransformerAssetBase } from '../utils/service';
import { useImageFetch } from '../hooks/useImageFetch';

const placeholderImage = getAssetURL(digitalTransformerAssetBase, 'project-background.png');

export function ThumbnailImage({ className, projectId, ...props }) {
  const src = useImageFetch({ imagePath: `${projectId}/cover`, placeholderImage });

  return (
    <ProgressiveImage src={src} placeholder={placeholderImage}>
      {(_src, loading) => (
        <img
          className={clsx(className, 'MuiCardMedia-media', 'MuiCardMedia-img')}
          style={{ opacity: loading ? 0.5 : 1 }}
          {...props}
          src={_src}
          alt="Project Thumbnail"
        />
      )}
    </ProgressiveImage>
  );
}
