/**
 * @file
 *
 * This component renders the common actions in the Selection Panel like Copy and Delete
 */

import React from 'react';
import { Grid, IconButton, makeStyles, Divider, withStyles, Tooltip } from '@material-ui/core';
import { MdContentCopy, MdDelete, MdFlipToBack, MdFlipToFront } from 'react-icons/md';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    color: theme.palette.common.white,
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 0.5),
  },
}));

export function CommonSection(props) {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Grid container justifyContent="center">
          <CustomTooltip title="Copy" placement="bottom">
            <div>
              <IconButton
                className={classes.button}
                onClick={props.handleCopy}
                disabled={props.isChildNode || !props.copyEnabled}
              >
                <MdContentCopy />
              </IconButton>
            </div>
          </CustomTooltip>
          <CustomTooltip title="Delete" placement="bottom">
            <div>
              <IconButton className={classes.button} onClick={props.handleDelete}>
                <MdDelete />
              </IconButton>
            </div>
          </CustomTooltip>

          <CustomTooltip title="Send Back" placement="bottom">
            <div>
              <IconButton
                className={classes.button}
                onClick={props.handlePushToBack}
                disabled={props.isChildNode || props.isMultiSelect}
              >
                <MdFlipToBack />
              </IconButton>
            </div>
          </CustomTooltip>
          <CustomTooltip title="Bring to Front" placement="bottom">
            <div>
              <IconButton
                className={classes.button}
                onClick={props.handlePullToFront}
                disabled={props.isChildNode || props.isMultiSelect}
              >
                <MdFlipToFront />
              </IconButton>
            </div>
          </CustomTooltip>
        </Grid>
        <Divider />
      </Grid>
    </>
  );
}
