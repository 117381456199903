/**
 * @file
 *
 * This file includes all the configuration related to the GoJS setup
 */

import * as go from 'gojs';

import {
  guidelineColor,
  uniqueKeyGenerator,
  selectionAdornmentTemplate,
  showAllPorts,
  primaryNodeCategories,
  entityChildNodeCategories,
} from './helpers';
import {
  MODEL_NODES,
  DEFAULTS,
  GROUP_NODES,
  NODE_MODEL_ATTRS,
  LINK_MODEL_ATTRS,
  STROKE_TYPES,
  MODEL_LINKS,
} from '../utils/constants';
import {
  rectangleNodeTemplate,
  circleNodeTemplate,
  triangleNodeTemplate,
  squareNodeTemplate,
  roundedRectangleNodeTemplate,
  ellipseNodeTemplate,
  cloudNodeTemplate,
  hexagonNodeTemplate,
  cylinderNodeTemplate,
  databaseNodeTemplate,
  actorNodeTemplate,
  clockNodeTemplate,
} from './shapeModels';
import { GuidedDraggingTool } from './extensions/guidedDraggingTool';
import { createCustomTextEditorBlock } from './extensions/customTextBlockEditor';
import {
  finishDrop,
  groupTemplate,
  basicLinkValidation,
  linkTemplate,
  textNodeTemplate,
  watermarkTemplate,
  mapperTemplateLinkTemplate,
} from './baseModels';
import { relayoutDiagram } from './extensions/UniformColumnLayout';
import { OrthogonalSnapLinkReshapingTool } from './extensions/OrthogonaSnaplLinkReshapingTool';
import { CustomCommandHandler } from './extensions/CustomCommandHandler';

const $ = go.GraphObject.make;

export function initDiagram() {
  const diagram = $(go.Diagram, {
    grid: $(go.Panel, 'Grid', { gridCellSize: new go.Size(1, 1) }),
    nodeSelectionAdornmentTemplate: selectionAdornmentTemplate,
    groupSelectionAdornmentTemplate: selectionAdornmentTemplate,
    mouseDrop: event => finishDrop(event, null),
    scrollMargin: new go.Margin(1000),
    'undoManager.isEnabled': true,
    'animationManager.isEnabled': false,
    'linkingTool.portGravity': 1,
    linkReshapingTool: new OrthogonalSnapLinkReshapingTool(),
    'textEditingTool.defaultTextEditor': createCustomTextEditorBlock(),
    draggingTool: new GuidedDraggingTool(),
    'draggingTool.horizontalGuidelineColor': guidelineColor,
    'draggingTool.verticalGuidelineColor': guidelineColor,
    'draggingTool.centerGuidelineColor': guidelineColor,
    'draggingTool.guidelineWidth': 1,
    'toolManager.mouseWheelBehavior': go.ToolManager.WheelZoom,
    'clickCreatingTool.archetypeNodeData': {
      [NODE_MODEL_ATTRS.TEXT]: DEFAULTS.TEXT,
      [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.COLOR,
      [NODE_MODEL_ATTRS.FONT_FAMILY]: DEFAULTS.FONT_PROPERTIES.FAMILY,
      [NODE_MODEL_ATTRS.FONT_SIZE]: DEFAULTS.FONT_PROPERTIES.SIZE,
      [NODE_MODEL_ATTRS.FONT_WEIGHT]: DEFAULTS.FONT_PROPERTIES.WEIGHT,
    },
    SelectionCopied: relayoutDiagram,
    SelectionMoved: relayoutDiagram,
    PartResized: relayoutDiagram,
    model: $(go.GraphLinksModel, {
      // key property for links
      linkKeyProperty: 'key',
      linkFromPortIdProperty: 'fromPort',
      linkToPortIdProperty: 'toPort',
      // to force generation of unique keys when diagrams are dragged into the editor from palette
      copyNodeDataFunction: data => Object.assign({}, data),
      makeUniqueKeyFunction: uniqueKeyGenerator('node'),
      // how to create keys for links
      makeUniqueLinkKeyFunction: uniqueKeyGenerator('link'),
    }),
  });

  diagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelScroll;

  diagram.toolManager.hoverDelay = 200;
  diagram.toolManager.toolTipDuration = NaN;

  diagram.toolManager.linkingTool.doActivate = () => {
    showAllPorts(diagram, true);
    go.LinkingTool.prototype.doActivate.call(diagram.toolManager.linkingTool);
  };
  diagram.toolManager.relinkingTool.doActivate = () => {
    showAllPorts(diagram, true);
    go.RelinkingTool.prototype.doActivate.call(diagram.toolManager.relinkingTool);
  };
  diagram.toolManager.linkingTool.doDeactivate = () => {
    showAllPorts(diagram, false);
    go.LinkingTool.prototype.doDeactivate.call(diagram.toolManager.linkingTool);
  };
  diagram.toolManager.relinkingTool.doDeactivate = () => {
    showAllPorts(diagram, false);
    go.RelinkingTool.prototype.doDeactivate.call(diagram.toolManager.relinkingTool);
  };

  diagram.toolManager.linkingTool.linkValidation = basicLinkValidation;
  diagram.toolManager.relinkingTool.linkValidation = basicLinkValidation;
  diagram.toolManager.linkingTool.archetypeLinkData = {
    [LINK_MODEL_ATTRS.TEXT]: '',
    [LINK_MODEL_ATTRS.LABEL]: '',
    [LINK_MODEL_ATTRS.STROKE_TYPE]: STROKE_TYPES.SOLID.KEY,
    [LINK_MODEL_ATTRS.STROKE_WIDTH]: DEFAULTS.LINK.STROKE_WIDTH,
    [LINK_MODEL_ATTRS.COLOR]: DEFAULTS.LINK_COLOR,
    [LINK_MODEL_ATTRS.MAPPER_PROJECT_ID]: null,
    [LINK_MODEL_ATTRS.IS_VISIBLE]: true,
  };

  diagram.toolManager.rotatingTool.handleAngle = 270;
  diagram.toolManager.rotatingTool.snapAngleMultiple = 1.0;
  diagram.toolManager.rotatingTool.snapAngleEpsilon = 0.5;

  diagram.toolManager.resizingTool.isGridSnapEnabled = true;
  diagram.toolManager.draggingTool.isGridSnapEnabled = true;

  diagram.toolManager.panningTool.isEnabled = false;

  diagram.toolManager.dragSelectingTool.delay = 0;
  diagram.toolManager.dragSelectingTool.box = $(
    go.Part,
    { layerName: 'Tool' },
    $(go.Shape, 'Rectangle', {
      name: 'SHAPE',
      fill: DEFAULTS.DRAG_SELECT.FILL,
      stroke: DEFAULTS.DRAG_SELECT.STROKE,
      strokeWidth: DEFAULTS.DRAG_SELECT.STROKE_WIDTH,
      strokeDashArray: [4, 4],
      opacity: 0.3,
    })
  );

  diagram.nodeTemplate = textNodeTemplate;
  diagram.nodeTemplateMap.add(MODEL_NODES.SQUARE, squareNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.RECTANGLE, rectangleNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.ROUNDED_RECTANGLE, roundedRectangleNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.CIRCLE, circleNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.ELLIPSE, ellipseNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.TRIANGLE, triangleNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.CLOUD, cloudNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.HEXAGON, hexagonNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.CYLINDER, cylinderNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.DATABASE, databaseNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.ACTOR, actorNodeTemplate);
  diagram.nodeTemplateMap.add(MODEL_NODES.CLOCK, clockNodeTemplate);

  diagram.nodeTemplateMap.add(MODEL_NODES.WATERMARK, watermarkTemplate);

  diagram.linkTemplate = linkTemplate;
  diagram.linkTemplateMap.add(MODEL_LINKS.MAPPER_TEMPLATE, mapperTemplateLinkTemplate);

  diagram.groupTemplate = groupTemplate;
  diagram.groupTemplate.ungroupable = true;

  diagram.commandHandler = new CustomCommandHandler();

  diagram.commandHandler.archetypeGroupData = {
    [NODE_MODEL_ATTRS.IS_GROUP]: true,
    [NODE_MODEL_ATTRS.COLOR]: DEFAULTS.GROUP_COLOR,
    [NODE_MODEL_ATTRS.TITLE]: 'Group',
  };

  /**
   * overriding the method to block the movement of non primary nodes
   *
   * @param {go.Set<go.Part>} selection
   *
   * @param {go.Set<go.Part>}
   */
  diagram.commandHandler.whitelistSelectionBeforeKeyMove = function(selection) {
    return selection.filter(part =>
      primaryNodeCategories.includes(part.data[NODE_MODEL_ATTRS.CATEGORY])
    );
  };

  diagram.commandHandler.doKeyDown = function() {
    const event = diagram.lastInput;

    const selectedObjects = diagram.selection.toArray();

    if (selectedObjects.length) {
      diagram.commandHandler.arrowKeyBehavior = CustomCommandHandler.arrowKeyBehaviorTypes.move;
    } else {
      diagram.commandHandler.arrowKeyBehavior = CustomCommandHandler.arrowKeyBehaviorTypes.scroll;
    }

    const mod = event.control || event.meta;

    if (event.key === 'G' && mod) {
      for (let selectedObject of selectedObjects) {
        if (entityChildNodeCategories.includes(selectedObject.data[NODE_MODEL_ATTRS.CATEGORY])) {
          return;
        }
      }

      CustomCommandHandler.prototype.doKeyDown.call(diagram.commandHandler);
    } else if (event.key === 'X' && event.shift) {
      CustomCommandHandler.prototype.resetZoom.call(diagram.commandHandler);
    } else {
      CustomCommandHandler.prototype.doKeyDown.call(diagram.commandHandler);
    }
  };

  diagram.commandHandler.pasteSelection = function(position) {
    CustomCommandHandler.prototype.pasteSelection.call(diagram.commandHandler, position);

    const updatedSelection = diagram.selection
      .toArray()
      .filter(part => primaryNodeCategories.includes(part.data[NODE_MODEL_ATTRS.CATEGORY]));

    diagram.selectCollection(updatedSelection);
  };

  diagram.commandHandler.copyToClipboard = function(collection) {
    const iterator = collection.iterator;

    // filter the parts that can be copied
    const toBeCopiedParts = iterator.filter(part => {
      const groupKey = part.data[NODE_MODEL_ATTRS.GROUP];
      const category = part.data[NODE_MODEL_ATTRS.CATEGORY];

      // if the part is a field node or a spacer node
      // then check if the field group (if any) and the entity group it is part of is also copied
      if (category === MODEL_NODES.FIELD || category === MODEL_NODES.SPACER) {
        // get the immediate parent group
        const containingGroup = diagram.findNodeForKey(groupKey);

        // initialize some defaults to work with
        let containingFieldGroupValidity = true;
        let entityGroupKey = containingGroup;

        // if the containing group is fields group
        if (containingGroup.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP) {
          // then check if the field group is also copied
          containingFieldGroupValidity = iterator.any(
            p =>
              p.data[NODE_MODEL_ATTRS.KEY] === groupKey &&
              p.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.FIELDS_GROUP
          );

          // and also update the containing entity group since the immediate parent group was not an entity group
          entityGroupKey = diagram.findNodeForKey(containingGroup.data[NODE_MODEL_ATTRS.GROUP]);
        }

        // check if the entity parent group is also copied
        const containingEntityGroupValidity = iterator.any(
          p =>
            p.data[NODE_MODEL_ATTRS.KEY] === entityGroupKey &&
            p.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
        );

        return containingFieldGroupValidity && containingEntityGroupValidity;
      }

      // if the part is a field group node
      // then the parent entity group node must be also be copied
      if (category === GROUP_NODES.FIELDS_GROUP) {
        return iterator.any(
          p =>
            p.data[NODE_MODEL_ATTRS.KEY] === groupKey &&
            p.data[NODE_MODEL_ATTRS.CATEGORY] === GROUP_NODES.ENTITY_GROUP
        );
      }

      return true;
    });

    if (toBeCopiedParts.count) {
      CustomCommandHandler.prototype.copyToClipboard.call(diagram.commandHandler, toBeCopiedParts);
    } else {
      CustomCommandHandler.prototype.copyToClipboard.call(diagram.commandHandler, null);
    }
  };

  return diagram;
}

let overviewSingleton = null;
export const initOverview = () => {
  if (overviewSingleton) {
    return overviewSingleton;
  }

  overviewSingleton = $(go.Overview, {
    box: $(
      go.Node,
      'Auto',
      $(go.Shape, 'Rectangle', {
        stroke: DEFAULTS.OVERVIEW.STROKE,
        fill: DEFAULTS.OVERVIEW.FILL,
        opacity: 0.4,
      })
    ),
  });

  return overviewSingleton;
};
