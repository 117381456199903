/**
 * @file
 *
 * This component is an internal text field component that can be used to
 * create more advanced edit field component like SizeEdit and TextFieldEdit, etc.
 */

import React from 'react';
import { TextField, Grid, Tooltip, withStyles } from '@material-ui/core';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.error.main,
  },
  arrow: { color: theme.palette.error.main },
}))(Tooltip);

export function InnerTextField(props) {
  return (
    <Grid container alignItems="flex-start" justifyContent="space-between">
      <CustomTooltip arrow title={props.error || ''}>
        <TextField
          style={{ width: props.width }}
          InputProps={{
            style: {
              height: '35px',
            },
          }}
          variant="outlined"
          name={props.attr}
          type="text"
          margin="dense"
          autoComplete=""
          value={props.value}
          onChange={props.onChange}
          error={Boolean(props.error)}
          multiline={Boolean(props.multiline)}
        />
      </CustomTooltip>
    </Grid>
  );
}
