/**
 * @file
 *
 * Contains systems related hooks which can fetch systems from the API.
 */

import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { systemsApiBase } from '../utils/service';
import { getShortName } from '../utils/helpers';

/**
 * Get the systems.
 */
export function useSystems() {
  const { data, ...rest } = useQuery(`${systemsApiBase}/system`);

  const systems = useMemo(
    () =>
      (data ?? []).map(system => ({
        code: system.system_code,
        name: system.system_name,
        shortName: getShortName(system),
        description: system.description,
        updatedAt: system.updated_at,
        systemConfigTypes: system.system_config_types,
      })),
    [data]
  );

  return {
    systems,
    ...rest,
  };
}
