/**
 * @file
 *
 * This component is used to render form to create new template sheets
 */
import React, { useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormikTextField } from './FormikFields';
import { useCreateTemplateMutation } from '../data/projects';

const id = 'create-template-form-dialog';

export const CreateTemplateFormDialog = ({
  open,
  onClose,
  projectId,
  sheetKey,
  sheetName,
  diagramInstance,
}) => {
  const { formatMessage } = useIntl();

  const createTemplateMutation = useCreateTemplateMutation();

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(1)
      .required()
      .label(formatMessage({ id: 'NAME' })),
    description: yup.string().label(formatMessage({ id: 'DESCRIPTION' })),
  });

  const handleSubmit = useCallback(
    async values => {
      await createTemplateMutation.mutateAsync({ sheetKey, projectId, values, diagramInstance });
      onClose();
    },
    [createTemplateMutation, diagramInstance, onClose, projectId, sheetKey]
  );

  return (
    <Dialog open={open} aria-labelledby={id}>
      <DialogTitle>
        <FormattedMessage id="CREATE_TEMPLATE_SHEET" />
      </DialogTitle>
      <Formik
        validationSchema={schema}
        initialValues={{ name: `[Template] ${sheetName}`, description: '' }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <FormikTextField
                name="name"
                label={formatMessage({ id: 'TEMPLATE_NAME' })}
                autoFocus
              />
              <FormikTextField name="description" label={formatMessage({ id: 'DESCRIPTION' })} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage id="CANCEL" />
              </Button>
              <Button
                type="submit"
                disabled={createTemplateMutation.isLoading}
                color="primary"
                variant="contained"
                startIcon={createTemplateMutation.isLoading ? <CircularProgress size={15} /> : ''}
              >
                {createTemplateMutation.isLoading ? (
                  <FormattedMessage id="CREATING" />
                ) : (
                  <FormattedMessage id="CREATE" />
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
