/**
 * @file
 *
 * The file handles the queryClient configuration for react-query
 */
import { QueryClient } from 'react-query';

import { getServiceInstance } from './service';

const defaultFetcher = ({ queryKey }) => {
  const [endpoint] = queryKey;

  return getServiceInstance().get(endpoint);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultFetcher,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
      cacheTime: 1000 * 60 * 60 * 1, // 1 hour
      staleTime: Infinity,
    },
  },
});
